import ms from 'ms';

import { parseDate } from '../helpers';

export const useLocalSavedDates = (dateToCheck: Date) => {
  const LOCAL_STORAGE_KEY = 'lsd';

  const lastSavedDate =
    localStorage.getItem(LOCAL_STORAGE_KEY) ||
    parseDate(new Date('01/01/1970'));

  return {
    isPastDate: parseInt(lastSavedDate) + ms('10m') >= dateToCheck.getTime(),
    saveLocalDate: () =>
      localStorage.setItem(LOCAL_STORAGE_KEY, parseDate(new Date())),
  };
};
