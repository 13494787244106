import { ACTION_TYPE } from '../actions';
import { startOfMonth, subDays, differenceInDays, isBefore } from 'date-fns';
import { DATE_RANGE, DateRangeFilterTypes } from '../actions/dateRangerFilter';

const curDate = new Date();
const startOfMonthDate = startOfMonth(curDate);
const referenceStartDate = subDays(startOfMonthDate, differenceInDays(curDate, startOfMonthDate));

export interface DateRangeFilterState {
  dateRange: DATE_RANGE;
  startDate: Date;
  endDate: Date;
  referenceStartDate: Date;
  referenceEndDate: Date;
  minStartDatesByAccount: {
    [account: string]: Date;
  };
}

export const initialState: DateRangeFilterState = {
  dateRange: DATE_RANGE.MONTH_TO_DATE,
  startDate: startOfMonthDate,
  endDate: curDate,
  referenceStartDate: referenceStartDate,
  referenceEndDate: startOfMonthDate,
  minStartDatesByAccount: {},
};

const dateRangeFilter = (state = initialState, action: DateRangeFilterTypes) => {
  switch (action.type) {
    case ACTION_TYPE.SET_DATE_RANGE_FILTER:
      const { startDate, paramAccountId } = action.payload;
      const minStartDate = state.minStartDatesByAccount[paramAccountId];

      return {
        ...state,
        ...action.payload,
        startDate: minStartDate && isBefore(startDate, new Date(minStartDate)) ? new Date(minStartDate) : startDate,
      };
    case ACTION_TYPE.SET_USER:
      const { mainAccount, createdAt } = action.payload || {};

      if (mainAccount) {
        const dateCreatedAt = new Date(createdAt);
        dateCreatedAt.setHours(0, 0, 0, 0);

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // because of src/components/header/DateRangePicker.tsx line 93
        // date range for FREE users should be 'last three days'
        state.minStartDatesByAccount[mainAccount] =
          differenceInDays(new Date(), dateCreatedAt) < 3 ? subDays(today, 3) : dateCreatedAt;
      }

      return {
        ...state,
        minStartDatesByAccount: {
          ...state.minStartDatesByAccount,
        },
      };
    case ACTION_TYPE.SET_SHARED_CONNECTIONS:
      const createdDatesByAccount: [string, Date][] = [];

      (action.payload || []).forEach(item => {
        if (item?.fromUser?.mainAccount?._id) {
          const { _id, createdAt } = item.fromUser.mainAccount;
          const dateCreatedAt = new Date(createdAt);
          dateCreatedAt.setHours(0, 0, 0, 0);
          createdDatesByAccount.push([_id, dateCreatedAt]);
        }
      });

      const minStartDatesByAccount = { ...state.minStartDatesByAccount, ...Object.fromEntries(createdDatesByAccount) };

      return { ...state, minStartDatesByAccount };

    case ACTION_TYPE.SET_GROUP_CONNECTIONS:
      const { payload } = action;
      const createdDatedByGroups = (payload?.groupConnections || []).reduce((general, item) => {
        if (item?.user?.mainAccount?._id && item?.group?.id) {
          const { id: groupId } = item.group;
          const { createdAt, _id: userId } = item.user.mainAccount;

          let setIsDate: Date;

          if (payload.user && new Date(payload.user.createdAt).getTime() < new Date(createdAt).getTime()) {
            setIsDate = payload.user.createdAt;
          } else {
            setIsDate = createdAt;
          }

          const dataCreatedAt = new Date(setIsDate);
          dataCreatedAt.setHours(0, 0, 0, 0);

          general[userId] = dataCreatedAt;

          if (general[groupId]) {
            general[groupId] = general[groupId].getTime() > dataCreatedAt.getTime() ? dataCreatedAt : general[groupId];
          } else {
            general[groupId] = createdAt;
          }
        }

        return general;
      }, {} as { [x: string]: Date });

      return { ...state, minStartDatesByAccount: { ...state.minStartDatesByAccount, ...createdDatedByGroups } };
    default:
      return state;
  }
};

export default dateRangeFilter;
