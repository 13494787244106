import { useMemo } from 'react';
import { RootState } from '../store/reducers';
import { useSelector } from 'react-redux';

interface Props {
  isGroup: boolean;
}

const useMaximumStartDate = ({ isGroup }: Props) => {
  const { selected, selectedGroup } = useSelector((state: RootState) => state.linkedAccounts);
  const { startDate, minStartDatesByAccount } = useSelector((state: RootState) => state.dateRangeFilter);

  const isChosenMaxStartDate = useMemo(
    () =>
      isGroup
        ? selectedGroup &&
          startDate &&
          minStartDatesByAccount &&
          minStartDatesByAccount[selectedGroup.id].getTime() === startDate.getTime()
        : selected &&
          startDate &&
          minStartDatesByAccount &&
          minStartDatesByAccount[selected._id].getTime() === startDate.getTime(),
    [isGroup, selected, selectedGroup, startDate, minStartDatesByAccount]
  );

  return { isChosenMaxStartDate };
};

export { useMaximumStartDate };
