import { parse } from 'qs';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParam = (parameterName: string) => {
  const location = useLocation();
  return useMemo(() => {
    const parsed = parse(location.search, { ignoreQueryPrefix: true });
    return parsed[parameterName] ?? null;
  }, [location.search, parameterName]);
};
