import classNames from 'classnames';
import React from 'react';
import * as Styled from './LoadingBar.styles';

interface LoadingBarProps {
  active: boolean;
  className?: string;
}

const LoadingBar: React.FC<LoadingBarProps> = ({ active, className }) => {
  return (
    <Styled.Bar className={classNames('progress is-primary', className)} value={active ? undefined : '0'} max='100' />
  );
};
export default LoadingBar;
