import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../../styles/components/ProfileOptimizationChecklist.scss';
import {
  updateProfileOptimizationChecklistActionStatus,
  getProfileOptimizationsChecklist,
  ProfileOptimizationCategory,
} from '../../services/profile-service';
import { useParams } from 'react-router-dom';
import { colors } from '../../styles/variables';
import { useQuery } from 'react-query';
import StyledTooltip from '../StyledTooltip';
import { HelpCircle, ChevronDown, ChevronUp } from '@styled-icons/ionicons-solid';
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  RadarChart,
  Radar as ReRadar,
  ResponsiveContainer,
  RadialBarChart,
  RadialBar,
} from 'recharts';
import { initialCategories } from '../../data/profile-optimizations';
import { useDemoUser } from '../../hooks/useDemoUser';
import classNames from 'classnames';

const ProfileOptimizationChecklist = () => {
  const isDemoUser = useDemoUser();

  const { accountId } = useParams<{ accountId: string }>();
  const [categories, setCategories] = useState<ProfileOptimizationCategory[]>(initialCategories);
  const [circleColor, setCircleColor] = useState(colors.green);

  const containerRef = useRef<HTMLDivElement>(null);
  const actionRef = useRef<HTMLDivElement>(null);
  const [inRow, setInRow] = useState<number>(initialCategories.length);
  const [isOpen, setIsOpen] = useState(true);
  const [fullHeight, setFullHeight] = useState<number>(0);

  useQuery([accountId, 'getProfileOptimizationsChecklist'], getProfileOptimizationsChecklist, {
    onSuccess: checkedCategories => {
      setCategories(current => {
        const copy = [...current];
        current.forEach(category => {
          let checked = 0;
          category.actions.forEach(action => {
            action.checked = checkedCategories.includes(action.id);
            checked += +action.checked; // true = 1, false = 0
          });
          if (category.actions.length) {
            category.progress = checked / category.actions.length;
          }
        });
        return copy;
      });
    },
  });

  useEffect(() => {
    let amountActions = 0,
      amountChecked = 0;
    categories.forEach(category => {
      category.actions.forEach(action => {
        amountActions++;
        amountChecked += +action.checked;
      });
    });
    let progress = 0;
    if (amountActions > 0) {
      progress = amountChecked / amountActions;
    }

    const chartData = categories.map(cat => ({ category: cat.name, value: Math.round(cat.progress * 100) }));

    setRechartDist(chartData);
    setRechartCircle([{ value: Math.round(-progress * 100), max: 100 }]);
  }, [categories]);

  const openHandler = useCallback(
    (toOpen: boolean) => {
      if (toOpen && containerRef.current) {
        setInRow(initialCategories.length);
        setIsOpen(true);
        return (containerRef.current.style.height = `${fullHeight}px`);
      }

      if (containerRef.current && actionRef.current) {
        setInRow(Math.trunc(containerRef.current.clientWidth / actionRef.current.clientWidth));
        containerRef.current.style.height = `${actionRef.current.clientHeight}px`;
        setIsOpen(false);
      }
    },
    [fullHeight]
  );

  useEffect(() => {
    if (inRow === initialCategories.length && !fullHeight) {
      setFullHeight(containerRef?.current?.clientHeight || 0);
    }
  }, [inRow, fullHeight]);

  useEffect(() => {
    openHandler(false);
  }, [openHandler]);

  const handleOnCheckboxChange = (actionId: string, newState: boolean) => {
    if (!accountId) {
      return;
    }
    // Update local
    setCategories(current => {
      const copy = [...current];
      current.forEach(category => {
        let checked = 0;
        category.actions.forEach(action => {
          if (action.id === actionId) {
            action.checked = newState;
          }
          checked += +action.checked; // true = 1, false = 0
        });
        if (category.actions.length) {
          category.progress = checked / category.actions.length;
        } else {
          category.progress = 0;
        }
      });
      return copy;
    });
    // Update on server
    updateProfileOptimizationChecklistActionStatus(accountId, actionId, newState).catch(console.error); // TODO proper error handling
  };

  const [rechartDist, setRechartDist] = useState<{ category: string; value: number }[]>();

  const [rechartCircle, setRechartCircle] = useState<[{ value: number; max: number }]>();

  useEffect(() => {
    if (!rechartCircle) return;

    switch (Math.trunc((-rechartCircle[0]?.value - 1) / 25)) {
      case 0:
        setCircleColor(colors.green);
        break;
      case 1:
        setCircleColor(colors.yellow);
        break;
      case 2:
        setCircleColor(colors.lightOrange);
        break;
      case 3:
        setCircleColor(colors.orange);
    }
  }, [rechartCircle]);

  return (
    <>
      <div className={`ProfileOptimizationChecklist ${isOpen && 'no-opacity'}`} ref={containerRef}>
        <div className='categories grid-slider'>
          {categories.map((category, i) => (
            <div
              className={`category ${category.type}`}
              style={{ opacity: i >= inRow ? 0 : 1 }}
              key={category.type}
              ref={actionRef}>
              <div className='top'>
                <span className='name'>{category.name}</span>
                <div
                  className='button is-white is-rounded is-help-button'
                  data-tip
                  data-for={`styled-tooltip-profile-optimization-${category.type}`}>
                  <HelpCircle />
                </div>
                <StyledTooltip id={`styled-tooltip-profile-optimization-${category.type}`}>
                  {category.helpText}
                </StyledTooltip>
              </div>
              <div className='progress'>
                <div className='outer-bar'>
                  <div className='fill' style={{ width: `${category.progress * 100}%` }}></div>
                </div>
              </div>
              <div className='actions'>
                {category.actions.map(action => (
                  <div className='action' key={action.id}>
                    <label htmlFor={`profile-optmization-${action.id}`} className='container'>
                      {action.name}
                      <input
                        id={`profile-optmization-${action.id}`}
                        type='checkbox'
                        checked={action.checked}
                        onChange={e => handleOnCheckboxChange(action.id, e.currentTarget.checked)}
                        name={action.id}
                        disabled={isDemoUser}
                        className={classNames({ 'is-disabled': isDemoUser })}
                      />
                      <span className='checkmark' />
                      <div
                        className='button is-white is-rounded is-help-button '
                        data-tip
                        data-for={`styled-tooltip-profile-optimization-${action.id}`}>
                        <HelpCircle size='1rem' />
                      </div>
                      <StyledTooltip
                        id={`styled-tooltip-profile-optimization-${action.id}`}
                        delayHide={250}
                        clickable={true}>
                        {action.helpText}
                      </StyledTooltip>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
          {/*</Carousel>*/}
        </div>
        <div className='charts' style={{ opacity: isOpen ? 1 : 0 }}>
          <div className='score'>
            <div className='title'>Overall score</div>
            <div className='chart-wrapper'>
              {rechartCircle && (
                <ResponsiveContainer width='100%' height={300}>
                  <RadialBarChart
                    innerRadius={100}
                    outerRadius={300}
                    barSize={25}
                    startAngle={90}
                    endAngle={450}
                    data={rechartCircle}>
                    <PolarAngleAxis type='number' dataKey='max' tick={false} />
                    <RadialBar dataKey='value' fill={circleColor} background />
                    <text x='50%' y='50%' textAnchor='middle'>
                      {-rechartCircle[0].value} of 100
                    </text>
                  </RadialBarChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
          <div className='distribution'>
            <div className='title'>Score per category</div>
            <ResponsiveContainer width='100%' height={400}>
              <RadarChart data={rechartDist}>
                <PolarGrid />
                <PolarAngleAxis dataKey='category' />
                <PolarRadiusAxis domain={[0, 100]} angle={90} />
                <ReRadar dataKey='value' stroke={colors.royalBlueDark} fill={colors.royalBlueLight} fillOpacity={0.3} />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className='has-text-centered btn' onClick={openHandler.bind(null, !isOpen)}>
        {isOpen ? <ChevronUp className='icon' /> : <ChevronDown className='icon' />}
      </div>
    </>
  );
};

export default ProfileOptimizationChecklist;
