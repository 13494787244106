import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import {
  Statistics,
  loadMetricsForAccount,
  loadActivityStatisticsForAccount,
  ActivityStatistics,
  calculateEngagementRate,
  loadHistoryForAccount,
  StatisticsSnapshot,
  agregateStatistics,
} from '../services/statistics-service';
import '../styles/components/Dashboard.scss';
import { useParams } from 'react-router-dom';
import Header from './Header';
import DashboardStatisticCards from './DashboardStatisticCards';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import DashboardTimeline, { TIMELINE_TYPE } from './DashboardTimeline';
import TopActivityCard from './TopActivityCard';
import {
  CaretBack,
  CaretForward,
  HelpCircle,
  Timer,
} from '@styled-icons/ionicons-solid';
import RecentlyConnectedBanner from './banner/RecentlyConnectedBanner';
import DateRangePicker from './header/DateRangePicker';
import StyledTooltip from './StyledTooltip';
import { Delighted, Loader } from 'delighted-loader';
import { helpTexts } from '../helpers/helpTexts';
import { useAmountStats } from '../hooks/useAmountStats';

const entriesPerPage = 4;

const Dashboard = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const [statistics, setStatistics] = useState<Statistics>();
  const [referenceStatistics, setReferenceStatistics] = useState<Statistics>();
  const { startDate, endDate } = useSelector(
    (state: RootState) => state.dateRangeFilter
  );
  const startTimeStamp = startDate.getTime();
  const endTimeStamp = endDate.getTime();
  const { data: activities, isLoading: activitiesLoading } = useQuery(
    [`dashboard-highlights`, startTimeStamp, endTimeStamp],
    () => loadActivityStatisticsForAccount(accountId, startDate, endDate)
  );

  const [loading, setLoading] = useState(false);
  const [topPosts, setTopPosts] = useState<ActivityStatistics[]>();
  const [currentTopPosts, setCurrentTopPosts] = useState<ActivityStatistics[]>(
    []
  );
  const [page, setPage] = useState(0);
  const [filteredData, setFilteredData] = useState<StatisticsSnapshot[]>([]);
  const [unifiedStatistics, setUnifiedStatistics] = useState<Statistics>();
  const { data, isLoading } = useQuery(
    ['loadHistory', accountId, startDate, endDate],
    () => loadHistoryForAccount(accountId, startDate, endDate),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!accountId) {
      return;
    }
    setLoading(true);
    const startDate = new Date(startTimeStamp);
    const endDate = new Date(endTimeStamp);

    Promise.all([
      loadMetricsForAccount(accountId, startDate, endDate),
      loadActivityStatisticsForAccount(accountId, startDate, endDate),
    ])
      .then(([metrics, { activities: activityStatistics }]) => {
        // Metrics
        setStatistics(metrics.statistics);
        if (metrics.reference) {
          setReferenceStatistics(metrics.reference);
        }
        // Activitiy Snapshots
        const sortedPosts = activityStatistics
          .map(entry => ({
            ...entry,
            engagementRate: calculateEngagementRate(entry.statistics),
          }))
          .sort((a, b) => b.engagementRate - a.engagementRate);
        setPage(0);
        setTopPosts(sortedPosts);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [accountId, startTimeStamp, endTimeStamp]);

  useEffect(() => {
    setCurrentTopPosts(
      [...(topPosts || [])].slice(
        page * entriesPerPage,
        page * entriesPerPage + entriesPerPage
      )
    );
  }, [page, topPosts]);

  useEffect(() => {
    const apiKey = 'YQPArxQW8j1KHVua';
    const loader = new Loader(apiKey);
    loader.load().then((delighted: Delighted) => {
      delighted.survey({
        email: user?.email,
        name: user?.profile.givenName + ' ' + user?.profile.familyName,
        properties: {
          plan: user?.plan ? user.plan : '',
        },
      });
    });
  }, [user]);

  useEffect(() => {
    let newFilteredData = data?.filter(
      dat =>
        dat.timestamp >= startDate.getTime() &&
        dat.timestamp <= endDate.getTime()
    );
    setFilteredData(newFilteredData || []);
  }, [data, startDate, endDate]);

  const id = Math.floor(Math.random() * 1000);
  const { generalStats } = useAmountStats({ data: filteredData, id: id });

  useEffect(() => {
    if (generalStats) {
      setUnifiedStatistics(agregateStatistics(generalStats));
    }
  }, [generalStats]);

  const statisticsData: Statistics = {
    amountComments:
      activities?.activities.reduce(
        (acc, act) => acc + act.statistics.amountComments,
        0
      ) || 0,
    amountImpressions:
      activities?.activities.reduce(
        (acc, act) => acc + act.statistics.amountImpressions,
        0
      ) || 0,
    amountLikes:
      activities?.activities.reduce(
        (acc, act) => acc + act.statistics.amountLikes,
        0
      ) || 0,
    amountShares:
      activities?.activities.reduce(
        (acc, act) => acc + act.statistics.amountShares,
        0
      ) || 0,
    amountViews:
      activities?.activities.reduce(
        (acc, act) => acc + act.statistics.amountViews,
        0
      ) || 0,
    amountReactionType: [],
  };

  return (
    <div className='Dashboard'>
      <Header title='Home' content={<DateRangePicker />} loading={loading} />

      <RecentlyConnectedBanner />

      <section className='section timelines-container'>
        {statistics && (
          <>
            <DashboardTimeline
              id={1}
              statistics={statisticsData}
              referenceStatistics={referenceStatistics}
              data={filteredData}
              isLoading={isLoading || activitiesLoading}
              defaultTimeLineType={TIMELINE_TYPE.VIEWS}
              typeAmount={statisticsData}
            />
            <DashboardTimeline
              id={2}
              statistics={statisticsData}
              referenceStatistics={referenceStatistics}
              data={filteredData}
              isLoading={isLoading || activitiesLoading}
              defaultTimeLineType={TIMELINE_TYPE.IMPRESSIONS}
              typeAmount={statisticsData}
            />
          </>
        )}
      </section>
      <section className='section'>
        {statistics && (
          <DashboardStatisticCards
            data={data}
            statistics={unifiedStatistics}
            referenceStatistics={referenceStatistics}
            cardFilter={[
              'views',
              'impressions',
              'likes',
              'comments',
              'engagement',
            ]}
          />
        )}
      </section>

      {topPosts !== undefined && (
        <section className='section'>
          <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
            Top posts
            <HelpCircle
              data-tip
              data-for={`styled-tooltip-dashboard-interval-top-posts`}
              size={'1.5rem'}
              style={{
                marginLeft: '0.5rem',
              }}
            />
            <StyledTooltip
              id={`styled-tooltip-dashboard-interval-top-posts`}
              delayHide={500}
              clickable={true}
            >
              <p
                style={{
                  textTransform: 'initial',
                }}
              >
                <Timer
                  height={'1rem'}
                  style={{
                    marginRight: '0.3rem',
                  }}
                />
                Updates every 30 min
              </p>
              <br />
              <span
                style={{
                  textTransform: 'initial',
                }}
              >
                {helpTexts.topPosts}
              </span>
            </StyledTooltip>
          </h5>
          <div className='top-activity-card-container'>
            <button
              className='button button-pagination is-left'
              onClick={() => setPage(page - 1)}
              disabled={page === 0}
            >
              <CaretBack className='icon has-text-grey' />
            </button>

            {currentTopPosts.map(statistic => (
              <TopActivityCard
                key={statistic.activity._id}
                activityStatistics={statistic}
              />
            ))}
            <button
              className='button button-pagination is-right'
              onClick={() => setPage(page + 1)}
              disabled={(page + 1) * entriesPerPage > topPosts.length}
            >
              <CaretForward className='icon has-text-grey' />
            </button>
          </div>
        </section>
      )}
    </div>
  );
};

export default Dashboard;
