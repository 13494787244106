import styled from 'styled-components';
import LoadingBar from '../../components/loader/LoadingBar';

export const LoadingContainer = styled.div`
  min-height: 150px;
  display: flex;
  padding: 1rem 2rem;
  align-items: center;
  justify-content: center;
  height: '100%';
`;

export const LoadingBarSmall = styled(LoadingBar)`
  display: block;
  width: 5rem;
  height: 1rem;
  margin: 0 auto;
`;
