import React, { useEffect, useState } from 'react';
import Header from '../../Header';
import DateRangePicker from '../../header/DateRangePicker';
import DashboardStatisticCards from '../../DashboardStatisticCards';
import { VisitorsTimeline } from './VisitorsTimeline';
import {
  CompanyDemographics,
  getCompanyDemographics,
  getCompanyVisitors,
  ICompanyVisitorsAnalytics,
  ICompanyVisitorsTimeline,
} from '../../../services/company-service';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { CompanyDemographic } from './CompanyDemographic';
import * as StyledLoading from '../../../styles/common/Loading.styles';
import { InviteMemberWrapper } from '../../group/InviteMemberWrapper';

interface Props {}

const CompanyVisitors: React.FC<Props> = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const { inviteMemberModalOpen } = useSelector((state: RootState) => state.settings);

  const { startDate, endDate } = useSelector((state: RootState) => state.dateRangeFilter);
  const [isLoading, setIsLoading] = useState(false);
  const [visitorsData, setVisitorsData] = useState<{
    analytics: ICompanyVisitorsAnalytics;
    timeline: ICompanyVisitorsTimeline[];
  }>();
  const [demographicData, setDemographicData] = useState<CompanyDemographics>();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const [visData, demoData] = await Promise.all([
          getCompanyVisitors({ id: companyId, startDate, endDate }),
          getCompanyDemographics({ id: companyId, startDate, endDate }),
        ]);

        setVisitorsData(visData);
        setDemographicData(demoData);
        setIsLoading(false);
      } catch (e) {
        console.log(e.message);
      }
    };
    getData().catch();
  }, [companyId, startDate, endDate]);

  return (
    <div className='Content CompanyVisitors'>
      <Header
        title={
          <>
            <span>Visitors Analytics</span>
          </>
        }
        content={
          <>
            <DateRangePicker />
          </>
        }
        loading={isLoading}
      />

      {isLoading ? (
        <StyledLoading.LoadingContainer>
          <StyledLoading.LoadingBarSmall active />
        </StyledLoading.LoadingContainer>
      ) : (
        visitorsData && (
          <>
            <section className='section'>
              <DashboardStatisticCards
                pageViews={{
                  number: visitorsData.analytics.pageViews || 0,
                  percentage: visitorsData.analytics.pageViewsPercentage || 0,
                }}
                uniqueVisitors={{
                  number: visitorsData.analytics.uniqueVisitors || 0,
                  percentage: visitorsData.analytics.uniqueVisitorsPercentage || 0,
                }}
                buttonClicks={{
                  number: visitorsData.analytics.buttonClicks || 0,
                  percentage: visitorsData.analytics.buttonClicksPercentage || 0,
                }}
                cardFilter={['pageViews', 'uniqueVisitors', 'buttonClicks']}
              />
            </section>

            <section className='section'>
              <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>Visitors Metrics</h5>
              <VisitorsTimeline brushEnabled data={visitorsData.timeline} />
            </section>
          </>
        )
      )}

      {isLoading ? (
        <StyledLoading.LoadingContainer>
          <StyledLoading.LoadingBarSmall active />
        </StyledLoading.LoadingContainer>
      ) : (
        demographicData && (
          <section className='section'>
            <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>Visitors Demographic</h5>
            <CompanyDemographic data={demographicData} />
          </section>
        )
      )}

      {inviteMemberModalOpen && <InviteMemberWrapper isCompany />}
    </div>
  );
};

export { CompanyVisitors };
