import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useMutation } from 'react-query';
import { actOnInvitation, InvitationAction, TOKEN_TYPE } from '../../services/organization-service';
import { APIError } from '../../services/api-client';
import { StyledCommon } from '../../styles/common/Common.styles';
import classNames from 'classnames';
import * as Styled from './Invitation.styles';

const Invitation = () => {
  const { token } = useParams<{ token: string }>();
  const [type, setType] = useState<TOKEN_TYPE>();
  const [inviteeName, setInviteeName] = useState('');
  const [targetName, setTargetName] = useState('');
  const [targetId, setTargetId] = useState('');

  useEffect(() => {
    const tokenPayload = jwtDecode<{
      type: TOKEN_TYPE;
      data: { inviteeName: string; companyName: string; companyId: string };
    }>(token);
    setType(tokenPayload.type);
    if (tokenPayload.type === TOKEN_TYPE.COMPANY_INVITATION) {
      setInviteeName(tokenPayload.data.inviteeName);
      setTargetId(tokenPayload.data.companyId);
      setTargetName(tokenPayload.data.companyName);
    }
  }, [token]);

  const [actOnInvitationMutation, { error, isLoading, isSuccess }] = useMutation<any, APIError, InvitationAction>(
    (action: InvitationAction) => {
      if (type === TOKEN_TYPE.COMPANY_INVITATION) {
        return actOnInvitation(targetId, token, action);
      }
      return Promise.reject('Invalid invitation type provided.');
    }
  );

  if (!targetName) {
    return <></>;
  }

  if (isSuccess) {
    return <Redirect to='/' />;
  }

  return (
    <StyledCommon.Section>
      <Styled.Wrapper>
        {error && <div className='notification is-danger'>{error.message}</div>}
        <p className='mb-3'>
          <b>{inviteeName}</b> has invited you to join <b>{targetName}</b>.
        </p>
        <Styled.Buttons className='buttons'>
          <button
            className={classNames('button is-primary', { 'is-loading': isLoading })}
            onClick={() => actOnInvitationMutation('accept')}>
            Accept invitation
          </button>
          <button
            className={classNames('button', { 'is-loading': isLoading })}
            onClick={() => actOnInvitationMutation('decline')}>
            Decline invitation
          </button>
        </Styled.Buttons>
      </Styled.Wrapper>
    </StyledCommon.Section>
  );
};

export default Invitation;
