import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';

const useGroupName = (groupId: string): { groupName: string } => {
  const { groups } = useSelector((state: RootState) => state.linkedAccounts);

  const groupName: string = useMemo(() => {
    const currentGroup = groups?.find(group => group.id === groupId);
    return currentGroup?.name || '';
  }, [groupId, groups]);

  return { groupName };
};

export { useGroupName };
