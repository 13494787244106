import React, { useState, useEffect } from "react";
import { ActivityRegionHighlight } from "../services/activities-service";
import "../styles/components/ActivityHighlightsRegionMap.scss";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import variables, { colors } from "../styles/variables";
import allCountries from "country-region-data/data.json";
import { getCountryFromCity } from "../services/statistics-service";

const worldGeoJSON = require("world-atlas/countries-110m.json");

const TOP_LIST_MAX = 10;

const ActivityHighlightsRegionMap: React.FC<{
  highlights?: ActivityRegionHighlight[];
  showMaxList?: boolean;
}> = ({ highlights, showMaxList }) => {
  const [maxAmount, setMaxAmount] = useState<number>();
  const [topCountries, setTopCountries] = useState<string[]>([]);
  const [countryHighlights, setCountryHighlights] = useState<
    { country: string; amountViews: number }[]
  >();
  const [tooltipContent, setTooltipContent] = useState("");

  useEffect(() => {
    if (!highlights?.length) {
      setCountryHighlights([]);
      setTopCountries([]);
      return;
    }

    const mappedByCountry = new Map<string, number>();
    highlights.forEach(({ amountViews, region }) => {
      const results = /(?=.*)+, ([^,]*)$/.exec(region);
      const resultsRegion = region
        .split(" Metropolitan")[0]
        .replace("Greater", "")
        .replace("City", "");
      const country =
        results?.[1] && !results?.[1].includes("Metropolitan")
          ? results?.[1]
          : "";

      let metropolia: any = allCountries.find(country => {
        return (
          country.regions.filter(reg => reg.name.includes(resultsRegion.trim()))
            .length > 0
        );
      });

      if (!country && !metropolia) {
        metropolia = getCountryFromCity(resultsRegion.trim());
        if (!metropolia?.countryName) {
          // No country name found
          return;
        }
      }
      if (country) {
        mappedByCountry.set(
          country,
          (mappedByCountry.get(country) ?? 0) + amountViews
        );
        return;
      }
      if (metropolia) {
        let countryName = metropolia.countryName;
        switch (true) {
          case metropolia.countryName === "Russian Federation":
            countryName = "Russia";
            break;
          case metropolia.countryName === "United States":
            countryName = "United States of America";
            break;
        }
        mappedByCountry.set(
          countryName,
          (mappedByCountry.get(countryName) ?? 0) + amountViews
        );
      }
    });

    const countries = Array.from(
      mappedByCountry.entries()
    ).map(([country, amountViews]) => ({ country, amountViews }));
    setCountryHighlights(countries);

    const countriesSorted = countries.sort(
      (a, b) => b.amountViews - a.amountViews
    );

    if (showMaxList) {
      setTopCountries(
        countriesSorted.slice(0, TOP_LIST_MAX).map(({ country }) => country)
      );
    }

    const max = countriesSorted[0]?.amountViews;
    setMaxAmount(max);
  }, [highlights, showMaxList]);

  return (
    <div className="region-map-highlights">
      {showMaxList && !!topCountries.length && (
        <ul className="region-map-top-list">
          <li className="region-map-top-list__item region-map-top-list__item--header">
            Top {topCountries.length}
          </li>
          {topCountries.map(country => (
            <li key={country} className="region-map-top-list__item">
              {country}
            </li>
          ))}
        </ul>
      )}
      <div data-tip="" className="ActivityHighlightsRegionMap">
        {maxAmount && countryHighlights && countryHighlights.length > 0 && (
          <ComposableMap
            projection="geoMercator"
            fill="#fff000"
            projectionConfig={{ center: [0, 10] }}
          >
            <Geographies geography={worldGeoJSON}>
              {({ geographies }) =>
                geographies.map(geo => {
                  const countryName = geo.properties.name;
                  const amountViews =
                    countryHighlights.find(
                      entry => entry.country === countryName
                    )?.amountViews ?? 0;
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fillOpacity={
                        amountViews ? amountViews / maxAmount + 0.5 : 0
                      }
                      onMouseEnter={() =>
                        setTooltipContent(
                          `${countryName} — ${amountViews} Views`
                        )
                      }
                      onMouseLeave={() => setTooltipContent("")}
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
        )}
        {(!countryHighlights || !countryHighlights.length) && (
          <i>No data yet.</i>
        )}
        <ReactTooltip
          backgroundColor={colors.white}
          textColor={variables.textColorLight}
          className="tooltip"
        >
          {tooltipContent}
        </ReactTooltip>
      </div>
    </div>
  );
};

export default ActivityHighlightsRegionMap;
