import React from "react";
import * as StyledLoading from "../../styles/common/Loading.styles";
import { NoContentBox } from "./NoContentBox";
import { Link } from "react-router-dom";
import {
  CompanyOffer,
  handleCompanyOffer
} from "../../services/company-service";
import { JoinCompanyRequest } from "./JoinCompanyRequest";
import { useMutation } from "react-query";
import { APIError } from "../../services/api-client";
import { useDispatch } from "react-redux";
import { setCompanyOffers } from "../../store/actions/linkedAccounts";

interface Props {
  isLoading: boolean;
  companyOffers: CompanyOffer[];
}

export const SecurityOrganization: React.FC<Props> = ({
  companyOffers,
  isLoading
}) => {
  const dispatch = useDispatch();

  const [
    handleOfferMutation,
    { isLoading: isHandleOfferLoading }
  ] = useMutation<string, APIError, { companyId: string; confirmed: boolean }>(
    handleCompanyOffer,
    {
      onSuccess: companyId => {
        const newOffers = companyOffers.filter(item => item.id !== companyId);
        dispatch(setCompanyOffers(newOffers));
      }
    }
  );

  return (
    <>
      {isLoading || isHandleOfferLoading ? (
        <StyledLoading.LoadingContainer>
          <StyledLoading.LoadingBarSmall active />
        </StyledLoading.LoadingContainer>
      ) : (
        <>
          {companyOffers?.length ? (
            companyOffers.map(offer => (
              <JoinCompanyRequest
                key={offer.id}
                data={offer}
                handleOffer={handleOfferMutation}
              />
            ))
          ) : (
            <NoContentBox text="No companies Yet" />
          )}
        </>
      )}
      <div className="security-settings-link">
        <Link to="/settings/organizations">
          Bring me to my company settings
        </Link>
      </div>
    </>
  );
};
