import apiClient, { APIRequestMeta } from "./api-client";
import { store } from "../store";
import { GroupUserRoles } from "../components/accountSharing/AccountSharingModal";
import { AccountsTableEntryViewModel } from "../components/linked-accounts/AccountList";
import {
  setGroupOffers,
  setGroups,
  setLinkedAccountRequest
} from "../store/actions/linkedAccounts";
import { GroupOfferViewModel } from "../components/security/SecurityGroup";
import {
  FollowerSnapshot,
  LinkedInConnection,
  ProfileViewHistoryEntry
} from "./profile-service";
import { LinkedInAccount } from "./account-service";
import { ActivityHighlights } from "./activities-service";
import { StatisticLike } from "./statistics-service";
import { User } from "./user-service";

interface CreateGroupPayload {
  name: string;
  groupLogo?: File;
  emails: string[];
  type: "ANALYTICS" | "SCHEDULE";
  role: GroupUserRoles;
  includeStatistics: boolean;
}

export async function createGroup(
  {
    name,
    groupLogo,
    emails,
    type,
    role,
    includeStatistics
  }: CreateGroupPayload,
  meta: APIRequestMeta
) {
  const formData = new FormData();

  formData.append("name", name);

  if (groupLogo) {
    formData.append("groupLogo", groupLogo);
  }

  // TODO: fix body-parser on backend as it doesn't parse stringified arrays
  formData.append("emails", emails[0]);

  emails.forEach(email => formData.append("emails", email));
  formData.append("type", type);
  formData.append("role", role);
  formData.append("includeStatistics", JSON.stringify(includeStatistics));

  try {
    const res = await apiClient.post("/groups", formData, {
      headers: { "Content-Type": "multipart/form-data" }
    });

    if (res.status === 201) {
      getGroups();
    }

    if (res.data) {
      meta?.onSuccess(res.data);
    } else {
      meta?.onError(res);
    }
  } catch (e) {
    meta?.onError(e.message);
  }
}

export interface UpdateGroupPayload {
  name: string;
  groupId: string;
  hashtags: string;
  groupLogo?: File;
}

export async function updateGroup({
  name,
  hashtags,
  groupId,
  groupLogo
}: UpdateGroupPayload) {
  const formData = new FormData();

  formData.append("name", name);
  formData.append("hashtags", hashtags);

  if (groupLogo) {
    formData.append("groupLogo", groupLogo);
  }

  const res = await apiClient.patch(`/groups/${groupId}`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });

  return res;
}

export async function deleteGroup(groupId: string) {
  try {
    await apiClient.delete<void>(`/groups/${groupId}`);

    getGroups();
  } catch (e) {
    console.log(e.message);
  }
}

export async function unlinkGroup(groupId: string) {
  try {
    const res = await apiClient.delete(`/groups/${groupId}/unlink`);
    if (res.status === 204) {
      getGroups();
    }
  } catch (e) {
    console.log(e.message);
  }
}

export async function getGroups() {
  store.dispatch(setLinkedAccountRequest("isLoadingGroups", true));

  try {
    const res = await apiClient.get<{ groups: AccountsTableEntryViewModel[] }>(
      "/groups"
    );
    if (res.data) {
      store.dispatch(setGroups(res.data.groups));
    }
  } catch (e) {
    console.error(e.message);
  }

  store.dispatch(setLinkedAccountRequest("isLoadingGroups", false));
}

export async function getGroupOffers() {
  store.dispatch(setLinkedAccountRequest("isLoadingGroupOffers", true));
  try {
    const res = await apiClient.get<{ groupOffers: GroupOfferViewModel[] }>(
      "/groups/offers"
    );
    if (res.data?.groupOffers) {
      store.dispatch(setGroupOffers(res.data.groupOffers));
    }

    return res.data?.groupOffers;
  } catch (e) {
    console.error(e.message);
  }
  store.dispatch(setLinkedAccountRequest("isLoadingGroupOffers", false));
}

export async function inviteToGroup(
  groupId: string,
  emails: string[],
  role: GroupUserRoles,
  includeStatistics: boolean
) {
  try {
    await apiClient.post(`/groups/${groupId}/invite`, {
      emails,
      role,
      includeStatistics
    });
  } catch (e) {
    console.error(e.message);
  }
}

export async function acceptGroupOffer(id: string) {
  try {
    const res = await apiClient.patch(`/groups/offers/${id}`, {
      confirmed: true
    });
    if (res.status === 200) {
      getGroupOffers();
      getGroups();
    }
  } catch (e) {
    console.error(e.message);
  }
}

export async function declineGroupOffer(id: string) {
  try {
    const res = await apiClient.delete(`/groups/offers/${id}`);
    if (res.status === 200) {
      getGroupOffers();
      getGroups();
    }
  } catch (e) {
    console.error(e.message);
  }
}

export type GroupMember = {
  id: string;
  avatar?: string;
  name: string;
  role: GroupUserRoles;
  isCounted: boolean;
  userId: string;
};

export async function getGroupMembers(id: string) {
  const res = await apiClient.get<GroupMember[]>(`/groups/${id}/members`);
  return res.data;
}

export async function deleteGroupMember({
  objectId,
  id
}: {
  objectId: string;
  id: string;
}) {
  await apiClient.delete(`/groups/${objectId}/members/${id}`);
  return id;
}

export interface UpdateGroupMemberPayload {
  groupId: string;
  id: string;
  payload: {
    isIncludedInStatistics?: boolean;
    role?: string;
  };
}

export async function updateGroupMember({
  groupId,
  id,
  payload
}: UpdateGroupMemberPayload) {
  await apiClient.patch<GroupMember>(
    `/groups/${groupId}/members/${id}`,
    payload
  );
}

export interface GroupViewHistoryEntry {
  endTimestamp: Date;
  numViews: number;
  account: string;
}

export async function getGroupViewHistory(
  groupId: string,
  startDate: Date,
  endDate: Date
) {
  const res = await apiClient.get<{ history: GroupViewHistoryEntry[] }>(
    `/groups/${groupId}/group-view-history`,
    {
      params: {
        startDate,
        endDate
      }
    }
  );
  return res.data.history;
}

export async function getGroupFollowHistory(
  groupId: string,
  startDate: Date,
  endDate: Date
) {
  const res = await apiClient.get<{
    history: Array<Omit<FollowerSnapshot, "groupId">>;
  }>(`/groups/${groupId}/group-follow-history`, {
    params: {
      startDate,
      endDate
    }
  });
  return res.data.history;
}

export interface GroupMemberCardEntry {
  id: string;
  profile: LinkedInAccount;
  followers: FollowerSnapshot[];
  views: ProfileViewHistoryEntry[];
  connections: LinkedInConnection[];
  highlights: ActivityHighlights;
  activities: {
    length: number;
    statistics: StatisticLike;
    plannedLength: number;
  };
  isCounted: boolean;
  isConnected: number;
  user: User;
}

export async function getGroupMembersInfo(
  groupId: string,
  startDate?: Date,
  endDate?: Date
) {
  const res = await apiClient.get<GroupMemberCardEntry[]>(
    `/groups/${groupId}/group-members-info`,
    {
      params: {
        startDate,
        endDate
      }
    }
  );
  return res.data;
}

export interface GroupMembersHistory {
  updatedAt: Date;
  number: number;
}

export async function getGroupMembersHistory(
  groupId: string,
  startDate?: Date,
  endDate?: Date
) {
  const res = await apiClient.get<GroupMembersHistory[]>(
    `/groups/${groupId}/group-members-history`,
    {
      params: {
        startDate,
        endDate
      }
    }
  );
  return res.data;
}
