export enum STORAGE_KEY {
  RECENTLY_CONNECTED_BANNER_HIDDEN = "RECENTLY_CONNECTED_BANNER_HIDDEN",
  ENGAGEMENT_BANNER_HIDDEN = "ENGAGEMENT_BANNER_HIDDEN"
}

const PREFIX = "inlytics:";

export function getEntry(
  key: STORAGE_KEY.RECENTLY_CONNECTED_BANNER_HIDDEN
): boolean | null;
export function getEntry(key: STORAGE_KEY) {
  return JSON.parse(localStorage.getItem(`${PREFIX}${key}`) || "{}");
}
export function getEngagementEntry(
  key: STORAGE_KEY.ENGAGEMENT_BANNER_HIDDEN
): boolean | null;
export function getEngagementEntry(key: STORAGE_KEY) {
  return JSON.parse(localStorage.getItem(`${PREFIX}${key}`) || "{}");
}

export function setEntry(
  key: STORAGE_KEY.RECENTLY_CONNECTED_BANNER_HIDDEN,
  value: true
): void;
export function setEntry(key: STORAGE_KEY, value: any) {
  return localStorage.setItem(`${PREFIX}${key}`, JSON.stringify(value));
}
export function setEngagementEntry(
  key: STORAGE_KEY.ENGAGEMENT_BANNER_HIDDEN,
  value: true
): void;
export function setEngagementEntry(key: STORAGE_KEY, value: any) {
  return localStorage.setItem(`${PREFIX}${key}`, JSON.stringify(value));
}
