import React from 'react';

interface Props {
  fill: string;
}

const CompanyIcon = ({ fill }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='35' height='36' viewBox='0 0 35 36'>
    <g fill='none' fill-rule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill={fill}
                  fillRule='nonzero'
                  d='M17.745.002c9.438.133 17.124 7.97 17.253 17.594.135 10.034-7.898 18.226-17.741 18.089C7.818 35.55.133 27.715.002 18.092-.128 8.278 7.554.227 17.099.005L17.42 0zM25.375 6H9.625C9.004 6 8.5 6.504 8.5 7.125V28.5h-.938c-.31 0-.562.252-.562.563V30h21v-.938c0-.31-.252-.562-.563-.562H26.5V7.125C26.5 6.504 25.996 6 25.375 6zm-6.938 18c.311 0 .563.252.563.563V28.5h-3v-3.938c0-.31.252-.562.563-.562h1.875zm-3-6c.311 0 .563.252.563.563v1.875c0 .31-.252.562-.563.562h-1.874c-.311 0-.563-.252-.563-.563v-1.875c0-.31.252-.562.563-.562h1.874zm6 0c.311 0 .563.252.563.563v1.875c0 .31-.252.562-.563.562h-1.875c-.31 0-.562-.252-.562-.563v-1.875c0-.31.252-.562.563-.562h1.875zm-6-4.5c.311 0 .563.252.563.563v1.874c0 .311-.252.563-.563.563h-1.874c-.311 0-.563-.252-.563-.563v-1.874c0-.311.252-.563.563-.563h1.874zm6 0c.311 0 .563.252.563.563v1.874c0 .311-.252.563-.563.563h-1.875c-.31 0-.562-.252-.562-.563v-1.874c0-.311.252-.563.563-.563h1.875zm-6-4.5c.311 0 .563.252.563.563v1.874c0 .311-.252.563-.563.563h-1.874c-.311 0-.563-.252-.563-.563V9.563c0-.311.252-.563.563-.563h1.874zm6 0c.311 0 .563.252.563.563v1.874c0 .311-.252.563-.563.563h-1.875c-.31 0-.562-.252-.562-.563V9.563c0-.311.252-.563.563-.563h1.875z'
                  transform='translate(-1259.000000, -781.000000) translate(463.000000, 528.000000) translate(671.000000, 186.000000) translate(142.500000, 85.000000) scale(-1, 1) translate(-142.500000, -85.000000) translate(125.000000, 67.000000)'
                />
                <path
                  fill='#000'
                  fillRule='nonzero'
                  d='M9.608 21.313c-.23-.01-.46-.03-.686-.062.227.031.456.052.686.062zM7.549 10.98c-.507.287-.966.656-1.36 1.093-.266.295-.5.619-.699.966.198-.347.433-.671.699-.966.394-.437.853-.806 1.36-1.093zM14.412 17.843c.373-.685.6-1.418.667-2.165.017-.196.022-.388.017-.58.006.19 0 .384-.017.58-.068.747-.294 1.48-.667 2.165zM7.549 11.136c.22-.122.45-.226.686-.311-.236.085-.466.19-.686.31zM8.922 20.712c-.235-.066-.465-.148-.687-.247.222.099.452.181.687.247zM4.804 16.47c.213 1.818 1.304 3.368 2.745 4.118-1.444-.75-2.532-2.303-2.745-4.117zM17.859 15.043c.097 1.572.698 3.062 1.705 4.22.484.555 1.065 1.006 1.71 1.325-.645-.32-1.226-.77-1.71-1.325-1.007-1.158-1.608-2.648-1.705-4.22-.014-.212-.019-.423-.014-.631-.005.205 0 .416.014.631zM21.96 20.692c-.233-.06-.462-.13-.685-.207.223.078.452.147.686.207zM17.843 13.04c.213-1.028.687-1.976 1.373-2.746-.686.77-1.16 1.718-1.373 2.745zM27.428 10.314c.828.935 1.321 2.14 1.396 3.411-.075-1.27-.568-2.476-1.396-3.411-.563-.627-1.26-1.104-2.036-1.392.775.288 1.473.765 2.036 1.392zM28.824 14.412c-.065.952-.298 1.883-.687 2.745.39-.862.622-1.793.687-2.745zM17.112 13.725c.008-.23.041-.459.1-.686-.059.227-.092.456-.1.686zM28.287 17.843c-.093.235-.193.464-.3.686.107-.222.207-.451.3-.686z'
                  transform='translate(-1259.000000, -781.000000) translate(463.000000, 528.000000) translate(671.000000, 186.000000) translate(142.500000, 85.000000) scale(-1, 1) translate(-142.500000, -85.000000) translate(125.000000, 67.000000)'
                />
                <path
                  fill='#000'
                  fillRule='nonzero'
                  d='M13.724 12.449c.869.908 1.359 2.097 1.374 3.335-.015-1.238-.505-2.427-1.374-3.335-.732-.758-1.692-1.272-2.744-1.469 1.052.197 2.012.711 2.744 1.469zM11.667 20.588c-.218.279-.448.509-.687.687.24-.178.47-.408.687-.687zM4.804 15.098c.064-.712.297-1.409.686-2.059-.39.65-.622 1.347-.686 2.059zM12.353 20.455c-.221.096-.45.181-.686.266.236-.085.465-.17.686-.266zM14.412 17.843c-.193.24-.423.469-.687.686.264-.217.494-.447.687-.686zM5.374 13.725c.07-.234.147-.464.233-.686-.086.222-.164.452-.233.686zM12.353 20.588c.539-.377 1.003-.842 1.372-1.372-.369.53-.833.995-1.372 1.372z'
                  transform='translate(-1259.000000, -781.000000) translate(463.000000, 528.000000) translate(671.000000, 186.000000) translate(142.500000, 85.000000) scale(-1, 1) translate(-142.500000, -85.000000) translate(125.000000, 67.000000)'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export { CompanyIcon };
