import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import DayPicker from 'react-day-picker';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { getSharedConnections } from '../../services/profile-service';
import { USER_PLAN } from '../../services/user-service';
import {
  DATE_RANGE,
  setDateRangeFilter,
} from '../../store/actions/dateRangerFilter';
import RangeCategorySelect from './RangeCategorySelect';
import { format, isAfter, isBefore } from 'date-fns';
import ClickAwayListener from '../ClickAwayListener';
import {
  ArrowForwardCircle,
  ArrowBackCircle,
} from '@styled-icons/ionicons-solid';
import '../../styles/components/HeaderDateRange.scss';
import classNames from 'classnames';
import { getLocaleDateString } from '../../helpers/functions';

enum SELECTED_TYPE {
  START = 'START',
  END = 'END',
}

const formatDate = (date: Date) =>
  format(date, getLocaleDateString(navigator.language));

interface TDateRangePickerProps {
  onclick?: (args: boolean) => void;
}

const DateRangePicker = ({ onclick }: TDateRangePickerProps) => {
  const dispatch = useDispatch();
  const { accountId: paramAccountId, groupId: paramGroupId } = useParams<{
    accountId: string;
    groupId: string;
  }>();

  const { user } = useSelector((state: RootState) => state.auth);
  const {
    dateRange,
    startDate,
    endDate,
    referenceStartDate,
    referenceEndDate,
    minStartDatesByAccount,
  } = useSelector((state: RootState) => state.dateRangeFilter);

  const minStartDate = useMemo(
    () =>
      new Date(minStartDatesByAccount[paramGroupId || paramAccountId] || null),
    [paramGroupId, paramAccountId, minStartDatesByAccount]
  );

  useEffect(() => {
    if (user?.plan === USER_PLAN.FREE) {
      // Free users are limited to a data history of 30 days
      dispatch(
        setDateRangeFilter(
          DATE_RANGE.LAST_30_DAYS,
          paramGroupId || paramAccountId
        )
      );
    }
  }, [dispatch, user, paramGroupId, paramAccountId]);

  useEffect(() => {
    if (
      !minStartDatesByAccount[paramAccountId] &&
      !minStartDatesByAccount[paramGroupId]
    ) {
      getSharedConnections();
    }
    // additional check on didMount only
    // eslint-disable-next-line
  }, []);

  const [selected, setSelected] = useState<SELECTED_TYPE | null>(null);
  const [dayPickerVisible, setDayPickerVisible] = useState(false);

  const openDayPicker = (selected: SELECTED_TYPE) => {
    if (user!.plan === USER_PLAN.FREE) {
      return;
    }
    setDayPickerVisible(true);
    setSelected(selected);
  };

  const handleDayClick = (day: Date) => {
    if (!day) {
      return;
    }

    // DayPicker sets 12:00 by default; change it to 00:00 as all start and end dates have that format
    day.setHours(0, 0, 0, 0);

    if (isAfter(day, new Date())) {
      return;
    }

    if (selected === SELECTED_TYPE.START) {
      if (!isAfter(day, endDate) && !isBefore(day, minStartDate)) {
        dispatch(
          setDateRangeFilter(day, endDate, paramGroupId || paramAccountId)
        );
      }
    } else {
      if (!isBefore(day, startDate)) {
        dispatch(
          setDateRangeFilter(startDate, day, paramGroupId || paramAccountId)
        );
      }
    }
    setDayPickerVisible(false);
    onclick && onclick(false);
  };

  return (
    <div className='HeaderDateRange'>
      <RangeCategorySelect />
      <ClickAwayListener onOutSideClick={() => setDayPickerVisible(false)}>
        <div className='field has-addons date-range'>
          <label className='label is-small'>Date range</label>
          <div className='control has-icons-left'>
            <button
              className={classNames('input is-small', {
                'is-focused':
                  selected === SELECTED_TYPE.START && dayPickerVisible,
              })}
              id='date-range-from'
              name='date-range-from'
              onClick={() => {
                openDayPicker(SELECTED_TYPE.START);
                onclick && onclick(true);
              }}
              disabled={user!.plan === USER_PLAN.FREE}
            >
              {formatDate(startDate)}
            </button>
            <span className='icon is-left'>
              <ArrowForwardCircle size='1rem' />
            </span>
          </div>
          <div className='control'>
            <button
              className='button is-static is-small'
              disabled={user!.plan === USER_PLAN.FREE}
            >
              &minus;
            </button>
          </div>
          <div className='control has-icons-left'>
            <button
              className={classNames('input is-small', {
                'is-focused':
                  selected === SELECTED_TYPE.END && dayPickerVisible,
              })}
              id='date-range-to'
              name='date-range-to'
              onClick={() => {
                openDayPicker(SELECTED_TYPE.END);
                onclick && onclick(true);
              }}
              disabled={user!.plan === USER_PLAN.FREE}
            >
              {formatDate(endDate)}
            </button>
            <span className='icon is-left'>
              <ArrowBackCircle size='1rem' />
            </span>
          </div>
          {dayPickerVisible && (
            <DayPicker
              todayButton='Today'
              className='day-picker'
              disabledDays={
                selected === SELECTED_TYPE.START
                  ? { after: new Date(), before: minStartDate }
                  : { before: startDate, after: new Date() }
              }
              selectedDays={{ to: endDate, from: startDate }}
              onDayClick={handleDayClick}
            />
          )}
        </div>
      </ClickAwayListener>
      <div className='field has-addons compare-date'>
        <label className='label is-small'>Compared to</label>
        <div className='control has-icons-left'>
          <input
            className='input is-small'
            id='compare-date-range-from'
            name='compare-date-range-from'
            type='text'
            value={
              dateRange !== DATE_RANGE.ALL_TIME
                ? formatDate(referenceStartDate)
                : '-'
            }
            readOnly
          />
          <span className='icon is-left'>
            <ArrowForwardCircle size='1rem' />
          </span>
        </div>
        <div className='control'>
          <button className='button is-static is-small'> &minus; </button>
        </div>
        <div className='control has-icons-left'>
          <input
            className='input is-small'
            id='compare-date-range-to'
            name='compare-date-range-to'
            type='text'
            value={
              dateRange !== DATE_RANGE.ALL_TIME
                ? formatDate(referenceEndDate)
                : '-'
            }
            readOnly
          />
          <span className='icon is-left'>
            <ArrowBackCircle size='1rem' />
          </span>
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
