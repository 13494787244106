import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Newspaper,
  People,
  Eye,
  PersonAdd,
  ChevronDown
} from "@styled-icons/ionicons-solid";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import classNames from "classnames";
import {
  CompanyUserRoles,
  GroupUserRoles
} from "./accountSharing/AccountSharingModal";
import { store } from "../store";
import { setInviteMemberModalOpen } from "../store/actions/settings";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import ClickAwayListener from "./ClickAwayListener";
import { GroupAccountIcon } from "./accountSharing/GroupAccountIcon";
import { LinkedInAccount } from "../services/account-service";
import { setSelectedCompany } from "../store/actions/linkedAccounts";
import { AccountsTableEntryViewModel } from "./linked-accounts/AccountList";
import { demoUserEmail } from "../services/user-service";
import "../styles/components/SidebarAccountSection.scss";

const SidebarCompanyList = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { selected, selectedCompany, companies } = useSelector(
    (state: RootState) => state.linkedAccounts
  );
  const { user } = useSelector((state: RootState) => state.auth);

  const location = useLocation();

  const [dropdownActive, setDropdownActive] = useState(false);
  const { sidebarOpen } = useSelector((state: RootState) => state.settings);

  const role = useMemo(() => {
    return companies?.find(company => company.id === companyId)?.role;
  }, [companyId, companies]);

  useEffect(() => {
    const company = companies.find(com => com.id === companyId);
    if (company) {
      store.dispatch(setSelectedCompany(company));
    }
  }, [companies, companyId]);

  const handleSelectGroup = (group: AccountsTableEntryViewModel) => {
    setDropdownActive(false);
    store.dispatch(setSelectedCompany(group));
  };

  const isDemoUser = useCallback(
    (account: LinkedInAccount | null | undefined) =>
      account?.email === demoUserEmail,
    []
  );

  const getGroupLink = (id: string) => {
    return id === companyId
      ? `/company/${id}/visitors`
      : location.pathname.replace(companyId, id);
  };

  const dropdownGroupOptions: AccountsTableEntryViewModel[] = useMemo(() => {
    const res = companies.filter(
      group =>
        // group[filterKey] && commented while testing scheduler
        group.role === GroupUserRoles.Analyst ||
        group.role === GroupUserRoles.Admin
    );
    return [...res];
  }, [companies]);

  return (
    <>
      <div className="SidebarAccountSection">
        <>
          <figure className="image">
            {(companyId ? (
              selectedCompany?.avatar
            ) : isDemoUser(selected) ? (
              user?.profile.picture
            ) : (
              selected?.profile.displayPhoto.small
            )) ? (
              <img
                className="is-rounded current-avatar"
                src={
                  companyId
                    ? selectedCompany?.avatar
                    : isDemoUser(selected)
                    ? user?.profile.picture
                    : selected?.profile.displayPhoto.small
                }
                alt="Account avatar"
              />
            ) : (
              <div className="current-avatar">
                <GroupAccountIcon fill="#919FA9" />
              </div>
            )}
          </figure>
          <ClickAwayListener onOutSideClick={() => setDropdownActive(false)}>
            <div
              className={classNames("dropdown", {
                "is-active": dropdownActive
              })}
            >
              <div
                className="dropdown-trigger"
                onClick={() => setDropdownActive(current => !current)}
              >
                <div className="info">
                  <div className="name truncate has-text-weight-bold">
                    {companyId
                      ? selectedCompany?.name
                      : isDemoUser(selected)
                      ? `${user?.profile.givenName} ${user?.profile.familyName}`
                      : `${selected?.profile.firstName} ${selected?.profile.lastName}`}
                  </div>
                  {!companyId && !isDemoUser(selected) && (
                    <div className="occupation truncate">
                      {selected?.profile.occupation}
                    </div>
                  )}
                </div>
                <span className="icon">
                  <ChevronDown
                    size="1rem"
                    style={{
                      transform: `rotate(${dropdownActive ? "180deg" : "0deg"})`
                    }}
                  />
                </span>
              </div>
              <div
                className="dropdown-menu is-fullwidth"
                id="select-account-dropdown"
                role="menu"
              >
                <div className="dropdown-content">
                  {dropdownGroupOptions.map(group => (
                    <Link
                      key={group.id}
                      to={getGroupLink(group.id)}
                      onClick={() => handleSelectGroup(group)}
                      className="dropdown-item"
                    >
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item image is-32x32 avatar">
                            {group.avatar ? (
                              <img
                                className="is-rounded"
                                src={group.avatar}
                                alt={`Avatar of ${group.name}`}
                              />
                            ) : (
                              <GroupAccountIcon fill="#919FA9" />
                            )}
                          </div>
                        </div>
                        <div className="level-right name-part">
                          <div className="level-item">{group.name}</div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </>
      </div>

      <div className="menu options-list">
        <ul className="menu-list">
          <li>
            <NavLink to={`/company/${companyId}/visitors`} exact={true}>
              <Eye className="icon" />
              <span>Visitors</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={`/company/${companyId}/updates`}>
              <Newspaper className="icon" />
              <span>Updates</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={`/company/${companyId}/followers`}>
              <People className="icon" />
              <span>Followers</span>
            </NavLink>
          </li>
        </ul>
        <div className="button-container button-invite">
          {companyId && role === CompanyUserRoles.Admin && (
            <button
              className="button is-secondary"
              onClick={() => store.dispatch(setInviteMemberModalOpen(true))}
            >
              <span className="icon">
                <PersonAdd size="1rem" />
              </span>
              {sidebarOpen && <span>Invite team members</span>}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default SidebarCompanyList;
