import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { USER_PLAN } from '../../services/user-service';
import { RootState } from '../../store/reducers';
import Banner from './Banner';
import * as Styled from './UpgradeBanner.styles';

const UpgradeBanner: React.FC = () => {
  const isOnUpgradePage = !!useRouteMatch('/upgrade');
  const user = useSelector((root: RootState) => root.auth.user);

  if (!user || user.plan !== USER_PLAN.FREE || isOnUpgradePage) {
    return null;
  }

  return (
    <Banner>
      <Styled.Wrapper>
        <p>
          You are on the Free Plan. <b>Upgrade now, get more data history and access to more features!</b>
        </p>
        <Styled.Button to='/upgrade' className='button is-contrast ml-4'>
          Upgrade
        </Styled.Button>
      </Styled.Wrapper>
    </Banner>
  );
};

export default UpgradeBanner;
