import { ACTION_TYPE } from '.';
import { ScheduledActivity } from '../../services/schedule-service';

export interface SetScheduledActivitiesAction {
  type: ACTION_TYPE.SET_SCHEDULED_ACTIVITIES_FOR_ACCOUNT;
  payload: {
    activities: ScheduledActivity[];
    accountId: string;
  };
}

export const setScheduledActivities = (
  accountId: string,
  activities: ScheduledActivity[]
): SetScheduledActivitiesAction => ({
  type: ACTION_TYPE.SET_SCHEDULED_ACTIVITIES_FOR_ACCOUNT,
  payload: { accountId, activities },
});

export interface SetCurrentScheduledActivityAction {
  type: ACTION_TYPE.SET_CURRENT_SCHEDULED_ACTIVITY;
  payload: ScheduledActivity | null;
}

export const setCurrentScheduledActivity = (
  scheduledActivity: ScheduledActivity | null
): SetCurrentScheduledActivityAction => ({
  type: ACTION_TYPE.SET_CURRENT_SCHEDULED_ACTIVITY,
  payload: scheduledActivity,
});

export interface SetScheduledGroupActivitiesAction {
  type: ACTION_TYPE.SET_SCHEDULED_ACTIVITIES_FOR_GROUP;
  payload: {
    activities: ScheduledActivity[];
    groupId: string;
  };
}

export const setScheduledGroupActivities = (
  groupId: string,
  activities: ScheduledActivity[]
): SetScheduledGroupActivitiesAction => ({
  type: ACTION_TYPE.SET_SCHEDULED_ACTIVITIES_FOR_GROUP,
  payload: { groupId, activities },
});

export interface SetChosenAnalyticJob {
  type: ACTION_TYPE.SET_CHOSEN_ANALYTIC_JOB;
  payload: {
    name: string;
    jobId: string;
  } | null;
}

export const setChosenAnalyticJob = (jobId: string | null, name: string | null): SetChosenAnalyticJob => ({
  type: ACTION_TYPE.SET_CHOSEN_ANALYTIC_JOB,
  payload: jobId && name ? { jobId, name } : null,
});

export type ScheduledActivitiesAction =
  | SetScheduledActivitiesAction
  | SetCurrentScheduledActivityAction
  | SetScheduledGroupActivitiesAction
  | SetChosenAnalyticJob;
