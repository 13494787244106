import styled from 'styled-components';
import variables from '../../styles/variables';
import CloseButtonComponent from '../CloseButton';

const Wrapper = styled.div`
  z-index: 3001;
  overflow-y: scroll;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(10, 10, 10, 0.85);
  animation: fadeIn 0.25s ease forwards;
`;

const Content = styled.div<{ fullWidth: boolean }>`
  position: relative;
  opacity: 0;
  animation: fadeUp 0.25s ease forwards;
  max-width: 100%;
  max-height: calc(100vh - 5rem);
  @media (min-width: 768px) {
    min-width: 640px;
    ${props => (props.fullWidth ? '' : 'max-width: 640px')};
  }
`;

const ContentCard = styled.div<{ noPadding?: boolean }>`
  padding: 1rem 2rem 1.5rem;
  ${props => (props.noPadding ? 'padding: 0;' : '')}
  border-radius: 10px;
  background-color: ${variables.contrast};
  //box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.1);
`;

const Headline = styled.h5`
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${variables.primaryDark};
  padding-right: 1.5rem;
`;

const CloseButton = styled(CloseButtonComponent)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const Styled = { Wrapper, Background, Content, ContentCard, Headline, CloseButton };
