import React, { useMemo, useState } from "react";
import { GroupUserRoles } from "../accountSharing/AccountSharingModal";
import InviteGroupMember from "../accountSharing/InviteGroupMember";
import Modal from "../modal/Modal";
import { Styled as StyledModal } from "../modal/Modal.styles";
import { store } from "../../store";
import { setInviteMemberModalOpen } from "../../store/actions/settings";
import { inviteToGroup } from "../../services/groups-service";
import { useParams } from "react-router-dom";
import Styled from "../media/MediaUploadDroparea.styles";

const InviteContext = React.createContext({});

interface Props {}

const InviteGroupMemberWrapper: React.FC<Props> = () => {
  const { groupId } = useParams<{ groupId: string }>();

  const [emailInput, setEmailInput] = useState<string>("");
  const [groupRole, setGroupRole] = useState<GroupUserRoles>(
    GroupUserRoles.User
  );
  const [isIncludeStatistics, setIncludeStatistics] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isActive = useMemo(() => !!emailInput, [emailInput]);

  const close = () => store.dispatch(setInviteMemberModalOpen(false));

  const invite = () => {
    if (isLoading) return;

    const emails = emailInput
      .split(",")
      .map(email => email.trim())
      .filter(email => email.length);

    setIsLoading(true);
    inviteToGroup(groupId, emails, groupRole, isIncludeStatistics).finally(
      () => {
        setIsLoading(false);
        close();
      }
    );
  };

  return (
    <Modal className="accountSharingModal" open={true} fullWidth>
      <div className="box connect-account">
        <StyledModal.ContentCard style={{ boxShadow: "none" }}>
          {isLoading && <Styled.LoadingIndicator active={true} />}

          <InviteContext.Provider
            value={{
              userInputData: [emailInput, setEmailInput],
              userGroupRole: [groupRole, setGroupRole],
              includeStatistics: [isIncludeStatistics, setIncludeStatistics]
            }}
          >
            <div id="accountSharingModal">
              <button type="button" className="modal-close" onClick={close} />
              <div className="wrapper">
                <InviteGroupMember context={InviteContext} />
              </div>
              <div className="modal-footer">
                <div className="button-container responsive-container">
                  {isActive && (
                    <button className="button is-primary" onClick={invite}>
                      Invite people
                    </button>
                  )}
                  <button className="button is-secondary" onClick={close}>
                    Close popup
                  </button>
                </div>
              </div>
            </div>
          </InviteContext.Provider>
        </StyledModal.ContentCard>
      </div>
    </Modal>
  );
};

export { InviteGroupMemberWrapper };
