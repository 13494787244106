import React from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import * as Styled from './OnlyLoggedXStyles.styles';

const OnlyHasAccessRoute: React.FC = props => {
  const location = useLocation();
  const { accountId } = useParams<{ accountId: string }>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { sharedConnections, groupsConnections } = useSelector((state: RootState) => state.linkedAccounts);

  let redirectUrl: string = '/register';
  if (location.pathname.length && location.pathname !== '/') {
    redirectUrl = `${redirectUrl}?redirect=${location.pathname}`;
  }

  if (user === undefined) {
    // User is not yet loaded
    return (
      <Styled.Wrapper>
        <Styled.Loader active />
        <Styled.Text>Loading...</Styled.Text>
      </Styled.Wrapper>
    );
  }
  if (user === null) {
    // User is not logged in
    return <Redirect to={redirectUrl}></Redirect>;
  } else {
    // User is logged in

    if (accountId !== user.mainAccount) {
      const filterKey = /\/schedule$/.test(location.pathname) ? 'isSharedSchedule' : 'isSharedAnalytics';
      const isShared = (sharedConnections?.find(connection => connection._id === accountId) || {})[filterKey];
      const isGroupShared =
        groupsConnections?.find(connection => connection._id === accountId && connection[filterKey]) || {};
      if (!isShared && !isGroupShared) {
        return <Redirect to={'/'} />;
      }
    }

    return <>{props.children}</>;
  }
};

export default OnlyHasAccessRoute;
