import React, { useState } from 'react';
import HowToLinkAccount from '../HowToLinkAccount';
import * as Styled from './LinkPersonalAccountPopup.styles';

const LinkPersonalAccountPopup: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [isInstalled, setIsInstalled] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  return (
    <div className='box'>
      <Styled.Delete className='delete' onClick={onClose}></Styled.Delete>
      <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>Link personal account</h5>
      <p className='mb-4'>Follow the instructions below to connect your personal account:</p>
      <HowToLinkAccount
        setIsInstalled={setIsInstalled}
        isInstalled={isInstalled}
        isConnected={isConnected}
        setIsConnected={setIsConnected}
      />
      <Styled.Buttons className='level'>
        <button className='button is-primary is-left' disabled={!isConnected} onClick={onClose}>
          Continue
        </button>
        <button className='button is-outlined is-right' onClick={onClose}>
          Cancel
        </button>
      </Styled.Buttons>
    </div>
  );
};

export default LinkPersonalAccountPopup;
