import React, { Dispatch, Fragment, SetStateAction, useContext } from 'react';
import * as StyledLoading from '../../../styles/common/Loading.styles';
import SettingHeader from '../../accountSettings/SettingHeader';
import { CompanySettings, getConnectedCompany } from '../../../services/company-service';
import { useMutation } from 'react-query';
import { APIError } from '../../../services/api-client';
import { AccountSharingModalContext } from '../../accountSharing/AccountSharingModal';
import './ConnectCompany.scss';

interface Props {
  setChosenCompany: Dispatch<SetStateAction<string>>;
  chosenCompany: string;
}

const ConnectCompany: React.FC<Props> = ({ chosenCompany, setChosenCompany }) => {
  const {
    connectedCompany: [settings, setSettings],
  } = useContext(AccountSharingModalContext);

  const [loadCompany, { isLoading }] = useMutation<CompanySettings[], APIError, void>(getConnectedCompany, {
    onSuccess: data => {
      setSettings(data as SetStateAction<CompanySettings[]>);
    },
  });

  const loadCompanyHandler = () => {
    loadCompany().catch(e => console.log(e.message));
  };

  const setChosenURNHandler = (id: string) => setChosenCompany(id);

  return (
    <div>
      <div className='settings-card ConnectCompany pb-2'>
        <SettingHeader
          headline='Connect Company Page'
          description={
            'In this step you will need to login to your LinkedIn account to authorize us to observe the data from your company profile and visualize it here.'
          }
          tip=''
        />
        {settings.length ? (
          <table className='list-grid single-account-sharing-settings'>
            <thead className='header'>
              <tr>
                <th className='setting-column'>Company Name</th>
                <th className='setting-column'>Role</th>
                <th className='setting-column has-text-right'>Team Members</th>
              </tr>
            </thead>
            <tbody>
              {settings.map(({ id, name, members, logo, role }) => (
                <Fragment key={id}>
                  <tr
                    className={`list-grid__account-row ${chosenCompany === id ? 'list-grid__account-row-active' : ''}`}
                    onClick={() => setChosenURNHandler(id)}>
                    <td className='list-grid__cell list-grid__cell--name'>{name}</td>
                    <td className='list-grid__cell list-grid__cell--name'>{role}</td>
                    <td className='list-grid__cell list-grid__cell--name has-text-right'>{members}</td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        ) : isLoading ? (
          <StyledLoading.LoadingContainer>
            <div>
              <StyledLoading.LoadingBarSmall active />
            </div>
          </StyledLoading.LoadingContainer>
        ) : (
          <div className='no-content'>
            <img src='/assets/images/parcel.svg' alt='empty box' />
            <span className='no-content__text mb-1'>No content</span>
            <button className='button is-primary' onClick={loadCompanyHandler}>
              Load connected company
            </button>
          </div>
        )}
      </div>
      {!settings.length && isLoading && (
        <p className='description' style={{ transform: 'translateY(30px)', fontSize: '14px', color: '#919fa9' }}>
          We are loading the data from LinkedIn. Please stay on this page until the data has loaded. This can take a few
          minutes.
        </p>
      )}
    </div>
  );
};

export { ConnectCompany };
