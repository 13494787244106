import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Settings } from "@styled-icons/ionicons-solid";
import classNames from "classnames";
import { Timer } from "@styled-icons/fluentui-system-filled";
import { differenceInDays } from "date-fns";
import {
  AnalyticJob,
  AnalyticJobStatus,
  AudienceAvailable,
  deleteAnalyticJob,
  deleteDaysNumber,
  getApproximateNumberOfPosts,
  TargetGroup
} from "../../../services/audience.service";
import { RootState } from "../../../store/reducers";
import { USER_PLAN } from "../../../services/user-service";
import { Button } from "../../button/Button";
import DeleteButton from "../../button/DeleteButton";
import { setChosenAnalyticJob } from "../../../store/actions/scheduledActivities";
import { DeleteModal } from "../../linked-accounts/delete-modal/DeleteModal";
import Modal from "../../modal/Modal";
import { APIError } from "../../../services/api-client";
import { useMutation } from "react-query";
import StyledTooltip from "../../StyledTooltip";
import "./AudienceTable.scss";
import {
  AudienceSettingInitValue,
  CreateAudience
} from "../createAudience/CreateAudience";

interface Props {
  analyticJobs: AnalyticJob[];
  setIsModalOpen?: React.Dispatch<React.SetStateAction<any>>;
  skipActions?: boolean;
  selectable?: boolean;
  isNoneActive?: boolean;
}
const VipUsers: string[] = [
  "yuhrynkiv@gmail.com",
  "yuyuspamer@gmail.com",
  "tim@inlytics.io",
  "eugen@inlytics.io",
  "kevin@kevinmeyerconsulting.de",
  "jens.polomski@gmail.com"
];

const AudienceTable: React.FC<Props> = ({
  analyticJobs,
  setIsModalOpen,
  skipActions,
  selectable,
  isNoneActive
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { chosenAnalyticJob } = useSelector(
    (state: RootState) => state.scheduledActivities
  );

  const [isSettingsOpened, setIsSettingsOpened] = useState<boolean>(false);
  const [settingValues, setSettingsValues] = useState<
    AudienceSettingInitValue
  >();

  const [jobToDelete, setJobToDelete] = useState<string>();
  const [jobs, setJobs] = useState<AnalyticJob[]>(analyticJobs);

  const [deleteMutate] = useMutation<AnalyticJob, APIError, string>(
    deleteAnalyticJob
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setJobs(analyticJobs);
  }, [analyticJobs]);

  const choseHandler = (job: AnalyticJob | null) => {
    dispatch(
      setChosenAnalyticJob(
        job ? job.statusData.jobID : null,
        job ? job.statusData.name : null
      )
    );
  };

  const deleteHandler = () => {
    deleteMutate(jobToDelete)
      .then(data => {
        setJobs(prevJobs =>
          prevJobs.map(item => (item._id === data?._id ? data : item))
        );
      })
      .catch(e => console.log(e.message));
    setJobToDelete.bind(null, "");
  };

  const openSettingHandler = (job: AnalyticJob) => {
    setSettingsValues({
      name: job.statusData.name,
      countries:
        job.targetGroup?.country?.map(item => ({ label: item, value: item })) ||
        [],
      occupations:
        job.targetGroup?.occupation?.map(item => ({
          label: item,
          value: item
        })) || []
    });
    setIsSettingsOpened(true);
  };

  const renderEmptyRow = () => (
    <tr>
      <td className="name">- empty -</td>
      <td>-</td>
      {!skipActions && (
        <td>
          <span
            className="add-audience"
            onClick={setIsModalOpen && setIsModalOpen.bind(null, true)}
          >
            Add audience
          </span>
        </td>
      )}
    </tr>
  );

  const getTargeting = (targetGroup: TargetGroup): string => {
    const countries = targetGroup?.country?.sort() || [];
    const occupations = targetGroup?.occupation?.sort() || [];

    if (!countries.length && !occupations.length) {
      return "-";
    }

    return [...countries, ...occupations].join(", ");
  };

  const renderActions = (
    job: AnalyticJob,
    jobId: string,
    isDeleted: boolean,
    isRunning: boolean,
    deleteTimestamp: number
  ) => {
    const dayNumber =
      deleteDaysNumber - differenceInDays(Date.now(), deleteTimestamp);

    return (
      <>
        {isDeleted ? (
          <div className="has-text-grey timer-block">
            {dayNumber}
            <Timer size="1.3rem" className="ml-1" />
          </div>
        ) : (
          <>
            <Button
              onClick={openSettingHandler.bind(null, job)}
              className="p-1"
              disabled={isRunning}
            >
              <Settings size="1rem" />
            </Button>
            <DeleteButton
              disabled={isRunning}
              className="p-1"
              onClick={setJobToDelete.bind(null, jobId)}
            />
          </>
        )}
      </>
    );
  };
  if (user && VipUsers.includes(user.email)) {
    user.test50 = 50;
  }
  return (
    <>
      <div className="audience-table-wrapper has-text-grey">
        <table>
          <thead>
            <tr>
              <td className="name">Name</td>
              <td>Targeting</td>
              <td>Post Considered (size)</td>
              {!skipActions && <td>Actions</td>}
            </tr>
          </thead>
        </table>
      </div>

      <div className="audience-table-wrapper has-text-grey">
        <table>
          <tbody className="scrollInit">
            {isNoneActive && (
              <tr
                className={classNames({
                  chosen: selectable && chosenAnalyticJob === null,
                  selectable
                })}
                onClick={() => {
                  selectable && choseHandler(null);
                }}
              >
                <td>- none (default) -</td>
                <td />
                <td />
              </tr>
            )}
            {user &&
              user?.plan !== USER_PLAN.FREE &&
              user?.test50 === undefined &&
              Array.from(
                Array(
                  isNoneActive ? jobs.length : AudienceAvailable[user.plan]
                ).keys()
              ).map(index => {
                const job = jobs[index];

                if (!job) return renderEmptyRow();

                const isChosen =
                  job.statusData.jobID === chosenAnalyticJob?.jobId;
                const isDeleted =
                  job.statusData.status === AnalyticJobStatus.DELETED;
                const isRunning =
                  job.statusData.status === AnalyticJobStatus.RUNNING;
                const isAborted =
                  job.statusData.status === AnalyticJobStatus.ABORTED;

                return (
                  <tr
                    key={job.statusData.jobID}
                    className={classNames({
                      "has-text-dark-blue": !isRunning,
                      chosen:
                        selectable && isChosen && !isRunning && !isDeleted,
                      selectable: selectable && !isDeleted && !isRunning
                    })}
                    onClick={() => {
                      selectable &&
                        !isRunning &&
                        !isDeleted &&
                        choseHandler(job);
                    }}
                  >
                    <td
                      className={`name ${isDeleted && "deleted"} ${isAborted &&
                        "aborted"}`}
                    >
                      {job.statusData.name}
                    </td>
                    {isAborted ? (
                      <>
                        <td style={{ minWidth: 322 }}>
                          <div
                            className="aborted"
                            style={{ whiteSpace: "normal" }}
                          >
                            {job.statusData.message}
                          </div>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className={`${isDeleted && "deleted"}`}>
                          {getTargeting(job.targetGroup)}
                        </td>
                      </>
                    )}

                    {!isAborted && (
                      <>
                        <td className={isDeleted ? "deleted" : ""}>
                          {isRunning ? (
                            <>
                              <div data-tip data-for="calculating">
                                Calculating
                              </div>
                              <StyledTooltip id="calculating" delayHide={1000}>
                                <div>
                                  Calculating can take a couple of minutes
                                </div>
                              </StyledTooltip>
                            </>
                          ) : (
                            getApproximateNumberOfPosts(job.activitiesFound)
                          )}
                        </td>
                      </>
                    )}
                    {!skipActions && (
                      <td>
                        {renderActions(
                          job,
                          job._id,
                          isDeleted,
                          isRunning,
                          job.statusData.deletedAt
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            {user &&
              user?.plan !== USER_PLAN.FREE &&
              user?.test50 === 50 &&
              Array.from(
                Array(isNoneActive ? jobs.length : user.test50).keys()
              ).map(index => {
                const job = jobs[index];

                if (!job) return renderEmptyRow();

                const isChosen =
                  job.statusData.jobID === chosenAnalyticJob?.jobId;
                const isDeleted =
                  job.statusData.status === AnalyticJobStatus.DELETED;
                const isRunning =
                  job.statusData.status === AnalyticJobStatus.RUNNING;

                return (
                  <tr
                    key={job.statusData.jobID}
                    className={classNames({
                      "has-text-dark-blue": !isRunning,
                      chosen:
                        selectable && isChosen && !isRunning && !isDeleted,
                      selectable: selectable && !isDeleted && !isRunning
                    })}
                    onClick={() => {
                      selectable &&
                        !isRunning &&
                        !isDeleted &&
                        choseHandler(job);
                    }}
                  >
                    <td className={`name ${isDeleted && "deleted"}`}>
                      {job.statusData.name}
                    </td>
                    <td className={`${isDeleted && "deleted"}`}>
                      {getTargeting(job.targetGroup)}
                    </td>
                    <td className={isDeleted ? "deleted" : ""}>
                      {isRunning ? (
                        <>
                          <div data-tip data-for="calculating">
                            Calculating
                          </div>
                          <StyledTooltip id="calculating" delayHide={1000}>
                            <div>Calculating can take a couple of minutes</div>
                          </StyledTooltip>
                        </>
                      ) : (
                        getApproximateNumberOfPosts(job.activitiesFound)
                      )}
                    </td>
                    {!skipActions && (
                      <td>
                        {renderActions(
                          job,
                          job._id,
                          isDeleted,
                          isRunning,
                          job.statusData.deletedAt
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {jobToDelete && (
          <Modal className="" open={true} fullWidth>
            <div className="box delete-modal">
              <DeleteModal
                id={jobToDelete}
                type="Audience"
                label="remove"
                onClose={setJobToDelete.bind(null, "")}
                onAccept={deleteHandler}
              />
            </div>
          </Modal>
        )}
      </div>

      {isSettingsOpened && (
        <CreateAudience
          isOpen={!!settingValues}
          setIsOpen={setIsSettingsOpened}
          isForSettings
          initialValues={settingValues}
        />
      )}
    </>
  );
};

export { AudienceTable };
