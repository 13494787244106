import { Link } from '@styled-icons/ionicons-solid';
import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';
import * as Styled from './Button.styles';

// TODO: make this button generic
const UnlinkButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <Styled.Button {...props} className={classNames('button is-outlined', props.className)}>
      <Link size='1rem' />
    </Styled.Button>
  );
};

export default UnlinkButton;
