import styled from 'styled-components';

export const TeaserWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const TeaserImage = styled.img`
  flex: 0 0 100%;
  filter: blur(5px);
`;

export const CtaContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CtaText = styled.p`
  margin-bottom: 2rem;
  font-weight: bold;
  font-size: 2rem;
`;
