import apiClient from './api-client';
import { store } from '../store';
import { setUser } from '../store/actions/auth';
import { updateUserState } from './user-service';

export async function logout() {
  await apiClient.post(`/auth/logout`);
  store.dispatch(setUser(null));
}

export async function loginLocal(email: string, password: string) {
  // Login
  await apiClient.post(`/auth/login`, { email, password });
  // Update user state, as this actually updates the user in the store
  await updateUserState();
}

export interface RegisterPayload {
  email: string;
  familyName: string;
  givenName: string;
  password: string;
  confirmPassword: string;
  tosAndPrivacy: boolean;
  promotionCode?: string;
  invitedBy?: string;
}

export async function register(values: RegisterPayload) {
  // Login
  await apiClient.post(`/auth/register`, values);
  // Update user state, as this actually updates the user in the store
  await updateUserState();
}

export interface UpdatePasswordPayload {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export async function updatePassword(values: UpdatePasswordPayload) {
  await apiClient.patch(`/auth/password`, values);
}

export async function forgotPassword(email: string) {
  await apiClient.post(`/auth/password/forgot`, { email });
}

export interface ResetPasswordPayload {
  password: string;
  confirmPassword: string;
  token: string;
}

export async function resetPassword(payload: ResetPasswordPayload) {
  await apiClient.post(`/auth/password/reset`, payload);
}
