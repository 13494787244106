import React, { useState } from 'react';

import { FlaskIcon, ShieldCheckmarkIcon } from '../CustomIcons';
import { CompanyUserRoles } from '../accountSharing/AccountSharingModal';
import { CompanyOffer } from '../../services/company-service';

const ROLE_ICONS = {
  [CompanyUserRoles.Analyst]: <FlaskIcon />,
  [CompanyUserRoles.Admin]: <ShieldCheckmarkIcon />,
};

interface Props {
  data: CompanyOffer;
  handleOffer: ({ companyId, confirmed }: { companyId: string; confirmed: boolean }) => void;
}

export const JoinCompanyRequest: React.FC<Props> = ({ data, handleOffer }) => {
  const { id, firstName, lastName, role, avatar, companyName } = data;

  const [isExpanded, setExpanded] = useState(false);

  return (
    <div className='security-settings-connection-request'>
      {isExpanded && <h4 className='security-settings-title'>Offer to join {companyName}</h4>}
      <div className='security-settings-shared'>
        <img
          className='security-settings-shared__profile-picture'
          src={avatar || '/assets/images/default-avatar.svg'}
          alt="user's avatar"
        />
        <p className='security-settings-shared__profile-name'>
          {firstName} {lastName}
        </p>
        <input
          type='checkbox'
          id={`security-settings-shared-expand-control-${id}`}
          name={`security-settings-expand-control-${id}`}
          className='security-settings-shared__expand-checkbox'
          onChange={() => setExpanded(!isExpanded)}
        />
        <label
          htmlFor={`security-settings-shared-expand-control-${id}`}
          className='security-settings-shared__expand-label'>
          See offered permissions
        </label>
      </div>
      {isExpanded && (
        <>
          <div className='security-settings-shared-expand'>
            <p className='security-settings-shared-expand__note'>
              You can always change your settings later by navigating to your organization settings.
            </p>
            <p className='security-settings-shared-expand__description'>{`${firstName} wants you to join as:`}</p>
            <div className='shared-areas'>
              <div className='shared-areas__item'>
                {ROLE_ICONS[role]}
                <span className='shared-areas__item-title shared-areas__item-title--capitalize'>
                  {role.toLowerCase()}
                </span>
              </div>
            </div>
            <a className='security-settings-shared-expand__a' href='https://www.inlytics.io/article/groups'>
              Learn more about roles in companies (Coming soon)
            </a>
          </div>
          <div className='security-settings-btn-group'>
            <button className='btn is-secondary' onClick={() => handleOffer({ companyId: id, confirmed: true })}>
              Decline
            </button>
            <button className='btn is-primary' onClick={() => handleOffer({ companyId: id, confirmed: true })}>
              Accept
            </button>
          </div>
        </>
      )}
    </div>
  );
};
