import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { OptionsType } from 'react-select';
import classNames from 'classnames';
import Modal from '../../modal/Modal';
import { Styled } from '../../modal/Modal.styles';
import {
  AnalyticJob,
  AudienceOptions,
  createAnalyticJob,
  CreateAnalyticJobBody,
  getAudienceOptions,
  Occupation,
} from '../../../services/audience.service';
import { APIError } from '../../../services/api-client';
import { Select } from '../select/Select';
import LoadingBar from '../../loader/LoadingBar';
import './CreateAudience.scss';
import { RefetchOptions } from 'react-query/types/core/query';

export interface AudienceSettingInitValue {
  countries: OptionsType<{ label: string; value: string }>;
  occupations: OptionsType<{ label: string; value: string }>;
  name: string;
}

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isForSettings?: boolean;
  initialValues?: AudienceSettingInitValue;
  refetch?: (options?: RefetchOptions) => Promise<AnalyticJob[] | undefined>;
}

const CreateAudience: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  isForSettings,
  initialValues,
  refetch,
}) => {
  const [name, setName] = useState<string>(initialValues?.name || '');

  const [chosenOccupations, setChosenOccupations] = useState<
    OptionsType<{ label: string; value: string }>
  >(initialValues?.occupations || []);
  const [chosenCountries, setChosenCountries] = useState<
    OptionsType<{ label: string; value: string }>
  >(initialValues?.countries || []);

  const [occupationOptions, setOccupationOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [countryOptions, setCountryOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const [allOccupations, setAllOccupations] = useState<Occupation[]>([]);

  const [loadOptions, { isLoading }] = useMutation<
    AudienceOptions,
    APIError,
    AudienceOptions
  >(getAudienceOptions, {
    onSuccess: data => {
      const countriesOptions = data.countries.values.map(c => ({
        label: c.Country,
        value: c.Country,
      }));
      setCountryOptions(countriesOptions);
      setAllOccupations(data.occupations);
    },
  });

  const [
    createAudienceMutation,
    { isLoading: isCreatingLoading, error },
  ] = useMutation<AnalyticJob, APIError, CreateAnalyticJobBody>(
    createAnalyticJob,
    {
      onSuccess: () => {
        setIsOpen(false);
      },
    }
  );

  useEffect(() => {
    if (!chosenCountries.length) {
      setChosenOccupations([]);
      setOccupationOptions([]);
      return;
    }

    const countries = chosenCountries.map(item => item.value);
    const newOccupations: Set<string> = new Set();

    countries.forEach(country => {
      const neededOcc = allOccupations.find(occ => occ.country === country);
      if (neededOcc)
        neededOcc.values.forEach(value => {
          newOccupations.add(value.Occupation);
        });
    });

    const occupationsOptions = Array.from(newOccupations).map(value => ({
      value,
      label: value,
    }));
    setOccupationOptions(occupationsOptions);
  }, [chosenCountries, allOccupations]);

  useEffect(() => {
    if (!occupationOptions.length) return;

    const newChosenOccupations = occupationOptions.filter(occ =>
      chosenOccupations.find(chosen => chosen.value === occ.value)
    );
    if (newChosenOccupations.length !== chosenOccupations.length)
      setChosenOccupations(newChosenOccupations);
  }, [occupationOptions, chosenOccupations]);

  useEffect(() => {
    if (isOpen) {
      loadOptions().catch(e => console.log(e));
    }
  }, [isOpen, loadOptions]);

  const createHandler = () => {
    if (name)
      createAudienceMutation({
        name,
        country: chosenCountries.map(item => item.value),
        occupation: chosenOccupations.map(item => item.value),
      })
        .then(() => refetch && refetch())
        .catch(e => console.log(e.message));
  };

  return (
    <>
      <Modal
        className='CreateAudience'
        fullWidth
        open={isOpen}
        onClose={setIsOpen.bind(null, false)}
      >
        <Styled.ContentCard noPadding>
          <Styled.CloseButton onClick={setIsOpen.bind(null, false)} />
          <>
            <div className='inputs-wrapper'>
              {isCreatingLoading ? (
                <LoadingBar active={isCreatingLoading} />
              ) : (
                <div className='ghost-block' />
              )}
              {!isForSettings && <div className='alpha'>Beta</div>}
              <h2 className='title mb-4'>
                {isForSettings ? 'View Your Audience' : 'New Target Audience'}
              </h2>

              {isForSettings ? (
                <p className='has-text-grey has-text-justified mb-6'>
                  In this view you can view the targeting options of your
                  audience. To change it you have to create a new audience. If
                  you delete an audience it will take 30 days to recover that
                  slot.
                </p>
              ) : (
                <p className='has-text-grey has-text-justified mb-6'>
                  Define the target audience that you want to reach with your
                  content on Linkedin.
                  <span className='has-text-weight-bold'>
                    {' '}
                    Within the scheduling tool{' '}
                  </span>{' '}
                  we will
                  <span className='has-text-weight-bold'>
                    {' '}
                    show you the days and time{' '}
                  </span>
                  when your chances of getting the
                  <span className='has-text-weight-bold'>
                    {' '}
                    most views{' '}
                  </span>{' '}
                  with your content are the highest. You can change your target
                  audience slot every 30 days in your{' '}
                  <Link to='/settings'>settings</Link>.
                </p>
              )}

              <h3 className='has-font-size-2 has-text-weight-normal has-text-grey mb-3'>
                Audience Name
              </h3>
              <div className='input-item has-font-size-3 mb-4'>
                <label className='mr-auto'>Name of audience</label>
                <input
                  disabled={isForSettings}
                  value={name}
                  onChange={e => setName(e.target.value)}
                  type='text'
                  className='name-input'
                />
              </div>

              <h3 className='has-font-size-2 has-text-weight-normal has-text-grey mb-3'>
                Targeting
              </h3>
              <div className='input-item has-font-size-3 mb-4 select-container'>
                <label className='mr-auto'>Based in..</label>
                <Select
                  isLoading={isLoading}
                  isDisabled={isCreatingLoading || isForSettings}
                  value={chosenCountries.map(item => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  onChange={value => setChosenCountries(value)}
                  className='select-input'
                  options={countryOptions}
                  isMulti
                />
              </div>
              <div className='input-item has-font-size-3 mb-4 select-container'>
                <label className='mr-auto'>I`m trying to reach...</label>
                <Select
                  isLoading={isLoading}
                  isDisabled={isCreatingLoading || isForSettings}
                  value={chosenOccupations.map(item => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  onChange={value => setChosenOccupations(value)}
                  className='select-input'
                  options={occupationOptions}
                  noOptionsMessage='Choose country to see options'
                  isMulti
                />
              </div>
            </div>
            <div className='button-container'>
              {!isForSettings && (
                <button
                  className={classNames('button is-primary mr-4', {
                    disabled:
                      !name ||
                      !chosenOccupations.length ||
                      !chosenCountries.length,
                  })}
                  onClick={createHandler}
                  disabled={
                    !name ||
                    !chosenOccupations.length ||
                    !chosenCountries.length ||
                    isCreatingLoading ||
                    isLoading
                  }
                >
                  Create Audience
                </button>
              )}
              <button
                className={`button ${
                  isForSettings ? 'is-primary' : 'is-secondary'
                }`}
                onClick={setIsOpen.bind(null, false)}
              >
                {isForSettings
                  ? 'Thanks, got it!'
                  : 'Skip for now, remind me later'}
              </button>
            </div>
            {error && (
              <div className='notification is-danger is-light px-6'>
                {error.message}
              </div>
            )}
          </>
        </Styled.ContentCard>
      </Modal>
    </>
  );
};

export { CreateAudience };
