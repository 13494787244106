import React from 'react';
import '../styles/components/ChartTooltip.scss';

const ChartTooltip = (props: {
  fill?: string;
  value: string;
  textAnchor: 'start' | 'end' | 'middle';
  fontSize?: number;
  viewBox: { width: number; x: number; y: number };
  dx?: number;
  dy?: number;
}) => {
  const { fill, value, textAnchor = 'start', fontSize = 14, viewBox, dy = 20 } = props;
  const x = viewBox.width + viewBox.x + 10;
  const y = viewBox.y - 6;

  return (
    <>
      <text x={x} y={y} dy={dy} fill={fill} fontSize={fontSize} textAnchor={textAnchor}>
        {value.split('.').map(string => (
          <tspan x={x} dy={y + 1.2 * fontSize}>
            {string.trim()}
          </tspan>
        ))}
      </text>
    </>
  );
};

export { ChartTooltip };
