import styled from 'styled-components';
import variables from '../variables';

const Header = styled.td`
  color: ${variables.primaryDark};
  border-bottom: 2px solid ${variables.lightGrey};

  &.is-centered {
    text-align: center;
  }
`;

export const StyledTable = { Header };
