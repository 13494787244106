import styled from 'styled-components';
import { variables, colors } from '../../styles/variables';
import { CheckmarkCircle } from '@styled-icons/ionicons-solid';
import { Link } from 'react-router-dom';
import { StyledCommon } from '../../styles/common/Common.styles';

export const Wrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 0;
`;

export const Card = styled(StyledCommon.Card)`
  padding-bottom: 0;
`;

export const Table = styled.table`
  && {
    margin-bottom: 0;
  }

  .table__cell {
    &--name {
      min-width: 130px;
    }
  }
`;

export const TableCell = styled.td`
  && {
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;

    min-width: 130px;
    max-width: 200px;
  }

  &.is-centered {
    text-align: center;
  }

  &.is-avatar {
    min-width: 0;
  }

  &.has-text-orange {
    color: #f9560d;
  }

  .inline-block {
    display: inline-block;
  }
`;

export const AvatarCell = styled(TableCell)`
  width: 5rem;
`;

export const Avatar = styled.img`
  height: auto;
  padding: 0.25rem;
  && {
    width: 5rem;
  }
`;

export const MainAccountCheckmark = styled(CheckmarkCircle)`
  position: absolute;
  top: 0rem;
  right: 0rem;
  width: 1.5rem;
  fill: ${variables.primary};
  background: white;
  border-radius: 50%;
`;

export const ProductButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ProductButton = styled(Link)`
  padding: 3rem 2rem;
  border: none;
  border-radius: 0;
  color: ${variables.primaryDark};
  font-weight: bold;

  &:hover {
    background-color: ${colors.lightGrey};
    color: ${variables.primaryDark};
  }

  span {
    margin-left: 0.5rem;
    font-size: 1rem;
  }
`;

export const ProductButtonDisabled = styled.span`
  padding: 3rem 2rem;
  border: none;
  cursor: not-allowed;
  font-weight: bold;

  &:hover {
    color: ${variables.textColor};
  }

  span {
    margin-left: 0.5rem;
  }
`;

export const LinkToPlan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 1;
`;

export const ReconnectCell = styled.div`
  display: flex;
  justify-content: center;
`;

export const ReconnectBtn = styled.button`
  display: flex;
  align-items: center;

  margin-left: 1rem;
  border: 0;
  padding: 0 0.5rem;
  background-color: transparent;
  font-size: 1rem;
  color: rgb(0, 46, 92);
  outline: none;
  cursor: pointer;

  .margin-right {
    margin-right: 0.5rem;
  }

  .tooltip {
    cursor: default;
  }

  .link-text {
    color: ${variables.lightBlue};
    cursor: pointer;
  }
`;

export const CreateNewRow = styled.tr<{ isEmpty?: boolean }>`
  background: ${props => (props.isEmpty ? '#f5f9fc' : '#fff')};

  td {
    text-align: center !important;
    padding: 2rem 0;
    vertical-align: middle;
  }

  .create-text {
    cursor: pointer;

    color: ${variables.lightBlue};
  }

  .create-text_content {
    text-align: left;
    padding: 0px 15px;
  }

  .no-content {
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
