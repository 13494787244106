import React, { AnchorHTMLAttributes, useMemo } from 'react';
import { ReactComponent as Icon } from '../../assets/images/google.svg';
import classNames from 'classnames';
import * as Styled from './GoogleButton.styles';
import { ParsedQs } from 'qs';

interface GoogleButtonProps {
  redirectUrl?: string | ParsedQs | string[] | ParsedQs[] | null;
}

export const GoogleButton: React.FC<AnchorHTMLAttributes<HTMLAnchorElement> & GoogleButtonProps> = props => {
  const href = useMemo(() => {
    let url = `${process.env.REACT_APP_BASE_URL}/auth/google`;
    if (props.redirectUrl) {
      url += `?redirect=${props.redirectUrl}`;
    }
    return url;
  }, [props.redirectUrl]);

  return (
    <Styled.Button {...props} href={href} className={classNames('button', props.className)}>
      <Styled.Icon>
        <Icon />
      </Styled.Icon>
      <Styled.Label>{props.children}</Styled.Label>
    </Styled.Button>
  );
};

export default GoogleButton;
