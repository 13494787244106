import React, { useEffect, useState } from "react";
import Header from "../../Header";
import DateRangePicker from "../../header/DateRangePicker";
import DashboardStatisticCards from "../../DashboardStatisticCards";
import { UpdatesTimeline } from "./UpdatesTimeline";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducers";
import {
  getCompanyUpdates,
  ICompanyUpdatesAnalytics,
  ICompanyUpdatesTimeline,
  RechartData
} from "../../../services/company-service";
import * as StyledLoading from "../../../styles/common/Loading.styles";
import { InviteMemberWrapper } from "../../group/InviteMemberWrapper";
import { UpdateTable } from "./UpdatesTable";

interface Props {}

const CompanyUpdates: React.FC<Props> = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const { inviteMemberModalOpen } = useSelector(
    (state: RootState) => state.settings
  );

  const { startDate, endDate } = useSelector(
    (state: RootState) => state.dateRangeFilter
  );
  const [isLoading, setIsLoading] = useState(false);
  const [updatesData, setUpdatesData] = useState<{
    analytics: ICompanyUpdatesAnalytics;
    timeline: ICompanyUpdatesTimeline[];
    engagement: RechartData[];
  }>();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const [visData] = await Promise.all([
          getCompanyUpdates({ id: companyId, startDate, endDate })
        ]);

        setUpdatesData(visData);
        setIsLoading(false);
      } catch (e) {
        console.log(e.message);
      }
    };
    getData().catch();
  }, [companyId, startDate, endDate]);

  console.log("updatesData?.timeline", updatesData?.timeline);

  return (
    <div className="Content CompanyVisitors">
      <Header
        title={
          <>
            <span>Updates Analytics</span>
          </>
        }
        content={
          <>
            <DateRangePicker />
          </>
        }
        loading={isLoading}
      />

      {isLoading ? (
        <StyledLoading.LoadingContainer>
          <StyledLoading.LoadingBarSmall active />
        </StyledLoading.LoadingContainer>
      ) : (
        updatesData && (
          <>
            <section className="section">
              <DashboardStatisticCards
                reactions={{
                  number: updatesData.analytics.reactions || 0,
                  percentage: updatesData.analytics.reactionsPercentage || 0
                }}
                shares={{
                  number: updatesData.analytics.shares || 0,
                  percentage: updatesData.analytics.sharesPercentage || 0
                }}
                comments={{
                  number: updatesData.analytics.comments || 0,
                  percentage: updatesData.analytics.commentsPercentage || 0
                }}
                cardFilter={["comments", "reactions", "shares"]}
              />
            </section>

            <section className="section">
              <h5 className="title is-size-5 has-text-grey has-text-weight-normal">
                Visitors Metrics
              </h5>
              <UpdatesTimeline brushEnabled data={updatesData.timeline} />
            </section>
            <section className="section">
              <h5 className="title is-size-5 has-text-grey has-text-weight-normal">
                Visitors Metrics
              </h5>
              <UpdateTable brushEnabled data={updatesData.engagement} />
            </section>
          </>
        )
      )}

      {inviteMemberModalOpen && <InviteMemberWrapper isCompany />}
    </div>
  );
};

export { CompanyUpdates };
