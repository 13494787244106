import React from 'react';

import StyledTooltip from '../StyledTooltip';
import { variables } from '../../styles/variables';
import '../../styles/components/accountSharing/ToolsCard.scss';

interface Props {
  headline: string;
  description: string;
  image: any;
  state?: boolean;
  setState: any;
  helpIcon?: string;
  helpText?: string;
  disabled?: boolean;
}

const ToolsCard = (props: Props) => (
  <div className='tool'>
    {typeof props.image === 'function' ? (
      <props.image className={!props.state || props.disabled ? 'is-disabled' : ''} />
    ) : (
      <img className='icon' src={props.image} alt='' />
    )}
    <div className='text-container'>
      <h4 className={`headline ${props.disabled && 'headline--disabled'}`}>
        {props.headline}
        {!!props.helpIcon && (
          <>
            <div
              className='tool__tooltip-label'
              data-tip
              data-for={`tools-card-popup-${props.headline}`}
              style={{ color: variables.lightGrey, backgroundColor: 'rgba(0, 0, 0, 0)' }}>
              {props.helpIcon}
            </div>
            <StyledTooltip id={`tools-card-popup-${props.headline}`} delayHide={1000} clickable={true}>
              <div>{props.helpText}</div>
            </StyledTooltip>
          </>
        )}
      </h4>
      <span className='description'>{props.description}</span>
    </div>
    <label className='switch'>
      <input
        type='checkbox'
        className='tool-input'
        checked={!!props.state}
        onChange={(event: any) => props.setState(event.target.checked)}
        disabled={props.disabled}
      />
      <span className='slider round'></span>
    </label>
  </div>
);

export default ToolsCard;
