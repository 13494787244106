import React from 'react';
import { adjustHue } from 'polished';
import {
  ActivityCompanyHighlight,
  ActivityOccupationHighlight,
  ActivityRegionHighlight,
} from '../../services/activities-service';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import * as Styled from './ActivityHighlightsChart.styles';
import variables from '../../styles/variables';

export interface ActivityHighlightsChartProps {
  highlights?: Array<ActivityCompanyHighlight | ActivityOccupationHighlight | ActivityRegionHighlight>;
}

function isCompanyHighlight(
  highlights: Array<ActivityCompanyHighlight | ActivityOccupationHighlight | ActivityRegionHighlight>
): highlights is Array<ActivityCompanyHighlight> {
  return (highlights as Array<ActivityCompanyHighlight>).every(entry => entry.universalName);
}
function isCompanyRegion(
  highlights: Array<ActivityCompanyHighlight | ActivityOccupationHighlight | ActivityRegionHighlight>
): highlights is Array<ActivityRegionHighlight> {
  return (highlights as Array<ActivityRegionHighlight>).every(entry => entry.region);
}

const ActivityHighlightsChart: React.FC<ActivityHighlightsChartProps> = ({ highlights }) => {
  if (!highlights || !highlights.length) {
    return (
      <div className='ActivityHighlightsChart'>
        <i>No data yet.</i>
      </div>
    );
  }

  const nameKey = isCompanyHighlight(highlights) ? 'name' : isCompanyRegion(highlights) ? 'region' : 'title';

  return (
    <Styled.Wrapper>
      <PieChart width={400} height={400}>
        <Pie data={highlights} innerRadius={40} outerRadius={80} dataKey='amountViews' label nameKey={nameKey}>
          {highlights.map((_entry, index) => (
            <Cell key={index} fill={adjustHue(60 * index, variables.primary)} />
          ))}
        </Pie>
        <Legend />
      </PieChart>
    </Styled.Wrapper>
  );
};

export default ActivityHighlightsChart;
