import { ACTION_TYPE } from ".";
import { AccountsTableEntryViewModel } from "../../components/linked-accounts/AccountList";
import {
  AccountConnection,
  GroupConnection,
  LinkedInAccount
} from "../../services/account-service";
import { LinkedAccountRequests } from "../reducers/linkedAccounts";
import { GroupOfferViewModel } from "../../components/security/SecurityGroup";
import { User } from "../../services/user-service";
import { CompanyOffer } from "../../services/company-service";

export const setMainAccount = (account: LinkedInAccount | null) => ({
  type: ACTION_TYPE.SET_MAIN_ACCOUNT as ACTION_TYPE.SET_MAIN_ACCOUNT,
  payload: account
});

export const setSharedConnections = (payload: AccountConnection[] | null) => ({
  type: ACTION_TYPE.SET_SHARED_CONNECTIONS as ACTION_TYPE.SET_SHARED_CONNECTIONS,
  payload
});

export const setGroupConnections = (payload: {
  groupConnections: GroupConnection[] | null;
  user: User | null;
}) => ({
  type: ACTION_TYPE.SET_GROUP_CONNECTIONS as ACTION_TYPE.SET_GROUP_CONNECTIONS,
  payload
});

export const setGroups = (payload: AccountsTableEntryViewModel[]) => ({
  type: ACTION_TYPE.SET_GROUPS as ACTION_TYPE.SET_GROUPS,
  payload
});

export const setSelectedAccount = (account: LinkedInAccount) => ({
  type: ACTION_TYPE.SELECT_ACCOUNT as ACTION_TYPE.SELECT_ACCOUNT,
  payload: account
});

export const setSelectedGroup = (account: AccountsTableEntryViewModel) => ({
  type: ACTION_TYPE.SELECT_GROUP as ACTION_TYPE.SELECT_GROUP,
  payload: account
});

export const setSelectedCompany = (account: AccountsTableEntryViewModel) => ({
  type: ACTION_TYPE.SELECT_COMPANY as ACTION_TYPE.SELECT_COMPANY,
  payload: account
});

export const setLinkedAccountRequest = (
  key: LinkedAccountRequests,
  value: boolean
) => ({
  type: ACTION_TYPE.SET_LINKED_ACCOUNTS_REQUEST as ACTION_TYPE.SET_LINKED_ACCOUNTS_REQUEST,
  payload: { key, value }
});

export const setGroupOffers = (offers: GroupOfferViewModel[]) => ({
  type: ACTION_TYPE.SET_GROUP_OFFERS as ACTION_TYPE.SET_GROUP_OFFERS,
  payload: offers
});

export const setCompanies = (companies: AccountsTableEntryViewModel[]) => ({
  type: ACTION_TYPE.SET_COMPANIES as ACTION_TYPE.SET_COMPANIES,
  payload: companies
});

export const setCompanyOffers = (offers: CompanyOffer[]) => ({
  type: ACTION_TYPE.SET_COMPANY_OFFERS as ACTION_TYPE.SET_COMPANY_OFFERS,
  payload: offers
});

export type LinkedAccountsTypes =
  | ReturnType<typeof setMainAccount>
  | ReturnType<typeof setSharedConnections>
  | ReturnType<typeof setGroups>
  | ReturnType<typeof setSelectedAccount>
  | ReturnType<typeof setLinkedAccountRequest>
  | ReturnType<typeof setGroupOffers>
  | ReturnType<typeof setSelectedCompany>
  | ReturnType<typeof setGroupConnections>
  | ReturnType<typeof setSelectedGroup>
  | ReturnType<typeof setCompanyOffers>
  | ReturnType<typeof setCompanies>;
