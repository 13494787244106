import styled from 'styled-components';

export const Button = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 3px;
  border: 1px solid transparent;
  color: #fff;
  overflow: hidden;
  background-color: #4285f4;
  height: 2.5rem;
  &:hover {
    border-color: transparent;
  }
  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }
  &:active {
    background-color: #366dc7;
  }
`;
export const Icon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  padding: 0 0.75rem;
  height: 100%;
  svg {
    height: 1.125rem;
    width: auto;
  }
`;
export const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.75rem;
  font-weight: bold;
  letter-spacing: 0.35px;
`;
