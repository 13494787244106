import React, { FC } from "react";
import { LinkedInAccount } from "../../services/account-service";
import { User } from "../../services/user-service";
import { useValidateImageURL } from "use-validate-image-url";
import emptyAvatarIcon from "../../assets/icons/IconImage.svg";

interface IProps {
  isDemoUser: (account: LinkedInAccount | null | undefined) => boolean;
  account: LinkedInAccount;
  user?: User | null | undefined;
}

const SidebarDropDownAvatar: FC<IProps> = ({ isDemoUser, account, user }) => {
  const userAvatarStatus = useValidateImageURL(
    user ? user.profile.picture : ""
  );
  const accountAvatarStatus = useValidateImageURL(
    account ? account.profile.displayPhoto.small : ""
  );
  const userAvatar =
    userAvatarStatus === "invalid" ? emptyAvatarIcon : user?.profile.picture;
  const accountAvatar =
    accountAvatarStatus === "invalid"
      ? emptyAvatarIcon
      : account.profile.displayPhoto.small;

  return (
    <>
      {userAvatarStatus !== "progress" &&
        accountAvatarStatus !== "progress" && (
          <img
            className="is-rounded"
            src={isDemoUser(account) ? userAvatar : accountAvatar}
            alt={
              isDemoUser(account)
                ? `Avatar of ${user?.profile.givenName} ${user?.profile.familyName}`
                : `Avatar of ${account.profile.firstName} ${account.profile.lastName}`
            }
          />
        )}
    </>
  );
};

export { SidebarDropDownAvatar };
