import React from 'react';

interface Props {
  fill: string;
}

export const SingleAccountIcon = ({ fill }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 35 35'>
    <path
      fill={fill}
      d='M17.5 0C7.833 0 0 7.833 0 17.5S7.833 35 17.5 35 35 27.167 35 17.5 27.167 0 17.5 0zm0 6.774c3.43 0 6.21 2.78 6.21 6.21s-2.78 6.21-6.21 6.21-6.21-2.78-6.21-6.21 2.78-6.21 6.21-6.21zm0 24.274c-4.142 0-7.854-1.877-10.338-4.812 1.327-2.498 3.924-4.22 6.95-4.22.17 0 .34.028.502.078.917.296 1.877.487 2.886.487 1.01 0 1.976-.19 2.886-.487.162-.05.332-.078.501-.078 3.027 0 5.624 1.722 6.95 4.22-2.483 2.935-6.195 4.812-10.337 4.812z'
    />
  </svg>
);
