import React from 'react';
import SelectComponent, { components } from 'react-select';
import './Select.scss';

interface Props {
  options: { label: string; value: string }[];
  value: { label: string; value: string }[];
  className?: string;
  onChange: (value: any) => void;
  noOptionsMessage?: string | undefined;
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className='dropdown'>Show options</div>
    </components.DropdownIndicator>
  );
};

const Control = (props: any) => {
  return (
    <components.Control {...props} className='control'>
      {props.children}
    </components.Control>
  );
};

const IndicatorsContainer = (props: any) => {
  return (
    <components.IndicatorsContainer {...props} className='indicators-container'>
      {props.children}
    </components.IndicatorsContainer>
  );
};

const Select: React.FC<Props> = ({
  value,
  options,
  className,
  onChange,
  noOptionsMessage,
  isMulti,
  isDisabled,
  isLoading,
}) => {
  return (
    <SelectComponent
      isDisabled={isDisabled}
      isLoading={isLoading}
      hideSelectedOptions
      tabSelectsValue
      value={value}
      onChange={onChange}
      className={className}
      options={options}
      noOptionsMessage={() => noOptionsMessage || ''}
      isMulti={isMulti}
      components={{
        DropdownIndicator,
        Control,
        IndicatorsContainer,
      }}
    />
  );
};

export { Select };
