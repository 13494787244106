import styled from 'styled-components';

export const Delete = styled.button`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const Buttons = styled.div`
  margin-top: 2rem;
`;
