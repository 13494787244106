import styled from 'styled-components';
import { readableColor } from 'polished';
import CloseButton from '../CloseButton';

export const Wrapper = styled.div<{ bgColor: string }>`
  padding: 0.75rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${props => props.bgColor};
  color: ${props => readableColor(props.bgColor)};
`;

export const Content = styled.div`
  flex-grow: 1;
  margin-right: 0.5rem;
`;

export const Close = styled(CloseButton)`
  flex: 0 0 auto;
`;
