import { AuthTypes } from '../actions/auth';
import { ACTION_TYPE } from '../actions';
import { User } from '../../services/user-service';

export interface AuthState {
  user?: User | null; // User model, `null` if logged out
}

const auth = (state: AuthState = {}, action: AuthTypes): AuthState => {
  switch (action.type) {
    case ACTION_TYPE.SET_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default auth;
