import React from 'react';
import { Link } from 'react-router-dom';

import * as StyledLoading from '../../styles/common/Loading.styles';
import { GroupUserRoles } from '../accountSharing/AccountSharingModal';
import { JoinGroupRequest } from './JoinGroupRequest';
import { NoContentBox } from './NoContentBox';

export interface GroupOfferViewModel {
  id: string;
  firstName: string;
  lastName: string;
  role: GroupUserRoles;
  type: 'request' | 'offer';
  avatar?: string;
  groupName: string;
}

interface Props {
  groupOffers: GroupOfferViewModel[] | undefined;
  isLoading: boolean;
}

export const SecurityGroup: React.FC<Props> = ({ groupOffers, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <StyledLoading.LoadingContainer>
          <StyledLoading.LoadingBarSmall active />
        </StyledLoading.LoadingContainer>
      ) : (
        <>
          {groupOffers?.length ? (
            groupOffers.map(offer => <JoinGroupRequest key={offer.id} data={offer} />)
          ) : (
            <NoContentBox text='No Groups Yet' />
          )}
        </>
      )}
      <div className='security-settings-link'>
        <Link to='/settings/group'>Bring me to my group settings</Link>
      </div>
    </>
  );
};
