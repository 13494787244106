import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { APIError } from '../../services/api-client';
import * as Yup from 'yup';
import GoogleButton from './GoogleButton';
import '../../styles/components/LoginRegisterForm.scss';
import classNames from 'classnames';
import { useMutation } from 'react-query';
import { loginLocal } from '../../services/auth-service';
import { Mail, Key } from '@styled-icons/ionicons-solid';
import { Link, useHistory } from 'react-router-dom';
import { useQueryParam } from '../../hooks/useQueryParam';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('This field is required'),
  password: Yup.string().required('This field is required'),
});

const LoginForm: React.FC = () => {
  const history = useHistory();
  const redirectParam = useQueryParam('redirect');

  const [loginMutation, { error }] = useMutation<void, APIError, { email: string; password: string }>(
    ({ email, password }) => loginLocal(email, password),
    {
      onSuccess: () => {
        if (typeof redirectParam === 'string') {
          history.push(redirectParam);
        }
      },
    }
  );

  return (
    <div className='login-signup-form-wrapper'>
      <div className='level'>
        <div className='level-item'>
          <GoogleButton redirectUrl={redirectParam}>Sign in with Google</GoogleButton>
        </div>
      </div>
      <div className='divider'>or</div>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={values => loginMutation(values)}
        validationSchema={LoginSchema}>
        {({ isSubmitting, isValid, errors, touched }) => (
          <Form>
            <div className='field'>
              <label htmlFor='email' className='label'>
                Email address
              </label>
              <div className='control is-expanded has-icons-left'>
                <Field
                  className={classNames('input', { 'is-danger': touched.email && errors.email })}
                  type='email'
                  name='email'
                  id='email'
                  autoComplete='email'
                  required
                  placeholder='name@example.com'
                />
                <span className='icon is-left'>
                  <Mail size='1rem' />
                </span>
              </div>
              <ErrorMessage component='p' className='help is-danger' name='email' />
            </div>

            <div className='field'>
              <label htmlFor='password' className='label'>
                Password
              </label>
              <div className='control is-expanded has-icons-left'>
                <Field
                  className={classNames('input', { 'is-danger': touched.password && errors.password })}
                  type='password'
                  name='password'
                  id='password'
                  autoComplete='current-password'
                  required
                />
                <span className='icon is-small is-left'>
                  <Key size='1rem' />
                </span>
              </div>
              <ErrorMessage component='p' className='help is-danger' name='password' />
            </div>
            <button
              className={classNames('button is-fullwidth is-primary', { 'is-loading': isSubmitting })}
              type='submit'
              disabled={isSubmitting || !isValid}>
              Login
            </button>
          </Form>
        )}
      </Formik>

      {/* Error */}
      {error && <div className='notification is-danger is-light has-text-centered'>{error.message}</div>}

      <div className='has-text-centered is-size-7 mt-4'>
        <Link to='/forgot-password'>Forgot password?</Link>
      </div>
    </div>
  );
};

export default LoginForm;
