import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ChevronForward, HelpCircle } from "@styled-icons/ionicons-solid";
import Header from "../Header";
import DateRangePicker from "../header/DateRangePicker";
import DashboardStatisticCards from "../DashboardStatisticCards";
import {
  loadHistoryForGroup,
  loadMetricsForGroup,
  Statistics,
  StatisticsSnapshot
} from "../../services/statistics-service";
import { RootState } from "../../store/reducers";
import ContentTable from "../ContentTable";
import ActivityTimelineChart from "../ActivityTimelineChart";
import { InviteGroupMemberWrapper } from "./InviteGroupMemberWrapper";
import { useGroupName } from "../../hooks/useGroupName";
import { CsvLoad, PageType } from "../CsvLoad";
import {
  contentConverter,
  IContentConverter
} from "../../services/csv-service";
import { colors } from "../../styles/variables";
import StyledTooltip from "../StyledTooltip";

interface Props {}

const GroupContent: React.FC<Props> = () => {
  const { accountId, groupId } = useParams<{
    accountId: string;
    groupId: string;
  }>();
  const { startDate, endDate } = useSelector(
    (state: RootState) => state.dateRangeFilter
  );
  const startTimeStamp = startDate.getTime();
  const endTimeStamp = endDate.getTime();

  const { inviteMemberModalOpen } = useSelector(
    (state: RootState) => state.settings
  );

  const [statistics, setStatistics] = useState<Statistics>();
  const [referenceStatistics, setReferenceStatistics] = useState<Statistics>();
  const [history, setHistory] = useState<StatisticsSnapshot[]>();
  const [csvData, setCsvData] = useState<IContentConverter>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { groupName } = useGroupName(groupId);

  useEffect(() => {
    if (!accountId || !groupId) return;

    setIsLoading(true);
    const startDate = startTimeStamp ? new Date(startTimeStamp) : undefined;
    const endDate = endTimeStamp ? new Date(endTimeStamp) : undefined;

    Promise.allSettled([
      loadMetricsForGroup(accountId, groupId, startDate, endDate),
      loadHistoryForGroup(accountId, groupId, startDate, endDate)
    ])
      .then(([metrics, timeline]) => {
        if (timeline.status === "fulfilled") {
          setHistory(timeline.value);
        } else {
          setHistory([]);
        }
        if (metrics.status === "fulfilled") {
          setStatistics(metrics.value.statistics);
          if (metrics.value.reference) {
            setReferenceStatistics(metrics.value.reference);
          } else {
            setReferenceStatistics(undefined);
          }
        } else {
          setStatistics(undefined);
          setReferenceStatistics(undefined);
        }
      })
      .catch(e => {
        setHistory([]);
        setStatistics(undefined);
        console.warn(e.message);
      })
      .finally(() => setIsLoading(false));
  }, [accountId, groupId, startTimeStamp, endTimeStamp]);

  const addToCsv = useCallback((data: any) => {
    setCsvData(prevState => ({ ...(prevState || {}), ...data }));
  }, []);

  return (
    <div className="Content">
      <Header
        title={
          groupName && (
            <>
              <span>Group</span>
              <ChevronForward className="icon arrow" />
              <span>{groupName}</span>
            </>
          )
        }
        content={
          <>
            {csvData && (
              <CsvLoad
                type={PageType.Content}
                group
                data={csvData}
                converter={contentConverter}
              />
            )}
            <DateRangePicker />
          </>
        }
        loading={isLoading}
      />

      {statistics && (
        <section className="section">
          <h5 className="title is-size-5 has-text-grey has-text-weight-normal">
            Tracked Aggregated Group Statistics
          </h5>
          <DashboardStatisticCards
            data={history}
            statistics={statistics}
            referenceStatistics={referenceStatistics}
            cardFilter={["views", "likes", "comments", "engagement"]}
          />
        </section>
      )}

      {history && (
        <section className="section">
          <h5 className="title is-size-5 has-text-grey has-text-weight-normal">
            <span>Timeline all content</span>
            <HelpCircle
              size="1.5rem"
              className="ml-2"
              color={colors.grey}
              data-for="styled-tooltip-history"
              data-tip="This data is being collected from the time you register for inlytics. Please note that historical data cannot be visualized, so there may be a discrepancy between the visualized data and the data listed above."
            />
          </h5>
          <StyledTooltip id="styled-tooltip-history" />
          <ActivityTimelineChart
            brushEnabled={true}
            timeline={history}
            isGroup
          />
        </section>
      )}

      <section className="section">
        <h5 className="title is-size-5 has-text-grey has-text-weight-normal">
          Content overview
        </h5>
        <ContentTable group={true} onDataLoaded={addToCsv} />
      </section>

      {inviteMemberModalOpen && <InviteGroupMemberWrapper />}
    </div>
  );
};

export { GroupContent };
