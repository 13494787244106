import React, { useEffect, useMemo, useState } from 'react';
import { format, getWeek, getYear, startOfWeek } from 'date-fns';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { GroupMemberCardEntry } from '../../../services/groups-service';
import { colors } from '../../../styles/variables';
import './GroupMemberCard.scss';
import { calculateEngagementRate } from '../../../services/statistics-service';
import { Link } from 'react-router-dom';
import { Button } from '../../button/Button';
import { Settings } from '@styled-icons/ionicons-solid';
import { CompanyUserRoles, GroupUserRoles } from '../../accountSharing/AccountSharingModal';
import { ChartInfoBlock } from '../../ChartInfoBlock';
import { endOfWeek } from 'date-fns/esm';
import { useDemoUser } from '../../../hooks/useDemoUser';
import { FollowerSnapshot } from '../../../services/profile-service';

enum ContentViewBy {
  COMPANY = 'company',
  REGION = 'region',
  OCCUPATION = 'occupation',
}

enum ContentNames {
  company = 'name',
  region = 'region',
  occupation = 'title',
}

interface Props extends GroupMemberCardEntry {
  userRole: GroupUserRoles | CompanyUserRoles | undefined;
  onEditOpen: () => void;
}

const GroupMemberCard: React.FC<Props> = ({
  id,
  profile,
  followers,
  connections,
  views,
  highlights,
  activities,
  isCounted,
  isConnected,
  onEditOpen,
  userRole,
  user,
}) => {
  const isDemoUser = useDemoUser(user);
  const [currentViewBy, setCurrentViewBy] = useState<ContentViewBy>(ContentViewBy.REGION);

  const [followersChartData, setFollowersChartData] = useState<{ Followers: number; timestamp: string }[]>();
  const [connectionsChartData, setConnectionsChartData] = useState<{ Connections: number; timestamp: string }[]>();
  const [viewsChartData, setViewsChartData] = useState<{ Views: number; timestamp: string }[]>();
  const [contentViewChartData, setContentViewChartData] = useState<any>();

  useEffect(() => {
    if (!followers) {
      return setFollowersChartData([]);
    }
    const sortedFollowHistoryEntries = followers.sort(
      (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
    if (!sortedFollowHistoryEntries.length) {
      setFollowersChartData([]);
    } else {
      let previous: FollowerSnapshot;
      const followerData: any = [];
      sortedFollowHistoryEntries.forEach(entry => {
        followerData.push({
          timestamp: format(new Date(entry.createdAt), 'yyyy-MM-dd'),
          Followers: previous
            ? previous.amountFollowers && entry.amountFollowers >= previous.amountFollowers
              ? entry.amountFollowers - previous.amountFollowers
              : 0
            : 0,
        });
        previous = entry;
      });
      setFollowersChartData(followerData);
    }
  }, [followers]);

  useEffect(() => {
    if (!connections?.length) {
      return setConnectionsChartData([]);
    }
    // Count connections by date (day)
    const mappedByDay = new Map();
    connections.forEach(con => {
      con.createdAt.setHours(0, 0, 0, 0);
      const dateString = con.createdAt.toISOString();
      const entry = mappedByDay.get(dateString) || 0;
      if (con.urn) {
        mappedByDay.set(dateString, entry + 1);
      } else {
        mappedByDay.set(dateString, entry);
      }
    });

    const connectionsData: { Connections: number; timestamp: string }[] = [];
    let lastAmount = 0;
    mappedByDay.forEach((entry, date) => {
      connectionsData.push({ Connections: lastAmount + entry, timestamp: format(new Date(date), 'yyyy-MM-dd') });
      lastAmount += entry;
    });
    setConnectionsChartData(connectionsData);
  }, [connections]);

  useEffect(() => {
    if (!views || !views.length) {
      return setViewsChartData([]);
    }
    const sorted = views.sort((a, b) => a.endTimestamp.getTime() - b.endTimestamp.getTime());
    const viewData: { Views: number; timestamp: string }[] = [];
    const mappedByWeek = new Map();
    sorted.forEach(entry => {
      // Only use one entry per week, as we actually store the newest date per week
      if (entry.numViews) {
        entry.endTimestamp = new Date(entry.endTimestamp);
        const week = getWeek(entry.endTimestamp, { weekStartsOn: 1 });
        const year = getYear(entry.endTimestamp);
        const key = `${year}-${week}`;
        mappedByWeek.set(key, entry);
      }
    });
    mappedByWeek.forEach(entry => {
      const startOfWeekDate = startOfWeek(entry.endTimestamp, { weekStartsOn: 1 });
      const endOfWeekDate = endOfWeek(entry.endTimestamp, { weekStartsOn: 1 });
      const timestamp = `${format(startOfWeekDate, 'do LLL')} - ${format(endOfWeekDate, 'do LLL')}`;
      viewData.push({ Views: entry.numViews, timestamp });
    });
    setViewsChartData(viewData);
  }, [views]);

  useEffect(() => {
    if (!highlights) {
      return;
    }
    setContentViewChartData(highlights[currentViewBy]?.slice(0, 5));
  }, [highlights, currentViewBy]);

  const percentageForFollowers = useMemo(() => {
    if (!followersChartData?.length || followersChartData.length === 1) {
      return { increase: 0, percentage: 0 };
    }
    const copy = [...followersChartData];
    let [last, previous] = [copy.pop(), copy.pop()];
    const percentage =
      (last?.Followers && previous?.Followers && ((last.Followers - previous.Followers) / previous.Followers) * 100) ||
      0;
    const increase = followersChartData.reduce((gen, curr) => (gen += curr.Followers), 0);
    return { increase, percentage };
  }, [followersChartData]);

  const percentageForConnections = useMemo(() => {
    if (!connectionsChartData?.length || connectionsChartData.length === 1) {
      return { increase: 0, percentage: 0 };
    }
    const copy = [...connectionsChartData];
    let [last, previous] = [copy.pop(), copy.pop()];
    const percentage =
      (last?.Connections &&
        previous?.Connections &&
        ((last.Connections - previous.Connections) / previous.Connections) * 100) ||
      0;
    return { increase: last?.Connections || 0, percentage };
  }, [connectionsChartData]);

  const percentageForViews = useMemo(() => {
    if (!viewsChartData?.length || viewsChartData.length === 1) {
      return { increase: 0, percentage: 0 };
    }
    const copy = [...viewsChartData];
    let [last, previous] = [copy.pop(), copy.pop()];
    const percentage = (last?.Views && previous?.Views && ((last.Views - previous.Views) / previous.Views) * 100) || 0;
    const increase = viewsChartData.reduce((sum, curr) => (sum += curr.Views), 0) || 0;

    return { increase, percentage };
  }, [viewsChartData]);

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <div>
        <div className='card-header-block'>
          <div className='profile-info'>
            <img
              src={isDemoUser ? user.profile.picture : profile?.profile.displayPhoto.small}
              alt={isDemoUser ? user.profile.givenName : profile?.profile.firstName}
            />
            <div className='profile-info-text'>
              <span className='card-header-title'>
                {isDemoUser
                  ? `${user.profile.givenName} ${user.profile.familyName}`
                  : `${profile?.profile.firstName} ${profile?.profile.lastName}`}
              </span>
              {!isDemoUser && <span>{profile.profile.occupation}</span>}
            </div>
          </div>

          <div className='profile-connection has-text-grey'>
            <span>Connection Status </span>
            <span className={isConnected ? 'active' : 'disconnected'}>
              {isConnected ? 'Connected' : 'Not connected'}
            </span>
            <span>Team Stats </span>
            <span className={isCounted ? 'active' : 'excluded'}>{isCounted ? 'Included' : 'Excluded'}</span>
          </div>

          <div className='actions'>
            <Link className='margin-right' to={`/account/${id}/analyze`}>
              View inlytics account
            </Link>
            {userRole === GroupUserRoles.Admin && (
              <Button onClick={onEditOpen}>
                <Settings size='1.25rem' />
              </Button>
            )}
          </div>
        </div>
        <div className='card-body'>
          <div className='section'>
            <h6 className='title is-size-6 has-text-grey has-text-weight-normal'>Activity</h6>
            <div className='content-view'>
              Content posted <span>{activities.length}</span>
            </div>
            <div className='content-view'>
              Content planned <span>{activities.plannedLength}</span>
            </div>

            <h6 className='title is-size-6 has-text-grey has-text-weight-normal'>Performance</h6>
            <div className='content-view'>
              Engagement Rate
              <span>
                {(calculateEngagementRate(activities.statistics) * 100).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                %
              </span>
            </div>
          </div>

          {/*FOLLOWERS*/}
          <div className='section chart-block'>
            <h6 className='title title-no-margin is-size-6 has-text-grey has-text-weight-normal'>Followers</h6>
            <ChartInfoBlock number={percentageForFollowers.increase} percentage={percentageForFollowers.percentage} />
            <ResponsiveContainer width='100%' height={200} className='chart'>
              <AreaChart data={followersChartData}>
                <defs>
                  <linearGradient id='gradient-followers' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='0%' stopColor={colors.pink} stopOpacity={0.8} />
                    <stop offset='100%' stopColor={colors.pink} stopOpacity={0.05} />
                  </linearGradient>
                </defs>

                <XAxis dataKey='timestamp' tick={false} axisLine={false} />
                <Tooltip />
                <Area type='monotone' dataKey='Followers' stroke={colors.pink} fill='url(#gradient-followers)' />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          {/*/FOLLOWERS*/}

          {/*CONNECTIONS*/}
          <div className='section chart-block'>
            <h6 className='title title-no-margin is-size-6 has-text-grey has-text-weight-normal'>Connections</h6>
            <ChartInfoBlock
              number={percentageForConnections.increase}
              percentage={percentageForConnections.percentage}
            />
            <ResponsiveContainer width='100%' height={200} className='chart'>
              <AreaChart data={connectionsChartData}>
                <defs>
                  <linearGradient id='gradient' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='0%' stopColor={colors.royalBlueDark} stopOpacity={0.8} />
                    <stop offset='100%' stopColor={colors.royalBlueDark} stopOpacity={0.05} />
                  </linearGradient>
                </defs>

                <XAxis dataKey='timestamp' tick={false} axisLine={false} />
                <Tooltip />
                <Area type='monotone' dataKey='Connections' stroke={colors.royalBlueLight} fill='url(#gradient)' />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          {/*/CONNECTIONS*/}

          {/*PROFILE VIEWS*/}
          <div className='section chart-block'>
            <h6 className='title title-no-margin is-size-6 has-text-grey has-text-weight-normal'>Profile Views</h6>
            <ChartInfoBlock number={percentageForViews.increase} percentage={percentageForViews.percentage} />
            <ResponsiveContainer width='100%' height={200} className='chart'>
              <AreaChart data={viewsChartData}>
                <defs>
                  <linearGradient id='gradient' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='0%' stopColor={colors.royalBlueDark} stopOpacity={0.8} />
                    <stop offset='100%' stopColor={colors.royalBlueDark} stopOpacity={0.05} />
                  </linearGradient>
                </defs>

                <XAxis dataKey='timestamp' tick={false} axisLine={false} />
                <Tooltip />
                <Area type='monotone' dataKey='Views' fill='url(#gradient)' />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          {/*/PROFILE VIEWS*/}

          <div className='section '>
            <div className='title is-size-6 has-text-grey has-text-weight-normal select'>
              Content Views By
              <select
                defaultValue={ContentViewBy.REGION}
                onChange={e => setCurrentViewBy(e.target.value as ContentViewBy)}>
                {Object.values(ContentViewBy).map(item => (
                  <option key={item} value={item.toLowerCase()}>
                    {item?.charAt(0).toUpperCase() + item?.slice(1)}
                  </option>
                ))}
              </select>
            </div>

            {contentViewChartData && contentViewChartData.length ? (
              contentViewChartData.map(
                (item: any) =>
                  item && (
                    <div className='content-view' key={item[ContentNames[currentViewBy]]}>
                      {item[ContentNames[currentViewBy]]} <span>{item.amountViews}</span>
                    </div>
                  )
              )
            ) : (
              <div>No data</div>
            )}
          </div>
        </div>
      </div>
    </ResponsiveContainer>
  );
};

export { GroupMemberCard };
