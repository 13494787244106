import { ResponsiveContainer } from 'recharts';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  flex-basis: 50%;
  margin: 1rem;
  min-width: calc(50% - 2rem);
`;

export const InfoContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`;

export const ChartContainer = styled(ResponsiveContainer)`
  flex-grow: 1;
`;
