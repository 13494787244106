import React, { useEffect } from 'react';
import useScript from '../hooks/useScript';

const Tracking = () => {
  // Setup configuration
  useEffect(() => {
    // Setup GTM data layer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    // Assign iubenda config to window object
    window._iub = window._iub || [];
    window._iub.csConfiguration = {
      enableCcpa: true,
      countryDetection: true,
      cookiePolicyInOtherWindow: true,
      enableCMP: true,
      googleAdsPreferenceManagement: true,
      lang: 'en',
      siteId: 1800246,
      consentOnContinuedBrowsing: false,
      perPurposeConsent: true,
      whitelabel: false,
      cookiePolicyId: 88329618,
      banner: {
        acceptButtonDisplay: true,
        customizeButtonDisplay: true,
        acceptButtonColor: '#378ae8',
        acceptButtonCaptionColor: 'white',
        customizeButtonColor: '#949494',
        customizeButtonCaptionColor: 'white',
        position: 'bottom',
        textColor: '#010101',
        backgroundColor: '#ffffff',
        closeButtonDisplay: false,
      },
      callback: {
        onPreferenceExpressedOrNotNeeded: function(preference: any) {
          window.dataLayer.push({
            iubenda_ccpa_opted_out: window._iub.cs.api.isCcpaOptedOut(),
          });
          if (!preference) {
            window.dataLayer.push({
              event: 'iubenda_preference_not_needed',
            });
          } else {
            if (preference.consent === true) {
              window.dataLayer.push({
                event: 'iubenda_consent_given',
              });
            } else if (preference.consent === false) {
              window.dataLayer.push({
                event: 'iubenda_consent_rejected',
              });
            } else if (preference.purposes) {
              for (var purposeId in preference.purposes) {
                if (preference.purposes[purposeId]) {
                  window.dataLayer.push({
                    event: 'iubenda_consent_given_purpose_' + purposeId,
                  });
                }
              }
            }
          }
        },
      },
    };
  }, []);

  // Inject scripts at runtime
  // Iubenda
  useScript('//cdn.iubenda.com/cs/tcf/stub.js');
  useScript('//cdn.iubenda.com/cs/iubenda_cs.js');

  return <></>;
};

export default Tracking;
