import React from 'react';
import { Link } from 'react-router-dom';
import { USER_PLAN } from '../../services/user-service';
import * as Styled from './PremiumTeaser.styles';

interface PremiumTeaserProps {
  className?: string;
  image: string;
  requiredPlan: USER_PLAN.SOLO | USER_PLAN.BUSINESS;
}

const PremiumTeaser: React.FC<PremiumTeaserProps> = ({ className, image, requiredPlan }) => {
  return (
    <Styled.TeaserWrapper className={className}>
      <Styled.TeaserImage src={image} />
      <Styled.CtaContainer>
        <Styled.CtaText>
          Upgrade to {requiredPlan === USER_PLAN.SOLO ? 'Solo' : 'Business'} plan to unlock
        </Styled.CtaText>
        <Link className='button is-primary' to={'/upgrade'}>
          Upgrade
        </Link>
      </Styled.CtaContainer>
    </Styled.TeaserWrapper>
  );
};

export default PremiumTeaser;
