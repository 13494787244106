import apiClient from './api-client';
import { User, USER_PLAN } from './user-service';
import { LinkedInAccount } from './account-service';
import { Media } from './media-service';
import {
  setScheduledActivities,
  setScheduledGroupActivities,
} from '../store/actions/scheduledActivities';
import { store } from '../store';

export const POSTS_PER_MONTH = {
  [USER_PLAN.FREE]: 3,
  [USER_PLAN.SOLO]: 20,
  [USER_PLAN.BUSINESS]: 100,
  [USER_PLAN.APPSUMO]: 100,
  [USER_PLAN.ANALYZE]: 20,
  [USER_PLAN.COMPLETE]: 20,
  [USER_PLAN.CORPORATE]: 20,
  [USER_PLAN.ESSENTIAL]: 20,
  [USER_PLAN.PROFESSIONAL]: 20,
};

export const POSTS_PER_MONTH_WARNING_LIMIT = {
  [USER_PLAN.FREE]: 3,
  [USER_PLAN.SOLO]: 4,
  [USER_PLAN.BUSINESS]: 9,
  [USER_PLAN.APPSUMO]: 9,
  [USER_PLAN.ANALYZE]: 20,
  [USER_PLAN.COMPLETE]: 20,
  [USER_PLAN.CORPORATE]: 20,
  [USER_PLAN.ESSENTIAL]: 20,
  [USER_PLAN.PROFESSIONAL]: 20,
};

export interface ScheduledActivity {
  _id: string;
  author: User;
  account: LinkedInAccount;
  commentary: string;
  media: Media[];
  postType: 'auto' | 'notification';
  publishingDate: Date;
  jobId: string; // Id of the job this activity belongs to
  postUrn: string; // Id of posted LinkedIn activity
}

interface CreateScheduledActivityRequestBody {
  commentary: string;
  media: string[];
  postType: 'auto' | 'notification';
  publishingDate: Date;
}
export async function createScheduledActivity(
  accountId: string,
  payload: CreateScheduledActivityRequestBody
) {
  const response = await apiClient.post<{
    scheduledActivity: ScheduledActivity;
  }>(`/schedule/${accountId}/activity`, payload);
  return response.data['scheduledActivity'];
}

interface UpdateScheduledActivityRequestBody {
  commentary?: string;
  media?: string[];
  postType?: 'auto' | 'notification';
  publishingDate?: Date;
}
export async function updateScheduledActivity(
  accountId: string,
  activityid: string,
  payload: UpdateScheduledActivityRequestBody,
  retry?: boolean
): Promise<ScheduledActivity> {
  const response = await apiClient.patch<{
    scheduledActivity: ScheduledActivity;
  }>(
    `/schedule/${accountId}/activity/${activityid}${
      retry ? `?retry=${retry}` : ''
    }`,
    payload
  );
  return response.data.scheduledActivity;
}

export async function deleteScheduledActivity(
  accountId: string,
  activityId: string
): Promise<{ success: true }> {
  const response = await apiClient.delete<{ success: true }>(
    `/schedule/${accountId}/activity/${activityId}`
  );
  return response.data;
}

export async function loadAllScheduledActivities(
  accountId: string
): Promise<ScheduledActivity[]> {
  const response = await apiClient.get<{
    scheduledActivities: ScheduledActivity[];
  }>(`/schedule/${accountId}/activities`);
  const { scheduledActivities } = response.data;
  store.dispatch(setScheduledActivities(accountId, scheduledActivities));
  return scheduledActivities;
}

export async function loadAllGroupScheduledActivities(
  groupId: string
): Promise<ScheduledActivity[]> {
  const response = await apiClient.get<{
    scheduledActivities: ScheduledActivity[];
  }>(`/schedule/group/${groupId}/activities`);
  const { scheduledActivities } = response.data;
  store.dispatch(setScheduledGroupActivities(groupId, scheduledActivities));
  return scheduledActivities;
}

export async function loadScheduledActivity(
  accountId: string,
  activityId: string
): Promise<{
  scheduledActivity: ScheduledActivity;
  account: LinkedInAccount | User;
  authorEmail: string;
}> {
  const response = await apiClient.get<{
    scheduledActivity: ScheduledActivity;
    account: LinkedInAccount;
    authorEmail: string;
  }>(`/schedule/${accountId}/activity/${activityId}`);
  return response.data;
}
