import React, { useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { HelpCircle } from '@styled-icons/ionicons-solid';
import { Disable } from 'react-disable';

import { RootState } from '../../store/reducers';
import {
  LinkedInAccount,
  LinkedInAccountShared,
  useMainAccount,
} from '../../services/account-service';
import { User, USER_PLAN } from '../../services/user-service';
import LinkPersonalAccountPopup from './LinkPersonalAccountPopup';
import AccountList, {
  AccountListTypes,
  AccountsTableEntryViewModel,
} from './AccountList';
import StyledTooltip from '../StyledTooltip';
import * as Styled from './LinkedAccounts.styles';
import PersonalSettingsForm from './PersonalSettingsForm';
import {
  AccountSharingModal,
  Steps,
} from '../accountSharing/AccountSharingModal';
import Modal from '../modal/Modal';
import { StyledCommon } from '../../styles/common/Common.styles';
import { variables } from '../../styles/variables';
import { getSharedConnections } from '../../services/profile-service';
import { getGroupOffers, getGroups } from '../../services/groups-service';
import { useDemoUser } from '../../hooks/useDemoUser';
import { CreateAudience } from '../audience/createAudience/CreateAudience';
import { getCompanies } from '../../services/company-service';
import { Creating } from '../accountSharing/ChooseConnectionType';
import { helpTexts } from '../../helpers/helpTexts';
import UpgradeTooltip from '../UpgradeTooltip';
import Profitwell from '../profitwell/ProfitWell';

export const serializeMainAccount = (
  mainAccount: LinkedInAccount,
  plan: USER_PLAN | undefined,
  isDemoUser: boolean,
  user: User | null | undefined
): AccountsTableEntryViewModel => ({
  id: mainAccount._id,
  accountId: mainAccount._id,
  avatar: isDemoUser
    ? user?.profile.picture
    : mainAccount.profile.displayPhoto.small,
  name: isDemoUser
    ? `${user?.profile.givenName} ${user?.profile.familyName}`
    : `${mainAccount.profile.firstName} ${mainAccount.profile.lastName}`,
  plan,
  isSharedAnalytics: true,
  isSharedSchedule: true,
  status: mainAccount.hasValidSession,
});

const serializeSharedAccounts = (
  accounts: LinkedInAccountShared[]
): AccountsTableEntryViewModel[] =>
  accounts.map(account => ({
    id: account.connectionId,
    accountId: account._id,
    avatar: account.profile.displayPhoto.small,
    name: `${account.profile.firstName} ${account.profile.lastName}`,
    isSharedAnalytics: account.isSharedAnalytics,
    isSharedSchedule: account.isSharedSchedule,
    status: account.hasValidSession,
    plan: account.plan,
  }));

const LinkedAccounts: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [
    linkPersonalAccountPopupOpen,
    setLinkPersonalAccountPopupOpen,
  ] = useState(false);
  const [createGroupPopupOpen, setCreateGroupPopupOpen] = useState<
    { type: Steps; currentCreating: Creating; isOpened: boolean } | boolean
  >(false);
  const [createAudienceOpen, setCreateAudienceOpen] = useState(false);
  const isDemoUser = useDemoUser();

  const { data: mainAccount } = useMainAccount();
  const { sharedConnections, groups, companies } = useSelector(
    (state: RootState) => state.linkedAccounts
  );

  const isUserBusinessPlan = useMemo(
    () => user?.plan === USER_PLAN.BUSINESS || user?.plan === USER_PLAN.APPSUMO,
    [user]
  );

  const serializedMainAccount = useMemo(() => {
    return mainAccount
      ? serializeMainAccount(mainAccount, user?.plan, isDemoUser, user)
      : null;
  }, [mainAccount, user, isDemoUser]);

  const serializedSharedAccounts = useMemo(() => {
    return serializeSharedAccounts(sharedConnections);
  }, [sharedConnections]);

  useEffect(() => {
    if (user?.plan === USER_PLAN.BUSINESS) {
      getSharedConnections();
      getGroups();
      getGroupOffers();
      getCompanies();
    }
  }, [user]);

  if (user && !user.onboarding) {
    return <Redirect to='/onboarding'></Redirect>;
  }

  const handleOnLinkPersonalAccountBtnClick = () => {
    if (mainAccount) {
      return;
    }
    setLinkPersonalAccountPopupOpen(true);
  };

  const getTooltipContent = () => {
    if (!mainAccount) {
      return '';
    }
    // TODO add: If you would like to access other accounts, you need permission from the owner of said account. To request access, click on the button on the left.
    return `You already connected your personal account.
    
If you would like to change your personal account, you must first delete your current one. To do so, click on the trashcan next to your connected account. `;
  };

  const getLinkedAccountsTooltipContent = () =>
    isUserBusinessPlan
      ? ''
      : 'This feature is only available on the Business Plan - upgrade to unlock it';

  const disableGroups = ![USER_PLAN.CORPORATE, USER_PLAN.BUSINESS].includes(
    user?.plan as USER_PLAN
  );
  const disableCompanies = [USER_PLAN.ANALYZE, USER_PLAN.ESSENTIAL].includes(
    user?.plan as USER_PLAN
  );

  return (
    <div>
      {serializedMainAccount && !serializedMainAccount.status && (
        <Styled.Banner>
          <span className='is-primary'>
            Your inlytics account is not connected.
          </span>
          <span> Please reconnect your account</span>
          <Styled.HowToReconnectBnt href={helpTexts.HOW_TO_RECONNECT_BLOG_URL}>
            How to reconnect your extension
          </Styled.HowToReconnectBnt>
        </Styled.Banner>
      )}
      <Styled.Header>
        <Styled.HeaderTitle className='title'>
          Linked Accounts
        </Styled.HeaderTitle>
        <div className='buttons'>
          <Styled.CreateGroupBtn
            className={classNames('button is-primary', {
              disabled: isDemoUser,
            })}
            onClick={setCreateAudienceOpen.bind(null, true)}
          >
            Create audience
          </Styled.CreateGroupBtn>
          <Styled.CreateGroupBtn
            className={classNames('button is-primary', {
              disabled: !isUserBusinessPlan || isDemoUser,
            })}
            onClick={() => isUserBusinessPlan && setCreateGroupPopupOpen(true)}
            data-tip
            data-for='linked-accounts-tooltip'
          >
            Create a group/ Invite people/ Request access
          </Styled.CreateGroupBtn>
          <StyledTooltip
            id={'linked-accounts-tooltip'}
            getContent={getLinkedAccountsTooltipContent}
            delayHide={500}
            clickable={true}
          />
          <Styled.ConnectMainAccountBtn
            className={classNames('button is-primary', {
              disabled: !!mainAccount,
            })}
            onClick={handleOnLinkPersonalAccountBtnClick}
            data-tip
            data-for='connect-main-account-tooltip'
          >
            Connect personal account
          </Styled.ConnectMainAccountBtn>
          <StyledTooltip
            id={'connect-main-account-tooltip'}
            getContent={getTooltipContent}
            delayHide={500}
            clickable={true}
          />
        </div>
      </Styled.Header>

      <StyledCommon.Wrapper>
        <h5
          className={`title is-size-5 has-text-grey has-text-weight-normal ${!serializedMainAccount &&
            'mb-4'}`}
        >
          Your Personal Account
        </h5>
        {serializedMainAccount ? (
          <AccountList
            accounts={[serializedMainAccount]}
            type={AccountListTypes.Own}
            isCreateNewAvailable={false}
            personalAccount
          />
        ) : (
          <StyledCommon.Card>
            <p className='mb-4 has-text-grey'>No connected accounts yet.</p>
            <Styled.ConnectMainAccountBtn
              className={classNames('button is-primary', {
                disabled: !!mainAccount,
              })}
              onClick={handleOnLinkPersonalAccountBtnClick}
            >
              Connect personal account
            </Styled.ConnectMainAccountBtn>
          </StyledCommon.Card>
        )}
      </StyledCommon.Wrapper>
      {user?.plan === USER_PLAN.BUSINESS && (
        <StyledCommon.Wrapper>
          <h5 className='title is-size-5 has-text-grey has-text-weight-normal with-tooltip'>
            You Have Access To These Accounts
            <div
              className='button is-white is-rounded is-help-button'
              data-tip
              data-for='who-can-view-your'
              style={{
                color: variables.lightGrey,
                backgroundColor: 'rgba(0, 0, 0, 0)',
              }}
            >
              <HelpCircle size='1.25rem' />
            </div>
            <StyledTooltip
              id='who-can-view-your'
              delayHide={1000}
              clickable={true}
            >
              <div>
                Want to manage who can view your account? Go to your{' '}
                <a href='/settings/personal' rel='noopener noreferrer'>
                  personal settings
                </a>
                .
              </div>
            </StyledTooltip>
          </h5>
          <AccountList
            accounts={serializedSharedAccounts || []}
            type={AccountListTypes.Shared}
            isCreateNewAvailable
            personalAccount={false}
            createNewText='Connect another inlytic profile'
            createNewHandler={setCreateGroupPopupOpen.bind(null, {
              isOpened: true,
              type: Steps.One,
              currentCreating: Creating.Account,
            })}
          />
        </StyledCommon.Wrapper>
      )}

      <StyledCommon.Wrapper data-tip data-for='groups-disabled-tooltip'>
        <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
          Your Groups
        </h5>
        <Disable disabled={disableGroups}>
          <AccountList
            accounts={groups || []}
            type={AccountListTypes.Group}
            isCreateNewAvailable
            createNewText={`${
              groups?.length ? 'Create new group' : 'Create your first group'
            }`}
            createNewHandler={setCreateGroupPopupOpen.bind(null, {
              isOpened: true,
              type: Steps.One,
              currentCreating: Creating.Group,
            })}
          />
        </Disable>
        <UpgradeTooltip
          id={disableGroups ? 'groups-disabled-tooltip' : ''}
          top
        />
      </StyledCommon.Wrapper>

      <StyledCommon.Wrapper data-tip data-for='companies-disabled-tooltip'>
        <Disable disabled={disableCompanies}>
          <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
            Your Companies
          </h5>
          <AccountList
            accounts={companies || []}
            type={AccountListTypes.Company}
            isCreateNewAvailable
            createNewText={`${
              groups?.length
                ? 'Connect new company'
                : 'Connect your first company'
            }`}
            createNewHandler={setCreateGroupPopupOpen.bind(null, {
              isOpened: true,
              type: Steps.One,
              currentCreating: Creating.Company,
            })}
          />
        </Disable>
        <UpgradeTooltip
          id={disableCompanies ? 'companies-disabled-tooltip' : ''}
          top
        />
      </StyledCommon.Wrapper>

      <Modal open={linkPersonalAccountPopupOpen}>
        <LinkPersonalAccountPopup
          onClose={() => setLinkPersonalAccountPopupOpen(false)}
        />
      </Modal>

      <Modal open={!!mainAccount && !user?.employmentType}>
        <div className='box'>
          <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
            Setup Your Connected Account
          </h5>
          <p>Set up your account the way we should display your stats.</p>
          <Styled.PersonalSettingsForm>
            <PersonalSettingsForm />
          </Styled.PersonalSettingsForm>

          <p className='note is-size-7 has-text-grey has-text-weight-normal'>
            We use this data to anonymously benchmark you with other inlytics
            users, so we can show you how you perform compared to other persons
            in your industry, with the same intend. You can later on change
            these settings.
          </p>
        </div>
      </Modal>

      {createAudienceOpen && (
        <CreateAudience
          isOpen={createAudienceOpen}
          setIsOpen={setCreateAudienceOpen}
        />
      )}

      <Profitwell
        authToken='bfd02d45efe2b5886f20bf2e7b135532'
        email={user?.email}
      />

      {(typeof createGroupPopupOpen === 'boolean'
        ? createGroupPopupOpen
        : createGroupPopupOpen.isOpened) && (
        <Modal className='accountSharingModal' open={true} fullWidth>
          <div className='box connect-account'>
            <AccountSharingModal
              type={
                typeof createGroupPopupOpen === 'object'
                  ? createGroupPopupOpen.type
                  : Steps.ChooseType
              }
              currentCreating={
                typeof createGroupPopupOpen === 'object'
                  ? createGroupPopupOpen.currentCreating
                  : Creating.Account
              }
              setOpen={setCreateGroupPopupOpen}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default LinkedAccounts;
