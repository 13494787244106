import React from 'react';

import { UPGRADE_TEXT } from '../helpers/plans';
import StyledTooltip from './StyledTooltip';

interface UpgradeTooltipProps {
  id: string;
  top?: boolean;
}

function UpgradeTooltip({ id, top }: UpgradeTooltipProps) {
  return (
    <StyledTooltip
      id={id}
      clickable
      delayHide={1000}
      place={top ? 'top' : 'bottom'}
    >
      {`${UPGRADE_TEXT} `}
      <a href='/upgrade'>Upgrade</a>
    </StyledTooltip>
  );
}

export default UpgradeTooltip;
