import React, { useEffect, useState } from 'react';
import * as localStorage from '../../services/local-storage-service';
import { updateMainAccount } from '../../services/account-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { useQuery } from 'react-query';
import { Time } from '@styled-icons/ionicons-solid';
import './RecentlyConnectedBanner.webflow.scss';
import { StyledCommon } from '../../styles/common/Common.styles';
import { colors } from '../../styles/variables';

/**
 * Shows a banner if the user has connected their account within the last 24 hours to warn them about potentially missing data.
 */
const RecentlyConnectedBanner: React.FC = () => {
  const user = useSelector((root: RootState) => root.auth.user);
  const { data: mainAccount } = useQuery(['updateMainAccount'], updateMainAccount, { staleTime: 0, enabled: !!user });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (mainAccount && localStorage.getEntry(localStorage.STORAGE_KEY.RECENTLY_CONNECTED_BANNER_HIDDEN) !== true) {
      setVisible(true);
    }
  }, [mainAccount]);

  if (!user || !visible) {
    return null;
  }

  return (
    <StyledCommon.Section>
      <StyledCommon.Wrapper>
        <div className='RecentlyConnectedBanner announce-bar'>
          <div className='announce-bar-icon-wrap'>
            <Time size='1.5rem' color={colors.lightOrange} />
          </div>
          <div className='paragraph-wrap'>
            <div className='bold-text-wrap'>
              <div className='paragraph'>
                <b>Information:</b>
              </div>
            </div>
            <p className='paragraph has-text-grey'>
              We are visualizing your stats. Some data can be retrieved immediately, others can only be tracked from now
              on. Learn more about which metrics we can pull historically and what we track from now on.{' '}
            </p>
          </div>
          <div className='button-wrap'>
            <div className='button-right-space'>
              <a
                href='https://www.inlytics.io/article/missing-data'
                target='_blank'
                rel='noopener noreferrer'
                className='button is-primary'>
                Show me what you track
              </a>
            </div>
            <button
              className='button is-outlined is-primary'
              onClick={() => {
                setVisible(false);
                localStorage.setEntry(localStorage.STORAGE_KEY.RECENTLY_CONNECTED_BANNER_HIDDEN, true);
              }}>
              Alright, got it
            </button>
          </div>
        </div>
      </StyledCommon.Wrapper>
    </StyledCommon.Section>
  );
};

export default RecentlyConnectedBanner;
