import React, { useEffect, useState } from 'react';
import { Area, AreaChart, Bar, BarChart, Brush, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { colors, variables } from '../../../styles/variables';
import { dateFormatter } from '../../profile/ConnectionTimeline';
import { ICompanyFollowersSnapshot } from '../../../services/company-service';

interface Props {
  data: ICompanyFollowersSnapshot[];
  brushEnabled?: boolean;
}

interface RechartData {
  followers: number;
  timestamp: number;
}

export const FollowersTimeline: React.FC<Props> = ({ data, brushEnabled }) => {
  const [followersRechartData, setFollowersRechartData] = useState<RechartData[]>([]);

  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(0);

  const handleUpdate = ({ startIndex, endIndex }: { startIndex: number; endIndex: number }) => {
    setStartIndex(startIndex);
    setEndIndex(endIndex);
  };

  useEffect(() => {
    const newData: RechartData[] = data.map(item => ({
      followers: item.organicNumber,
      timestamp: item.timestamp,
    }));
    setFollowersRechartData(newData);
    setStartIndex(0);
    setEndIndex(data.length - 1);
  }, [data]);

  return (
    <div className='box info-block'>
      <ResponsiveContainer height={400}>
        <AreaChart data={brushEnabled ? followersRechartData.slice(startIndex, endIndex + 1) : followersRechartData}>
          <defs>
            <linearGradient id='gradient-mobile' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor={colors.pink} stopOpacity={0.8} />
              <stop offset='100%' stopColor={colors.pink} stopOpacity={0.05} />
            </linearGradient>
          </defs>

          {/*<CartesianGrid />*/}
          <XAxis dataKey='timestamp' tickLine={false} axisLine={false} tickFormatter={dateFormatter} />
          <YAxis tickLine={false} axisLine={false} />
          <Tooltip labelFormatter={dateFormatter} />
          <Area type='monotone' dataKey='followers' stroke={colors.pink} fill='url(#gradient-mobile)' />
        </AreaChart>
      </ResponsiveContainer>
      {brushEnabled && !!followersRechartData?.length && (
        <div className='chart-container'>
          <ResponsiveContainer height={60} className='chart-base'>
            <BarChart data={followersRechartData}>
              <YAxis yAxisId='followers' tickLine={false} axisLine={false} tick={false} />

              <Bar
                yAxisId='followers'
                dataKey='followers'
                stroke={variables.lightGrey}
                fill={variables.lightGrey}
                isAnimationActive={false}
              />
            </BarChart>
          </ResponsiveContainer>
          <ResponsiveContainer height={60} className='chart-brush'>
            <BarChart data={followersRechartData}>
              <YAxis yAxisId='followers' tick={false} />

              <Brush
                startIndex={startIndex}
                endIndex={endIndex}
                height={60}
                fill='rgba(0, 0, 0, 0)'
                stroke={colors.pink}
                onChange={handleUpdate}
                dataKey='timestamp'
                tickFormatter={dateFormatter}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};
