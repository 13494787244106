import React from 'react';
import {
  calculateEngagementRate,
  Statistics,
} from '../../services/statistics-service';
import { HelpCircle } from '@styled-icons/ionicons-solid';
import StyledTooltip from '../StyledTooltip';
import { StatsChart } from '@styled-icons/ionicons-outline';
import { Link, useParams } from 'react-router-dom';
import './GroupTopActivityCard.scss';
import { Activity } from '../../services/activities-service';
import { ScheduledActivity } from '../../services/schedule-service';
import { LinkedInAccount } from '../../services/account-service';
import { format } from 'date-fns';
import { User } from '../../services/user-service';
import { useDemoUser } from '../../hooks/useDemoUser';

interface Props {
  activity: Activity | ScheduledActivity;
  user: LinkedInAccount | User;
  statistics: Statistics;
  scheduled?: boolean;
  onClick?: () => void;
}

const GroupTopActivityCard: React.FC<Props> = ({
  activity,
  statistics,
  user,
  scheduled,
  onClick,
}) => {
  const { groupId } = useParams<{ groupId: string }>();
  const isDemoUser = useDemoUser(user);

  return (
    <div className='box TopActivityCard'>
      <div className='card-header'>
        <img
          src={
            isDemoUser
              ? ('author' in activity && activity?.author.profile.picture) || ''
              : 'displayPhoto' in user?.profile
              ? user?.profile?.displayPhoto.tiny
              : user?.profile?.picture
          }
          alt=''
        />
        <span className={'card-header-title'}>
          {isDemoUser
            ? 'author' in activity &&
              `${activity?.author.profile.givenName} ${activity?.author.profile.familyName}`
            : 'firstName' in user?.profile
            ? `${user?.profile?.firstName} ${user?.profile?.lastName}`
            : `${user?.profile?.givenName} ${user?.profile?.familyName}`}
        </span>
      </div>
      {onClick ? (
        <div className='content-container' onClick={onClick}>
          <div className='open-in-new-icon'>
            <StatsChart />
          </div>
          <div className='content'>
            {'content' in activity ? activity.content : activity.commentary}
          </div>
          {'image' in activity && activity.image && (
            <img src={activity.image} alt={activity.content} />
          )}
          {'media' in activity && activity?.media[0] && (
            <img src={activity.media[0].publicUrl} alt={activity.commentary} />
          )}
        </div>
      ) : (
        <Link
          className='content-container'
          to={`/account/${user._id}/group/${groupId}/analyze/activity/${activity._id}`}
        >
          <div className='open-in-new-icon'>
            <StatsChart />
          </div>
          <div className='content'>
            {'content' in activity ? activity.content : activity.commentary}
          </div>
          {'image' in activity && activity.image && (
            <img src={activity.image} alt={activity.content} />
          )}
          {'media' in activity && activity?.media[0] && (
            <img src={activity.media[0].publicUrl} alt={activity.commentary} />
          )}
        </Link>
      )}
      <div className={`statistics-container ${scheduled && 'mt-6'}`}>
        {scheduled ? (
          <>
            <div className='statistic engagement-rate'>
              <div className='name'>
                <span>When ?</span>
              </div>
              <div className='value'>
                {format(
                  ('publishingDate' in activity && activity.publishingDate) ||
                    new Date(),
                  'yyyy.MM.dd'
                )}
              </div>
            </div>
            <div className='statistic'>
              <div className='name'>Posting type</div>
              <div className='value'>
                {('postType' in activity && activity.postType) || 'auto'}
              </div>
            </div>
          </>
        ) : (
          <>
            <progress
              className='progress'
              value={calculateEngagementRate(statistics) * 100}
              max='25'
            />
            <div className='statistic engagement-rate'>
              <div className='name'>
                <span>Total Engagement</span>
                <div
                  className='button is-white is-help-button'
                  data-tip='The total engagement rate is calculated from the engagement of all your reactions, comments and shares in relation to the post views.'
                >
                  <div className='icon'>
                    <HelpCircle size='1rem' />
                  </div>
                </div>
              </div>
              <div className='value'>
                {(calculateEngagementRate(statistics) * 100).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
                {'%'}
              </div>
            </div>
            <div className='statistic'>
              <div className='name'>Views</div>
              <div className='value'>{statistics.amountViews}</div>
            </div>
            <div className='statistic'>
              <div className='name'>Reactions</div>
              <div className='value'>{statistics.amountLikes}</div>
            </div>
            <div className='statistic'>
              <div className='name'>Comments</div>
              <div className='value'>{statistics.amountComments}</div>
            </div>
            {/* TODO: uncomment this when shares are available */}
            {/* <div className='statistic'>
            <div className='name'>Shares</div>
            <div className='value'>{statistics.amountShares}</div>
          </div> */}
          </>
        )}
      </div>
      <StyledTooltip />
    </div>
  );
};

export { GroupTopActivityCard };
