import styled from 'styled-components';
import { variables } from '../../styles/variables';
import LoadingBar from '../loader/LoadingBar';

const DropzoneWrapper = styled.div`
  border-radius: 0.5rem;
  border: 2px dashed ${variables.lightGrey};
  overflow: hidden;
  &:hover {
    border-color: ${variables.primary};
  }
`;

const Dropzone = styled.div`
  padding: 1.5rem;
  background-color: ${variables.offWhite};
  cursor: pointer;
  text-align: center;
  transition: 0.25s ease;
  transition-property: border-color;
`;

const LoadingIndicator = styled(LoadingBar)`
  margin-bottom: 0 !important;
  color: red;
`;

const Styled = {
  DropzoneWrapper,
  Dropzone,
  LoadingIndicator,
};

export default Styled;
