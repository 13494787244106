import React from "react";
import { Media } from "../../services/media-service";

import "../../styles/components/ScheduledActivityMedia.scss";

interface MediaImgProps
  extends React.HTMLAttributes<HTMLImageElement | HTMLVideoElement> {
  media: Media;
  classNames?: string;
  controls?: boolean;
}

const MediaImg: React.FC<MediaImgProps> = ({
  media,
  classNames,
  controls,
  ...props
}) => {
  // If a public URL is set, we use it, otherwise we will use the proxy to get the latest publicUrl
  const src =
    media.publicUrl ??
    `${process.env.REACT_APP_API_BASE_URL}/media/${media._id}/url`;

  if (media.mimeType === "application/pdf") {
    return (
      <iframe
        src={src}
        title={`Media: ${media._id} uploaded by ${media.uploadedBy}`}
      />
    );
  }
  if (media.type === "video") {
    return (
      <video
        className={`${classNames} video-section`}
        {...props}
        controls={controls}
      >
        <source src={src} />
      </video>
    );
  }

  return <img {...props} src={src} alt={`${media._id}`} />;
};

export default MediaImg;
