import apiClient from './api-client';
import { User } from './user-service';

export enum COMPANY_MEMBER_ROLE {
  OWNER = 'OWNER',
  MANAGER = 'MANAGER',
  USER = 'USER',
}

export enum TOKEN_TYPE {
  COMPANY_INVITATION = 'COMPANY_INVITATION',
}

export interface Organization {
  _id: string;
  name: string;
  invitations: OrganizationInvitation[];
  owners: User[];
  managers: User[];
  users: User[];
}

export type OrganizationAsMember = Pick<Organization, '_id' | 'name'>;

export interface OrganizationInvitation {
  _id: string;
  email: string;
  role: COMPANY_MEMBER_ROLE;
}

export interface CreateOrganizationPayload {
  name: string;
  paymentMethodId: string;
}

export async function getOrganizations() {
  const res = await apiClient.get<{ organizations: (Organization | OrganizationAsMember)[] }>(`/companies`);
  return res.data.organizations;
}

export async function getOrganization(organizationId: string) {
  const res = await apiClient.get<Organization | OrganizationAsMember>(`/companies/${organizationId}`);
  return res.data;
}

export async function createOrganization(payload: CreateOrganizationPayload) {
  const response = await apiClient.post<{ organization: Organization }>(`/companies`, payload);
  return response.data.organization;
}

export interface EditOrganizationPayload {
  name: string;
}

export async function editOrganization(companyId: string, payload: EditOrganizationPayload) {
  const response = await apiClient.patch<{ organization: Organization }>(`/companies/${companyId}`, payload);
  return response.data.organization;
}

export async function deleteOrganization(organizationId: string) {
  return await apiClient.delete<void>(`/companies/${organizationId}`);
}

export function removeMember(organizationId: string, userId: string) {
  return apiClient.delete(`/companies/${organizationId}/members/${userId}`);
}

export interface UpdateMemberPayload {
  role: COMPANY_MEMBER_ROLE;
}

export function updateMember(organizationId: string, userId: string, payload: UpdateMemberPayload) {
  return apiClient.patch(`/companies/${organizationId}/members/${userId}`, payload);
}

export interface InviteMemberPayload {
  email: string;
  role: COMPANY_MEMBER_ROLE;
}

export async function inviteMember(organizationId: string, payload: InviteMemberPayload) {
  const res = await apiClient.post<{ invitation: OrganizationInvitation }>(
    `/companies/${organizationId}/invitations`,
    payload
  );
  return res.data.invitation;
}

export function revokeInvitation(organizationId: string, invitationId: string) {
  return apiClient.delete<void>(`/companies/${organizationId}/invitations/${invitationId}`);
}

export type InvitationAction = 'accept' | 'decline';

export function actOnInvitation(organizationId: string, token: string, action: InvitationAction) {
  return apiClient.post<void>(`/companies/${organizationId}/invitations/act`, { token, action });
}

export function getStripePortalUrl(organizationId: string) {
  return `${process.env.REACT_APP_API_BASE_URL}/companies/${organizationId}/payment/stripe/portal`;
}
