import styled from 'styled-components';
import { colors } from '../../styles/variables';
import LoadingBar from '../loader/LoadingBar';

export const Wrapper = styled.div`
  margin: 0 auto;
  padding: 2rem 0;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Loader = styled(LoadingBar)`
  display: block;
  width: 5rem;
  height: 1rem;
  margin: 0 auto;
  && {
    margin-bottom: 0.25rem;
  }
`;

export const Text = styled.p`
  color: ${colors.grey};
  font-size: 0.875rem;
`;
