export const colors = {
  black: '#000',
  white: '#fff',
  royalBlueDark: '#0062c5',
  royalBlueLight: '#008dc5',
  whiteSmoke: '#f8f8f8',
  lachs: '#fffbf9',
  grey: '#919fa9',
  lightGrey: '#dee6ec',
  veryDarkBlue: '#011536',
  veryLightBlue: '#f5f9fc',
  darkBlue: '#002e5c',
  mediumBlue: '#0268cf',
  lightBlue: '#087cf0',
  orange: '#f9560d',
  pink: '#bd10e0',
  green: '#1abb2d',
  yellow: '#eedc09',
  lightOrange: '#f5a623',
  cyan: '#08b7f0',
};

export const variables = {
  // Colors
  primary: colors.mediumBlue,
  primaryDark: colors.darkBlue,
  lightBlue: colors.lightBlue,
  lightGrey: colors.grey,
  lighterGrey: colors.lightGrey,
  offWhite: colors.lachs,
  contrast: colors.white,

  // Font
  textColor: colors.veryDarkBlue,
  textColorLight: colors.grey,
  inputBackground: '#f5f5f5',

  errorRed: '#ce1126',

  // Types
  typeViews: '#0063c5',
  typeImpressions: '#7300c5',
  typeLikes: colors.green,
  typeComments: colors.pink,
  typeShares: 'red',
  typeEngagement: colors.lightOrange,
  typePosts: 'cornflowerblue',
  typeArticles: 'olive',
  typeConnections: colors.lightOrange,
};

export default variables;
