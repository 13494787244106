import React, { useState } from 'react';
import SettingHeader from '../../accountSettings/SettingHeader';
import { CompanyUserRoles } from '../../accountSharing/AccountSharingModal';
import { AccountsTableEntryViewModel } from '../../linked-accounts/AccountList';
import { useMutation } from 'react-query';
import { APIError } from '../../../services/api-client';
import { useDemoUser } from '../../../hooks/useDemoUser';
import { updateCompany, UpdateCompanyPayload } from '../../../services/company-service';
import { CompanyMembersList } from './CompanyMembersList';
import '../group/GroupEditModal.scss';

interface Props {
  data: AccountsTableEntryViewModel;
  onClose: () => void;
  onSave: () => void;
}

export const CompanyEditModal: React.FC<Props> = ({ data, onClose, onSave }) => {
  const { id, avatar, name, role } = data;
  const isDemoUser = useDemoUser();

  const [companyName, setCompanyName] = useState<string>(name);
  const [companyImage, setCompanyImage] = useState<File>();

  const [updateCompanyMutation] = useMutation<any, APIError, UpdateCompanyPayload>(updateCompany);

  const handleLogo = (event: any) => {
    const file: File = event.target.files[0];

    if (!file) {
      return;
    }

    // 1 Mb in bytes
    if (file.size > 1048576) {
      window.alert('File size is too big: 1 Mb allowed');
      return;
    }

    setCompanyImage(file);
  };

  const saveChanges = () => {
    if (companyImage || name !== companyName) {
      return updateCompanyMutation({
        companyId: id,
        companyLogo: companyImage,
        name: companyName,
      }).then(() => onSave());
    }

    onClose();
  };

  return (
    <>
      <button type='button' className='modal-close' onClick={() => onClose()} />
      <div className=''>
        <SettingHeader
          headline={`Settings for ${name}`}
          description='Here you can configurate how the company should work.'
          tip={role === CompanyUserRoles.Admin ? 'Company Branding & Invites' : 'Company Page Members'}
        />

        {role !== CompanyUserRoles.Analyst && (
          <div className='groupInfo-container'>
            <div className='name-container responsive-container'>
              <label className='label'>Name of the company</label>
              <input
                className={`name ${role !== CompanyUserRoles.Admin && 'disabled'}`}
                type='text'
                onChange={event => setCompanyName(event.target.value)}
                value={companyName}
                disabled={role !== CompanyUserRoles.Admin}
              />
            </div>
            <div className='upload-container responsive-container'>
              <label className='label'>Add company Logo (optional 1:1)</label>
              <input
                className='upload'
                id='upload'
                type='file'
                accept='image/png, image/jpeg'
                multiple={false}
                onChange={handleLogo}
                disabled={role !== CompanyUserRoles.Admin}
              />
              <label htmlFor='upload' className={`upload-button ${role !== CompanyUserRoles.Admin && 'disabled'}`}>
                Upload file
              </label>
              {(!!companyImage || !!avatar) && (
                <div className='preview-container'>
                  <img
                    className='preview'
                    alt='Group preview'
                    src={!!companyImage ? URL.createObjectURL(companyImage) : avatar}
                  />
                </div>
              )}
            </div>
            <div className='setting-header'>
              <p className='tip'>Company Page Members</p>
            </div>
          </div>
        )}

        <CompanyMembersList companyId={id} closeModal={onClose} />
      </div>

      <div className='modal-footer'>
        <div className='button-container responsive-container'>
          <button className='button is-secondary' onClick={onClose}>
            Cancel
          </button>
          <button
            disabled={isDemoUser}
            className={`button is-primary ${isDemoUser && 'is-disabled'}`}
            type='submit'
            onClick={saveChanges}>
            Save
          </button>
        </div>
      </div>
    </>
  );
};
