import React from 'react';
import '../../styles/components/accountSharing/ChooseTool.scss';

const ChooseTool = (props: { headline: string; children: any }) => {
  return (
    <div className='chooseTool'>
      <h3 className='headline-3'>{props.headline}</h3>
      <div className='tool-wrapper'>{props.children}</div>
    </div>
  );
};

export default ChooseTool;
