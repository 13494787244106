import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { CompanyUserRoles } from '../../accountSharing/AccountSharingModal';
import { Button } from '../../button/Button';
import { Trash } from '@styled-icons/ionicons-solid';
import Modal from '../../modal/Modal';
import { useMutation } from 'react-query';
import { APIError } from '../../../services/api-client';
import '../group/GroupMembersList.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { useDemoUser } from '../../../hooks/useDemoUser';
import { DeleteModal } from '../group/delete-modal/DeleteModal';
import {
  CompanyMember,
  deleteCompanyMember,
  getCompanyMembers,
  updateCompanyMember,
  UpdateCompanyMemberPayload,
} from '../../../services/company-service';

interface Props {
  companyId: string;
  closeModal: () => void;
}

const CompanyMembersList: React.FC<Props> = ({ companyId, closeModal }) => {
  const [currentDeleteId, setCurrentDeleteId] = useState('');
  const [companyMembers, setCompanyMembers] = useState<CompanyMember[]>([]);
  const { user } = useSelector((state: RootState) => state.auth);
  const isDemoUser = useDemoUser();

  const [getCompanyMembersMutation, { isLoading }] = useMutation(() => getCompanyMembers(companyId), {
    onSuccess: data => setCompanyMembers(data || []),
    onError: () => closeModal(),
  });

  const [updateMemberMutation, { isLoading: isMutationLoading }] = useMutation<
    any,
    APIError,
    UpdateCompanyMemberPayload
  >(updateCompanyMember);

  const [deleteMemberMutation] = useMutation<any, APIError, { objectId: string; id: string }>(deleteCompanyMember, {
    onSuccess: () => {
      getCompanyMembersMutation().catch(e => console.log(e.message));
      setCurrentDeleteId('');
    },
  });

  useEffect(() => {
    getCompanyMembersMutation();
  }, [getCompanyMembersMutation]);

  const numberOfAdmins = useMemo(() => {
    return (companyMembers || []).filter(member => member.role === CompanyUserRoles.Admin).length;
  }, [companyMembers]);

  const userRole = useMemo(() => {
    return (companyMembers || []).find(member => member.userId === user?._id)?.role;
  }, [companyMembers, user]);

  const updateHandler = (id: string, payload: { role: CompanyUserRoles }) => {
    return updateMemberMutation({ companyId, id, payload }).then(() => {
      const list = [...companyMembers];
      const memberIndex = list.findIndex(member => member.id === id);

      list[memberIndex] = { ...list[memberIndex], ...payload };
      setCompanyMembers(list);
    });
  };

  return (
    <>
      <table className='list-grid group-settings__table'>
        <thead className='header'>
          <tr className='tr'>
            <th className='th' />
            <th className='th'>Name</th>
            <th className='th'>Role</th>
            <th className='th'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {companyMembers?.map(({ id, avatar, name, role, userId }) => (
            <tr key={id} className='tr tr--tbody'>
              <td className='td list-grid__cell--avatar'>
                {!!avatar && <img className='is-rounded avatar' src={avatar} alt='Group Member avatar' />}
              </td>
              <td className='td list-grid__cell list-grid__cell--name'>{name}</td>

              <td className='td list-grid__cell list-grid__cell--products'>
                {
                  <div className='select'>
                    <select
                      required
                      disabled={
                        isMutationLoading ||
                        userRole !== CompanyUserRoles.Admin ||
                        (role === CompanyUserRoles.Admin && numberOfAdmins < 2) ||
                        (userId !== user?._id && role === CompanyUserRoles.Admin)
                      }
                      defaultValue={role}
                      onChange={e => updateHandler(id, { role: e.target.value as CompanyUserRoles })}>
                      {Object.values(CompanyUserRoles).map(role => (
                        <option key={role} value={role}>
                          {role?.toLowerCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                }
              </td>

              <td className='td list-grid__cell'>
                <Button
                  className={classNames({ 'is-loading': isLoading || isMutationLoading, 'is-disabled': isDemoUser })}
                  onClick={setCurrentDeleteId.bind(null, id)}
                  disabled={
                    isDemoUser ||
                    isMutationLoading ||
                    userRole !== CompanyUserRoles.Admin ||
                    (role === CompanyUserRoles.Admin && numberOfAdmins < 2) ||
                    (userId !== user?._id && role === CompanyUserRoles.Admin)
                  }>
                  <Trash size='1rem' />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {currentDeleteId && (
        <Modal className='' open={true} fullWidth>
          <div className='box delete-modal'>
            <DeleteModal
              objectId={companyId}
              id={currentDeleteId}
              onClose={setCurrentDeleteId.bind(null, '')}
              closeModal={closeModal}
              onSuccess={getCompanyMembersMutation}
              remove={deleteMemberMutation}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export { CompanyMembersList };
