import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { demoUserEmail, User } from '../services/user-service';
import { LinkedInAccount } from '../services/account-service';

const useDemoUser = (account?: LinkedInAccount | User | null | undefined): boolean => {
  const { user } = useSelector((state: RootState) => state.auth);

  const isDemoUser = useMemo(
    () => (account ? account.email === demoUserEmail && user?.email === demoUserEmail : user?.email === demoUserEmail),
    [user, account]
  );

  return isDemoUser;
};

export { useDemoUser };
