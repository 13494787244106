import React, { useCallback, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { Download } from '@styled-icons/ionicons-solid';
import classNames from 'classnames';
import { RootState } from '../store/reducers';
import { IContentConverter, IGMembersConverter, IProfileConverter, TypeCSV } from '../services/csv-service';
import '../styles/components/CsvLoad.scss';
import Modal from './modal/Modal';
import { Styled as StyledModal } from './modal/Modal.styles';
import { format } from 'date-fns';
import { endOfWeek } from 'date-fns/esm';
import { useMutation } from 'react-query';
import { loadFullAccountCsv } from '../services/statistics-service';
import { useParams } from 'react-router-dom';

enum Type {
  Full = 'Full',
  Page = 'Page',
}

export enum PageType {
  Profile = 'PROFILE',
  Content = 'CONTENT',
  Full = 'FULL',
}

interface Props {
  data: IProfileConverter | IContentConverter | IGMembersConverter;
  converter: TypeCSV;
  type: PageType;
  group?: boolean;
}

const CsvLoad: React.FC<Props> = ({ data, converter, type, group }) => {
  const { accountId } = useParams<{ accountId: string }>();

  const { selected, selectedGroup } = useSelector((state: RootState) => state.linkedAccounts);
  const { startDate, endDate } = useSelector((state: RootState) => state.dateRangeFilter);

  const [fullData, setFullData] = useState<(string | number)[][]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [active, setActive] = useState<Type>(Type.Page);

  const [loadFullAccount, { isLoading }] = useMutation(() => loadFullAccountCsv(accountId, startDate, endDate), {
    onSuccess: data => {
      setFullData(data);
    },
  });

  const loadData = () => {
    if (!fullData.length) {
      return loadFullAccount().then(setActive.bind(null, Type.Full));
    }
    setActive(Type.Full);
  };

  const fileName = useMemo(() => {
    return `${active === Type.Full ? 'FULL' : type}
    _data-export_
    ${group ? selectedGroup?.name : selected?.profile.firstName}${group ? '' : '-' + selected?.profile.lastName}_
    ${format(startDate, 'MMM-do-Y')}-${format(endOfWeek(endDate), 'MMM-do-Y')}
    .csv`.replace(/\s/gim, '');
  }, [active, type, group, selectedGroup, selected, startDate, endDate]);

  const csv = useMemo(() => {
    // @ts-ignore
    return converter(data, group);
  }, [data, converter, group]);

  const dataToLoad = useMemo(() => (active === Type.Full ? fullData : csv), [active, csv, fullData]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setFullData([]);
    setActive(Type.Page);
  }, []);

  return (
    <>
      <div className='csv-block' onClick={setIsModalOpen.bind(null, true)}>
        <Download className='icon' />
        Download CSV
      </div>

      {isModalOpen && (
        <Modal fullWidth open onClose={closeModal}>
          <div className='box modal-box'>
            <StyledModal.ContentCard>
              <StyledModal.Headline>Download CSV</StyledModal.Headline>

              <p className='has-text-grey mb-6'>
                Choose if you want to download only this page or all data from this account within the chosen date
                range.
              </p>

              <div
                className={classNames('csv-block option', { loading: isLoading })}
                onClick={setActive.bind(null, Type.Page)}>
                <Download className={classNames(`icon`, { active: active === Type.Page })} />
                Download CSV (this page)
              </div>
              <div
                className={classNames('csv-block option', { loading: isLoading })}
                onClick={loadData}
                aria-disabled={isLoading}>
                <Download className={classNames(`icon`, { active: active === Type.Full })} />
                Download CSV (full inlytics account)
              </div>

              <div className='navbar-divider'></div>

              <div className='level-left button-container mt-5'>
                <button className='button is-secondary' onClick={closeModal}>
                  Cancel
                </button>
                <CSVLink
                  className='button is-primary level-right'
                  data={[
                    type === PageType.Profile
                      ? []
                      : ['date range', `${format(startDate, 'MMM-do-Y')}-${format(endDate, 'MMM-do-Y')}`],
                    ...dataToLoad,
                  ]}
                  filename={fileName}>
                  Download CSV
                </CSVLink>
              </div>
            </StyledModal.ContentCard>
          </div>
        </Modal>
      )}
    </>
  );
};

export { CsvLoad };
