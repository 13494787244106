import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  Brush,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { colors, variables } from "../../../styles/variables";
import { dateFormatter } from "../../profile/ConnectionTimeline";
import { ICompanyUpdatesTimeline } from "../../../services/company-service";

export enum TIMELINE_TYPE {
  IMPRESSIONS,
  UNIQUE_IMPRESSIONS,
  CLICKS,
  REACTIONS,
  COMMENTS,
  ENGAGEMENT_RATE
}

const LABEL_MAP = {
  [TIMELINE_TYPE.IMPRESSIONS]: "Impressions",
  [TIMELINE_TYPE.UNIQUE_IMPRESSIONS]: "Unique Impressions",
  [TIMELINE_TYPE.CLICKS]: "Clicks",
  [TIMELINE_TYPE.REACTIONS]: "Reactions",
  [TIMELINE_TYPE.COMMENTS]: "Comments",
  [TIMELINE_TYPE.ENGAGEMENT_RATE]: "Engagement Rate"
};

const KEY_MAP = {
  [TIMELINE_TYPE.IMPRESSIONS]: "impressions",
  [TIMELINE_TYPE.UNIQUE_IMPRESSIONS]: "uniqueImpressions",
  [TIMELINE_TYPE.CLICKS]: "clicks",
  [TIMELINE_TYPE.REACTIONS]: "reactions",
  [TIMELINE_TYPE.COMMENTS]: "comments",
  [TIMELINE_TYPE.ENGAGEMENT_RATE]: "engagementRate"
};

interface Props {
  data: ICompanyUpdatesTimeline[];
  brushEnabled?: boolean;
}

interface RechartData {
  number: number;
  timestamp: number;
}

export const UpdatesTimeline: React.FC<Props> = ({ data, brushEnabled }) => {
  const [visitorsRechartData, setVisitorsRechartData] = useState<RechartData[]>(
    []
  );
  const [currentTimeline, setCurrentTimeline] = useState<TIMELINE_TYPE>(
    TIMELINE_TYPE.IMPRESSIONS
  );

  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(0);

  const handleUpdate = ({
    startIndex,
    endIndex
  }: {
    startIndex: number;
    endIndex: number;
  }) => {
    setStartIndex(startIndex);
    setEndIndex(endIndex);
  };

  const handleOnTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const type = +e.currentTarget.value as TIMELINE_TYPE;
    setCurrentTimeline(type);
  };

  useEffect(() => {
    const key = KEY_MAP[currentTimeline] as
      | "impressions"
      | "uniqueImpressions"
      | "clicks"
      | "reactions"
      | "comments"
      | "engagementRate";

    if (key === "engagementRate") {
    } else {
      const newData: RechartData[] = data.map(item => ({
        number: item[key],
        timestamp: item.timestamp
      }));

      setVisitorsRechartData(newData);
      setStartIndex(0);
      setEndIndex(newData.length - 1);
    }
  }, [currentTimeline, data]);

  return (
    <div className="box info-block">
      <div className="select-container">
        <div className="select">
          <select
            onChange={handleOnTypeChange}
            defaultValue={TIMELINE_TYPE.IMPRESSIONS}
          >
            {Object.entries(LABEL_MAP).map(([type, value]) => (
              <option key={type} value={type}>
                {value}
              </option>
            ))}
          </select>
        </div>
      </div>
      <ResponsiveContainer height={400}>
        <AreaChart
          data={
            brushEnabled
              ? visitorsRechartData.slice(startIndex, endIndex + 1)
              : visitorsRechartData
          }
        >
          <XAxis
            dataKey="timestamp"
            tickLine={false}
            axisLine={false}
            tickFormatter={dateFormatter}
          />
          <YAxis tickLine={false} axisLine={false} />
          <Tooltip
            labelFormatter={dateFormatter}
            formatter={value => [value, LABEL_MAP[currentTimeline]]}
            label={LABEL_MAP[currentTimeline]}
          />
          <Area
            strokeWidth={3}
            dataKey="number"
            stroke={colors.pink}
            fill="url(#gradient-number)"
          />
        </AreaChart>
      </ResponsiveContainer>
      {brushEnabled && !!visitorsRechartData?.length && (
        <div className="chart-container">
          <ResponsiveContainer height={60} className="chart-base">
            <BarChart data={visitorsRechartData}>
              <YAxis
                yAxisId="number"
                tickLine={false}
                axisLine={false}
                tick={false}
              />

              <Bar
                yAxisId="number"
                dataKey="number"
                stroke={variables.lightGrey}
                fill={variables.lightGrey}
                isAnimationActive={false}
              />
            </BarChart>
          </ResponsiveContainer>
          <ResponsiveContainer height={60} className="chart-brush">
            <BarChart data={visitorsRechartData}>
              <YAxis yAxisId="number" tick={false} />

              <Brush
                startIndex={startIndex}
                endIndex={endIndex}
                height={60}
                fill="rgba(0, 0, 0, 0)"
                stroke={colors.pink}
                onChange={handleUpdate}
                dataKey="timestamp"
                tickFormatter={dateFormatter}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};
