import React, { useEffect, useState } from "react";
import Header from "../Header";
import DateRangePicker from "../header/DateRangePicker";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import {
  ActivityStatistics,
  calculateEngagementRate,
  loadActivitiesForGroup,
  loadHistoryForGroup,
  loadMetricsForGroup,
  Statistics
} from "../../services/statistics-service";
import DashboardStatisticCards from "../DashboardStatisticCards";
import {
  CaretBack,
  CaretForward,
  ChevronForward
} from "@styled-icons/ionicons-solid";
import { GroupTopActivityCard } from "./GroupTopActivityCard";
import DashboardTimeline from "../DashboardTimeline";
import { InviteGroupMemberWrapper } from "./InviteGroupMemberWrapper";
import { useGroupName } from "../../hooks/useGroupName";
import { useQuery } from "react-query";

interface Props {}

const entriesPerPage = 4;

const GroupDashboard: React.FC<Props> = () => {
  const { accountId, groupId } = useParams<{
    accountId: string;
    groupId: string;
  }>();
  const { inviteMemberModalOpen } = useSelector(
    (state: RootState) => state.settings
  );
  const { startDate, endDate } = useSelector(
    (state: RootState) => state.dateRangeFilter
  );
  const startTimeStamp = startDate.getTime();
  const endTimeStamp = endDate.getTime();

  const [statistics, setStatistics] = useState<Statistics>();
  const [referenceStatistics, setReferenceStatistics] = useState<Statistics>();
  const [topPosts, setTopPosts] = useState<ActivityStatistics[]>();
  const [currentTopPosts, setCurrentTopPosts] = useState<ActivityStatistics[]>(
    []
  );

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const { groupName } = useGroupName(groupId);

  const { data, isLoading } = useQuery(
    ["loadHistory", accountId, startDate, endDate, groupId],
    () => loadHistoryForGroup(accountId, groupId, startDate, endDate)
  );

  useEffect(() => {
    if (!accountId || !groupId) return;
    setLoading(true);

    const startDate = new Date(startTimeStamp);
    const endDate = new Date(endTimeStamp);

    Promise.all([
      loadMetricsForGroup(accountId, groupId, startDate, endDate),
      loadActivitiesForGroup(accountId, groupId, startDate, endDate)
    ])
      .then(([metrics, { activities }]) => {
        setStatistics(metrics.statistics);
        if (metrics.reference) {
          setReferenceStatistics(metrics.reference);
        }

        const sortedPosts = activities
          .map(entry => ({
            ...entry,
            engagementRate: calculateEngagementRate(entry.statistics)
          }))
          .sort((a, b) => b.engagementRate - a.engagementRate);

        setPage(0);
        setTopPosts(sortedPosts);
      })
      .catch(e => {
        setReferenceStatistics(undefined);
        setStatistics(undefined);
        console.warn(e.message);
      })
      .finally(() => setLoading(false));
  }, [accountId, groupId, startTimeStamp, endTimeStamp]);

  useEffect(() => {
    setCurrentTopPosts(
      [...(topPosts || [])].slice(
        page * entriesPerPage,
        page * entriesPerPage + entriesPerPage
      )
    );
  }, [page, topPosts]);

  return (
    <div className="Dashboard">
      <Header
        title={
          groupName && (
            <>
              <span>Group</span>
              <ChevronForward className="icon arrow" />
              <span>{groupName}</span>
            </>
          )
        }
        content={<DateRangePicker />}
        loading={loading}
      />

      <section className="section timelines-container">
        {statistics && (
          <>
            <DashboardTimeline
              id={1}
              group={true}
              statistics={statistics}
              referenceStatistics={referenceStatistics}
              data={data}
              isLoading={isLoading || loading}
            />
            <DashboardTimeline
              id={2}
              group={true}
              statistics={statistics}
              referenceStatistics={referenceStatistics}
              data={data}
              isLoading={isLoading || loading}
            />
          </>
        )}
      </section>

      <section className="section">
        {statistics && (
          <>
            <h5 className="title is-size-5 has-text-grey has-text-weight-normal">
              Aggregated Group Statistics
            </h5>
            <DashboardStatisticCards
              data={data}
              statistics={statistics}
              referenceStatistics={referenceStatistics}
              cardFilter={["views", "likes", "comments", "engagement"]}
            />
          </>
        )}
      </section>

      {topPosts !== undefined && (
        <section className="section">
          <h5 className="title is-size-5 has-text-grey has-text-weight-normal">
            Top Posts By Person
          </h5>
          <div className="top-activity-card-container">
            <button
              className="button button-pagination is-left"
              onClick={() => setPage(page - 1)}
              disabled={page === 0}
            >
              <CaretBack className="icon has-text-grey" />
            </button>

            {currentTopPosts.map(statistic => (
              <GroupTopActivityCard
                key={statistic.activity._id}
                activity={statistic.activity}
                user={statistic.user}
                statistics={statistic.statistics}
              />
            ))}
            <button
              className="button button-pagination is-right"
              onClick={() => setPage(page + 1)}
              disabled={(page + 1) * entriesPerPage > topPosts.length}
            >
              <CaretForward className="icon has-text-grey" />
            </button>
          </div>
        </section>
      )}

      {inviteMemberModalOpen && <InviteGroupMemberWrapper />}
    </div>
  );
};

export { GroupDashboard };
