import React, {
  useState,
  ChangeEvent,
  useEffect,
  useLayoutEffect,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import classNames from 'classnames';
import ReactMde from 'react-mde';
import { adjustHue } from 'polished';
import {
  LogoLinkedin,
  StatsChart,
  Document,
  ChevronUp,
  ChevronDown,
  ArrowBack,
  ArrowForward,
  HelpCircle,
  Pricetags,
} from '@styled-icons/ionicons-solid';
import { Document as DocumentOutline } from '@styled-icons/ionicons-outline';
import ReactTooltip from 'react-tooltip';

import {
  Activity,
  ACTIVITY_TYPE_NAME_MAP,
  loadNotes,
  ActivityNote,
  upsertNoteForActivity,
  deleteNoteForActivity,
  ActivityHighlights,
} from '../services/activities-service';
import Modal from './modal/Modal';
import { converter } from '../services/markdown-service';
import '../styles/components/ContentTable.scss';
import {
  loadActivityStatisticsForAccount,
  Statistics,
  calculateEngagementRate,
  loadActivitiesForGroup,
  loadNotesForGroup,
} from '../services/statistics-service';

import { RootState } from '../store/reducers';

import StyledTooltip from './StyledTooltip';
import { colors } from '../styles/variables';
import { OverviewHighlightsChart } from './ContentTableHighlightsChart';
import ActivityHighlightsRegionMap from './ActivityHighlightsRegionMap';
import { LinkedInAccount } from '../services/account-service';
import { AccountAvatar } from './accountAvatar/AccountAvatar';
import LabelsModal from './LabelsModal';
import { getUserLabels } from '../helpers';

type ContentRow = {
  activity: Activity;
  statistics: StatisticsWithRelativeColor;
};

interface StatisticsWithEngagementRate extends Statistics {
  engagementRate: number;
}

export interface StatisticsWithRelativeColor
  extends StatisticsWithEngagementRate {
  amountViewsColor: string;
  amountImpressionsColor: string;
  amountLikesColor: string;
  amountCommentsColor: string;
  amountSharesColor: string;
  engagementRateColor: string;
}

const ROWS_PER_PAGE_OPTION = {
  FIVE: {
    value: 5,
    text: '5',
  },
  TEN: {
    value: 10,
    text: '10',
  },
  TWENTY_FIVE: {
    value: 25,
    text: '25',
  },
  FIFTY: {
    value: 50,
    text: '50',
  },
  ALL: {
    value: -1,
    text: 'All',
  },
};

interface TableHeaderSortable {
  name: string;
  sortable: true;
  sortFnAsc: (a: ContentRow, b: ContentRow) => number;
}
interface TableHeaderNonSortable {
  name: string;
  sortable: false;
}

const tableHeaders: {
  [key: string]: TableHeaderSortable | TableHeaderNonSortable;
} = {
  picture: {
    name: 'Picture',
    sortable: false,
  },
  type: {
    name: 'Type',
    sortable: true,
    sortFnAsc: (a, b) => b.activity.type.localeCompare(a.activity.type),
  },
  preview: {
    name: 'Preview',
    sortable: false,
  },
  date: {
    name: 'Date',
    sortable: true,
    sortFnAsc: (a, b) =>
      new Date(a.activity.publicationDate).getTime() -
      new Date(b.activity.publicationDate).getTime(),
  },
  views: {
    name: 'Views',
    sortable: true,
    sortFnAsc: (a, b) => a.statistics.amountViews - b.statistics.amountViews,
  },
  impressions: {
    name: 'Impressions',
    sortable: true,
    sortFnAsc: (a, b) =>
      a.statistics.amountImpressions - b.statistics.amountImpressions,
  },
  likes: {
    name: 'Reactions',
    sortable: true,
    sortFnAsc: (a, b) => a.statistics.amountLikes - b.statistics.amountLikes,
  },
  comments: {
    name: 'Comments',
    sortable: true,
    sortFnAsc: (a, b) =>
      a.statistics.amountComments - b.statistics.amountComments,
  },
  /* TODO: uncomment this when shares are available */
  // shares: {
  //   name: 'Shares',
  //   sortable: true,
  //   sortFnAsc: (a, b) => a.statistics.amountShares - b.statistics.amountShares,
  // },
  engagementRate: {
    name: 'Engagement Rate',
    sortable: true,
    sortFnAsc: (a, b) =>
      a.statistics.engagementRate - b.statistics.engagementRate,
  },
  action: {
    name: 'Action',
    sortable: false,
  },
};

const initialSortBy = 'date';
const initialSortDirection = 'desc';

interface Props {
  group?: boolean;
  onDataLoaded?: (data: any) => void;
  isLoading?: boolean;
}

const ContentTable: React.FC<Props> = ({ group, onDataLoaded, isLoading }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { accountId, groupId } = useParams<{
    accountId: string;
    groupId: string;
  }>();
  const { startDate, endDate } = useSelector(
    (state: RootState) => state.dateRangeFilter
  );
  const startTimeStamp = startDate.getTime();
  const endTimeStamp = endDate.getTime();

  const [sortBy, setSortBy] = useState<string>(initialSortBy);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(
    initialSortDirection
  );
  const [activities, setActivities] = useState<
    {
      activity: Activity;
      statistics: StatisticsWithRelativeColor;
      user?: LinkedInAccount;
    }[]
  >([]);
  const [highlights, setHighlights] = useState<ActivityHighlights>();
  const [currentRows, setCurrentRows] = useState<
    {
      activity: Activity;
      statistics: StatisticsWithRelativeColor;
      user?: LinkedInAccount;
    }[]
  >([]);
  const [, setLoading] = useState(false);

  /**
   * Labels
   */
  const [entryActivityId, setEntryActivityId] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('Filter by label');
  const [userLabels, setUserLabels] = useState<string[]>([]);
  const [isLabelsModalOpen, setIsLabelsModalOpen] = useState(false);

  useEffect(() => {
    const fetchUserLabels = async () => {
      return await getUserLabels(accountId);
    };
    fetchUserLabels().then(response => {
      setUserLabels(response);
    });
  }, [accountId]);

  /**
   * Notes
   */
  const [notes, setNotes] = useState<Map<string, ActivityNote>>();
  const [currentNote, setCurrentNote] = useState<ActivityNote | null>();
  const [selectedMarkdownTab, setSelectedMarkdownTab] = React.useState<
    'write' | 'preview'
  >('preview');

  // Load notes associated with these activities
  useEffect(() => {
    if (!accountId) {
      return;
    }
    setLoading(true);

    const startDate = new Date(startTimeStamp);
    const endDate = new Date(endTimeStamp);

    const loadActivity = group
      ? loadActivitiesForGroup.bind(
          null,
          accountId,
          groupId,
          startDate,
          endDate
        )
      : loadActivityStatisticsForAccount.bind(
          null,
          accountId,
          startDate,
          endDate
        );

    const loadActivityNotes = group
      ? loadNotesForGroup.bind(null, accountId, groupId)
      : loadNotes.bind(null, accountId);

    Promise.all([loadActivityNotes(), loadActivity()])
      .then(([notes, { activities, highlights }]) => {
        // Notes
        setNotes(notes);
        // Activities
        let activitiesWithEngagement: {
          activity: Activity;
          statistics: StatisticsWithRelativeColor;
          user?: LinkedInAccount;
        }[] = activities
          .map(entry => {
            const { statistics } = entry;
            return {
              ...entry,
              statistics: {
                ...entry.statistics,
                engagementRate: calculateEngagementRate(statistics),
                amountViewsColor: '',
                amountImpressionsColor: '',
                amountLikesColor: '',
                amountCommentsColor: '',
                amountSharesColor: '',
                engagementRateColor: '',
              },
            };
          })
          .sort((a, b) => {
            if (b.activity.publicationDate && a.activity.publicationDate) {
              return (
                new Date(b.activity.publicationDate).getTime() -
                new Date(a.activity.publicationDate).getTime()
              );
            }
            return a.activity.sortIndex - b.activity.sortIndex;
          });
        // Calculate % of total value for activities
        const propsToShowRelative = [
          'amountComments',
          'amountLikes',
          'amountViews',
          'amountImpressions',
          'amountShares',
          'engagementRate',
        ];
        const maxAmounts = new Map();
        propsToShowRelative.forEach(prop => {
          const max = Math.max(
            ...activitiesWithEngagement.map(
              act => (act as any).statistics[prop]
            )
          );
          maxAmounts.set(prop, max === 0 ? 0.01 : max);
        });
        activitiesWithEngagement = activitiesWithEngagement.map(entry => {
          propsToShowRelative.forEach(prop => {
            const max = maxAmounts.get(prop);
            const percentage = (entry as any).statistics[prop] / max;
            let color = adjustHue(percentage * 120, '#f0bba9');
            if (max < 0.02) {
              color = '';
            }
            (entry as any).statistics[prop + 'Color'] = color;
          });
          return entry;
        });
        setActivities(activitiesWithEngagement);
        setHighlights(highlights);
        onDataLoaded &&
          onDataLoaded({
            Activities: activitiesWithEngagement,
            Highlights: highlights,
          });
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [accountId, groupId, startTimeStamp, endTimeStamp, group, onDataLoaded]);

  const handleOnNoteClick = (activity: string) => {
    const note = notes?.get(activity);
    if (note) {
      // We are editing an existing note
      setSelectedMarkdownTab('preview');
      setCurrentNote(note);
    } else {
      // This is a new note
      setSelectedMarkdownTab('write');
      setCurrentNote({
        activity,
        content: '',
        user: '', // Not needed as we are authenticated while the request anyways
      });
    }
  };

  const handleOnNoteModalSave = () => {
    if (!currentNote || !notes) {
      return;
    }
    upsertNoteForActivity(currentNote.activity, currentNote.content).then(
      () => {
        // Update or create the note locally
        const newNotes = new Map(notes);
        const entry = notes.get(currentNote.activity) || {
          activity: currentNote.activity,
          user: '',
        };
        if (entry) {
          newNotes.set(currentNote.activity, {
            ...entry,
            content: currentNote.content,
          });
        }
        setNotes(newNotes);
        // Close the modal
        setCurrentNote(null);
      }
    );
  };

  const handleOnNoteModalDelete = () => {
    if (!currentNote || !notes) {
      return;
    }
    deleteNoteForActivity(currentNote.activity).then(() => {
      // Delete the note locally
      const newNotes = new Map(notes);
      newNotes.delete(currentNote.activity);
      setNotes(newNotes);
      // Close modal
      setCurrentNote(null);
    });
  };

  const handleOnModalContentInput = (newContent: string) => {
    if (!currentNote) {
      return;
    }
    setCurrentNote({ ...currentNote, content: newContent });
  };

  useEffect(() => {
    let activitiesCopy = [...activities];
    // Sort the rows
    const header = tableHeaders[sortBy];
    if (header && header.sortable) {
      activitiesCopy.sort(header.sortFnAsc);
      if (sortDirection === 'desc') {
        activitiesCopy = activitiesCopy.reverse();
      }
    }
    // Only display a portion of it
    if (rowsPerPage > 0) {
      activitiesCopy = activitiesCopy.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    }
    setCurrentRows(activitiesCopy);
  }, [activities, page, rowsPerPage, sortBy, sortDirection]);

  useEffect(() => {
    setPage(0);
  }, [activities]);

  useLayoutEffect(() => {
    ReactTooltip.rebuild();
  }, [currentRows]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOnHeaderClick = (headerId: string) => {
    const header = tableHeaders[headerId];
    if (!header || !header.sortable) {
      // This is a non sortable header, simply ignore the click
      return false;
    }
    if (sortBy === headerId) {
      // This is what we've currently sorted by, simply swap the direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setPage(0);
      setSortBy(headerId);
      setSortDirection(initialSortDirection);
    }
  };

  return (
    <>
      <div className='box table-wrapper ContentTable' id='ContentOVerview'>
        <table className='table is-fullwidth is-hoverable'>
          <thead>
            <tr>
              {group && (
                <th className={classNames('table-header')}>
                  <span>People</span>
                </th>
              )}
              {Object.keys(tableHeaders).map(headerId => {
                const header = tableHeaders[headerId];
                const isActive = sortBy === headerId;
                return (
                  <th
                    key={headerId}
                    className={classNames('table-header', {
                      sortable: header.sortable,
                      active: isActive,
                    })}
                    onClick={() => handleOnHeaderClick(headerId)}
                  >
                    <div>
                      <span>{header.name}</span>
                      {header.sortable && (
                        <div
                          className='sort-arrows'
                          data-html2canvas-ignore='true'
                        >
                          <ChevronUp
                            className={classNames('icon is-small', {
                              active: isActive && sortDirection === 'asc',
                            })}
                          />
                          <ChevronDown
                            className={classNames('icon is-small', {
                              active: isActive && sortDirection === 'desc',
                            })}
                          />
                        </div>
                      )}
                      {header.name === 'Action' && (
                        <span
                          className='select is-primary'
                          data-html2canvas-ignore={
                            header.name === 'Action' ? 'true' : 'false'
                          }
                        >
                          <select
                            onChange={e => {
                              setSelectedLabel(e.target.value);
                            }}
                          >
                            {['Filter by label', ...userLabels].map(label => (
                              <option value={label} key={label}>
                                {label}
                              </option>
                            ))}
                          </select>
                        </span>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          {
            <tfoot data-html2canvas-ignore='true'>
              <tr>
                <th colSpan={100}>
                  <div className='level'>
                    <div className='level-left'>
                      {rowsPerPage !== ROWS_PER_PAGE_OPTION.ALL.value && (
                        <div className='level-item is-size-6'>
                          Page {page + 1} of{' '}
                          {Math.ceil(activities.length / rowsPerPage)}
                        </div>
                      )}
                    </div>
                    <div className='level-right'>
                      <div className='level-item'>
                        <label htmlFor='rows-per-page' className='label'>
                          Rows per page
                        </label>
                      </div>
                      <div className='level-item'>
                        <div className='select'>
                          <select
                            id='rows-per-page'
                            onChange={handleChangeRowsPerPage}
                            value={rowsPerPage}
                          >
                            {Object.values(ROWS_PER_PAGE_OPTION).map(
                              (entry, i) => (
                                <option key={i} value={entry.value}>
                                  {entry.text}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className='level-item'>
                        <div className='buttons'>
                          <button
                            className='button has-icons-left'
                            onClick={() => handleChangePage(page - 1)}
                            disabled={page === 0}
                          >
                            <span className='icon'>
                              <ArrowBack height='1rem' />
                            </span>
                            <span>Previous page</span>
                          </button>
                          <button
                            className='button has-icons-right'
                            onClick={() => handleChangePage(page + 1)}
                            disabled={
                              (page + 1) * rowsPerPage > activities.length
                            }
                          >
                            <span>Next page</span>
                            <span className='icon'>
                              <ArrowForward height='1rem' />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </tfoot>
          }
          <tbody>
            {(isLoading ? activities : currentRows)
              .filter(entry =>
                selectedLabel !== 'Filter by label'
                  ? entry.activity.labels
                    ? entry.activity.labels.includes(selectedLabel)
                    : entry
                  : entry
              )
              .map(entry => {
                const activityURL = group
                  ? `/account/${entry.user?._id}/group/${groupId}/analyze/activity/${entry.activity._id}`
                  : `/account/${accountId}/analyze/activity/${entry.activity._id}`;
                return (
                  <tr key={entry.activity.urn}>
                    {group && (
                      <td className='person-info'>
                        <AccountAvatar
                          account={{
                            avatar: entry.user?.profile?.displayPhoto.tiny,
                            name: '',
                          }}
                          uniqueKey={entry.activity._id}
                          variant={'lg'}
                        />
                        <div>{`${entry.user?.profile.firstName} ${entry.user?.profile.lastName}`}</div>
                      </td>
                    )}
                    <td className='thumbnail'>
                      {entry.activity.imageThumb && (
                        <Link
                          to={`/account/${accountId}/analyze/activity/${entry.activity._id}`}
                        >
                          <img
                            src={entry.activity.imageThumb}
                            alt={entry.activity.content.substr(0, 20)}
                          />
                        </Link>
                      )}
                      {!entry.activity.imageThumb && '...'}
                    </td>
                    <td>{ACTIVITY_TYPE_NAME_MAP[entry.activity.type]}</td>
                    <td title={entry.activity.content}>
                      {entry.activity.content.substring(0, 25).concat('...')}
                    </td>
                    <td>
                      {entry.activity.publicationDate
                        ? format(
                            new Date(entry.activity.publicationDate || 0),
                            'yyyy-MM-dd HH:mm:ss'
                          )
                        : 'No date'}
                    </td>
                    <td
                      style={{ background: entry.statistics.amountViewsColor }}
                    >
                      {entry.statistics.amountViews > 0
                        ? entry.statistics.amountViews.toLocaleString()
                        : '0'}
                    </td>
                    <td
                      style={{
                        background: entry.statistics.amountImpressionsColor,
                      }}
                    >
                      {entry.statistics.amountImpressions &&
                      entry.statistics.amountImpressions > 0
                        ? entry.statistics.amountImpressions.toLocaleString()
                        : '0'}
                    </td>
                    <td
                      style={{ background: entry.statistics.amountLikesColor }}
                    >
                      {entry.statistics.amountLikes > 0
                        ? entry.statistics.amountLikes.toLocaleString()
                        : '0'}
                    </td>
                    <td
                      style={{
                        background: entry.statistics.amountCommentsColor,
                      }}
                    >
                      {entry.statistics.amountComments > 0
                        ? entry.statistics.amountComments.toLocaleString()
                        : '0'}
                    </td>
                    {/* TODO: uncomment this when shares are available */}
                    {/* <td style={{ background: entry.statistics.amountSharesColor }}>
                    {entry.statistics.amountShares.toLocaleString()}
                  </td> */}
                    <td
                      style={{
                        background: entry.statistics.engagementRateColor,
                      }}
                    >{`${
                      entry.statistics.engagementRate * 100 > 0
                        ? (
                            entry.statistics.engagementRate * 100
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : '0,00'
                    }%`}</td>
                    <td data-html2canvas-ignore='true'>
                      <div className='buttons'>
                        <a
                          className='button is-outlined'
                          data-tip='View on LinkedIn'
                          data-for='styled-tooltip-content-table'
                          href={`https://www.linkedin.com/feed/update/${entry.activity.urn}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <LogoLinkedin size='1rem' />
                        </a>
                        <Link
                          className='button is-outlined'
                          to={activityURL}
                          data-tip='Details'
                          data-for='styled-tooltip-content-table'
                        >
                          <StatsChart size='1rem' />
                        </Link>
                        <button
                          className='button is-outlined'
                          type='button'
                          data-html={true}
                          onClick={() => handleOnNoteClick(entry.activity._id)}
                          data-tip={
                            notes?.has(entry.activity._id)
                              ? converter.makeHtml(
                                  notes.get(entry.activity._id)?.content || ''
                                )
                              : '<span>Add note</span>'
                          }
                          data-for='styled-tooltip-note'
                        >
                          {notes?.has(entry.activity._id) ? (
                            <Document size='1rem' />
                          ) : (
                            <DocumentOutline size='1rem' />
                          )}
                        </button>
                        {
                          <button
                            className='button is-outlined'
                            type='button'
                            data-tip='View labels'
                            data-for='styled-tooltip-note'
                            onClick={() => {
                              setEntryActivityId(entry.activity._id);
                              setIsLabelsModalOpen(true);
                            }}
                          >
                            <Pricetags size='1rem' />
                          </button>
                        }
                      </div>
                    </td>
                  </tr>
                );
              })}
            <LabelsModal
              isOpen={isLabelsModalOpen}
              handleOpen={() => setIsLabelsModalOpen(prev => !prev)}
              userId={accountId}
              activityId={entryActivityId}
              userLabels={userLabels}
              activityLabels={
                entryActivityId === ''
                  ? []
                  : currentRows.filter(row => {
                      return row.activity._id === entryActivityId;
                    })[0].activity.labels
              }
            />
          </tbody>
        </table>

        {/* Note modal */}
        <Modal open={!!currentNote} onClose={() => setCurrentNote(null)}>
          <div className='modal-card'>
            <header className='modal-card-head'>
              <p className='modal-card-title'>Modal title</p>
              <button
                className='delete'
                aria-label='close'
                onClick={() => setCurrentNote(null)}
              ></button>
            </header>
            <section className='modal-card-body'>
              <p className='field'>Enter a note for this activity:</p>
              <ReactMde
                value={currentNote ? currentNote.content : ''}
                onChange={handleOnModalContentInput}
                selectedTab={selectedMarkdownTab}
                onTabChange={setSelectedMarkdownTab}
                generateMarkdownPreview={markdown =>
                  Promise.resolve(converter.makeHtml(markdown))
                }
              ></ReactMde>
            </section>
            <footer className='modal-card-foot level'>
              <div className='level-left'>
                <div className='level-item'>
                  <button
                    className='button is-primary'
                    onClick={handleOnNoteModalSave}
                  >
                    Save changes
                  </button>
                  <button
                    className='button'
                    onClick={() => setCurrentNote(null)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className='level-right'>
                <div className='level-item'>
                  <button
                    className='button is-danger'
                    onClick={handleOnNoteModalDelete}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </footer>
          </div>
        </Modal>
        <StyledTooltip
          id='styled-tooltip-note'
          clickable={true}
          delayHide={250}
        />
        <StyledTooltip id='styled-tooltip-content-table' delayShow={500} />
      </div>

      <div
        className='overview-pie-charts'
        id='people-viewing-your-content'
        data-html2canvas-ignore='true'
      >
        <h5 className='title is-size-5 has-text-grey has-text-weight-normal overview-pie-charts__header'>
          <span>People viewing {group ? 'group' : 'your'} content</span>
          <HelpCircle
            data-html2canvas-ignore='true'
            size='1.5rem'
            className='ml-2'
            color={colors.grey}
            data-for='styled-tooltip-overview-pie-charts'
            data-tip='The displayed statistics will ONLY include data from posts that have been PUBLISHED within the chosen data range.'
          />
        </h5>
        <div className='overview-pie-charts__carousel'>
          <div className='box'>
            <h6 className='overview-pie-charts__box-header'>By Company</h6>
            <OverviewHighlightsChart highlights={highlights?.company} />
          </div>
          <div className='box'>
            <h6 className='overview-pie-charts__box-header'>By Position</h6>
            <OverviewHighlightsChart highlights={highlights?.occupation} />
          </div>
          <div className='box'>
            <h6 className='overview-pie-charts__box-header'>By Region</h6>
            <OverviewHighlightsChart highlights={highlights?.region} />
          </div>
        </div>
        <StyledTooltip id='styled-tooltip-overview-pie-charts' />
      </div>
      <div className='overview-region-map' id='content-views-country'>
        <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
          <span>
            People viewing {group ? 'group' : 'your'} content - by country
          </span>
        </h5>
        <div className='box'>
          <ActivityHighlightsRegionMap
            highlights={highlights?.region}
            showMaxList
          />
        </div>
      </div>
    </>
  );
};

export default ContentTable;
