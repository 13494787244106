import { createStore, applyMiddleware, compose } from 'redux';
import { rootReducer, rootEpic, RootState } from './reducers';
import { createEpicMiddleware } from 'redux-observable';
import { initialState } from './reducers/dateRangeFilter';

const epicMiddleWare = createEpicMiddleware();
// Enable debugging with Redux Devtools
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const localStorageKey = 'inlytics:redux-state';

const localstorageState: Partial<RootState> = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
// Only persist certain parts of the settings
const persistedState: Partial<RootState> = {
  settings: localstorageState?.settings,
  dateRangeFilter: {
    dateRange: localstorageState?.dateRangeFilter?.dateRange || initialState.dateRange,
    startDate: convertToDateWithFallback(localstorageState?.dateRangeFilter?.startDate, initialState.startDate),
    endDate: convertToDateWithFallback(localstorageState?.dateRangeFilter?.endDate, initialState.endDate),
    referenceStartDate: convertToDateWithFallback(
      localstorageState?.dateRangeFilter?.referenceStartDate,
      initialState.referenceStartDate
    ),
    referenceEndDate: convertToDateWithFallback(
      localstorageState?.dateRangeFilter?.referenceEndDate,
      initialState.referenceEndDate
    ),
    minStartDatesByAccount:
      localstorageState?.dateRangeFilter?.minStartDatesByAccount || initialState.minStartDatesByAccount,
  },
};

export const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(epicMiddleWare)));

// Persist whole state in localStorage
store.subscribe(() => localStorage.setItem(localStorageKey, JSON.stringify(store.getState())));

epicMiddleWare.run(rootEpic);

// Helper function to convert date strings to date
function convertToDateWithFallback(dateLike: Date | string | undefined, fallbackValue: Date) {
  const date = new Date(dateLike || 'INVALID');
  if (isNaN(date.getTime())) {
    // Invalid, use fallback
    return fallbackValue;
  }
  return date;
}
