import { HelpCircle } from "@styled-icons/ionicons-solid";
import React from "react";
import "../../styles/components/accountSettings/SettingHeader.scss";
import StyledTooltip from "../StyledTooltip";

const SettingHeader = (props: {
  headline: string;
  description: string | React.ReactElement;
  tip: string;
  helpText?: string;
}) => (
  <div className="setting-header">
    <h2 className="headline">
      {props.helpText && (
        <>
          <div>
            {props.headline}
            <HelpCircle
              size={"1.5rem"}
              data-tip
              data-for={`styled-tooltip-dashboard-title`}
              style={{
                marginLeft: "1rem"
              }}
            />
          </div>
          <StyledTooltip
            id={`styled-tooltip-dashboard-title`}
            delayHide={500}
            clickable={true}
          >
            {props.helpText}
          </StyledTooltip>
        </>
      )}
    </h2>
    <p className="description">{props.description}</p>
    <p className="tip">{props.tip}</p>
  </div>
);

export default SettingHeader;
