import apiClient from './api-client';
import { USER_PLAN } from './user-service';

export const deleteDaysNumber = 30;

export const AudienceAvailable = {
  [USER_PLAN.SOLO]: 2,
  [USER_PLAN.BUSINESS]: 5,
  [USER_PLAN.APPSUMO]: 5,
  [USER_PLAN.ANALYZE]: 5,
  [USER_PLAN.COMPLETE]: 5,
  [USER_PLAN.CORPORATE]: 5,
  [USER_PLAN.ESSENTIAL]: 5,
  [USER_PLAN.PROFESSIONAL]: 5,
  TEST: 50,
};

export enum AnalyticJobStatus {
  RUNNING = 'running',
  FINISHED = 'finished',
  DELETED = 'deleted',
  ABORTED = 'aborted',
}

export interface CreateAnalyticJobBody {
  name: string;
  country: string[];
  occupation: string[];
}

export interface TargetGroup {
  country: string[];
  occupation: string[];
}

export interface AnalyticJob {
  _id: string;
  activitiesFound: number;
  statusData: {
    name: string;
    jobID: string;
    status: AnalyticJobStatus;
    message: string;
    deletedAt: number;
  };
  targetGroup: TargetGroup;
  bestPostTime?: {
    publicationDay: string;
    publicationHour: string;
    activitiesAmount: number;
    Score: number;
  }[];
}

export interface Occupation {
  field: string;
  country: string;
  values: {
    Occupation: string;
    activitiesOccupation: number;
  }[];
}

export interface Country {
  field: string;
  values: {
    Country: string;
    activitiesCountry: number;
  }[];
}

export interface AudienceOptions {
  countries: Country;
  occupations: Occupation[];
}

export const getApproximateNumberOfPosts = (val: number) => {
  if (val < 2500) {
    return '0 - 2.500';
  } else if (val < 5000) {
    return '2.500 - 5.000';
  } else if (val < 10000) {
    return '5.000 - 10.000';
  } else if (val < 15000) {
    return '10.000 - 15.000';
  } else if (val < 50000) {
    return '15.000 - 50.000';
  } else if (val >= 50000) {
    return '50.000 - ...';
  }
};

export const getUserAudiences = async (): Promise<AnalyticJob[]> => {
  const response = await apiClient.get<AnalyticJob[]>('/audience');
  return response.data;
};

export const getAudienceOptions = async (): Promise<AudienceOptions> => {
  const response = await apiClient.get<AudienceOptions>('/audience/options');
  return response.data;
};

export const getAnalyticJob = async (jobId: string): Promise<AnalyticJob> => {
  const response = await apiClient.get<AnalyticJob>('/audience/job', {
    params: { jobId },
  });
  return response.data;
};

export const createAnalyticJob = async (
  body: CreateAnalyticJobBody
): Promise<AnalyticJob> => {
  const response = await apiClient.post<AnalyticJob>('/audience/job', body);
  return response.data;
};

export const deleteAnalyticJob = async (
  jobId: string
): Promise<AnalyticJob> => {
  const response = await apiClient.delete(`/audience/job/${jobId}`);
  return response.data;
};
