import { useEffect, useState } from 'react';

const useScript = (url: string, async = false) => {
  const [script, setScript] = useState<HTMLScriptElement>();
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = async;

    document.body.appendChild(script);

    setScript(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [async, url]);

  return script;
};

export default useScript;
