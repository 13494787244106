import React from "react";
import "../../styles/components/LoginRegisterPage.scss";
import dashboard from "../../assets/images/linkedin-dashboard-analytics-tool.jpg";
import dashboard2x from "../../assets/images/linkedin-dashboard-analytics-tool@2x.jpg";
import { Switch, Route, NavLink } from "react-router-dom";
import RegisterForm from "./RegisterForm";
import LoginForm from "./LoginForm";
import { useQueryParam } from "../../hooks/useQueryParam";
import ResetPasswordForm from "./ResetPasswordForm";
import ForgotPasswordForm from "./ForgotPasswordForm";

const LoginRegisterPage: React.FC = () => {
  const redirectParam = useQueryParam("redirect");

  return (
    <div className="LoginRegisterPage">
      <div className="columns">
        {/* Actual forms */}
        <div className="column left-side">
          <section className="section">
            <div className="title-container">
              <h1 className="title is-1">LinkedIn Analytics.</h1>
              <h2 className="subtitle is-5 has-text-grey mt-4">
                Optimize content performance and grow your influence.
              </h2>
            </div>
            <section className="section">
              <div className="columns is-centered is-vcentered">
                <div className="column is-narrow box mt-5">
                  <div className="tabs is-toggle is-boxed is-fullwidth has-text-weight-bold">
                    <NavLink
                      className="tab"
                      to={`/register${
                        redirectParam ? `?redirect=${redirectParam}` : ""
                      }`}
                      activeClassName="is-active"
                    >
                      Sign up
                    </NavLink>
                    <NavLink
                      className="tab"
                      to={`/login`}
                      activeClassName="is-active"
                    >
                      Login
                    </NavLink>
                  </div>
                  <div className="form-container">
                    <Switch>
                      <Route path="/login">
                        <LoginForm />
                      </Route>
                      <Route path="/register">
                        <RegisterForm />
                      </Route>
                      <Route path="/forgot-password">
                        <ForgotPasswordForm />
                      </Route>
                      <Route path="/reset-password/:token">
                        <ResetPasswordForm />
                      </Route>
                    </Switch>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>

        {/* Screenshot preview */}
        <div className="column image-container">
          <figure className="image">
            <img
              src={dashboard}
              srcSet={`${dashboard2x} 2x`}
              alt="Screenshot showing the account dashboard"
            />
          </figure>
        </div>
      </div>
    </div>
  );
};

export default LoginRegisterPage;
