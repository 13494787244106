import { ACTION_TYPE } from '../actions';
import { SettingsTypes } from '../actions/settings';
import { TIMELINE_TYPE } from '../../components/DashboardTimeline';

export interface SettingsState {
  sidebarOpen: boolean;
  inviteMemberModalOpen: boolean;
  dashboardTimelineOptions: {
    [id: number]: {
      type: TIMELINE_TYPE;
    };
  };
}

const initialState: SettingsState = {
  sidebarOpen: true,
  dashboardTimelineOptions: {},
  inviteMemberModalOpen: false,
};

const settings = (state: SettingsState = initialState, action: SettingsTypes): SettingsState => {
  switch (action.type) {
    case ACTION_TYPE.SET_SIDEBAR_OPEN:
      return { ...state, sidebarOpen: action.payload };
    case ACTION_TYPE.SET_DASHBOARD_TIMELINE:
      const { id, type } = action.payload;
      state.dashboardTimelineOptions[id] = state.dashboardTimelineOptions[id] || { type: TIMELINE_TYPE.IMPRESSIONS };
      state.dashboardTimelineOptions[id].type = type;
      return { ...state };
    case ACTION_TYPE.SET_INVITE_MEMBER_MODAL_OPEN:
      return { ...state, inviteMemberModalOpen: action.payload };
    default:
      return state;
  }
};

export default settings;
