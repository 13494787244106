import apiClient, { APIRequestMeta } from "./api-client";
import { CompanyUserRoles } from "../components/accountSharing/AccountSharingModal";
import { store } from "../store";
import {
  setCompanies,
  setCompanyOffers,
  setLinkedAccountRequest
} from "../store/actions/linkedAccounts";
import { AccountsTableEntryViewModel } from "../components/linked-accounts/AccountList";

export interface CompanySettings {
  id: string;
  logo?: string[];
  name: string;
  role: string;
  members: string;
}

export interface CompanyOffer {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
  role: CompanyUserRoles;
  companyName: string;
}

export type CompanyMember = {
  id: string;
  avatar?: string;
  name: string;
  role: CompanyUserRoles;
  userId: string;
};

export const getConnectedCompany = async (): Promise<CompanySettings[]> => {
  const response = await apiClient.get<CompanySettings[]>(
    "/companies/connected"
  );

  return response.data;
};

export const createCompany = async (
  urn: string,
  role: CompanyUserRoles,
  emails: string[],
  meta: APIRequestMeta
): Promise<void> => {
  try {
    const response = await apiClient.post("/companies/connected", {
      role,
      emails,
      urn
    });

    meta.onSuccess(response.data);
  } catch (e) {
    meta.onError(e.message);
  }
};

export const getCompanies = async (): Promise<void> => {
  store.dispatch(setLinkedAccountRequest("isLoadingCompanies", true));
  try {
    const response = await apiClient.get<{
      companies: AccountsTableEntryViewModel[];
    }>("/companies/list");

    if (response.data.companies) {
      store.dispatch(setCompanies(response.data.companies));
    }
  } catch (e) {
    console.log(e.message);
  }
  store.dispatch(setLinkedAccountRequest("isLoadingCompanies", false));
};

export const getOrganization = async (): Promise<Record<string, any>[]> => {
  let returnData = [];
  store.dispatch(setLinkedAccountRequest("isLoadingCompanies", true));
  try {
    const response = await apiClient.get("/companies/");
    returnData = response.data.organizations;
  } catch (e) {
    console.log(e.message);
  }
  store.dispatch(setLinkedAccountRequest("isLoadingCompanies", false));

  return returnData;
};

export const getCompanyOffers = async (): Promise<{
  companyOffers: CompanyOffer[];
} | void> => {
  store.dispatch(setLinkedAccountRequest("isLoadingCompanyOffers", true));
  try {
    const response = await apiClient.get<{ companyOffers: CompanyOffer[] }>(
      "/companies/offers"
    );
    if (response.data?.companyOffers?.length) {
      store.dispatch(setCompanyOffers(response.data.companyOffers));
    }
  } catch (e) {
    console.log(e.message);
  }
  store.dispatch(setLinkedAccountRequest("isLoadingCompanyOffers", false));
};

export const handleCompanyOffer = async ({
  companyId,
  confirmed
}: {
  companyId: string;
  confirmed: boolean;
}): Promise<string> => {
  await apiClient.put<{ message: string }>(`/companies/${companyId}/offer`, {
    confirmed
  });
  return companyId;
};

export const deleteCompany = async (
  companyId: string,
  label: "remove" | "unlink"
): Promise<void> => {
  await apiClient.delete(`/companies/connected/${companyId}/${label}`);
  getCompanies().catch(e => console.log(e.message));
};

export interface UpdateCompanyPayload {
  name: string;
  companyId: string;
  companyLogo?: File;
}

export const updateCompany = async ({
  companyLogo,
  companyId,
  name
}: UpdateCompanyPayload): Promise<any> => {
  const formData = new FormData();

  formData.append("name", name);

  if (companyLogo) {
    formData.append("companyLogo", companyLogo);
  }

  const response = await apiClient.put(
    `/companies/connected/${companyId}`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  );
  return response.data;
};

export const getCompanyMembers = async (companyId: string) => {
  const response = await apiClient.get(`/companies/connected/${companyId}`);
  return response.data;
};

export interface UpdateCompanyMemberPayload {
  companyId: string;
  id: string;
  payload: {
    role: CompanyUserRoles;
  };
}

export const updateCompanyMember = async ({
  companyId,
  id,
  payload
}: UpdateCompanyMemberPayload) => {
  const response = await apiClient.put(
    `/companies/connected/${companyId}/members/${id}`,
    payload
  );
  return response.data;
};

export const deleteCompanyMember = async ({
  id,
  objectId
}: {
  objectId: string;
  id: string;
}): Promise<void> => {
  await apiClient.delete(`/companies/connected/${objectId}/members/${id}`);
};

export interface ICompanyVisitorsAnalytics {
  buttonClicks: number;
  buttonClicksPercentage: number;
  pageViews: number;
  pageViewsPercentage: number;
  uniqueVisitors: number;
  uniqueVisitorsPercentage: number;
}

export interface DeviceStats {
  general: number;
  home: number;
  about: number;
  insights: number;
  people: number;
  product: number;
}

export interface ICompanyVisitorsTimeline {
  timestamp: number;
  companyId: string;
  desktop: DeviceStats;
  mobile: DeviceStats;
}

export const getCompanyVisitors = async ({
  id,
  startDate,
  endDate
}: {
  id: string;
  startDate: Date;
  endDate: Date;
}): Promise<{
  analytics: ICompanyVisitorsAnalytics;
  timeline: ICompanyVisitorsTimeline[];
}> => {
  const res = await apiClient.get(`/statistics/company/${id}/visitors`, {
    params: {
      startDate,
      endDate
    }
  });
  return res.data;
};

export interface CompanyDemographics {
  jobFunction: Record<string, number>;
  location: Record<string, number>;
  seniority: Record<string, number>;
  industry: Record<string, number>;
  companySize: Record<string, number>;
}

export const getCompanyDemographics = async ({
  id,
  startDate,
  endDate
}: {
  id: string;
  startDate: Date;
  endDate: Date;
}): Promise<CompanyDemographics> => {
  const res = await apiClient.get(
    `/statistics/company/${id}/visitors/demographics`,
    {
      params: {
        startDate,
        endDate
      }
    }
  );
  return res.data;
};

export interface ICompanyUpdatesAnalytics {
  reactions: number;
  reactionsPercentage: number;
  comments: number;
  commentsPercentage: number;
  shares: number;
  sharesPercentage: number;
}

export interface ICompanyUpdatesTimeline {
  timestamp: number;
  companyId: string;
  impressions: number;
  uniqueImpressions: number;
  clicks: number;
  reactions: number;
  comments: number;
  shares: number;
}

export interface RechartData {
  _id: string;
  timestamp: number;
  clickThroughRate: number;
  clicks: number;
  comments: number;
  engagementRate: number;
  impressions: number;
  likes: number;
  shares: number;
  views?: number;
  follows?: number;
  text?: string;
  type?: string;
  clickThroughRateColor: string;
  clicksColor: string;
  commentsColor: string;
  engagementRateColor: string;
  impressionsColor: string;
  likesColor: string;
  sharesColor: string;
  viewsColor: string;
  followsColor: string;
}

export const getCompanyUpdates = async ({
  id,
  startDate,
  endDate
}: {
  id: string;
  startDate: Date;
  endDate: Date;
}): Promise<{
  analytics: ICompanyUpdatesAnalytics;
  timeline: ICompanyUpdatesTimeline[];
  engagement: RechartData[];
}> => {
  const res = await apiClient.get(`/statistics/company/${id}/updates`, {
    params: {
      startDate,
      endDate
    }
  });
  return res.data;
};

export interface ICompanyFollowersAnalytic {
  totalFollowers: number;
  newFollowers: number;
  totalPercentage: number;
}

export interface ICompanyFollowersSnapshot {
  timestamp: number;
  organicNumber: number;
  totalNumber: number;
  organization: string;
}

export const getCompanyFollowers = async ({
  id,
  startDate,
  endDate
}: {
  id: string;
  startDate: Date;
  endDate: Date;
}): Promise<{
  analytics: ICompanyFollowersAnalytic;
  timeline: ICompanyFollowersSnapshot[];
}> => {
  const res = await apiClient.get<{
    analytics: ICompanyFollowersAnalytic;
    timeline: ICompanyFollowersSnapshot[];
  }>(`/statistics/company/${id}/followers`, {
    params: {
      startDate,
      endDate
    }
  });
  return res.data;
};

export const getCompanyFollowersDemographics = async ({
  id,
  startDate,
  endDate
}: {
  id: string;
  startDate: Date;
  endDate: Date;
}): Promise<CompanyDemographics> => {
  const res = await apiClient.get<CompanyDemographics>(
    `/statistics/company/${id}/followers/demographics`
  );
  return res.data;
};

export async function inviteToCompany(
  companyId: string,
  emails: string[],
  role: CompanyUserRoles
) {
  try {
    await apiClient.post(`/companies/connected/${companyId}/invite`, {
      emails,
      role
    });
  } catch (e) {
    console.error(e.message);
  }
}
