import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  Brush,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { colors, variables } from "../../../styles/variables";
import { dateFormatter } from "../../profile/ConnectionTimeline";
import "./CompanyVisitors.scss";
import { ICompanyVisitorsTimeline } from "../../../services/company-service";

export enum TIMELINE_TYPE {
  GENERAL,
  HOME,
  ABOUT,
  INSIGHTS,
  PEOPLE,
  PRODUCT
}

// export enum UNIQUE_TYPE {
//   GENERAL,
//   UNIQUE,
// }

// const UNIQUE_MAP = {
//   [UNIQUE_TYPE.GENERAL]: 'Page views',
//   [UNIQUE_TYPE.UNIQUE]: 'Unique visitors',
// };

const LABEL_MAP = {
  [TIMELINE_TYPE.GENERAL]: "All pages",
  [TIMELINE_TYPE.HOME]: "Home",
  [TIMELINE_TYPE.ABOUT]: "About",
  [TIMELINE_TYPE.INSIGHTS]: "Insights",
  [TIMELINE_TYPE.PEOPLE]: "People",
  [TIMELINE_TYPE.PRODUCT]: "Product"
};

const KEY_MAP = {
  [TIMELINE_TYPE.GENERAL]: "general",
  [TIMELINE_TYPE.HOME]: "home",
  [TIMELINE_TYPE.ABOUT]: "about",
  [TIMELINE_TYPE.INSIGHTS]: "insights",
  [TIMELINE_TYPE.PEOPLE]: "people",
  [TIMELINE_TYPE.PRODUCT]: "product"
};

interface Props {
  data: ICompanyVisitorsTimeline[];
  brushEnabled?: boolean;
}

interface RechartData {
  desktop: number;
  mobile: number;
  timestamp: number;
}

export const VisitorsTimeline: React.FC<Props> = ({ data, brushEnabled }) => {
  const [visitorsRechartData, setVisitorsRechartData] = useState<RechartData[]>(
    []
  );
  const [currentTimeline, setCurrentTimeline] = useState<TIMELINE_TYPE>(
    TIMELINE_TYPE.GENERAL
  );
  // const [currentUnique, setCurrentUnique] = useState<UNIQUE_TYPE>(UNIQUE_TYPE.GENERAL);

  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(0);

  const handleUpdate = ({
    startIndex,
    endIndex
  }: {
    startIndex: number;
    endIndex: number;
  }) => {
    setStartIndex(startIndex);
    setEndIndex(endIndex);
  };

  const handleOnTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const type = +e.currentTarget.value as TIMELINE_TYPE;
    setCurrentTimeline(type);
  };

  // const handleOnUniqueChange = (e: ChangeEvent<HTMLSelectElement>) => {
  //   const type = +e.currentTarget.value as UNIQUE_TYPE;
  //   setCurrentUnique(type);
  // };

  useEffect(() => {
    let key = KEY_MAP[currentTimeline] as
      | "general"
      | "home"
      | "about"
      | "insights"
      | "people"
      | "product";

    // if (currentUnique === UNIQUE_TYPE.UNIQUE) key += 'Unique';

    const newData: RechartData[] = data.map(item => ({
      desktop: item.desktop[key],
      mobile: item.mobile[key],
      timestamp: item.timestamp
    }));

    setVisitorsRechartData(newData);
    setStartIndex(0);
    setEndIndex(newData.length - 1);
  }, [currentTimeline, data]);

  return (
    <div className="CompanyVisitorsTimeline info-block company-box">
      <div className="select-container company-select-container">
        <div className="select">
          <select
            onChange={handleOnTypeChange}
            defaultValue={TIMELINE_TYPE.GENERAL}
          >
            {Object.entries(LABEL_MAP).map(([type, value]) => (
              <option key={type} value={type}>
                {value}
              </option>
            ))}
          </select>
        </div>
        {/*<div className='select'>*/}
        {/*  <select onChange={handleOnUniqueChange} defaultValue={UNIQUE_TYPE.GENERAL}>*/}
        {/*    {Object.entries(UNIQUE_MAP).map(([type, value]) => (*/}
        {/*      <option key={type} value={type}>*/}
        {/*        {value}*/}
        {/*      </option>*/}
        {/*    ))}*/}
        {/*  </select>*/}
        {/*</div>*/}
      </div>
      <ResponsiveContainer height={400} className="responsive-chart-container">
        <AreaChart
          data={
            brushEnabled
              ? visitorsRechartData.slice(startIndex, endIndex + 1)
              : visitorsRechartData
          }
        >
          {/*<CartesianGrid />*/}
          <XAxis
            dataKey="timestamp"
            tickLine={false}
            axisLine={false}
            tickFormatter={dateFormatter}
          />
          <YAxis tickLine={false} axisLine={false} />
          <Tooltip labelFormatter={dateFormatter} />
          <Area
            strokeWidth={3}
            dataKey="mobile"
            stroke={colors.pink}
            fill="url(#gradient-mobile)"
            baseLine={20}
          />
          <Area
            strokeWidth={3}
            dataKey="desktop"
            stroke={colors.orange}
            fill="url(#gradient-desktop)"
          />
        </AreaChart>
      </ResponsiveContainer>
      {brushEnabled && !!visitorsRechartData?.length && (
        <div className="chart-container">
          <ResponsiveContainer height={60} className="chart-base">
            <BarChart data={visitorsRechartData}>
              <YAxis
                yAxisId="desktop"
                tickLine={false}
                axisLine={false}
                tick={false}
              />

              <Bar
                yAxisId="desktop"
                dataKey="desktop"
                stroke={variables.lightGrey}
                fill={variables.lightGrey}
                isAnimationActive={false}
              />
            </BarChart>
          </ResponsiveContainer>
          <ResponsiveContainer height={60} className="chart-brush">
            <BarChart data={visitorsRechartData}>
              <YAxis yAxisId="desktop" tick={false} />

              <Brush
                startIndex={startIndex}
                endIndex={endIndex}
                height={60}
                fill="rgba(0, 0, 0, 0)"
                stroke={colors.orange}
                onChange={handleUpdate}
                dataKey="timestamp"
                tickFormatter={dateFormatter}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};
