import styled from 'styled-components';
import { variables } from '../../styles/variables';

const MediaLibrary = styled.div``;

const EmptyState = styled.div`
  background-color: ${variables.offWhite};
  text-align: center;
  padding: 2rem;
  margin-bottom: 1rem;
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -0.25rem 1rem;
  max-height: 250px;
  overflow-y: auto;
`;

const ImgEntry = styled.div<{ isSelected: boolean }>`
  display: block;
  width: 135px;
  margin: 0.25rem 0.25rem;
  height: 90px;
  position: relative;
  border: 2px solid ${props => (props.isSelected ? variables.primary : variables.offWhite)};
  border-radius: 0.5rem;
  overflow: hidden;
  transition: 0.25s ease;
  transition-property: border-color;
  cursor: pointer;
  &:hover {
    border-color: ${props => (props.isSelected ? variables.primary : variables.lightGrey)};
  }
`;

const ImgDeleteBtn = styled.div`
  position: absolute;
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 1000;
`;

const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Video = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

export const Styled = {
  EmptyState,
  MediaLibrary,
  Img,
  Video,
  ImgContainer,
  ImgEntry,
  ImgDeleteBtn,
};
