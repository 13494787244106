import React, { useState } from 'react';
import { Switch, NavLink, Route, useRouteMatch, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BuildingBank } from '@styled-icons/fluentui-system-filled';
import { RootState } from '../store/reducers';
import classNames from 'classnames';
import {
  ArrowUpCircle,
  LogOut,
  PeopleCircle,
  FingerPrint,
} from '@styled-icons/ionicons-solid';

import '../styles/components/AppBar.scss';

import LogoWithText from '../assets/images/logo-with-text.svg';
import { USER_AUTH_LEVEL, USER_PLAN } from '../services/user-service';
import { logout } from '../services/auth-service';
import { Security } from './security/Security';
import Headway from './headway/Headway';
import { RoadMapIcon } from '../assets/icons/RoadMapIcon';
import { DateRange } from './DateRange';
import UpdateNowButton from './updateNow/UpdateNowBtn';

const AppBar: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { mainAccount } = useSelector(
    (state: RootState) => state.linkedAccounts
  );

  // Date Range Modal
  const [openDateRangeModal, setOpenDateRangeModal] = useState(false);

  const hideNavigation = useRouteMatch([
    '/login',
    '/register',
    '/onboarding',
    '/forgot-password',
    '/reset-password/:token',
    '/appsumo',
  ]);

  // const hasAccessToSchedulePage = ![
  //   USER_PLAN.ANALYZE,
  //   USER_PLAN.PROFESSIONAL,
  // ].includes(user?.plan as USER_PLAN);

  return (
    <nav
      className={classNames('navbar is-fixed-top AppBar', {
        'navigation-hidden': hideNavigation,
      })}
      role='navigation'
      aria-label='main navigation'
    >
      <div className='navbar-brand'>
        <Link className='navbar-item' to='/'>
          <img src={LogoWithText} alt='inlytics' />
        </Link>
        {user && user.auth >= USER_AUTH_LEVEL.ADMIN && (
          <div className='navbar-item'>
            <div className='tag is-primary'>Admin</div>
          </div>
        )}
        <div
          role='button'
          className='navbar-burger burger'
          aria-label='menu'
          aria-expanded='false'
          data-target='navbarBasicExample'
        >
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
        </div>
      </div>

      <div className='navbar-menu'>
        {/* Navigation */}
        <div className='navbar-start'>
          <Switch>
            <Route path='/account/:accountId/group/:groupId' />
            {/*<Route*/}
            {/*  path='/account/:accountId'*/}
            {/*  render={({ match }) => (*/}
            {/*    <>*/}
            {/*      <AppBarSharedProductsLink*/}
            {/*        accountId={match.params['accountId']}*/}
            {/*        product='analyze'*/}
            {/*      />*/}
            {/*      <AppBarSharedProductsLink*/}
            {/*        disabled={!hasAccessToSchedulePage}*/}
            {/*        accountId={match.params['accountId']}*/}
            {/*        product='schedule'*/}
            {/*      />*/}
            {/*    </>*/}
            {/*  )}*/}
            {/*/>*/}
          </Switch>
        </div>

        <div className='navbar-end' style={{ display: 'none' }}>
          {user && user.plan !== USER_PLAN.FREE && (
            <UpdateNowButton account={`${mainAccount?._id}`} />
          )}
          {user && (
            <DateRange
              open={openDateRangeModal}
              handleOpen={() => setOpenDateRangeModal(true)}
              handleClose={() => setOpenDateRangeModal(false)}
            />
          )}
          {(user?.plan === USER_PLAN.BUSINESS ||
            user?.plan === USER_PLAN.APPSUMO) && <Security />}
          {/* Headway integration */}
          <Headway />
          {/* User dropdown */}
          {user && (
            <div
              id='user-dropdown'
              className='navbar-item has-dropdown is-hoverable'
            >
              <div className='navbar-link'>
                <figure className='image avatar'>
                  <img
                    className='is-rounded'
                    src={user.profile.picture || ''}
                    alt='Profile avatar'
                  />
                </figure>
                <div className='right'>
                  <div className='name is-size-6 has-text-weight-bold'>
                    {user.profile.givenName} {user.profile.familyName}
                  </div>
                  <div className='plan is-size-7'>{user.plan} plan</div>
                </div>
              </div>
              <div className='navbar-dropdown'>
                <span className='navbar-item no-cursor'>ACCOUNT</span>
                {[USER_PLAN.SOLO, USER_PLAN.FREE].includes(user!.plan) && (
                  <NavLink className='navbar-item upgrade' to={`/upgrade`}>
                    <ArrowUpCircle className='icon' />
                    <span>Upgrade plan</span>
                  </NavLink>
                )}
                <NavLink className='navbar-item' to={`/`} exact={true}>
                  <PeopleCircle className='icon' />
                  <span>Linked accounts</span>
                </NavLink>
                {/*<NavLink className='navbar-item' to={`/feedback`} exact={true}>*/}
                {/*  <Chatbubble className='icon' />*/}
                {/*  <span>Feedback</span>*/}
                {/*</NavLink>*/}
                {/* <NavLink className='navbar-item' to={`/share`} exact={true}>
                  <ShareSocial className='icon' />
                  <span>Share the Tool</span>
                </NavLink> */}
                {/*<NavLink className='navbar-item' to={`/settings`} exact={true}>*/}
                {/*  <Settings className='icon' />*/}
                {/*  <span>Settings</span>*/}
                {/*</NavLink>*/}

                <span className='navbar-item no-cursor'>GENERAL</span>
                <a
                  className='navbar-item'
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.inlytics.io/imprint'
                >
                  <BuildingBank className='icon' />
                  <span>Imprint</span>
                </a>
                <a
                  className='navbar-item'
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.inlytics.io/privacy'
                >
                  <FingerPrint className='icon' />
                  <span>Privacy Policy</span>
                </a>
                <a
                  className='navbar-item'
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://trello.com/b/ey29pvWU/inlytics-roadmap'
                >
                  <RoadMapIcon className='icon' />
                  <span>Roadmap</span>
                </a>

                <div className='navbar-divider'></div>
                <div className='navbar-item' onClick={logout}>
                  <LogOut className='icon' />
                  <span>Logout</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default AppBar;
