import React from 'react';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import Tracking from './components/Tracking';
import 'react-day-picker/lib/style.css';
import App from './App';
import { ReactQueryDevtools } from 'react-query-devtools';

const AppWrapper: React.FC = () => {
  return (
    <React.Fragment>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>

      {/* Tracking integrations */}
      <Tracking></Tracking>

      {/* React query devtools */}
      <ReactQueryDevtools />
    </React.Fragment>
  );
};

export default AppWrapper;
