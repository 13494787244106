import { AccountConnection, GroupConnection, updateMainAccount } from './account-service';
import apiClient, { APIRequestMeta } from './api-client';
import { store } from '../store';
import { setGroupConnections, setLinkedAccountRequest, setSharedConnections } from '../store/actions/linkedAccounts';
import { User } from './user-service';

export interface FollowerSnapshot {
  accountId: string;
  amountFollowers: number;
  amountFollowing: number;
  createdAt: Date; // Timestamp of query
}

export interface LinkedInConnection {
  urn: string;
  createdAt: Date;
  account: string;
}

export interface ProfileViewHistoryEntry {
  endTimestamp: Date;
  numViews: number;
  account: string;
}

export interface ProfileOptimizationCategory {
  name: string;
  helpText: React.ReactElement;
  type: string;
  progress: number;
  actions: ProfileOptimizationAction[];
}

export interface ProfileOptimizationAction {
  id: string;
  name: string;
  checked: boolean;
  helpText: React.ReactElement;
}

export interface ShareConnectionResult {
  result: string[];
  errors: {
    email: string;
    message: string;
  }[];
}

export interface SingleAccountSharingSetting {
  id: string;
  connectionId: string;
  picture: string;
  name: string;
  analytics: boolean;
  schedule: boolean;
}

export async function getProfileFollowHistory(accountId: string) {
  const res = await apiClient.get<{ history: Array<Omit<FollowerSnapshot, 'accountId'>> }>(
    `/profiles/${accountId}/follow-history`
  );
  return res.data.history;
}

export async function getProfileOptimizationsChecklist(accountId: string) {
  const res = await apiClient.get<{ categories: string[] }>(`/profiles/${accountId}/optimization-checklist`);
  return res.data.categories;
}

export async function updateProfileOptimizationChecklistActionStatus(
  accountId: string,
  actionId: string,
  newState: boolean
) {
  await apiClient.patch(`/profiles/${accountId}/optimization-checklist`, { actionId, newState });
}

export async function getProfileConnections(accountId: string) {
  const res = await apiClient.get<{ connections: LinkedInConnection[] }>(`/profiles/${accountId}/connections`);
  return res.data.connections;
}

export async function getProfileViewHistory(accountId: string) {
  const res = await apiClient.get<{ history: ProfileViewHistoryEntry[] }>(
    `/profiles/${accountId}/profile-view-history`
  );
  return res.data.history;
}

interface CreateShareConnectionPayload {
  emails: string[];
  tools: ('ANALYTICS' | 'SCHEDULE')[];
  requestType: 'request' | 'offer';
}

export async function getSharedConnections(groupId?: string) {
  store.dispatch(setLinkedAccountRequest('isLoadingConnections', true));

  try {
    const res = await apiClient.get<{
      connections: AccountConnection[] | null;
      groupConnections: GroupConnection[] | null;
      user: User | null;
    }>('/connections?type=offer');
    if (res.data) {
      const groupConnections = res.data.groupConnections;
      const user = res.data.user;
      store.dispatch(setSharedConnections(res.data.connections));
      store.dispatch(setGroupConnections({ groupConnections, user }));

      store.dispatch(setLinkedAccountRequest('isLoadingConnections', false));
    }
  } catch (e) {
    console.error(e.message);

    store.dispatch(setLinkedAccountRequest('isLoadingConnections', false));
  }
}

export async function getRequestsToOthers() {
  try {
    const res = await apiClient.get<{ requests: AccountConnection[] }>('/connections/requestsToOthers');
    return res.data?.requests;
  } catch (e) {
    console.error(e.message);
  }
}

export async function getSingleAccountSharingSettings(payload?: any, meta?: APIRequestMeta) {
  try {
    const res = await apiClient.get<{ connections: AccountConnection[] | null }>('/connections?type=request');

    if (res.data) {
      const sharingSettings: SingleAccountSharingSetting[] = (res.data.connections || []).map(
        ({ _id, toUser, analytics, schedule }) => ({
          id: _id,
          connectionId: _id,
          picture: toUser?.mainAccount?.profile.displayPhoto.small || '',
          name: `${toUser?.mainAccount?.profile.firstName} ${toUser?.mainAccount?.profile.lastName}`,
          analytics,
          schedule,
        })
      );

      meta?.onSuccess(sharingSettings);
    } else {
      meta?.onError(res);
    }
  } catch (e) {
    meta?.onError(e.message);
  }
}

export async function updateSingleAccountSharingSettings(
  payload: { [K in string]: { analytics?: boolean; schedule?: boolean } },
  meta?: APIRequestMeta
) {
  try {
    const updates = Object.entries(payload).map(([id, values]) => ({
      ...values,
      id,
    }));

    const res = await apiClient.post<{ connections: AccountConnection[] | null }>('/connections/updateSettings', {
      updates,
    });

    if (res.status === 200) {
      meta?.onSuccess();
    }
  } catch (e) {
    meta?.onError(e.message);
  }
}

export async function createSharedConnection(payload: CreateShareConnectionPayload, meta: APIRequestMeta) {
  try {
    const res = await apiClient.post('/connections', payload);

    if (res.data) {
      meta.onSuccess(res.data);
    }
  } catch (e) {
    meta.onError(e.message);
  }
}

export async function acceptAccountConnection(
  id: string,
  payload: { analytics?: boolean; schedule?: boolean },
  requestType: 'request' | 'offer'
) {
  await apiClient.patch(`/connections/${id}/confirm`, payload);

  updateMainAccount();

  if (requestType === 'offer') {
    getSharedConnections();
  }
}

export async function removeAccountConnection(id: string, cb?: () => void) {
  await apiClient.delete(`/connections/${id}`);

  if (typeof cb === 'function') {
    cb();
  } else {
    updateMainAccount();
    getSharedConnections();
  }
}
