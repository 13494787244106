import React from 'react';
import { Albums, Flask, Layers, Person, PieChart, ShieldCheckmark } from '@styled-icons/ionicons-solid';
import classNames from 'classnames';
import styled from 'styled-components';
import variables from '../styles/variables';

interface IconProps {
  className?: string;
}

const BaseIcon = styled.svg`
  path {
    fill: ${variables.textColor};
  }

  &.is-disabled {
    path {
      fill: ${variables.lightGrey};
    }
  }
`;

const StyledAnalyzeIcon = styled(BaseIcon)`
  path:nth-child(1) {
    fill: ${variables.primary};
  }

  &.is-disabled {
    path:nth-child(1) {
      fill: ${variables.lighterGrey};
    }
  }
`;

export const AnalyzeIcon: React.FC<IconProps> = ({ className = '' }) => {
  return <StyledAnalyzeIcon as={PieChart} className={classNames('icon is-medium icon-piechart', className)} />;
};

const StyledScheduleIcon = styled(BaseIcon)`
  path:nth-child(2) {
    fill: ${variables.primary};
  }

  &.is-disabled {
    path:nth-child(2) {
      fill: ${variables.lighterGrey};
    }
  }
`;

export const ScheduleIcon: React.FC<IconProps> = ({ className = '' }) => {
  return <StyledScheduleIcon as={Layers} className={classNames('icon is-medium icon-layers', className)} />;
};

export const AlbumsIcon: React.FC<IconProps> = ({ className = '' }) => {
  return <StyledScheduleIcon as={Albums} className={classNames('icon is-medium icon-layers', className)} />;
};

const LightBlueIcon = styled(BaseIcon)`
  path {
    fill: ${variables.lightBlue};
  }

  &.is-disabled {
    path {
      fill: ${variables.lightGrey};
    }
  }
`;

export const PersonIcon: React.FC<IconProps> = ({ className = '' }) => {
  return <LightBlueIcon as={Person} className={classNames('icon is-medium icon-layers', className)} />;
};

export const FlaskIcon: React.FC<IconProps> = ({ className = '' }) => {
  return <LightBlueIcon as={Flask} className={classNames('icon is-medium icon-layers', className)} />;
};

export const ShieldCheckmarkIcon: React.FC<IconProps> = ({ className = '' }) => {
  return <LightBlueIcon as={ShieldCheckmark} className={classNames('icon is-medium icon-layers', className)} />;
};
