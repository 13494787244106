import { subDays, startOfMonth, startOfYear, differenceInDays } from 'date-fns';
import { ACTION_TYPE } from '.';
import {
  AccountConnection,
  GroupConnection,
} from '../../services/account-service';
import { User } from '../../services/user-service';

export enum DATE_RANGE {
  ONE_WEEK = 'ONE_WEEK',
  FOUR_WEEKS = 'FOUR_WEEKS',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  ALL_TIME = 'ALL_TIME',
  CUSTOM = 'CUSTOM',
  LAST_3_DAYS = 'LAST_3_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
}

export interface SetDateRangeFilterAction {
  type: ACTION_TYPE.SET_DATE_RANGE_FILTER;
  payload: {
    dateRange: DATE_RANGE;
    startDate: Date;
    endDate: Date;
    referenceStartDate: Date;
    referenceEndDate: Date;
    paramAccountId: string;
  };
}
interface SetMinStartDatesForMainAccount {
  type: ACTION_TYPE.SET_USER;
  payload: Pick<User, 'mainAccount' | 'createdAt'>;
}

interface SetMinStartDatesForSharedConnections {
  type: ACTION_TYPE.SET_SHARED_CONNECTIONS;
  payload: AccountConnection[];
}

interface SetMinStartForSharedConnections {
  type: ACTION_TYPE.SET_GROUP_CONNECTIONS;
  payload: {
    groupConnections: GroupConnection[];
    user: User;
  };
}

export function setDateRangeFilter(
  dateRange: DATE_RANGE,
  paramAccountId: string
): SetDateRangeFilterAction;
export function setDateRangeFilter(
  startDate: Date,
  endDate: Date,
  paramAccountId: string
): SetDateRangeFilterAction;
export function setDateRangeFilter(
  param1: any,
  param2?: any,
  param3?: string
): any {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const oneWeekAgoDate = subDays(today, 7);
  const fourWeeksAgoDate = subDays(today, 7 * 4);
  const startOfMonthDate = startOfMonth(today);
  const startOfYearDate = startOfYear(today);
  const threeDaysAgoDate = subDays(today, 3);
  const thirtyDaysAgoDate = subDays(today, 30);
  // Defaults
  let dateRange = null;
  let startDate: Date = startOfMonthDate;
  let endDate: Date = today;

  if (typeof param1 === 'string') {
    switch (param1) {
      case DATE_RANGE.CUSTOM: {
        break;
      }
      case DATE_RANGE.ONE_WEEK: {
        startDate = oneWeekAgoDate;
        break;
      }
      case DATE_RANGE.FOUR_WEEKS: {
        startDate = fourWeeksAgoDate;
        break;
      }
      case DATE_RANGE.MONTH_TO_DATE: {
        startDate = startOfMonthDate;
        break;
      }
      case DATE_RANGE.YEAR_TO_DATE: {
        startDate = startOfYearDate;
        break;
      }
      case DATE_RANGE.ALL_TIME: {
        startDate = new Date(0);
        break;
      }
      case DATE_RANGE.LAST_3_DAYS: {
        startDate = threeDaysAgoDate;
        break;
      }
      case DATE_RANGE.LAST_30_DAYS: {
        startDate = thirtyDaysAgoDate;
        break;
      }
      default:
        throw new Error(`Invalid DATE_RANGE provided: '${param1}'.`);
    }
    dateRange = param1;
  } else if (param1 instanceof Date && param2 instanceof Date) {
    startDate = param1;
    endDate = param2;
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    dateRange = DATE_RANGE.CUSTOM;
  }

  const { referenceEndDate, referenceStartDate } = getReferenceDate(
    startDate,
    endDate
  );

  return {
    type: ACTION_TYPE.SET_DATE_RANGE_FILTER,
    payload: {
      dateRange,
      startDate,
      endDate,
      referenceEndDate,
      referenceStartDate,
      paramAccountId: param3 || param2,
    },
  };
}

function getReferenceDate(
  startDate: Date,
  endDate: Date
): { referenceStartDate: Date; referenceEndDate: Date } {
  const referenceStartDate = subDays(
    startDate,
    differenceInDays(endDate, startDate)
  );
  return {
    referenceStartDate,
    referenceEndDate: startDate,
  };
}

export type DateRangeFilterTypes =
  | SetDateRangeFilterAction
  | SetMinStartDatesForMainAccount
  | SetMinStartDatesForSharedConnections
  | SetMinStartForSharedConnections;
