import React, { useEffect, useState } from 'react';
import { StatisticsSnapshot } from '../services/statistics-service';
import { format } from 'date-fns';
import '../styles/components/ActivityTimeline.scss';
import { colors, variables } from '../styles/variables';
import {
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Area,
  AreaChart,
  Legend,
  Brush,
  Bar,
  BarChart,
  ReferenceLine,
} from 'recharts';
import { dateFormatter } from './profile/ConnectionTimeline';
import { useMaximumStartDate } from '../hooks/useMaximumStartDate';
import { ChartTooltip } from './ChartTooltip';

export interface ActivityTimelineProps {
  timeline: StatisticsSnapshot[];
  brushEnabled?: boolean;
  isGroup: boolean;
}

const COLOR_MAP = {
  amountViews: variables.typeViews,
  amountImpressions: variables.typeImpressions,
  amountLikes: variables.typeLikes,
  amountComments: variables.typeComments,
  // amountShares: variables.typeShares,
};

const LABEL_MAP: { [key: string]: string } = {
  amountViews: 'Post Views',
  amountImpressions: 'Post Impressions',
  amountLikes: 'Reactions',
  amountComments: 'Comments',
  // amountShares: 'Shares',
};

const ActivityTimelineChart: React.FC<ActivityTimelineProps> = ({
  timeline,
  brushEnabled,
  isGroup = false,
}) => {
  const [data, setData] = useState<any>([]);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(0);

  const { isChosenMaxStartDate } = useMaximumStartDate({ isGroup });

  useEffect(() => {
    let previous: StatisticsSnapshot;

    const newData = timeline.map(data => {
      if (!previous) {
        previous = data;
      }

      const toReturn: StatisticsSnapshot = {
        ...data,
        amountLikes:
          data.amountLikes >= previous.amountLikes &&
          data.amountLikes - previous.amountLikes > 0
            ? data.amountLikes - previous.amountLikes
            : 0,
        amountComments:
          data.amountComments >= previous.amountComments &&
          data.amountComments - previous.amountComments > 0
            ? data.amountComments - previous.amountComments
            : 0,
        amountViews:
          data.amountViews >= previous.amountViews &&
          data.amountViews - previous.amountViews > 0
            ? data.amountViews - previous.amountViews
            : 0,
        amountShares:
          data.amountShares >= previous.amountShares &&
          data.amountShares - previous.amountShares > 0
            ? data.amountShares - previous.amountShares
            : 0,
        amountImpressions:
          data.amountImpressions >= previous.amountImpressions &&
          data.amountImpressions - previous.amountImpressions > 0
            ? data.amountImpressions - previous.amountImpressions
            : 0,
      };
      const formattedTimestamp = data?.timestamp
        ? format(data?.timestamp, 'yyyy-MM-dd HH:mm')
        : '';

      previous = data;

      return {
        ...toReturn,
        timestamp: formattedTimestamp,
      };
    });
    setData(newData);
    setStartIndex(0);
    setEndIndex(newData.length - 1);
  }, [timeline]);

  const handleUpdate = ({
    startIndex,
    endIndex,
  }: {
    startIndex: number;
    endIndex: number;
  }) => {
    setStartIndex(startIndex);
    setEndIndex(endIndex);
  };

  return (
    <div className='box ActivityTimeline'>
      <ResponsiveContainer height={300}>
        <AreaChart
          data={brushEnabled ? data.slice(startIndex, endIndex + 1) : data}
        >
          {/* Gradient definitions */}
          <defs>
            {Object.entries(COLOR_MAP).map(([key, color]) => (
              <linearGradient
                key={key}
                id={`gradient-${key}`}
                x1='0'
                y1='0'
                x2='0'
                y2='1'
              >
                <stop offset='0%' stopColor={color} stopOpacity={0.8} />
                <stop offset='95%' stopColor={color} stopOpacity={0.2} />
              </linearGradient>
            ))}
          </defs>

          <XAxis dataKey='timestamp' tickLine={false} axisLine={false} />
          <YAxis yAxisId='views' tickLine={false} axisLine={false} />
          <YAxis
            yAxisId='others'
            orientation='right'
            tickLine={false}
            axisLine={false}
          />
          <Tooltip formatter={(value, name) => [value, LABEL_MAP[name]]} />

          {isChosenMaxStartDate && data.length && (
            <ReferenceLine
              isFront
              x={data[0].timestamp}
              yAxisId='views'
              stroke='#fff'
              strokeWidth={15}
              className='custom-tooltip'
            />
          )}
          {isChosenMaxStartDate && data.length && (
            <ReferenceLine
              className='custom-tooltip'
              x={data[0].timestamp}
              yAxisId='views'
              stroke='green'
              strokeDasharray='3'
              strokeWidth={1}
              label={props => (
                <ChartTooltip
                  {...props}
                  fontSize={11}
                  value={
                    'This is the date on which you have registered with inlytics. We will track your content performance metrics upon that day.'
                  }
                />
              )}
            />
          )}
          <Legend formatter={name => LABEL_MAP[name]} />

          {Object.entries(COLOR_MAP).map(([key, color]) => (
            <Area
              yAxisId={key === 'amountViews' ? 'views' : 'others'}
              key={key}
              type='monotone'
              dataKey={key}
              stroke={color}
              fill={`url(#gradient-${key})`}
              isAnimationActive={false}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
      {brushEnabled && !!data?.length && (
        <div className='chart-container'>
          <ResponsiveContainer height={60} className='chart-base'>
            <BarChart data={data}>
              <YAxis
                yAxisId='views'
                tickLine={false}
                axisLine={false}
                tick={false}
              />
              <YAxis
                yAxisId='others'
                orientation='right'
                tickLine={false}
                axisLine={false}
                tick={false}
              />

              {Object.entries(COLOR_MAP).map(([key, color]) => (
                <Bar
                  yAxisId={key === 'amountViews' ? 'views' : 'others'}
                  key={key}
                  dataKey={key}
                  stroke={variables.lightGrey}
                  fill={variables.lightGrey}
                  isAnimationActive={false}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
          <ResponsiveContainer height={60} className='chart-brush'>
            <BarChart data={data}>
              <YAxis yAxisId='views' tick={false} />
              <YAxis yAxisId='others' orientation='right' tick={false} />
              <Brush
                startIndex={startIndex}
                endIndex={endIndex}
                height={60}
                fill='rgba(0, 0, 0, 0)'
                stroke={colors.lightBlue}
                onChange={handleUpdate}
                dataKey='timestamp'
                tickFormatter={dateFormatter}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default ActivityTimelineChart;
