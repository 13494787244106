import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getGroupMembersHistory, GroupMembersHistory } from '../../../services/groups-service';
import { format } from 'date-fns';
import { colors } from '../../../styles/variables';
import { APIError } from '../../../services/api-client';
import * as StyledLoading from '../../../styles/common/Loading.styles';
import { RootState } from '../../../store/reducers';
import { ChartInfoBlock } from '../../ChartInfoBlock';

interface Props {
  onDataLoad: (data: any) => void;
}

enum GroupMembersCsv {
  'Group Members' = 'Group Members',
}

const GroupMemberTimeline: React.FC<Props> = ({ onDataLoad }) => {
  const { groupId } = useParams<{ groupId: string }>();
  const [historyChartData, setHistoryChartData] = useState<{ date: string; Members: number }[]>();

  const { startDate, endDate } = useSelector((state: RootState) => state.dateRangeFilter);

  const { data, isLoading } = useQuery<Array<Omit<GroupMembersHistory, 'groupId'>>, APIError, [string]>(
    [groupId, startDate, endDate, 'getGroupMembersHistory'],
    () => getGroupMembersHistory(groupId, startDate, endDate)
  );

  useEffect(() => {
    if (!data) return;
    const sorted = data.sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());
    if (!sorted.length) {
      return setHistoryChartData([]);
    }

    const newData = sorted.reduce((general, curr) => {
      general.push({
        date: format(new Date(curr.updatedAt), 'yyyy-MM-dd'),
        Members: curr.number,
      });
      return general;
    }, [] as { date: string; Members: number }[]);
    setHistoryChartData(newData);
    onDataLoad && onDataLoad({ [GroupMembersCsv['Group Members']]: newData.pop()?.Members || 0 });
  }, [groupId, data, onDataLoad]);

  const percentageForMembers = useMemo(() => {
    if (!historyChartData?.length || historyChartData.length === 1) {
      return { increase: 0 };
    }
    const copy = [...historyChartData];
    let [last, previous] = [copy.pop(), copy.pop()];
    while (copy.length && previous?.date === last?.date) {
      previous = copy.pop();
    }
    return { increase: last?.Members || 0 };
  }, [historyChartData]);

  if (isLoading) {
    return (
      <StyledLoading.LoadingContainer>
        <StyledLoading.LoadingBarSmall active />
      </StyledLoading.LoadingContainer>
    );
  }

  return (
    <div className='info-block'>
      <ChartInfoBlock number={percentageForMembers.increase} />
      <ResponsiveContainer width='100%' height={400}>
        <AreaChart data={historyChartData}>
          <defs>
            <linearGradient id='gradient' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor={colors.royalBlueDark} stopOpacity={0.8} />
              <stop offset='100%' stopColor={colors.royalBlueDark} stopOpacity={0.05} />
            </linearGradient>
          </defs>

          {/*<CartesianGrid />*/}
          <XAxis dataKey='date' tickLine={false} axisLine={false} />
          <YAxis tickLine={false} axisLine={false} />
          <Tooltip />
          <Area type='stepAfter' dataKey='Members' stroke={colors.royalBlueDark} fill='url(#gradient)' />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export { GroupMemberTimeline };
