import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Styled } from './Modal.styles';

interface ModalProps {
  open: boolean;
  onClose?: () => void;
  className?: string;
  fullWidth?: boolean;
}

const Modal: React.FC<ModalProps> = ({ children, open, onClose, className, fullWidth = false }) => {
  useEffect(() => {
    if (open) {
      document.body.classList.add('is-clipped');
    } else {
      document.body.classList.remove('is-clipped');
    }
    return () => {
      document.body.classList.remove('is-clipped');
    };
  }, [open]);

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Styled.Wrapper className={classNames('modal', className, { 'is-active': open })}>
      <Styled.Background onClick={handleOnClose} />
      <Styled.Content fullWidth={fullWidth}>{children}</Styled.Content>
    </Styled.Wrapper>
  );
};

export default Modal;
