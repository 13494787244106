import axios from 'axios';

const environment = process.env.REACT_APP_ENV;
const baseUrl =
  environment === 'development'
    ? 'http://localhost:4000'
    : `https://worker-${environment}-a7wk3dd7na-ey.a.run.app`;

export const workerClient = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  transformResponse: [],
});
