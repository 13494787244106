import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Reload, Checkmark } from '@styled-icons/ionicons-solid';

import StyledTooltip from '../StyledTooltip';
import styles from './UpdateNow.module.css';
import { helpTexts } from '../../helpers/helpTexts';
import { colors } from '../../styles/variables';
import { updateActivitiesHighlights } from '../../services/activities-service';
import { useLocalSavedDates } from '../../hooks';

interface Props {
  account: string;
}

const UpdateNowButton: FC<Props> = ({ account }) => {
  const {
    isLoading: loadingWorkers,
    refetch: runWorkers,
    isFetching: fetchingWorkers,
  } = useQuery(
    ['reload-now-workers'],
    () => updateActivitiesHighlights(account, 'LOAD_ACTIVITY_SNAPSHOTS'),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
  const { pathname } = useLocation();
  const { isPastDate, saveLocalDate } = useLocalSavedDates(new Date());

  const handleFetch = () => {
    runWorkers()
      .then(() => {
        saveLocalDate();
        window.location.reload();
      })
      .catch(error => console.log(error));
  };

  const freshData = isPastDate;

  const loadingData = loadingWorkers || fetchingWorkers;

  const doNothing = () => {};

  const hideIcon = !pathname.includes('/analyze');

  return (
    <div className='security-settings-container'>
      {!hideIcon && (
        <div>
          {freshData ? (
            <Checkmark
              size='1.75rem'
              color={colors.green}
              data-tip
              data-for='fetched'
            />
          ) : (
            <Reload
              size='1.75rem'
              color={loadingData ? '#92bde6' : colors.grey}
              data-tip
              data-for='reload-now'
              className={loadingData ? styles.reload : ''}
              onClick={loadingData ? doNothing : handleFetch}
              style={{
                cursor: loadingData ? 'progress' : 'pointer',
              }}
            />
          )}
          <StyledTooltip id='reload-now' place='left'>
            <div>
              <span>{helpTexts.RELOAD_BTN_TEXT}</span>
            </div>
          </StyledTooltip>
          <StyledTooltip id='fetched' place='left'>
            <div>
              <span>{helpTexts.FETCHED_DATA_TEXT}</span>
            </div>
          </StyledTooltip>
        </div>
      )}
    </div>
  );
};

export default UpdateNowButton;
