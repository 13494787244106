import React, { ChangeEvent, useEffect, useState } from "react";
import { CompanyDemographics } from "../../../services/company-service";

export enum DEMO_TYPE {
  JOB_FUNCTION,
  LOCATION,
  SENIORITY,
  INDUSTRY,
  COMPANY_SIZE
}

const LABEL_MAP = {
  [DEMO_TYPE.JOB_FUNCTION]: "Job Function",
  [DEMO_TYPE.LOCATION]: "Location",
  [DEMO_TYPE.SENIORITY]: "Seniority",
  [DEMO_TYPE.INDUSTRY]: "Industry",
  [DEMO_TYPE.COMPANY_SIZE]: "Company Size"
};

const KEY_MAP = {
  [DEMO_TYPE.JOB_FUNCTION]: "jobFunction",
  [DEMO_TYPE.LOCATION]: "location",
  [DEMO_TYPE.SENIORITY]: "seniority",
  [DEMO_TYPE.INDUSTRY]: "industry",
  [DEMO_TYPE.COMPANY_SIZE]: "companySize"
};

interface Props {
  data: CompanyDemographics;
}

export const CompanyDemographic: React.FC<Props> = ({ data }) => {
  const [currentDemo, setCurrentDemo] = useState<DEMO_TYPE>(
    DEMO_TYPE.JOB_FUNCTION
  );
  const [chartData, setChartData] = useState<[string, number][]>([]);
  const [generalNum, setGeneralNum] = useState<number>(1);

  useEffect(() => {
    let key = KEY_MAP[currentDemo] as
      | "jobFunction"
      | "location"
      | "seniority"
      | "industry"
      | "companySize";

    if (data[key]) {
      const newData = Object.entries(data[key])
        .filter(value => !!value[1])
        .sort((a, b) => b[1] - a[1]);
      const genNum = Object.values(data[key]).reduce(
        (gen, curr) => (gen += curr),
        0
      );

      setGeneralNum(genNum);
      setChartData(newData);
    }
  }, [data, currentDemo]);

  const handleOnTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const type = +e.currentTarget.value as DEMO_TYPE;
    setCurrentDemo(type);
  };

  return (
    <div className="company-box info-block CompanyDemographics">
      <div className="select-container company-select-container">
        <div className="select">
          <select
            onChange={handleOnTypeChange}
            defaultValue={DEMO_TYPE.JOB_FUNCTION}
          >
            {Object.entries(LABEL_MAP).map(([type, value]) => (
              <option key={type} value={type}>
                {value}
              </option>
            ))}
          </select>
        </div>
      </div>
      <table className="demo-table">
        <thead className="mb-2">
          <tr>
            <th className="name">Top {LABEL_MAP[currentDemo]}</th>
            <th className="num has-text-grey has-text-weight-normal">
              Visitors
            </th>
            <th className="has-text-grey has-text-weight-normal">
              {" "}
              % of visitors
            </th>
            <th className="percentage" />
          </tr>
        </thead>

        <tbody>
          {chartData.map(item => {
            const percentage = (item[1] / generalNum) * 100;
            return (
              <tr>
                <th className="name has-text-grey has-text-weight-normal">
                  {item[0]}
                </th>
                <th className="num">{item[1]}</th>
                <th className="block">
                  <div style={{ width: `${percentage}%` }} />
                </th>
                <th className="percentage has-text-grey has-text-weight-normal">
                  {percentage.toFixed(2)}%
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
