import React, { useEffect, lazy, Suspense } from 'react';
import { updateUserState } from './services/user-service';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import OnlyLoggedOutRoute from './components/auth-guards/OnlyLoggedOutRoute';
import OnlyHasAccessRoute from './components/auth-guards/OnlyHasAccessRoute';
import OnlyLoggedInRoute from './components/auth-guards/OnlyLoggedInRoute';
import Sidebar from './components/Sidebar';
import AppBar from './components/AppBar';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from './store/reducers';
import { hot } from 'react-hot-loader/root';
import LoginRegisterPage from './components/login-register/LoginRegisterPage';
import AccountContainer from './components/AccountContainer';

// Styles
import 'react-day-picker/lib/style.css';
import 'react-mde/lib/styles/scss/react-mde-all.scss';
import './App.scss';
import Invitation from './components/invitation/Invitation';
import UpgradeBanner from './components/banner/UpgradeBanner';
import RegisterAppSumoPage from './components/login-register/RegisterAppSumoPage';
import LoadingBar from './components/loader/LoadingBar';

// Lazy loaded components
const LinkedAccounts = lazy(() =>
  import('./components/linked-accounts/LinkedAccounts')
);
const Upgrade = lazy(() => import('./components/payment/Upgrade'));
const PaymentSuccess = lazy(() =>
  import('./components/payment/PaymentSuccess')
);
const Feedback = lazy(() => import('./components/Feedback'));
const Onboarding = lazy(() => import('./components/Onboarding'));
const Settings = lazy(() => import('./components/settings/Settings'));

const App: React.FC = () => {
  const { pathname } = useLocation();
  const hideSidebar =
    !!useRouteMatch([
      '/login',
      '/register',
      '/onboarding',
      '/forgot-password',
      '/reset-password/:token',
      '/upgrade',
      '/appsumo',
    ]) || pathname === '/';
  const settingsSidebarOpen = useSelector(
    (state: RootState) => state.settings.sidebarOpen
  );

  useEffect(() => {
    // Attempt to log in user
    updateUserState();
  }, []);

  return (
    <>
      <AppBar />
      <div
        className={classNames('main-container', {
          'sidebar-hidden': hideSidebar,
          'sidebar-closed': !settingsSidebarOpen,
        })}
      >
        <Sidebar hide={hideSidebar} />

        {/* Various global banners */}
        {!hideSidebar && <UpgradeBanner />}
        <Suspense fallback={<LoadingBar active />}>
          <Switch>
            <Route exact path='/'>
              <OnlyLoggedInRoute>
                <LinkedAccounts />
              </OnlyLoggedInRoute>
            </Route>
            <Route
              path={[
                '/register',
                '/login',
                '/forgot-password',
                '/reset-password/:token',
              ]}
            >
              <OnlyLoggedOutRoute>
                <LoginRegisterPage />
              </OnlyLoggedOutRoute>
            </Route>
            <Route path='/appsumo'>
              <OnlyLoggedOutRoute>
                <RegisterAppSumoPage />
              </OnlyLoggedOutRoute>
            </Route>
            <Route
              path={[
                '/account/:accountId/group/:groupId',
                '/account/:accountId',
                '/company/:companyId',
              ]}
            >
              <OnlyHasAccessRoute>
                <AccountContainer />
              </OnlyHasAccessRoute>
            </Route>
            {/* <Route path='/share'>
            <OnlyLoggedInRoute>
              <Share />
            </OnlyLoggedInRoute>
          </Route> */}
            <Route path='/feedback'>
              <OnlyLoggedInRoute>
                <Feedback />
              </OnlyLoggedInRoute>
            </Route>
            <Route path='/settings'>
              <OnlyLoggedInRoute>
                <Settings />
              </OnlyLoggedInRoute>
            </Route>
            <Route exact path='/onboarding'>
              <OnlyLoggedInRoute>
                <Onboarding />
              </OnlyLoggedInRoute>
            </Route>
            <Route path='/upgrade'>
              <OnlyLoggedInRoute>
                <Upgrade />
              </OnlyLoggedInRoute>
            </Route>
            <Route path='/payment/success'>
              <OnlyLoggedInRoute>
                <PaymentSuccess />
              </OnlyLoggedInRoute>
            </Route>
            <Route path='/invitation/:token'>
              <OnlyLoggedInRoute>
                <Invitation />
              </OnlyLoggedInRoute>
            </Route>
          </Switch>
        </Suspense>
      </div>
    </>
  );
};

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
