import React, { useContext, useMemo } from "react";
import ConnectionCard from "./ConnectionCard";
import { AccountSharingModalContext } from "./AccountSharingModal";
import "../../styles/components/accountSharing/ConnectSingleAccount.scss";
import ChooseTool from "./ChooseTool";
import ToolsCard from "./ToolsCard";
import LayerIcon from "../../assets/images/icon-layers.svg";
import ChartIcon from "../../assets/images/icon-chart.svg";
import AccountInputComponent from "./AccountInputComponent";

const sendRequestText = {
  toolsHeadline: "Tools For Which You Want To Get Access",
  accountInput: {
    headline: "Request access",
    description:
      "Enter the email addresses of the people you want to gain access to ",
    note: "(add a comma to seperate email addresses)."
  }
};
const giveAccessText = {
  toolsHeadline: "Tools You Want To Share",
  accountInput: {
    headline: "Send Access",
    description:
      "Enter the email addresses of the people you want to share the above tools with",
    note: "(add a comma to seperate email addresses)."
  }
};

interface Props {
  handleGivingAccess: (value: boolean) => void;
}

const ConnectSingleAccount = ({ handleGivingAccess }: Props) => {
  const accountSharing: any = useContext(AccountSharingModalContext);

  const [isGivingAccess] = accountSharing.givingAccess;
  const [userInputData, setUserInputData] = accountSharing.userInputData;
  const [
    isAnalyticsChecked,
    setAnalyticsChecked
  ] = accountSharing.analyticsChecked;
  const [
    isScheduleChecked,
    setScheduleChecked
  ] = accountSharing.scheduleChecked;

  const text = useMemo(() => {
    return isGivingAccess ? giveAccessText : sendRequestText;
  }, [isGivingAccess]);

  return (
    <div className="connectSingleAccount">
      <h2 className="headline-2">Connect A Single Account</h2>
      <p className="description-main">
        Decide if you want to give someone access to your Inlytics account or if
        you want to request someone to give you access to their account.
      </p>
      <div className="connectionCard-container responsive-container">
        <ConnectionCard
          isActive={isGivingAccess === true}
          title="I want to give somebody access to my account"
          handleClick={() => handleGivingAccess(true)}
        />
        <ConnectionCard
          isActive={isGivingAccess === false}
          title="I want to request somebody to give me access to their account"
          handleClick={() => handleGivingAccess(false)}
        />
      </div>
      {typeof isGivingAccess === "boolean" && (
        <>
          <ChooseTool headline={text.toolsHeadline}>
            <ToolsCard
              image={ChartIcon}
              headline="Analytics"
              description="Gives people access to your analytics tool"
              state={isAnalyticsChecked}
              setState={setAnalyticsChecked}
            />
            <ToolsCard
              image={LayerIcon}
              headline="Scheduling"
              description="Gives people access to your scheduling tool"
              state={isScheduleChecked}
              setState={setScheduleChecked}
            />
          </ChooseTool>
          {isGivingAccess && isScheduleChecked && (
            <p className="connectSingleAccount__note is-warning">
              Note: Sharing access to your scheduling tool enables people to
              post on your LinkedIn account. Please make sure you trust this
              person. You can always revoke this access later in your sharing
              settings.
            </p>
          )}
          <AccountInputComponent
            state={userInputData}
            setState={setUserInputData}
            headline={text.accountInput.headline}
            description={text.accountInput.description}
            note={text.accountInput.note}
          />
          <p className="connectSingleAccount__note">
            Remember: these are not groups. You wont be able to compare/view
            individually connected accounts in one dashboard. For this use-case,
            create a group.
          </p>
        </>
      )}
    </div>
  );
};

export default ConnectSingleAccount;
