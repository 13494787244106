import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import * as Styled from './OnlyLoggedXStyles.styles';

const OnlyLoggedOutRoute: React.FC = props => {
  const { user } = useSelector((state: RootState) => state.auth);
  if (user === undefined) {
    // User is not yet loaded
    return (
      <Styled.Wrapper>
        <Styled.Loader active />
        <Styled.Text>Loading...</Styled.Text>
      </Styled.Wrapper>
    );
  }
  if (user === null) {
    // User is not logged in
    return <>{props.children}</>;
  } else {
    // User is logged in
    // TODO redirect to previous url?
    return <Redirect to='/'></Redirect>;
  }
};

export default OnlyLoggedOutRoute;
