import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { employmentTypeOptions, employmentIndustryOptions } from '../../data/onboarding';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { updatePersonalSettings, PersonalSettings, updateUserState } from '../../services/user-service';
import { APIError } from '../../services/api-client';
import * as Styled from './PersonalSettingsForm.styles';

const OnboardingSchema = Yup.object().shape({
  employmentType: Yup.string().required('This field is required.'),
  employmentIndustry: Yup.string().required('This field is required.'),
  employmentIndustryOther: Yup.string().when('employmentIndustry', {
    is: 'other',
    then: Yup.string().required('Please name your industry.'),
  }),
});

function sortAlphabetically<T extends { name: string }>(sortable: T[]): T[] {
  return sortable.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true }));
}

const PersonalSettingsForm = () => {
  const [updatePersonalSettingsMutation] = useMutation<
    PersonalSettings,
    APIError,
    Yup.InferType<typeof OnboardingSchema>
  >(async ({ employmentIndustry, employmentIndustryOther, employmentType }) => {
    const res = await updatePersonalSettings({
      employmentIndustry: employmentIndustry ?? employmentIndustryOther,
      employmentType,
    });
    await updateUserState();
    return res;
  });

  return (
    <Formik
      initialValues={{
        employmentType: '',
        employmentIndustry: '',
        employmentIndustryOther: '',
      }}
      onSubmit={values => updatePersonalSettingsMutation(values)}
      validationSchema={OnboardingSchema}>
      {({ isSubmitting, isValid, values }) => (
        <Form>
          <div className='field'>
            <label htmlFor='onboarding-employment-industry' className='label'>
              I'm ...
            </label>
            <div className='select is-fullwidth'>
              <Field
                className='input'
                component='select'
                name='employmentType'
                id='onboarding-employment-type'
                required>
                <option value='' defaultChecked disabled hidden>
                  Please choose...
                </option>
                {sortAlphabetically(employmentTypeOptions).map(entry => (
                  <option key={entry.value} value={entry.value}>
                    {entry.name}
                  </option>
                ))}
              </Field>
            </div>
            <ErrorMessage component='p' className='help is-danger' name='employmentType' />
          </div>

          <div className='field'>
            <label htmlFor='onboarding-employment-industry' className='label'>
              The industry im working in
            </label>
            <div className='select is-fullwidth'>
              <Field
                className='input'
                component='select'
                name='employmentIndustry'
                id='onboarding-employment-industry'
                required>
                <option value='' defaultChecked disabled hidden>
                  Please choose...
                </option>
                {sortAlphabetically(employmentIndustryOptions).map(entry => (
                  <option key={entry.value} value={entry.value}>
                    {entry.name}
                  </option>
                ))}
                <option value='' defaultChecked disabled>
                  ---
                </option>
                <option value='other'>Other</option>
              </Field>
            </div>
            <ErrorMessage component='p' className='help is-danger' name='employmentIndustry' />

            {values.employmentIndustry === 'other' && (
              <Styled.OtherField className='field'>
                <div className='control'>
                  <Field
                    className='input'
                    name='employmentIndustryOther'
                    id='onboarding-employment-industry-other'
                    placeholder='Other industry'
                    required
                  />
                </div>
              </Styled.OtherField>
            )}
            <ErrorMessage component='p' className='help is-danger' name='employmentIndustryOther' />
          </div>

          <footer>
            <button
              className={classNames('button  is-left is-primary ', { 'is-loading': isSubmitting })}
              type='submit'
              disabled={isSubmitting || !isValid}>
              Update settings
            </button>
          </footer>
        </Form>
      )}
    </Formik>
  );
};

export default PersonalSettingsForm;
