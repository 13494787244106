import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import classNames from 'classnames';
import { Card, Key, Mail } from '@styled-icons/ionicons-solid';
import { useMutation } from 'react-query';
import { APIError } from '../../services/api-client';
import { register } from '../../services/auth-service';

interface Props {}

const RegisterSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('This field is required'),
  familyName: Yup.string().required('This field is required'),
  givenName: Yup.string().required('This field is required'),
  password: Yup.string()
    .min(8, 'Please use at least 8 characters')
    .required('This field is required'),
  confirmPassword: Yup.string().when('password', {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf([Yup.ref('password')], 'Both passwords need to be the same'),
  }),
  promotionCode: Yup.string().required('This field is required'),
  tosAndPrivacy: Yup.boolean().required(),
});

const RegisterAppSumoForm: React.FC<Props> = () => {
  const [needsTosAndPrivacyAgreement, setNeedsTosAndPrivacyAgreement] = useState(false);
  const checkboxEl = useRef<HTMLInputElement>(null);

  const [registerMutation, { error }] = useMutation<void, APIError, Parameters<typeof register>[0]>(register, {
    onError: () => setNeedsTosAndPrivacyAgreement(false),
  });

  return (
    <div className='login-signup-form-wrapper'>
      {needsTosAndPrivacyAgreement && (
        <div className='notification is-danger is-light'>Please make sure to agree to our terms and conditions.</div>
      )}
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirmPassword: '',
          givenName: '',
          familyName: '',
          promotionCode: '',
          tosAndPrivacy: false,
        }}
        onSubmit={values => registerMutation(values)}
        validationSchema={RegisterSchema}>
        {({ isSubmitting, isValid, errors, touched }) => (
          <Form>
            <div className='field'>
              <label htmlFor='given-name' className='label'>
                First name
              </label>
              <div className='control is-expanded has-icons-left'>
                <Field
                  className={classNames('input', { 'is-danger': touched.givenName && errors.givenName })}
                  id='given-name'
                  type='text'
                  name='givenName'
                  autoComplete='given-name'
                  required
                />
                <span className='icon is-left'>
                  <Card size='1rem' />
                </span>
              </div>
              <ErrorMessage component='p' className='help is-danger' name='givenName' />
            </div>
            <div className='field'>
              <label htmlFor='family-name' className='label'>
                Last name
              </label>
              <div className='control is-expanded has-icons-left'>
                <Field
                  className={classNames('input', { 'is-danger': touched.familyName && errors.familyName })}
                  id='family-name'
                  type='text'
                  name='familyName'
                  autoComplete='family-name'
                  required
                />
                <span className='icon is-left'>
                  <Card size='1rem' />
                </span>
              </div>
              <ErrorMessage component='p' className='help is-danger' name='familyName' />
            </div>

            <div className='field'>
              <label htmlFor='email' className='label'>
                Email address
              </label>
              <div className='control is-expanded has-icons-left'>
                <Field
                  className={classNames('input', { 'is-danger': touched.email && errors.email })}
                  id='email'
                  type='email'
                  name='email'
                  autoComplete='email'
                  required
                  placeholder='name@example.com'
                />
                <span className='icon is-left'>
                  <Mail size='1rem' />
                </span>
              </div>
              <ErrorMessage component='p' className='help is-danger' name='email' />
            </div>

            <div className='columns'>
              <div className='column'>
                <div className='field'>
                  <label htmlFor='password' className='label'>
                    Password
                  </label>
                  <div className='control is-expanded has-icons-left'>
                    <Field
                      className={classNames('input', { 'is-danger': touched.password && errors.password })}
                      id='password'
                      type='password'
                      name='password'
                      autoComplete='new-password'
                      required
                    />
                    <span className='icon is-left'>
                      <Key size='1rem' />
                    </span>
                  </div>
                  <ErrorMessage component='p' className='help is-danger' name='password' />
                </div>
              </div>
              <div className='column'>
                <div className='field'>
                  <label htmlFor='confirm-password' className='label'>
                    Password (confirm)
                  </label>
                  <div className='control is-expanded has-icons-left'>
                    <Field
                      className={classNames('input', {
                        'is-danger': touched.confirmPassword && errors.confirmPassword,
                      })}
                      id='confirm-password'
                      type='password'
                      name='confirmPassword'
                      autoComplete='new-password'
                      required
                    />
                    <span className='icon is-left'>
                      <Key size='1rem' />
                    </span>
                  </div>
                  <ErrorMessage component='p' className='help is-danger' name='confirmPassword' />
                </div>
              </div>
            </div>

            <div className='field'>
              <label htmlFor='given-name' className='label'>
                AppSumo Code
              </label>
              <div className='control is-expanded has-icons-left'>
                <Field
                  className={classNames('input', { 'is-danger': touched.promotionCode && errors.promotionCode })}
                  id='promotionCode'
                  type='text'
                  name='promotionCode'
                  autoComplete='promotionCode'
                  required
                />
                <span className='icon is-left'>
                  <Card size='1rem' />
                </span>
              </div>
              <ErrorMessage component='p' className='help is-danger' name='promotionCode' />
            </div>

            <div className='columns'>
              <div className='column'>
                <div className='field'>
                  <label className='checkbox has-text-grey'>
                    <Field id='tos-and-privacy' type='checkbox' name='tosAndPrivacy'>
                      {({ field }: any) => <input type='checkbox' required {...field} ref={checkboxEl} />}
                    </Field>
                    I confirm that I have read, consent and agree to inlytics's{' '}
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a href='https://www.inlytics.io/data-agreement' target='_blank'>
                      data agreement
                    </a>{' '}
                    and {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a href='https://www.inlytics.io/privacy' target='_blank'>
                      privacy policy
                    </a>
                    . I understand that I can adjust my communication preferences at any time in my account settings.
                  </label>
                </div>
              </div>
            </div>

            <button
              className={classNames('button is-fullwidth is-primary', { 'is-loading': isSubmitting })}
              type='submit'
              disabled={isSubmitting || !isValid}>
              Sign up
            </button>
          </Form>
        )}
      </Formik>
      {/* Error */}
      {error && <div className='notification is-danger is-light'>{error.message}</div>}
    </div>
  );
};

export { RegisterAppSumoForm };
