import React, { useContext } from 'react';
import { HelpCircle } from '@styled-icons/ionicons-solid';

import AccountInputComponent from './AccountInputComponent';
import ChooseTool from './ChooseTool';
import ToolsCard from './ToolsCard';
import { FlaskIcon, PersonIcon, ShieldCheckmarkIcon } from '../CustomIcons';
import { variables } from '../../styles/variables';
import '../../styles/components/accountSharing/InviteGroupMember.scss';
import { GroupUserRoles } from './AccountSharingModal';
import StyledTooltip from '../StyledTooltip';

interface Props {
  context: React.Context<any>;
}

const InviteGroupMember: React.FC<Props> = ({ context }) => {
  const groupMemberData: any = useContext(context);

  const [emailsInput, setEmailsInput]: [string, (val: string) => string] = groupMemberData.userInputData;
  const [role, setRole]: [
    GroupUserRoles,
    (val: GroupUserRoles | undefined) => GroupUserRoles | undefined
  ] = groupMemberData.userGroupRole;
  const [isIncludeStatistics, setIncludeStatistics]: [
    boolean,
    (val: boolean) => boolean
  ] = groupMemberData.includeStatistics;

  return (
    <div className='invite-group-member'>
      <h2 className='headline-2'>Invite People</h2>
      <p className='description-main'>
        Invite people and define their role in the group (later you’d be able to change the level of permission and
        invite more people).
      </p>
      <AccountInputComponent
        state={emailsInput}
        setState={setEmailsInput}
        headline='Group Access'
        description='Enter the email adresses of the people you want to join your group '
        note='(add comma to seperate adresses).'
      />
      <ChooseTool headline='Define The Rights For The Members Who Join'>
        <ToolsCard
          image={PersonIcon}
          headline='User (default role if you have e.g. clients in your group that should not see each other)'
          description='Only shares its data with „Analysts“ and „Admins“. Can’t view team statistics (dashboard). Can’t see who’s in the group (only see’s „Analysts“ and „Admins“ - other „Users“ are invisible)'
          state={role === GroupUserRoles.User}
          setState={(val: boolean) => setRole(val ? GroupUserRoles.User : undefined)}
        />
        <ToolsCard
          image={FlaskIcon}
          headline='Analyst (default role for most team members)'
          description='Can view the team statistics (dashboard).'
          state={role === GroupUserRoles.Analyst}
          setState={(val: boolean) => setRole(val ? GroupUserRoles.Analyst : undefined)}
        />
        <ToolsCard
          image={ShieldCheckmarkIcon}
          headline='Admin'
          description='Can view the team statistics (dashboard). Can add or delete group members. Can define new admins.'
          state={role === GroupUserRoles.Admin}
          setState={(val: boolean) => setRole(val ? GroupUserRoles.Admin : undefined)}
        />
      </ChooseTool>
      <h3 className='headline-3'>Included In Team Statistics?</h3>
      <div className='include-statistics'>
        <div className='include-statistics__description'>
          <span>Invited people will be included in the team statistics?</span>
          <div
            className='button is-white is-rounded is-help-button'
            data-tip
            data-for='include-group-statistics'
            style={{ color: variables.lightGrey, backgroundColor: 'rgba(0, 0, 0, 0)' }}>
            <HelpCircle size='1.75rem' />
          </div>
          <StyledTooltip id='include-group-statistics' delayHide={1000} clickable={true}>
            <div>
              This option defines whether or not someone counts in the group statistics. For example: You want all group
              members to be included in your stats but have one person that should only be able to analyze these stats
              but is not part of your team. You can separately invite him and exclude him from the dashboard.
            </div>
          </StyledTooltip>
        </div>
        <label className='switch'>
          <input
            type='checkbox'
            className='tool-input'
            defaultChecked={isIncludeStatistics}
            onClick={(event: any) => setIncludeStatistics(event.target.checked)}
          />
          <span className='slider round' />
        </label>
      </div>
    </div>
  );
};

export default InviteGroupMember;
