import { differenceInDays, format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  AreaChart,
  BarChart,
  Bar,
  Brush,
} from 'recharts';
import { APIError } from '../../services/api-client';
import {
  LinkedInConnection,
  getProfileConnections,
} from '../../services/profile-service';
import { colors, variables } from '../../styles/variables';
import * as StyledLoading from '../../styles/common/Loading.styles';
import { ChartInfoBlock } from '../ChartInfoBlock';
import { RootState } from '../../store/reducers';
import { useSelector } from 'react-redux';

export interface ConnectionTimelineEntry {
  Connections: number;
  timestamp: number;
}

export enum ConnectionsCsv {
  'Connections in total' = 'Connections in total',
  'New connections last week' = 'New connections last week',
}

interface Props {
  brushEnabled?: boolean;
  onDataLoaded?: (data: any) => void;
}

export const dateFormatter = (date: string | number = Date.now()) =>
  format(date ? new Date(date) : 0, 'yyyy-MM-dd');

const ConnectionTimeline: React.FC<Props> = ({
  brushEnabled,
  onDataLoaded,
}) => {
  const { accountId } = useParams<{ accountId: string }>();

  const [chartData, setChartData] = useState<ConnectionTimelineEntry[]>([
    { Connections: 0, timestamp: Date.now() },
  ]);

  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(0);
  const { startDate, endDate } = useSelector(
    (state: RootState) => state.dateRangeFilter
  );

  const { isLoading } = useQuery<
    LinkedInConnection[],
    APIError,
    [string, string]
  >(
    [accountId, 'getProfileConnections'],
    accountId => getProfileConnections(accountId),
    {
      onSuccess: connections => {
        if (!connections?.length) {
          setChartData([{ Connections: 0, timestamp: Date.now() }]);
          return;
        }
        // Count connections by date (day)
        const mappedByDay = new Map();
        connections.forEach(con => {
          con.createdAt.setHours(0, 0, 0, 0);
          const dateString = con.createdAt.toISOString();
          const entry = mappedByDay.get(dateString) || 0;
          mappedByDay.set(dateString, entry + 1);
        });

        const data: ConnectionTimelineEntry[] = [];
        const dataCsv: { Connections: number; timestamp: Date }[] = [];
        let lastAmount = 0;
        mappedByDay.forEach((entry, date) => {
          dataCsv.push({
            Connections: lastAmount + entry,
            timestamp: new Date(date),
          });
          data.push({
            Connections: lastAmount + entry,
            timestamp: new Date(date).getTime(),
          });
          lastAmount += entry;
        });

        setChartData(data);
        setStartIndex(0);
        setEndIndex(data.length - 1);
      },
    }
  );

  const handleUpdate = ({
    startIndex,
    endIndex,
  }: {
    startIndex: number;
    endIndex: number;
  }) => {
    setStartIndex(startIndex);
    setEndIndex(endIndex);
  };

  const percentage: { percentage: number; number: number } = useMemo(() => {
    if (!chartData.length) return { percentage: 0, number: 0 };
    const copy = [...chartData.slice(startIndex, endIndex + 1)];
    let [last, previous] = [copy.pop(), copy.pop()];

    const increase = last?.Connections || 0;
    const percentage =
      (((last?.Connections || 0) - (previous?.Connections || 0)) /
        (previous?.Connections || 0)) *
      100;

    while (
      copy.length &&
      differenceInDays(last?.timestamp || 0, previous?.timestamp || 0) < 7
    ) {
      previous = copy.pop();
    }

    onDataLoaded &&
      onDataLoaded({
        [ConnectionsCsv['Connections in total'].slice(
          startIndex,
          endIndex + 1
        )]: last?.Connections || 0,
        [ConnectionsCsv['New connections last week'].slice(
          startIndex,
          endIndex + 1
        )]: (last?.Connections || 0) - (previous?.Connections || 0),
      });

    return {
      percentage,
      number: increase,
    };
  }, [chartData, onDataLoaded, startIndex, endIndex]);

  if (isLoading) {
    return (
      <StyledLoading.LoadingContainer>
        <StyledLoading.LoadingBarSmall active />
      </StyledLoading.LoadingContainer>
    );
  }

  const selectedChartData = chartData.filter(({ timestamp }) => {
    return new Date(timestamp) >= startDate && new Date(timestamp) <= endDate;
  });

  console.log(selectedChartData);

  return (
    <div className='info-block'>
      <ChartInfoBlock
        title='Connections'
        number={percentage.number}
        percentage={percentage.percentage}
      />
      <ResponsiveContainer height={400}>
        <AreaChart
          data={
            brushEnabled ? chartData.slice(startIndex, endIndex + 1) : chartData
          }
        >
          <defs>
            <linearGradient id='gradient' x1='0' y1='0' x2='0' y2='1'>
              <stop
                offset='0%'
                stopColor={colors.royalBlueDark}
                stopOpacity={0.8}
              />
              <stop
                offset='100%'
                stopColor={colors.royalBlueDark}
                stopOpacity={0.05}
              />
            </linearGradient>
          </defs>

          {/*<CartesianGrid />*/}
          <XAxis
            dataKey='timestamp'
            type='number'
            domain={['dataMin', 'dataMax']}
            tickFormatter={dateFormatter}
            tickLine={false}
            axisLine={false}
          />
          <YAxis tickLine={false} axisLine={false} />
          <Tooltip label='timestamp' labelFormatter={dateFormatter} />
          <Area
            type='monotone'
            dataKey='Connections'
            stroke={colors.royalBlueLight}
            fill='url(#gradient)'
          />
        </AreaChart>
      </ResponsiveContainer>
      {brushEnabled && !!chartData?.length && (
        <div className='chart-container'>
          <ResponsiveContainer height={60} className='chart-base'>
            <BarChart data={chartData}>
              <YAxis
                yAxisId='Connections'
                tickLine={false}
                axisLine={false}
                tick={false}
              />

              <Bar
                yAxisId='Connections'
                dataKey='Connections'
                stroke={variables.lightGrey}
                fill={variables.lightGrey}
                isAnimationActive={false}
              />
            </BarChart>
          </ResponsiveContainer>
          <ResponsiveContainer height={60} className='chart-brush'>
            <BarChart data={chartData}>
              <YAxis yAxisId='Connections' tick={false} />

              <Brush
                startIndex={startIndex}
                endIndex={endIndex}
                height={60}
                fill='rgba(0, 0, 0, 0)'
                stroke={colors.royalBlueDark}
                onChange={handleUpdate}
                dataKey='timestamp'
                tickFormatter={dateFormatter}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default ConnectionTimeline;
