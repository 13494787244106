import React from 'react';
import '../../styles/components/accountSharing/AccountModalSuccess.scss';

interface Props {
  headline: string;
  note: string;
  sendToText: string;
  usernames: string;
  errors?: string;
}

const AccountModalSuccess = (props: Props) => {
  const { headline, note, sendToText, usernames, errors } = props;

  return (
    <div className='accountModalSuccess'>
      <>
        <h2 className='headline-2'>{headline}</h2>
        {usernames && (
          <>
            <p className='description-main'>{note}</p>

            <p className='text-container'>
              <span className='send-to-text'>{sendToText}</span>
              <span className='usernames'>{usernames}</span>
            </p>
          </>
        )}
      </>
      {errors && <p className='text-container send-to-text is-error'>Some requests have not been sent:</p>}
      {/* List of errors is on the main component */}
    </div>
  );
};

export default AccountModalSuccess;
