import React, { useState } from 'react';
import '../../styles/components/ScheduledActivityMedia.scss';
import { Media } from '../../services/media-service';
import MediaImg from '../media/MediaImg';
import MediaLibrary from '../media/MediaLibrary';
import Modal from '../modal/Modal';
import {
  ArrowBack,
  ArrowForward,
  HelpCircle,
} from '@styled-icons/ionicons-solid';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { USER_PLAN } from '../../services/user-service';
import StyledTooltip from '../StyledTooltip';
import { colors } from '../../styles/variables';

interface ScheduledActivityMediaProps {
  mediaFiles: Media[];
  onChange: (newMedia: Media[]) => void;
}

const ScheduledActivityMedia: React.FC<ScheduledActivityMediaProps> = ({
  mediaFiles,
  onChange,
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [popupOpen, setPopupOpen] = useState(false);

  const handleMoveMedia = (media: Media, direction: -1 | 1) => {
    const newValues = [...mediaFiles];
    let currentIndex = 0;
    newValues.forEach((entry, i) => {
      if (entry._id === media._id) {
        currentIndex = i;
      }
    });
    newValues.splice(currentIndex, 1);
    newValues.splice(currentIndex + direction, 0, media);
    onChange(newValues);
  };

  const handleOnMediaSelection = (newMedia: Media[]) => {
    onChange(newMedia);
    setPopupOpen(false);
  };

  const handleDeleteMedia = (media: Media) => {
    const returnMedia = mediaFiles.filter(item => item._id !== media._id);
    onChange(returnMedia);
  };

  return (
    <div className='ScheduledActivityMedia'>
      <div className='current-media'>
        {mediaFiles.map((media, i) => (
          <figure key={media._id} className='media-entry'>
            {/* Media type itself */}
            <MediaImg media={media} classNames='video-preview' />
            {/*Button to delete media*/}
            <div
              className='delete'
              onClick={handleDeleteMedia.bind(null, media)}
            />
            {/* Buttons to move media */}
            <div className='level'>
              <div className='level-left'>
                <button
                  className='level-item button is-small'
                  type='button'
                  onClick={() => handleMoveMedia(media, -1)}
                  disabled={i === 0}
                >
                  <span className='icon'>
                    <ArrowBack size='0.5rem' />
                  </span>
                </button>
              </div>
              <div className='level-right'>
                <button
                  className='level-item button is-small'
                  type='button'
                  onClick={() => handleMoveMedia(media, 1)}
                  disabled={i >= mediaFiles.length - 1}
                >
                  <span className='icon'>
                    <ArrowForward size='0.5rem' />
                  </span>
                </button>
              </div>
            </div>
          </figure>
        ))}
      </div>
      <button
        className='button is-primary'
        type='button'
        onClick={() => setPopupOpen(true)}
        disabled={user!.plan === USER_PLAN.FREE}
      >
        Choose media
      </button>
      {user!.plan === USER_PLAN.FREE && (
        <HelpCircle
          color={colors.grey}
          className='ml-2 mt-2'
          size='1.5rem'
          data-tip='This feature is not included in the free plan, please upgrade to a higher plan to unlock it.'
        />
      )}
      <StyledTooltip disable={user!.plan !== USER_PLAN.FREE} />
      <Modal open={popupOpen} onClose={() => setPopupOpen(false)}>
        <div className='box'>
          <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
            Choose post media
          </h5>
          <MediaLibrary
            onSelect={handleOnMediaSelection}
            initiallySelected={mediaFiles}
            modalOpenState={popupOpen}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ScheduledActivityMedia;
