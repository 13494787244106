import React from 'react';
import { Calendar } from '@styled-icons/ionicons-solid';

import { colors } from '../styles/variables';
import './style.scss';
import DateRangeModal from './DateRangeModal';

interface DateRangeProps {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

export const DateRange = ({
  open,
  handleOpen,
  handleClose,
}: DateRangeProps) => {
  return (
    <div className='security-settings-container'>
      <Calendar size='1.75rem' color={colors.grey} onClick={handleOpen} />
      <DateRangeModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
      />
    </div>
  );
};
