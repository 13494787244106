import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { ChevronForward } from '@styled-icons/ionicons-solid';
import Header from '../Header';
import ProfileViewTimeline from '../profile/ProfileViewTimeline';
import FollowerTimeline from '../profile/FollowerTimeline';
import DateRangePicker from '../header/DateRangePicker';
import { RootState } from '../../store/reducers';
import { getGroupMembersInfo, getGroups, GroupMemberCardEntry } from '../../services/groups-service';
import { GroupMemberCard } from './group-member-card/GroupMemberCard';
import './GroupMembers.scss';
import Modal from '../modal/Modal';
import { GroupEditModal } from '../settings/group/GroupEditModal';
import { AccountsTableEntryViewModel } from '../linked-accounts/AccountList';
import { useGroupName } from '../../hooks/useGroupName';
import { InviteMemberWrapper } from './InviteMemberWrapper';
import { GroupMemberTimeline } from './group-member-timeline/GroupMemberTimeline';
import * as StyledLoading from '../../styles/common/Loading.styles';
import { CsvLoad, PageType } from '../CsvLoad';
import { groupMembersConvertor, IGMembersConverter } from '../../services/csv-service';

interface Props {}

const GroupMembers: React.FC<Props> = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const { startDate, endDate } = useSelector((state: RootState) => state.dateRangeFilter);
  const { groups } = useSelector((state: RootState) => state.linkedAccounts);
  const { inviteMemberModalOpen } = useSelector((state: RootState) => state.settings);

  const [cardsData, setCardsData] = useState<GroupMemberCardEntry[]>([]);
  const [chosenGroup, setChosenGroup] = useState<AccountsTableEntryViewModel | undefined>();
  const [csvData, setCsvData] = useState<IGMembersConverter>();

  const { groupName } = useGroupName(groupId);

  const openEdit = () => {
    const groupToOpen = groups?.find(group => group.id === groupId);
    setChosenGroup(groupToOpen);
  };

  const onSaveHandler = () => {
    getGroups().catch(console.log);
    setChosenGroup(undefined);
  };

  const { isLoading } = useQuery(
    [groupId, startDate, endDate, 'getGroupMembersInfo'],
    () => getGroupMembersInfo(groupId, startDate, endDate),
    {
      onSuccess: data => {
        setCardsData(data);
        addToCsv({ Members: data });
      },
    }
  );

  const userRole = useMemo(() => {
    return groups.find(group => group.id === groupId)?.role;
  }, [groupId, groups]);

  const addToCsv = useCallback((data: any) => {
    setCsvData(prevState => ({ ...(prevState || {}), ...data }));
  }, []);

  return (
    <>
      <div className='GroupMembers'>
        <Header
          title={
            groupName && (
              <>
                <span>Group</span>
                <ChevronForward className='icon arrow' />
                <span>{groupName}</span>
              </>
            )
          }
          content={
            <>
              {csvData && <CsvLoad group type={PageType.Profile} data={csvData} converter={groupMembersConvertor} />}
              <DateRangePicker />
            </>
          }
          loading={isLoading}
        />

        <section className='section'>
          <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>Group Statistics</h5>
          <div className='line-container'>
            <div className='box history-chart'>
              <h6 className='title is-size-6 has-text-grey has-text-weight-normal'>Aggregated Profile Views</h6>
              <ProfileViewTimeline group={true} onDataLoaded={addToCsv} brushEnabled={true} />
            </div>
            <div className='box history-chart'>
              <h6 className='title is-size-6 has-text-grey has-text-weight-normal'>
                Aggregated Followers and Following
              </h6>
              <FollowerTimeline brushEnabled={true} onDataLoaded={addToCsv} group={true} />
            </div>
          </div>
        </section>

        <section className='section'>
          <div className='box history-chart'>
            <h6 className='title is-size-6 has-text-grey has-text-weight-normal'>Group Members</h6>
            <GroupMemberTimeline onDataLoad={addToCsv} />
          </div>
        </section>

        <section className='section'>
          <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>Group Members</h5>
          {isLoading && (
            <StyledLoading.LoadingContainer>
              <StyledLoading.LoadingBarSmall active />
            </StyledLoading.LoadingContainer>
          )}
          {cardsData?.map((data, i) => (
            <div key={data.user._id} className='box group-member-container'>
              <GroupMemberCard {...data} onEditOpen={openEdit} userRole={userRole} />
            </div>
          ))}
        </section>
      </div>

      {chosenGroup && (
        <Modal className='' open={true} fullWidth>
          <div className='box group-edit'>
            <GroupEditModal data={chosenGroup} onClose={setChosenGroup.bind(null, undefined)} onSave={onSaveHandler} />
          </div>
        </Modal>
      )}

      {inviteMemberModalOpen && <InviteMemberWrapper />}
    </>
  );
};

export { GroupMembers };
