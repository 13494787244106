import React, { useState } from 'react';

import DateRangePicker from './header/DateRangePicker';
import Modal from './modal/Modal';

interface DateRangeModalProps {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

const DateRangeModal = ({
  open,
  handleClose,
  handleOpen,
}: DateRangeModalProps) => {
  const [biggerModal, setBiggerModal] = useState(false);
  return (
    <Modal open={open} onClose={handleOpen} fullWidth>
      <div className='modal-card'>
        <header
          className='modal-card-head'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className='modal-card-title is-3'>Select date range</p>
          <button
            className='delete'
            aria-label='delete'
            onClick={handleClose}
          />
        </header>
        <section
          className='modal-card-body'
          style={{
            minHeight: biggerModal ? '25rem' : '',
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <DateRangePicker onclick={() => setBiggerModal(prev => !prev)} />
          </div>
        </section>
        <footer className='modal-card-foot'>
          <button className='button' onClick={handleClose}>
            Apply
          </button>
        </footer>
      </div>
    </Modal>
  );
};

export default DateRangeModal;
