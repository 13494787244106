import React, { useMemo, useState } from 'react';
import { CompanyUserRoles, GroupUserRoles } from '../accountSharing/AccountSharingModal';
import InviteGroupMember from '../accountSharing/InviteGroupMember';
import Modal from '../modal/Modal';
import { Styled as StyledModal } from '../modal/Modal.styles';
import { store } from '../../store';
import { setInviteMemberModalOpen } from '../../store/actions/settings';
import { inviteToGroup } from '../../services/groups-service';
import { useParams } from 'react-router-dom';
import Styled from '../media/MediaUploadDroparea.styles';
import InviteCompanyMember from '../accountSharing/InviteCompanyMember';
import { inviteToCompany } from '../../services/company-service';

const InviteContext = React.createContext({});

interface Props {
  isCompany?: boolean;
}

const InviteMemberWrapper: React.FC<Props> = ({ isCompany }) => {
  const { groupId, companyId } = useParams<{ groupId: string; companyId: string }>();

  const [emailInput, setEmailInput] = useState<string>('');
  const [role, setRole] = useState<GroupUserRoles | CompanyUserRoles>(
    isCompany ? CompanyUserRoles.Analyst : GroupUserRoles.User
  );
  const [isIncludeStatistics, setIncludeStatistics] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isActive = useMemo(() => !!emailInput, [emailInput]);

  const close = () => store.dispatch(setInviteMemberModalOpen(false));

  const invite = () => {
    if (isLoading) return;

    const emails = emailInput
      .split(',')
      .map(email => email.trim())
      .filter(email => email.length);

    setIsLoading(true);
    isCompany
      ? inviteToCompany(companyId, emails, role as CompanyUserRoles).finally(() => {
          setIsLoading(false);
          close();
        })
      : inviteToGroup(groupId, emails, role as GroupUserRoles, isIncludeStatistics).finally(() => {
          setIsLoading(false);
          close();
        });
  };

  return (
    <Modal className='accountSharingModal' open={true} fullWidth>
      <div className='box connect-account'>
        <StyledModal.ContentCard style={{ boxShadow: 'none' }}>
          {isLoading && <Styled.LoadingIndicator active={true} />}

          <InviteContext.Provider
            value={{
              userInputData: [emailInput, setEmailInput],
              userGroupRole: [role, setRole],
              userCompanyRole: [role, setRole],
              includeStatistics: [isIncludeStatistics, setIncludeStatistics],
            }}>
            <div id='accountSharingModal'>
              <button type='button' className='modal-close' onClick={close} />
              <div className='wrapper'>
                {isCompany ? (
                  <InviteCompanyMember context={InviteContext} />
                ) : (
                  <InviteGroupMember context={InviteContext} />
                )}
              </div>
              <div className='modal-footer'>
                <div className='button-container responsive-container'>
                  {isActive && (
                    <button className='button is-primary' onClick={invite}>
                      Invite people
                    </button>
                  )}
                  <button className='button is-secondary' onClick={close}>
                    Close popup
                  </button>
                </div>
              </div>
            </div>
          </InviteContext.Provider>
        </StyledModal.ContentCard>
      </div>
    </Modal>
  );
};

export { InviteMemberWrapper };
