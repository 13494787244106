import styled from 'styled-components';
import Banner from '../banner/Banner';
import * as StyledBanner from '../banner/Banner.styles';

export const LimitBanner = styled(Banner)`
  ${StyledBanner.Content} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a {
    height: 2.25rem;
  }
`;
