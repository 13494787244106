import React from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';
import { colors, variables } from '../styles/variables';

const StyledTooltip: React.FC<TooltipProps> = props => (
  <ReactTooltip
    effect='solid'
    backgroundColor={colors.white}
    textColor={variables.textColorLight}
    place='bottom'
    className='tooltip'
    {...props}>
    {props.children}
  </ReactTooltip>
);

export default StyledTooltip;
