import styled from 'styled-components';
import { StyledCommon } from '../../styles/common/Common.styles';

export const Wrapper = styled(StyledCommon.Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 32rem;
`;

export const Buttons = styled.div`
  justify-content: center;
`;
