import React, { useEffect, useState } from 'react';
import {
  loadHistoryForActivities,
  loadMetricsForActivities,
} from '../services/statistics-service';
import '../styles/components/SingleContent.scss';
import { loadActivity } from '../services/activities-service';
import { useParams, NavLink, Redirect } from 'react-router-dom';
import Header from './Header';
import ActivityTimelineChart from './ActivityTimelineChart';
import DashboardStatisticCards from './DashboardStatisticCards';
import ActivityHighlightsChart from './single-content/ActivityHighlightsChart';
import ActivityHighlightsRegionMap from './ActivityHighlightsRegionMap';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { ChevronForward, HelpCircle, Open } from '@styled-icons/ionicons-solid';
import { useSelector } from 'react-redux';
import { USER_PLAN } from '../services/user-service';
import { RootState } from '../store/reducers';
import PremiumTeaser from './payment/PremiumTeaser';
import contentSeenByImage from '../assets/images/premium-teaser/content-seen-by.png';
import contentSeenByRegionImage from '../assets/images/premium-teaser/content-seen-by-region.png';
import { colors } from '../styles/variables';
import StyledTooltip from './StyledTooltip';
import { useGroupName } from '../hooks/useGroupName';
import DateRangePicker from './header/DateRangePicker';

const SingleContent = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { startDate, endDate } = useSelector(
    (state: RootState) => state.dateRangeFilter
  );
  const { selected: account } = useSelector(
    (state: RootState) => state.linkedAccounts
  );
  const { accountId, groupId, activityId } = useParams<{
    accountId: string;
    groupId?: string;
    activityId: string;
  }>();
  const [isData, setIsData] = useState(true);

  const { groupName } = useGroupName(groupId || '');

  const loadHistory = () =>
    loadHistoryForActivities([activityId], startDate, endDate);
  const loadMetrics = () =>
    loadMetricsForActivities([activityId], startDate, endDate);

  const {
    data: activity,
    isLoading: isLoadingActivity,
    refetch: refetchActivity,
    isFetching: isActivityFetching,
  } = useQuery([activityId, 'loadActivity'], loadActivity, { cacheTime: 0 });
  const {
    data: history,
    isLoading: isLoadingHistory,
    refetch: refetchHistory,
    isFetching: isHistoryFetching,
  } = useQuery([activityId, 'loadHistoryForActivities'], loadHistory, {
    cacheTime: 0,
  });
  const {
    data: metrics,
    isLoading: isLoadingMetrics,
    refetch: refetchMetrics,
    isFetching: isMetricsFetching,
  } = useQuery([activityId, 'loadMetricsForActivities'], loadMetrics, {
    cacheTime: 0,
  });

  const [isLoading, setIsLoading] = useState(
    isLoadingActivity ?? isLoadingHistory ?? isLoadingMetrics
  );

  useEffect(() => {
    setIsData(
      Object.values(metrics?.statistics || {}).filter(stat => stat > 0).length >
        0
    );
  }, [metrics]);

  useEffect(() => {
    refetchActivity();
    refetchHistory();
    refetchMetrics();
  }, [startDate, endDate, refetchActivity, refetchHistory, refetchMetrics]);

  useEffect(() => {
    setIsLoading(isActivityFetching ?? isHistoryFetching ?? isMetricsFetching);
  }, [isActivityFetching, isHistoryFetching, isMetricsFetching]);

  if (activity?.account && activity.account.id !== accountId) {
    const redirectURL = groupId
      ? `/account/${accountId}/group/${groupId}/analyze/content`
      : `/account/${accountId}/analyze/content`;
    return <Redirect to={redirectURL} />;
  }

  return (
    <div className='SingleContent'>
      <Header
        content={<DateRangePicker />}
        title={
          activity &&
          accountId && (
            <>
              {groupId ? (
                <>
                  <span>Group</span>
                  <ChevronForward className='icon arrow' />
                  <NavLink
                    to={`/account/${accountId}/group/${groupId}/analyze/content`}
                  >
                    {groupName}
                  </NavLink>
                </>
              ) : (
                <NavLink to={`/account/${accountId}/analyze/content`}>
                  Content
                </NavLink>
              )}
              <ChevronForward className='icon arrow' />
              <span>{`"${activity.content
                ?.substring(0, 20)
                .concat('...')}"`}</span>
            </>
          )
        }
        loading={isLoading}
      />
      <div className='section wrapper'>
        <div className='info-wrapper'>
          {account && activity && (
            <>
              <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
                Your post
              </h5>
              <div className='box'>
                {activity.image && (
                  <div className='image'>
                    <img src={activity.image} alt='Content' />
                  </div>
                )}
                <div className='name'>
                  {account.profile.firstName} {account.profile.lastName}
                </div>
                <div className='occupation'>{account.profile.occupation}</div>
                <div className='post-date'>
                  {format(
                    activity.publicationDate ?? new Date(0),
                    'yyyy-MM-dd HH:mm'
                  )}
                </div>
                <a
                  className='button is-secondary'
                  href={`https://www.linkedin.com/feed/update/${activity.urn}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span>View on LinkedIn</span>
                  <span className='icon'>
                    <Open size='1rem' />
                  </span>
                </a>
                <hr />
                <div className='content'>{activity.content}</div>
              </div>
            </>
          )}
        </div>
        <div className='stats-wrapper'>
          {metrics && (
            <section className='section'>
              <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
                Statistics
              </h5>
              <DashboardStatisticCards
                statistics={metrics.statistics}
                cardFilter={['views', 'impressions']}
              />
              <DashboardStatisticCards
                statistics={metrics.statistics}
                cardFilter={['likes', 'comments', 'engagement']}
                extraClassName='margin-top-1rem'
              />
            </section>
          )}
          {history && (
            <section className='section'>
              <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
                Timeline
              </h5>
              <ActivityTimelineChart
                brushEnabled={true}
                timeline={history}
                isGroup={false}
              />
            </section>
          )}
          {isData && (
            <>
              <section className='section'>
                <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
                  <span>People viewing your content - by company</span>
                  <HelpCircle
                    size='1.5rem'
                    className='ml-2'
                    color={colors.grey}
                    data-for='styled-tooltip-single-content'
                    data-tip='We are able to display the top 9 statistics for this post.'
                  />
                </h5>
                <div className='box'>
                  {user!.plan === USER_PLAN.FREE ? (
                    <PremiumTeaser
                      requiredPlan={USER_PLAN.SOLO}
                      image={contentSeenByImage}
                    />
                  ) : (
                    <ActivityHighlightsChart
                      highlights={activity?.highlights?.company}
                    />
                  )}
                </div>
              </section>
              <section className='section'>
                <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
                  <span>People viewing your content - by job position</span>
                  <HelpCircle
                    size='1.5rem'
                    className='ml-2'
                    color={colors.grey}
                    data-for='styled-tooltip-single-content'
                    data-tip='We are able to display the top 9 statistics for this post.'
                  />
                </h5>
                <div className='box'>
                  {user!.plan === USER_PLAN.FREE ? (
                    <PremiumTeaser
                      requiredPlan={USER_PLAN.SOLO}
                      image={contentSeenByImage}
                    />
                  ) : (
                    <ActivityHighlightsChart
                      highlights={activity?.highlights?.occupation}
                    />
                  )}
                </div>
              </section>
              <section className='section'>
                <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
                  <span>People viewing your content - by region</span>
                  <HelpCircle
                    size='1.5rem'
                    className='ml-2'
                    color={colors.grey}
                    data-for='styled-tooltip-single-content'
                    data-tip='We are able to display the top 9 statistics for this post.'
                  />
                </h5>
                <div className='box'>
                  {user!.plan === USER_PLAN.FREE ? (
                    <PremiumTeaser
                      requiredPlan={USER_PLAN.SOLO}
                      image={contentSeenByImage}
                    />
                  ) : (
                    <ActivityHighlightsChart
                      highlights={activity?.highlights?.region}
                    />
                  )}
                </div>
              </section>
              <section className='section'>
                <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
                  <span>People viewing your content - by country</span>
                  <HelpCircle
                    size='1.5rem'
                    className='ml-2'
                    color={colors.grey}
                    data-for='styled-tooltip-single-content'
                    data-tip='We are able to display the top 9 statistics for this post.'
                  />
                </h5>
                <div className='box'>
                  {user!.plan === USER_PLAN.FREE ? (
                    <PremiumTeaser
                      requiredPlan={USER_PLAN.SOLO}
                      image={contentSeenByRegionImage}
                    />
                  ) : (
                    <ActivityHighlightsRegionMap
                      highlights={activity?.highlights?.region}
                    />
                  )}
                </div>
              </section>
            </>
          )}
        </div>
      </div>
      <StyledTooltip id='styled-tooltip-single-content' />
    </div>
  );
};

export default SingleContent;
