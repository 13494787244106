import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import * as Styled from './OnlyLoggedXStyles.styles';

const OnlyLoggedInRoute: React.FC = props => {
  const location = useLocation();
  const { user } = useSelector((state: RootState) => state.auth);

  let redirectUrl: string = '/register';
  if (location.pathname.length && location.pathname !== '/') {
    redirectUrl = `${redirectUrl}?redirect=${location.pathname}`;
  }

  if (user === undefined) {
    // User is not yet loaded
    return (
      <Styled.Wrapper>
        <Styled.Loader active />
        <Styled.Text>Loading...</Styled.Text>
      </Styled.Wrapper>
    );
  }
  if (user === null) {
    // User is not logged in
    return <Redirect to={redirectUrl}></Redirect>;
  } else {
    // User is logged in
    return <>{props.children}</>;
  }
};

export default OnlyLoggedInRoute;
