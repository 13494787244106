import React, { useState } from 'react';

import { GroupOfferViewModel } from './SecurityGroup';
import { FlaskIcon, PersonIcon, ShieldCheckmarkIcon } from '../CustomIcons';
import { GroupUserRoles } from '../accountSharing/AccountSharingModal';
import { acceptGroupOffer, declineGroupOffer } from '../../services/groups-service';

const ROLE_ICONS = {
  [GroupUserRoles.User]: <PersonIcon />,
  [GroupUserRoles.Analyst]: <FlaskIcon />,
  [GroupUserRoles.Admin]: <ShieldCheckmarkIcon />,
};

interface Props {
  data: GroupOfferViewModel;
}

export const JoinGroupRequest: React.FC<Props> = ({ data }) => {
  const { id, firstName, lastName, role, type: requestType, avatar, groupName } = data;

  const [isExpanded, setExpanded] = useState(false);

  return (
    <div className='security-settings-connection-request'>
      {isExpanded && (
        <h4 className='security-settings-title'>
          {requestType} to join {groupName}
        </h4>
      )}
      <div className='security-settings-shared'>
        <img
          className='security-settings-shared__profile-picture'
          src={avatar || '/assets/images/default-avatar.svg'}
          alt="user's avatar"
        />
        <p className='security-settings-shared__profile-name'>
          {firstName} {lastName}
        </p>
        <input
          type='checkbox'
          id={`security-settings-shared-expand-control-${id}`}
          name={`security-settings-expand-control-${id}`}
          className='security-settings-shared__expand-checkbox'
          onChange={() => setExpanded(!isExpanded)}
        />
        <label
          htmlFor={`security-settings-shared-expand-control-${id}`}
          className='security-settings-shared__expand-label'>
          See {requestType}ed permissions
        </label>
      </div>
      {isExpanded && (
        <>
          <div className='security-settings-shared-expand'>
            <p className='security-settings-shared-expand__note'>
              You can always change your settings later by navigating to your group settings.
            </p>
            <p className='security-settings-shared-expand__description'>
              {`${firstName} wants ${requestType === 'offer' ? 'you' : ''} to join as:`}
            </p>
            <div className='shared-areas'>
              <div className='shared-areas__item'>
                {ROLE_ICONS[role]}
                <span className='shared-areas__item-title shared-areas__item-title--capitalize'>
                  {role.toLowerCase()}
                </span>
              </div>
            </div>
            <a className='security-settings-shared-expand__a' href='https://www.inlytics.io/article/groups'>
              Learn more about roles in groups (Coming soon)
            </a>
          </div>
          <div className='security-settings-btn-group'>
            <button className='btn is-secondary' onClick={() => declineGroupOffer(id)}>
              Decline
            </button>
            <button className='btn is-primary' onClick={() => acceptGroupOffer(id)}>
              {requestType === 'offer' ? 'Accept' : 'Allow'}
            </button>
          </div>
        </>
      )}
    </div>
  );
};
