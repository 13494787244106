import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  AreaChart,
  BarChart,
  Bar,
  Brush,
} from 'recharts';
import { APIError } from '../../services/api-client';
import {
  FollowerSnapshot,
  getProfileFollowHistory,
} from '../../services/profile-service';
import { colors, variables } from '../../styles/variables';
import { getGroupFollowHistory } from '../../services/groups-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { ChartInfoBlock } from '../ChartInfoBlock';
import { dateFormatter } from './ConnectionTimeline';
import '../../styles/components/Profile.scss';
import { rangeFollowersChartData } from './filterRangeData';

export enum FollowersCsv {
  'Followers total' = 'Followers total',
  'Following total' = 'Following total',
  'Aggregated Followers' = 'Aggregated Followers',
}

interface Props {
  group?: boolean;
  brushEnabled?: boolean;
  onDataLoaded?: (data: any) => void;
}

interface IDataFollowing {
  Followers: IFollow['amountFollowers'];
  Following: IFollow['amountFollowing'];
}

interface IFollow {
  amountFollowers: number;
  amountFollowing: number;
}

interface IFollowEntries extends IFollow {
  createdAt: any;
}

const FollowerTimeline: React.VFC<Props> = ({
  group,
  brushEnabled,
  onDataLoaded,
}) => {
  const { accountId, groupId } = useParams<{
    accountId: string;
    groupId: string;
  }>();
  const [followReChartData, setFollowReChartData] = useState<any>([]);
  const { startDate, endDate } = useSelector(
    (state: RootState) => state.dateRangeFilter
  );
  const { selected, groupsConnections } = useSelector(
    (state: RootState) => state.linkedAccounts
  );

  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(0);

  const { data: followHistory } = useQuery<
    | Array<Omit<FollowerSnapshot, 'accountId'>>
    | Array<Omit<FollowerSnapshot, 'groupId'>>,
    APIError,
    [string, string]
  >(
    [
      accountId,
      groupId,
      startDate,
      endDate,
      'getProfileFollowHistory',
      'getGroupFollowHistory',
    ],
    accountId => {
      return group
        ? getGroupFollowHistory(groupId, startDate, endDate)
        : getProfileFollowHistory(accountId);
    }
  );

  useEffect(() => {
    if (!followHistory) {
      return;
    }
    const sortedFollowHistoryEntries = followHistory.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

    let previous: IFollowEntries;

    const newData = sortedFollowHistoryEntries.map(value => {
      if (!previous) {
        previous = value;
      }
      const toReturn: IDataFollowing = {
        Followers:
          value?.amountFollowers >= previous?.amountFollowers
            ? value?.amountFollowers - previous?.amountFollowers
            : 0,
        Following:
          value?.amountFollowing >= previous?.amountFollowing
            ? value?.amountFollowing - previous?.amountFollowing
            : 0,
      };
      previous = value;
      return {
        ...toReturn,
        timestamp: format(new Date(value.createdAt), 'yyyy-MM-dd'),
      };
    });

    setFollowReChartData(newData);
    setStartIndex(0);
    setEndIndex(newData.length - 1);
  }, [followHistory]);

  const handleUpdate = ({
    startIndex,
    endIndex,
  }: {
    startIndex: number;
    endIndex: number;
  }) => {
    setStartIndex(startIndex);
    setEndIndex(endIndex);
  };

  const percentageForFollowers = useMemo(() => {
    if (!followReChartData?.length || followReChartData.length === 1) {
      return { increase: 0, percentage: 0 };
    }
    const copy = [...followReChartData.slice(startIndex, endIndex + 1)];
    let [last, previous] = [copy.pop(), copy.pop()];

    while (copy.length && previous?.timestamp === last?.timestamp) {
      previous = copy.pop();
    }
    const percentage =
      (last?.Followers &&
        previous?.Followers &&
        ((last.Followers - previous.Followers) / previous.Followers) * 100) ||
      0;

    const increase =
      followReChartData
        .filter(({ timestamp }: { timestamp: string }) => {
          return (
            new Date(timestamp) >= startDate && new Date(timestamp) <= endDate
          );
        })
        .slice(startIndex, endIndex + 1)
        .reduce((gen: any, curr: any) => {
          return (gen += curr.Followers);
        }, 0) || 0;

    if (onDataLoaded) {
      if (groupId) {
        const amount = groupsConnections?.reduce(
          (sum, curr) =>
            groupId === curr.connectionId
              ? (sum += curr?.amountFollowers)
              : sum,
          0
        );
        onDataLoaded({ [FollowersCsv['Aggregated Followers']]: amount });
      } else {
        onDataLoaded({
          [FollowersCsv['Followers total']]: selected?.amountFollowers,
          [FollowersCsv['Following total']]: selected?.amountFollowing,
        });
      }
    }
    return { increase, percentage };
  }, [
    followReChartData,
    onDataLoaded,
    groupId,
    startIndex,
    endIndex,
    selected,
    groupsConnections,
    endDate,
    startDate,
  ]);

  const percentageForFollowing = useMemo(() => {
    if (!followReChartData?.length || followReChartData.length === 1) {
      return { increase: 0, percentage: 0 };
    }

    const copy = [...followReChartData.slice(startIndex, endIndex + 1)];
    let [last, previous] = [copy.pop(), copy.pop()];

    while (copy.length && previous?.timestamp === last?.timestamp) {
      previous = copy.pop();
    }
    const percentage =
      (last?.Following &&
        previous?.Following &&
        ((last.Following - previous.Following) / previous.Following) * 100) ||
      0;

    const increase =
      followReChartData
        .filter(({ timestamp }: { timestamp: string }) => {
          return (
            new Date(timestamp) >= startDate && new Date(timestamp) <= endDate
          );
        })
        .slice(startIndex, endIndex + 1)
        .reduce((gen: any, curr: any) => {
          return (gen += curr.Following);
        }, 0) || 0;

    return { increase, percentage };
  }, [followReChartData, startIndex, endIndex, startDate, endDate]);

  const selectedChartData = rangeFollowersChartData(
    followReChartData,
    startDate,
    endDate
  );

  return (
    <div className='info-block'>
      <div className='percentageWrapper'>
        <div className='item'>
          <ChartInfoBlock
            title={!group && 'Followers'}
            number={percentageForFollowers.increase}
            percentage={percentageForFollowers.percentage}
          />
        </div>
        <div className='item ml-6'>
          <ChartInfoBlock
            title={group ? '' : 'Following'}
            number={percentageForFollowing.increase}
            percentage={percentageForFollowing.percentage}
          />
        </div>
      </div>
      <ResponsiveContainer height={400}>
        <AreaChart
          data={
            brushEnabled
              ? selectedChartData.slice(startIndex, endIndex + 1)
              : selectedChartData
          }
        >
          <defs>
            <linearGradient id='gradient-followers' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor={colors.pink} stopOpacity={0.8} />
              <stop offset='100%' stopColor={colors.pink} stopOpacity={0.05} />
            </linearGradient>
            <linearGradient id='gradient-following' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor={colors.orange} stopOpacity={0.8} />
              <stop
                offset='100%'
                stopColor={colors.orange}
                stopOpacity={0.05}
              />
            </linearGradient>
          </defs>

          {/*<CartesianGrid />*/}
          <XAxis dataKey='timestamp' tickLine={false} axisLine={false} />
          <YAxis tickLine={false} axisLine={false} />
          <Tooltip />
          <Area
            type='monotone'
            dataKey='Followers'
            stroke={colors.pink}
            fill='url(#gradient-followers)'
          />
          <Area
            type='monotone'
            dataKey='Following'
            stroke={colors.orange}
            fill='url(#gradient-following)'
          />
        </AreaChart>
      </ResponsiveContainer>

      {brushEnabled && !!followReChartData?.length && (
        <div className='chart-container'>
          <ResponsiveContainer height={60} className='chart-base'>
            <BarChart data={selectedChartData}>
              <YAxis
                yAxisId='Followers'
                tickLine={false}
                axisLine={false}
                tick={false}
              />

              <Bar
                yAxisId='Followers'
                dataKey='Followers'
                stroke={variables.lightGrey}
                fill={variables.lightGrey}
                isAnimationActive={false}
              />
            </BarChart>
          </ResponsiveContainer>
          <ResponsiveContainer height={60} className='chart-brush'>
            <BarChart data={selectedChartData}>
              <YAxis yAxisId='Connections' tick={false} />

              <Brush
                startIndex={startIndex}
                endIndex={endIndex}
                height={60}
                fill='rgba(0, 0, 0, 0)'
                stroke={colors.orange}
                onChange={handleUpdate}
                dataKey='timestamp'
                tickFormatter={dateFormatter}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default FollowerTimeline;
