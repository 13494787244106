import React, { useState } from "react";
import { Styled as StyledModal } from "../../../components/modal/Modal.styles";
import { AccountListTypes } from "../AccountList";
import {
  CompanyUserRoles,
  GroupUserRoles
} from "../../accountSharing/AccountSharingModal";
import { Trash } from "@styled-icons/ionicons-solid";
import "./DeleteModal.scss";
import { getCompanies } from "../../../services/company-service";

interface Props {
  id: string;
  type: AccountListTypes | "Audience";
  label?: "remove" | "unlink";
  onAccept: (id: string, label?: "remove" | "unlink") => void;
  onClose: () => void;
  role?: GroupUserRoles | CompanyUserRoles;
}

const DeleteModal: React.FC<Props> = ({
  id,
  type,
  label,
  onAccept,
  onClose,
  role
}) => {
  const [disabledBtn, setDisabledBtn] = useState(false);
  const headerText = {
    [`${AccountListTypes.Own} remove`]: "Delete Account?",
    [`${AccountListTypes.Shared} unlink`]: "Unlink Account?",
    [`${AccountListTypes.Group} remove`]: "Leave Group?",
    [`${AccountListTypes.Group} unlink`]: "Unlink & Leave Group?",
    [`${AccountListTypes.Company} unlink`]: "Unlink & Leave Company?",
    [`${AccountListTypes.Company} remove`]: "Sure To Delete This Company Page?",
    [`Audience remove`]: "Sure To Remove This Audience?"
  };

  const contentText = {
    [`${AccountListTypes.Own} remove`]: "This will remove your account and any associated personal data. Any subscriptions associated with your account will be cancelled. This action is permanent. We cannot restore any data once your account was deleted.",
    [`${AccountListTypes.Shared} unlink`]: "Unlinking this account will remove your access to this account.",
    [`${AccountListTypes.Group} remove`]: "When you leave this group it won`t appear in your dashboard anymore and you are removed from the group. If you are the only admin in the group, the entire group gets deleted. Are you sure to continue?",
    [`${AccountListTypes.Group} unlink`]: "Unlinking your account will remove you from the group - your data captured to this point will still be visible for other members of the group. This action cannot be undone.",
    [`${AccountListTypes.Company} unlink`]: "Unlinking this account will remove your access to this account.",
    [`${AccountListTypes.Company} remove`]: "This action will remove this linked company page and delete all the stats forever. This action can’t be undone.",
    [`Audience remove`]: (
      <span>
        This action will remove this audience from your account. Keep in mind
        that{" "}
        <span className="has-text-weight-bold">
          you won’t be able to create another audience in this slot for 30 days!
        </span>{" "}
        Learn more{" "}
        <a href="https://www.inlytics.io/article/missing-data">here</a>.
      </span>
    )
  };

  const acceptButtonText = {
    [`${AccountListTypes.Own} remove`]: "Yes, delete my account",
    [`${AccountListTypes.Shared} unlink`]: "Yes, unlink this account",
    [`${AccountListTypes.Group} remove`]: "Yes, remove this group",
    [`${AccountListTypes.Group} unlink`]: "Yes, leave this group",
    [`${AccountListTypes.Company} unlink`]: "Yes, leave this company",
    [`${AccountListTypes.Company} remove`]: "Yes, delete this company",
    [`Audience remove`]: "Remove Audience - wait 30 days"
  };

  const deleteHandler = async (e: React.MouseEvent) => {
    e.preventDefault();

    setDisabledBtn(true);
    onAccept(id, label);
    await getCompanies();
    onClose();
  };

  return (
    <StyledModal.ContentCard>
      <StyledModal.Headline>
        {headerText[`${type} ${label || ""}`]}
      </StyledModal.Headline>

      <p className="has-text-grey mb-4">
        {contentText[`${type} ${label || ""}`]}
      </p>

      <div className="level button-container">
        <button
          className="button is-danger level-right"
          onClick={deleteHandler}
          disabled={disabledBtn}
        >
          {(type === AccountListTypes.Own || role === GroupUserRoles.Admin) && (
            <span className="icon">
              <Trash size="1rem" />
            </span>
          )}
          <span>{acceptButtonText[`${type} ${label || ""}`]}</span>
        </button>
        <button className="button is-secondary" onClick={onClose}>
          No, go back
        </button>
      </div>
    </StyledModal.ContentCard>
  );
};

export { DeleteModal };
