import {
  Activity,
  ActivityCompanyHighlight,
  ActivityHighlights,
  ActivityOccupationHighlight,
  ActivityRegionHighlight,
} from './activities-service';
import { StatisticsWithRelativeColor } from '../components/ContentTable';
import { LinkedInAccount } from './account-service';
import { GroupMemberCardEntry } from './groups-service';
import { calculateEngagementRate } from './statistics-service';

export interface IProfileConverter {
  ['Profile views']: number;
  ['Profile views last 90 days']: number;
  ['Search Appearance last week']: number;
  ['Connections in total']: number;
  ['New connections last week']: number;
  ['Followers total']: number;
  ['Following total']: number;
}

export const profileConverter = (data: IProfileConverter, group: boolean) => {
  const toReturn: (string | number)[][] = [];

  toReturn.push(Object.keys(data));
  toReturn.push(Object.values(data));

  return toReturn;
};

export interface IContentConverter {
  Activities: {
    activity: Activity;
    statistics: StatisticsWithRelativeColor;
    user?: LinkedInAccount;
  }[];

  Highlights: ActivityHighlights;
}

enum highlightsKeys {
  company = 'name',
  occupation = 'title',
  region = 'region',
}

export const contentConverter = (
  { Highlights, Activities }: IContentConverter,
  group: boolean
) => {
  const toReturn: (string | number)[][] = [];
  const activitiesKeys = [
    'Media',
    'Type',
    'Text',
    'Link',
    'Date',
    'Impressions',
    'Views',
    'Likes',
    'Comments',
    'Engagement Rate',
  ];

  if (group) {
    activitiesKeys.unshift('People');
  }
  toReturn.push(activitiesKeys);
  Activities?.forEach(({ activity, statistics, user }) => {
    let toPushPeople: { people: string } | {} = group
      ? { people: `${user?.profile.firstName} ${user?.profile.lastName}` }
      : {};
    let toPush = {
      media: activity.image ? 'yes' : 'no',
      type: activity.type,
      text: activity.content.replace(/\n|\r|\t|"/gi, ' '),
      link: `https://www.linkedin.com/feed/update/${activity.urn}`,
      date: activity.publicationDate?.toDateString() || '',
      views: activity.amountViews,
      impressions: activity.amountImpressions || 0,
      likes: activity.amountLikes,
      comments: activity.amountComments,
      engagement: `${(statistics.engagementRate * 100).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }
      )}%`,
    };
    toReturn.push(Object.values(Object.assign(toPushPeople, toPush)));
  });

  toReturn.push([]);

  Object.keys(Highlights).forEach((key: string) => {
    toReturn.push([key, 'amount']);
    // @ts-ignore
    const toPush = Highlights[key].reduce(
      (
        general: (string | number)[],
        curr:
          | ActivityCompanyHighlight
          | ActivityRegionHighlight
          | ActivityOccupationHighlight
      ) => {
        // @ts-ignore
        general.push([curr[highlightsKeys[key]], curr.amountViews]);
        return general;
      },
      []
    );
    toReturn.push(...toPush, []);
  });

  return toReturn;
};

export interface IGMembersConverter {
  ['Aggregated Profile Views']: number;
  ['Aggregated Followers']: number;
  ['Group Members']: number;
  Members: GroupMemberCardEntry[];
}

const keys: (keyof Omit<IGMembersConverter, 'Members'>)[] = [
  'Aggregated Profile Views',
  'Aggregated Followers',
  'Group Members',
];
const membersKeys = [
  'Person',
  'Followers',
  'Connections',
  'Profile View',
  'Content posted',
  'Content planned',
  'Engagement Rate',
];

export const groupMembersConvertor = (data: IGMembersConverter) => {
  const toReturn: (string | number)[][] = [];

  toReturn.push(keys);
  const toPushValues = keys.reduce((general, curr) => {
    general.push(data[curr]);
    return general;
  }, [] as number[]);
  toReturn.push(toPushValues, []);

  toReturn.push(membersKeys);
  data.Members?.forEach(curr => {
    const obj = {
      people: `${curr.profile.profile.firstName} ${curr.profile.profile.lastName}`,
      followers: curr.followers.pop()?.amountFollowers || 0,
      connections: curr.connections.filter(item => item.urn).length,
      profileVies: curr.views.pop()?.numViews || 0,
      contentPosted: curr.activities.length,
      contentPlanned: curr.activities.plannedLength,
      engagement: `${calculateEngagementRate(
        curr.activities.statistics
      ).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}%`,
    };
    toReturn.push(Object.values(obj));
  });
  toReturn.push([]);

  data.Members?.forEach(member => {
    toReturn.push([
      `${member.profile.profile.firstName} ${member.profile.profile.lastName}`,
    ]);
    Object.keys(member.highlights).forEach((key: string) => {
      toReturn.push([key, 'amount']);
      // @ts-ignore
      const toPush = member.highlights[key].reduce(
        (
          general: (string | number)[],
          curr:
            | ActivityCompanyHighlight
            | ActivityRegionHighlight
            | ActivityOccupationHighlight
        ) => {
          // @ts-ignore
          general.push([curr[highlightsKeys[key]], curr.amountViews]);
          return general;
        },
        []
      );
      toReturn.push(...toPush, []);
    });
    toReturn.push([]);
  });

  return toReturn;
};

export type TypeCSV =
  | typeof profileConverter
  | typeof contentConverter
  | typeof groupMembersConvertor;
