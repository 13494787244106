import React, { useEffect, useState } from 'react';
import Styled from './MediaUploadDroparea.styles';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import {
  uploadMediaFiles,
  Media,
  MediaUploadError,
  mediaUpload,
  mediaUploadToBucket,
} from '../../services/media-service';
import { useMutation } from 'react-query';
import { APIError } from '../../services/api-client';

export interface MediaUploadDropareaProps {
  onNewMediaFiles: (media: Media[]) => void;
  media: Media[];
  setIsMediaLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const MAX_VIDEO_SIZE = 200 * 1024 * 1024;
const MAX_IMAGE_SIZE = 8 * 1024 * 1024;

const MediaUploadDroparea: React.FC<MediaUploadDropareaProps> = ({
  onNewMediaFiles,
  media,
  setIsMediaLoading,
}) => {
  const [errors, setErrors] = useState<string[]>([]);

  const [uploadMediaFilesMutation, { error, isLoading }] = useMutation<
    Array<Media | MediaUploadError>,
    APIError,
    File[]
  >(uploadMediaFiles, {
    onSuccess: data => {
      const uploadedFiles = data.filter(
        (entry): entry is Media => !entry.hasOwnProperty('error')
      );
      if (uploadedFiles.length) {
        onNewMediaFiles(uploadedFiles);
      }
      const errors = data.filter((entry): entry is { error: string } =>
        entry.hasOwnProperty('error')
      );
      if (errors.length) {
        setErrors(err => [...err, ...errors.map(e => e.error)]);
      }
    },
  });

  useEffect(() => {
    setIsMediaLoading(isLoading);
  }, [isLoading, setIsMediaLoading]);

  const handleOnDropRejected = (rejections: FileRejection[]) => {
    setErrors(err => [
      ...err,
      ...rejections.map(
        rejection =>
          `'${rejection.file.name}': ${rejection.errors.reduce(
            (gen, curr) => (gen += `${curr?.message}. `),
            ''
          )}`
      ),
    ]);
  };

  const handleDropEvent = async (e: DropEvent) => {
    const files = [];
    let fileList = [];
    if ('dataTransfer' in e || 'target' in e) {
      //@ts-ignore
      fileList = e.dataTransfer ? e.dataTransfer.files : e.target?.files;
    }
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);

      if (file.type.includes('video')) {
        if (file.size < MAX_VIDEO_SIZE) {
          files.push(file);
        } else {
          setErrors(prevErrors => [
            ...prevErrors,
            'Uploaded video is too large. Max size for videos is 200MB',
          ]);
        }
      }

      if (file.type.includes('image')) {
        if (file.size < MAX_IMAGE_SIZE) {
          files.push(file);
        } else {
          setErrors(prevErrors => [
            ...prevErrors,
            'Uploaded file is too large. Max size for images is 8MB',
          ]);
        }
      }
    }
    return files;
  };

  const handleUploadFiles = (files: File[]) => {
    files.forEach((file, index) => {
      if (file.type !== 'application/unknown') {
        mediaUpload(file).then(res => {
          const { publicUrl, media } = res.data;
          mediaUploadToBucket(publicUrl, files[index]).then(r => {
            onNewMediaFiles([media]);
          });
        });
      } else {
        uploadMediaFilesMutation([file]);
      }
    });
  };
  const VALID_MIME_TYPES = [
    'video/mp4',
    'image/gif',
    'image/jpeg',
    'image/jpg',
    'image/png',
    'application/pdf',
    'application/msword', //.doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //.docx
    'application/vnd.ms-powerpoint', //.ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', //.pptx
  ];

  return (
    <div>
      <Styled.DropzoneWrapper>
        {isLoading && <Styled.LoadingIndicator active={true} />}
        <Dropzone
          getFilesFromEvent={handleDropEvent}
          onDropAccepted={files => {
            handleUploadFiles(files);
          }}
          onDropRejected={handleOnDropRejected}
          accept={VALID_MIME_TYPES}
          multiple={true}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Styled.Dropzone>
                  Drag &amp; drop or click to upload files!
                </Styled.Dropzone>
              </div>
            </section>
          )}
        </Dropzone>
      </Styled.DropzoneWrapper>
      {error && (
        <p className='notification is-danger is-light'>{error.message}</p>
      )}
      {errors.length > 0 && (
        <p className='notification is-danger is-light'>{errors.join('. ')}</p>
      )}
    </div>
  );
};

export default MediaUploadDroparea;
