import { Megaphone } from '@styled-icons/ionicons-solid';
import React, { useEffect } from 'react';
import useScript from '../../hooks/useScript';
import { colors } from '../../styles/variables';
import * as Styled from './Headway.styles';
import './Headway.scss';

const Headway = () => {
  const script = useScript('https://cdn.headwayapp.co/widget.js');

  useEffect(() => {
    if (!script) {
      return;
    }
    script.addEventListener('load', () => {
      window.Headway.init({
        selector: '#headway-container',
        account: '7zX3Gy',
      });
    });
  }, [script]);

  return (
    <Styled.Container id='headway-container'>
      <Megaphone size='1.5rem' color={colors.grey} />
    </Styled.Container>
  );
};

export default Headway;
