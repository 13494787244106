import {
  Activity,
  ActivityHighlights,
  ActivityNote,
} from './activities-service';
import { roundToNearestMinutes } from 'date-fns';
import apiClient from './api-client';
import { LinkedInAccount } from './account-service';
import cities from './countries.json';
export interface Statistics {
  amountComments: number;
  amountLikes: number;
  amountViews: number;
  amountImpressions: number;
  amountShares: number;
  amountReactionType: AmountReactionType[];
}

export interface StatisticsSnapshot extends Statistics {
  timestamp: number; // Timestamp of query
}

export interface AmountReactionType {
  type: string; // e.g. 'LIKE', 'PRAISE' or 'INTEREST'
  count: number;
}

export async function loadHistoryForAccount(
  accountId: string,
  startDate?: Date,
  endDate?: Date
): Promise<StatisticsSnapshot[]> {
  const { data } = await apiClient.get<{ timeline: StatisticsSnapshot[] }>(
    `/statistics/history/account`,
    {
      params: {
        accountId,
        startDate: startDate ? roundDate(startDate) : null,
        endDate: endDate ? roundDate(endDate) : null,
      },
    }
  );
  return data['timeline'];
}

export async function loadHistoryForGroup(
  accountId: string,
  groupId: string,
  startDate?: Date,
  endDate?: Date
): Promise<StatisticsSnapshot[]> {
  const { data } = await apiClient.get<StatisticsSnapshot[]>(
    `/statistics/history/group/${groupId}`,
    {
      params: {
        accountId,
        startDate: startDate ? roundDate(startDate) : null,
        endDate: endDate ? roundDate(endDate) : null,
      },
    }
  );

  return data;
}

export async function loadHistoryForActivities(
  activities: string[],
  startDate?: Date,
  endDate?: Date
): Promise<StatisticsSnapshot[]> {
  const { data } = await apiClient.get<{ timeline: StatisticsSnapshot[] }>(
    `/statistics/history/activities`,
    {
      params: {
        activities,
        startDate: startDate ? roundDate(startDate) : null,
        endDate: endDate ? roundDate(endDate) : null,
      },
    }
  );
  return data['timeline'];
}

export function agregateStatistics(generalStats: any): Statistics {
  return {
    amountComments: generalStats.comments.increase,
    amountLikes: generalStats.likes.increase,
    amountViews: generalStats.views.increase,
    amountImpressions: generalStats.impressions.increase,
    amountShares: 0,
    amountReactionType: [],
  };
}

export async function loadMetricsForAccount(
  accountId: string,
  startDate?: Date,
  endDate?: Date
): Promise<{ statistics: Statistics; reference: Statistics }> {
  const { data } = await apiClient.get(`/statistics/metrics/account`, {
    params: {
      accountId,
      startDate: startDate ? roundDate(startDate) : null,
      endDate: endDate ? roundDate(endDate) : null,
    },
  });
  return data;
}

export async function loadMetricsForGroup(
  accountId: string,
  groupId: string,
  startDate?: Date,
  endDate?: Date
): Promise<{ statistics: Statistics; reference: Statistics }> {
  const { data } = await apiClient.get(`/statistics/metrics/group/${groupId}`, {
    params: {
      accountId,
      startDate: startDate ? roundDate(startDate) : null,
      endDate: endDate ? roundDate(endDate) : null,
    },
  });
  return data;
}

export async function loadMetricsForActivities(
  activities: string[],
  startDate?: Date,
  endDate?: Date
): Promise<{ statistics: Statistics; reference: Statistics }> {
  const { data } = await apiClient.get(`/statistics/metrics/activities`, {
    params: {
      activities,
      startDate: startDate ? roundDate(startDate) : null,
      endDate: endDate ? roundDate(endDate) : null,
    },
  });
  return data;
}

export type ActivityStatistics = {
  activity: Activity;
  statistics: Statistics;
  user: LinkedInAccount;
  email: string;
};

export async function loadActivityStatisticsForAccount(
  accountId: string,
  startDate?: Date,
  endDate?: Date
): Promise<{
  activities: ActivityStatistics[];
  highlights: ActivityHighlights;
}> {
  const { data } = await apiClient.get<{
    activities: ActivityStatistics[];
    highlights: ActivityHighlights;
  }>(`/statistics/activities/account`, {
    params: {
      accountId,
      startDate: startDate ? roundDate(startDate) : null,
      endDate: endDate ? roundDate(endDate) : null,
    },
  });
  return { activities: data.activities, highlights: data.highlights };
}

export async function loadActivitiesForGroup(
  accountId: string,
  groupId: string,
  startDate?: Date,
  endDate?: Date
): Promise<{
  activities: ActivityStatistics[];
  highlights: ActivityHighlights;
}> {
  const { data } = await apiClient.get(
    `/statistics/activities/group/${groupId}`,
    {
      params: {
        accountId,
        startDate: startDate ? roundDate(startDate) : null,
        endDate: endDate ? roundDate(endDate) : null,
      },
    }
  );

  return { activities: data.activities, highlights: data.highlights };
}

export async function loadNotesForGroup(
  accountId: string,
  groupId: string
): Promise<Map<string, ActivityNote>> {
  const {
    data: { notes },
  } = await apiClient.get<{ notes: ActivityNote[] }>(
    `/statistics/activities/group/${groupId}/notes`,
    {
      params: { accountId },
    }
  );
  return new Map(notes.map(n => [n.activity, n]));
}

export async function loadActivityStatisticsForActivities(
  activities: string[],
  startDate?: Date,
  endDate?: Date
): Promise<ActivityStatistics[]> {
  const { data } = await apiClient.get<{ activities: ActivityStatistics[] }>(
    `/statistics/activities/activities`,
    {
      params: {
        activities,
        startDate: startDate ? roundDate(startDate) : null,
        endDate: endDate ? roundDate(endDate) : null,
      },
    }
  );
  return data.activities;
}

export async function loadFullAccountCsv(
  accountId: string,
  startDate: Date,
  endDate: Date
): Promise<(string | number)[][]> {
  const { data } = await apiClient.get<string>('/statistics/csv', {
    params: {
      accountId,
      startDate: startDate ? roundDate(startDate) : null,
      endDate: endDate ? roundDate(endDate) : null,
    },
  });
  return JSON.parse(data);
}

export interface StatisticLike {
  amountViews: number;
  amountComments: number;
  amountLikes: number;
  amountShares: number;
}

export function calculateEngagementRate(statisticEntry: StatisticLike) {
  if (statisticEntry.amountViews === 0) {
    return 0;
  }
  const engagementRate =
    (statisticEntry.amountComments +
      statisticEntry.amountLikes +
      statisticEntry.amountShares) /
    statisticEntry.amountViews;
  return engagementRate > 0 ? engagementRate : 0;
}

function roundDate(date: Date) {
  return roundToNearestMinutes(date, { nearestTo: 10 });
}

export function getCountryFromCity(city: string): { countryName: string } {
  let cityName = city.split(' ')[0].replace(',', '');
  if (cityName.length < 3) {
    cityName = city;
  }

  const country = Object.entries(cities).find(country => {
    return country[1].filter(reg => reg.includes(cityName.trim())).length > 0;
  });

  return {
    countryName: country ? country[0] : '',
  };
}
