import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../Header';
import DashboardStatisticCards from '../../DashboardStatisticCards';
import { FollowersTimeline } from './FollowersTimeline';
import DateRangePicker from '../../header/DateRangePicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import {
  CompanyDemographics,
  getCompanyFollowers,
  getCompanyFollowersDemographics,
  ICompanyFollowersAnalytic,
  ICompanyFollowersSnapshot,
} from '../../../services/company-service';
import * as StyledLoading from '../../../styles/common/Loading.styles';
import { CompanyDemographic } from '../companyVisitors/CompanyDemographic';
import { InviteMemberWrapper } from '../../group/InviteMemberWrapper';

interface Props {}

const CompanyFollowers: React.FC<Props> = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const { inviteMemberModalOpen } = useSelector((state: RootState) => state.settings);

  const { startDate, endDate } = useSelector((state: RootState) => state.dateRangeFilter);
  const [isLoading, setIsLoading] = useState(false);
  const [followersData, setFollowersData] = useState<{
    analytics: ICompanyFollowersAnalytic;
    timeline: ICompanyFollowersSnapshot[];
  }>();
  const [demographicData, setDemographicData] = useState<CompanyDemographics>();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const [followers, demographicData] = await Promise.all([
          getCompanyFollowers({ id: companyId, startDate, endDate }),
          getCompanyFollowersDemographics({ id: companyId, startDate, endDate }),
        ]);
        setFollowersData(followers);
        setDemographicData(demographicData);
        setIsLoading(false);
      } catch (e) {
        console.log(e.message);
      }
    };
    getData().catch();
  }, [companyId, startDate, endDate]);

  return (
    <div className='Content CompanyVisitors'>
      <Header
        title={
          <>
            <span>Follower Analytics</span>
          </>
        }
        content={
          <>
            <DateRangePicker />
          </>
        }
        loading={isLoading}
      />

      {isLoading ? (
        <StyledLoading.LoadingContainer>
          <StyledLoading.LoadingBarSmall active />
        </StyledLoading.LoadingContainer>
      ) : (
        followersData && (
          <>
            <section className='section'>
              <DashboardStatisticCards
                totalFollowers={{
                  number: followersData.analytics.totalFollowers,
                  percentage: followersData.analytics.totalPercentage,
                }}
                newFollowers={followersData.analytics.newFollowers}
                cardFilter={['totalFollowers', 'newFollowers']}
              />
            </section>

            <section className='section'>
              <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>Follower Metrics</h5>
              <FollowersTimeline brushEnabled data={followersData.timeline} />
            </section>
          </>
        )
      )}

      {isLoading ? (
        <StyledLoading.LoadingContainer>
          <StyledLoading.LoadingBarSmall active />
        </StyledLoading.LoadingContainer>
      ) : (
        demographicData && (
          <section className='section'>
            <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>Followers Demographic</h5>
            <CompanyDemographic data={demographicData} />
          </section>
        )
      )}

      {inviteMemberModalOpen && <InviteMemberWrapper isCompany />}
    </div>
  );
};

export { CompanyFollowers };
