import apiClient from './api-client';
import { store } from '../store';
import { setMainAccount } from '../store/actions/linkedAccounts';
import { useQuery } from 'react-query';
import { USER_PLAN } from './user-service';
import { GroupTypes } from '../components/accountSharing/AccountSharingModal';

export interface LinkedInAccount {
  _id: string;
  authorizedUsers: string[];
  profile: {
    firstName: string;
    lastName: string;
    occupation: string;
    publicIdentifier: string;
    displayPhoto: {
      expires: Date;
      tiny: string;
      small: string;
      normal: string;
      big: string;
    };
  };
  amountProfileViews: number;
  amountSearchAppearances: number;
  amountFollowing: number;
  amountFollowers: number;
  createdAt: Date;

  // Client specific
  hasValidSession: boolean;
  email: string;
  newAccountConnections?: AccountConnection[];
}

export interface LinkedInAccountShared extends LinkedInAccount {
  isSharedAnalytics: boolean;
  isSharedSchedule: boolean;
  connectionId: string;
  plan: USER_PLAN;
}

export interface LinkedInCookie {
  accountId: string;
  li_at: string;
  JSESSIONID: string;
}

export interface AccountConnection {
  _id: string;
  analytics: boolean;
  analyticsConfirmed: boolean;
  schedule: boolean;
  scheduleConfirmed: boolean;
  basisType: 'PERSONAL' | 'GROUP';
  requestType: 'request' | 'offer';
  fromUser?: {
    profile: {
      _id: string;
      givenName: string;
      familyName: string;
      picture: string;
    };
    mainAccount?: LinkedInAccount;
    cookies?: number;
    plan: USER_PLAN;
    email: string;
  };
  toUser?: {
    profile: {
      _id: string;
      givenName: string;
      familyName: string;
      picture: string;
    };
    mainAccount?: LinkedInAccount;
    cookies?: number;
  };
}

export interface GroupConnection {
  _id: string;
  isSharedAnalytics: boolean;
  isSharedSchedule: boolean;
  group: {
    id: string;
    name: string;
    type: GroupTypes;
  };
  user: {
    _id: string;
    mainAccount: LinkedInAccount;
    cookies?: number;
    plan: USER_PLAN;
    email: string;
  };
}

export async function updateMainAccount() {
  const response = await apiClient.get<{ account: LinkedInAccount | null }>(`/accounts/main`);
  store.dispatch(setMainAccount(response.data.account));
  return response.data.account;
}

export async function loadAccount(accountId: string) {
  const res = await apiClient.get<LinkedInAccount>(`/profiles/${accountId}`);
  return res.data;
}

export function deleteMainAccount() {
  return apiClient.delete<void>(`/accounts/main`);
}

export function useMainAccount() {
  return useQuery(['updateMainAccount'], updateMainAccount, { staleTime: 0 });
}

export async function getInvitedUsers(accountId: string): Promise<{ invitedUsers: number; purchasedUsers: number }> {
  const res = await apiClient.get<{ invitedUsers: number; purchasedUsers: number }>(
    `/profiles/${accountId}/invited-users`
  );
  return res.data;
}
