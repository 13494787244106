import React, { SetStateAction, useEffect, useMemo, useState } from "react";

import {
  createSharedConnection,
  ShareConnectionResult
} from "../../services/profile-service";
import { createGroup } from "../../services/groups-service";

import "../../styles/components/accountSharing/AccountSharingModal.scss";
import ChooseConnectionType, { Creating } from "./ChooseConnectionType";
import ConnectSingleAccount from "./ConnectSingleAccount";
import AccountModalSuccess from "./AccountModalSuccess";
import CreateGroup from "./CreateGroup";
import InviteGroupMember from "./InviteGroupMember";
import { ConnectCompany } from "../company/connectCompany/ConnectCompany";
import {
  CompanySettings,
  createCompany,
  getCompanies
} from "../../services/company-service";
import InviteCompanyMember from "./InviteCompanyMember";

export interface IAccountSharingModalContext {
  creating: [
    Creating | undefined,
    React.Dispatch<SetStateAction<Creating | undefined>>
  ];
  givingAccess: [
    boolean | undefined,
    React.Dispatch<SetStateAction<boolean | undefined>>
  ];
  analyticsChecked: [
    boolean | undefined,
    React.Dispatch<SetStateAction<boolean | undefined>>
  ];
  scheduleChecked: [
    boolean | undefined,
    React.Dispatch<SetStateAction<boolean | undefined>>
  ];
  userInputData: [string, React.Dispatch<SetStateAction<string>>];
  groupName: [string, React.Dispatch<SetStateAction<string>>];
  groupImage: [
    File | undefined,
    React.Dispatch<SetStateAction<File | undefined>>
  ];
  groupType: [
    GroupTypes | undefined,
    React.Dispatch<SetStateAction<GroupTypes | undefined>>
  ];
  userGroupRole: [
    GroupUserRoles,
    React.Dispatch<SetStateAction<GroupUserRoles>>
  ];
  includeStatistics: [boolean, React.Dispatch<SetStateAction<boolean>>];
  connectedCompany: [
    CompanySettings[],
    React.Dispatch<SetStateAction<CompanySettings[]>>
  ];
  userCompanyRole: [
    CompanyUserRoles | undefined,
    React.Dispatch<SetStateAction<CompanyUserRoles | undefined>>
  ];
  companyLoading: [boolean, React.Dispatch<SetStateAction<boolean>>];
}

const AccountSharingModalContext = React.createContext<
  IAccountSharingModalContext
>({} as IAccountSharingModalContext);

export enum Steps {
  ChooseType = 0,
  One = 1,
  Two = 2,
  Success = 3
}

export enum GroupUserRoles {
  Admin = "ADMIN",
  Analyst = "ANALYST",
  User = "USER"
}

export enum GroupTypes {
  Analytics = "ANALYTICS",
  Schedule = "SCHEDULE"
}

export enum CompanyUserRoles {
  Admin = "ADMIN",
  Analyst = "ANALYST"
}

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

interface Props {
  setOpen: React.Dispatch<
    React.SetStateAction<
      { type: Steps; currentCreating: Creating; isOpened: boolean } | boolean
    >
  >;
  type: Steps;
  currentCreating: Creating;
}

const AccountSharingModal = (prop: Props) => {
  const [step, setStep] = useState<Steps>(prop.type);
  const [errors, setErrors] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const [currentCreating, setCurrentCreating] = useState<Creating | undefined>(
    prop.currentCreating
  );

  const [emailsInput, setEmailsInput] = useState<string>("");
  const [isAnalyticsChecked, setAnalyticsChecked] = useState<boolean>();
  const [isScheduleChecked, setScheduleChecked] = useState<boolean>();

  // SINGLE ACCOUNTS
  const [isGivingAccess, setIsGivingAccess] = useState<boolean>();

  // GROUPS
  const [groupName, setGroupName] = useState<string>("");
  const [groupImage, setGroupImage] = useState<File>();
  const [groupType, setGroupType] = useState<GroupTypes | undefined>(
    GroupTypes.Analytics
  );
  const [groupRole, setGroupRole] = useState<GroupUserRoles>(
    GroupUserRoles.User
  );
  const [isIncludeStatistics, setIncludeStatistics] = useState<boolean>(true);

  //COMPANY
  const [connectedCompany, setConnectedCompany] = useState<CompanySettings[]>(
    []
  );
  const [companyRole, setCompanyRole] = useState<CompanyUserRoles | undefined>(
    CompanyUserRoles.Analyst
  );
  const [chosenCompany, setChosenCompany] = useState("");

  const isActive = useMemo(() => {
    switch (step) {
      case Steps.ChooseType:
        return currentCreating !== null;
      case Steps.One:
        return currentCreating === Creating.Account
          ? typeof isGivingAccess === "boolean"
          : currentCreating === Creating.Group
          ? !!(groupName && groupType)
          : !!connectedCompany.length && chosenCompany;
      case Steps.Two:
        return currentCreating
          ? groupRole
          : isAnalyticsChecked || isScheduleChecked;
      case Steps.Success:
      default:
        return false;
    }
  }, [
    step,
    currentCreating,
    isGivingAccess,
    groupName,
    groupRole,
    groupType,
    isAnalyticsChecked,
    isScheduleChecked,
    connectedCompany,
    chosenCompany
  ]);

  useEffect(() => {
    if (step === Steps.Two) {
      setErrors("");
    }
  }, [step, emailsInput]);

  const stepUp = () => {
    // validate and submit form
    if (step === Steps.Two) {
      const emails: string[] = emailsInput
        .split(",")
        .map((item: string) => item.trim());

      const emailError = emails.find(item => !emailRegex.test(item));

      if (emailError) {
        return setErrors(`Some emails format is incorrect: ${emailError}`);
      }

      if (currentCreating === Creating.Group) {
        if (!emails.length || !groupType || !groupName || !groupRole) {
          return setErrors("Please select all required fields");
        }

        return createGroup(
          {
            name: groupName,
            groupLogo: groupImage,
            type: groupType,
            emails,
            role: groupRole,
            includeStatistics: isIncludeStatistics
          },
          {
            onSuccess: ({ result, errors }: ShareConnectionResult) => {
              setStep(Steps.Success);
              setErrors(
                errors
                  ?.map(({ email, message }) => `${email}: ${message}`)
                  .join("\n")
              );
              setEmailsInput(result.join(", "));
            },
            onError: error => {
              setErrors(error || "Error");
            }
          }
        );
      } else if (currentCreating === Creating.Company) {
        setIsLoading(true);
        if (!companyRole) return;
        const emailsForSend = emailsInput ? emails : [];
        return createCompany(chosenCompany, companyRole, emailsForSend, {
          onSuccess: ({ result, errors }: ShareConnectionResult) => {
            setIsLoading(true);
            setStep(Steps.Success);
            setErrors(
              errors
                ?.map(({ email, message }) => `${email}: ${message}`)
                .join("\n")
            );
            setEmailsInput(result.join(", "));
          },
          onError: error => {
            setIsLoading(false);
            setErrors(error || "Error");
          }
        });
      } else {
        // ACA
        if (!isAnalyticsChecked && !isScheduleChecked) {
          return setErrors(`Please select at least one tool to share.`);
        }
        const tools: ("ANALYTICS" | "SCHEDULE")[] = [];
        if (isAnalyticsChecked) {
          tools.push("ANALYTICS");
        }
        if (isScheduleChecked) {
          tools.push("SCHEDULE");
        }

        return createSharedConnection(
          { emails, tools, requestType: isGivingAccess ? "offer" : "request" },
          {
            onSuccess: ({ result, errors }: ShareConnectionResult) => {
              setStep(Steps.Success);
              setErrors(
                errors
                  .map(({ email, message }) => `${email}: ${message}`)
                  .join("\n")
              );
              setEmailsInput(result.join(", "));
            },
            onError: error => {
              setErrors(error || "Error");
            }
          }
        );
      }
    }

    setStep(step + 1);
  };

  const stepBack = () => {
    if (step === Steps.Two) {
      setIsGivingAccess(undefined);
    }

    setStep(step - 1);
  };

  const handleGivingAccess = (value: boolean) => {
    setIsGivingAccess(value);
    setStep(Steps.Two);
  };

  const closeBtnHandler = async () => {
    setDisabledBtn(true);
    await getCompanies();
    prop.setOpen(false);
  };

  return (
    <AccountSharingModalContext.Provider
      value={{
        creating: [currentCreating, setCurrentCreating],
        givingAccess: [isGivingAccess, setIsGivingAccess],
        analyticsChecked: [isAnalyticsChecked, setAnalyticsChecked],
        scheduleChecked: [isScheduleChecked, setScheduleChecked],
        userInputData: [emailsInput, setEmailsInput],
        groupName: [groupName, setGroupName],
        groupImage: [groupImage, setGroupImage],
        groupType: [groupType, setGroupType],
        userGroupRole: [groupRole, setGroupRole],
        includeStatistics: [isIncludeStatistics, setIncludeStatistics],
        connectedCompany: [connectedCompany, setConnectedCompany],
        userCompanyRole: [companyRole, setCompanyRole],
        companyLoading: [isLoading, setIsLoading]
      }}
    >
      <div id="accountSharingModal">
        <button
          type="button"
          className="modal-close"
          onClick={() => prop.setOpen(false)}
        />
        <div className="wrapper">
          {step === Steps.ChooseType && <ChooseConnectionType />}
          {(step === Steps.One || step === Steps.Two) &&
            currentCreating === Creating.Account && (
              <ConnectSingleAccount handleGivingAccess={handleGivingAccess} />
            )}
          {step === Steps.One && currentCreating === Creating.Group && (
            <CreateGroup />
          )}
          {step === Steps.One && currentCreating === Creating.Company && (
            <ConnectCompany
              setChosenCompany={setChosenCompany}
              chosenCompany={chosenCompany}
            />
          )}
          {step === Steps.Two && currentCreating === Creating.Group && (
            <InviteGroupMember context={AccountSharingModalContext} />
          )}
          {step === Steps.Two && currentCreating === Creating.Company && (
            <InviteCompanyMember context={AccountSharingModalContext} />
          )}
          {step === Steps.Success && (
            <AccountModalSuccess
              headline={
                currentCreating === Creating.Group
                  ? "Success! Your Group Has Been Created."
                  : emailsInput
                  ? "Success! You have sent your request."
                  : "You have successfuly create company"
              }
              note={
                currentCreating === Creating.Group
                  ? 'You find your new group on your "Linkeded Accounts" overview page. There you change settings and invite new team members.'
                  : "Now you can go back to your dashboard."
              }
              sendToText={
                currentCreating === Creating.Group
                  ? "The following people have been invited:"
                  : "You request was send to:"
              }
              usernames={emailsInput}
              errors={errors}
            />
          )}
          {errors && <p className="validation-error">{errors}</p>}
        </div>
        <div className="modal-footer">
          <div className="button-container responsive-container">
            {isActive && step !== Steps.Success && (
              <button
                disabled={isLoading}
                className="button is-primary"
                onClick={stepUp}
              >
                {step === Steps.ChooseType && "Choose connection type"}
                {step === Steps.One && "Next step"}
                {step === Steps.Two &&
                  currentCreating === Creating.Group &&
                  "Invite people and create group"}
                {step === Steps.Two &&
                  currentCreating !== Creating.Group &&
                  (isGivingAccess ? "Share access" : "Send request")}
                {step === Steps.Two && Creating.Company && ""}
              </button>
            )}
            {(step === Steps.One || step === Steps.Two) && (
              <button className="button is-secondary" onClick={stepBack}>
                Previous Step
              </button>
            )}
            {step === Steps.Success && (
              <button
                className="button is-success"
                disabled={disabledBtn}
                onClick={closeBtnHandler}
              >
                Close popup
              </button>
            )}
          </div>
        </div>
      </div>
    </AccountSharingModalContext.Provider>
  );
};

export { AccountSharingModal, AccountSharingModalContext };
