import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AccountSharingModalContext } from './AccountSharingModal';
import { GroupAccountIcon } from './GroupAccountIcon';
import { SingleAccountIcon } from './SingleAccountIcon';
import { CompanyIcon } from '../company/CompanyIcon';
import '../../styles/components/accountSharing/ChooseConnectionType.scss';

export enum Creating {
  Account = 'account',
  Group = 'group',
  Company = 'company',
}

interface ConnectionTypesFeature {
  label: string;
  Icon: React.VFC<{ fill: string }>;
  creating: Creating;
  inBeta: boolean;
  linkLabel: string;
  linkUrl: string;
  disabled?: boolean;
}

const CONNECTION_TYPES_FEATURES: [ConnectionTypesFeature, ConnectionTypesFeature, ConnectionTypesFeature] = [
  {
    label: 'Connect to a Single Account',
    Icon: SingleAccountIcon,
    creating: Creating.Account,
    inBeta: false,
    linkLabel: 'Learn more about sharing your account',
    linkUrl: '//www.inlytics.io/article/connect-to-a-single-account',
  },
  {
    label: 'Create a Group',
    Icon: GroupAccountIcon,
    creating: Creating.Group,
    inBeta: true,
    linkLabel: 'Learn more about Groups',
    linkUrl: '//www.inlytics.io/article/groups',
  },
  {
    label: 'Add company page',
    Icon: CompanyIcon,
    creating: Creating.Company,
    inBeta: true,
    linkLabel: '',
    linkUrl: '',
  },
];

const renderConnectionTypesButton = ([currentCreating, setCurrentCreating]: [Creating, (arg0: Creating) => {}]) => {
  const selectHandler = (creating: Creating) => {
    setCurrentCreating(creating);
  };
  return CONNECTION_TYPES_FEATURES.map(({ label, Icon, creating, linkLabel, linkUrl, disabled, inBeta }, i) => (
    <div key={i} className='card-item'>
      <button
        className={currentCreating === creating ? 'card-button active' : 'card-button'}
        disabled={disabled}
        onClick={selectHandler.bind(null, creating)}>
        <Icon fill={currentCreating === creating ? '#087cf0' : '#919FA9'} />
        <label className='card-label'>{label}</label>
        {inBeta && <label className='card-label-beta'>(in Beta)</label>}
      </button>
      <Link className='info' to={linkUrl} target='_blank'>
        {linkLabel}
      </Link>
    </div>
  ));
};

const ChooseConnectionType = () => {
  const accountSharing: any = useContext(AccountSharingModalContext);

  return (
    <div className='choose-connection-type'>
      <h2 className='headline-2'>What Do You Want To Do?</h2>
      <p className='description-main'>
        Choose if you would like to add a group (eg. for your team to collaborate and have a shared dashboard) or if you
        would like to connect to a single person (send or request access).
      </p>
      <div className='card-container responsive-container'>{renderConnectionTypesButton(accountSharing.creating)}</div>
    </div>
  );
};

export default ChooseConnectionType;
