import React, { FC } from "react";
import emptyAvatarIcon from "../../assets/icons/IconImage.svg";
import * as Styled from "./AccountAvatar.styles";
import { useValidateImageURL } from "use-validate-image-url";

interface IProps {
  account: Record<string, any>;
  uniqueKey: string;
  variant?: "sm" | "lg";
}

const AccountAvatar: FC<IProps> = ({ account, uniqueKey, variant }) => {
  const status = useValidateImageURL(account.avatar);
  const isInvalid = status === "invalid";
  const avatar = isInvalid ? emptyAvatarIcon : account.avatar;

  return (
    <>
      {status !== "progress" && (
        <Styled.Avatar
          className={`is-rounded avatar ${isInvalid && "invalid"} ${variant} `}
          src={avatar}
          invalid={isInvalid}
          alt={`Avatar ${account.name}`}
          key={account.id + uniqueKey}
        />
      )}
    </>
  );
};

AccountAvatar.defaultProps = {
  variant: "sm"
};
export { AccountAvatar };
