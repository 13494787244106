import React from 'react';
import '../../styles/components/accountSharing/AccountInputComponent.scss';

const AccountInputComponent = (props: {
  headline: string;
  description: string;
  note: string;
  state: any;
  setState: any;
}) => {
  return (
    <div className='accountInputComponent'>
      <h3 className='headline-3'>{props.headline}</h3>
      <div className='input-wrapper responsive-container'>
        <p className='text-container'>
          <span className='description'>{props.description}</span>
          <span className='note'>{props.note}</span>
        </p>
        <textarea
          value={props.state}
          className='account-input'
          onChange={event => props.setState(event.target.value)}
          maxLength={500}
        />
      </div>
    </div>
  );
};

export default AccountInputComponent;
