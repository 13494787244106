import React, { useMemo } from 'react';
import {
  Albums,
  Calendar,
  Home,
  People,
  PersonAdd,
  PersonCircle,
} from '@styled-icons/ionicons-solid';
import { NavLink, useParams, useRouteMatch } from 'react-router-dom';
import { store } from '../store';
import { setInviteMemberModalOpen } from '../store/actions/settings';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { GroupUserRoles } from './accountSharing/AccountSharingModal';

const SidebarAnalyzeList = () => {
  const { groupId } = useParams<{ groupId: string }>();

  const { sidebarOpen } = useSelector((state: RootState) => state.settings);
  const { groups } = useSelector((state: RootState) => state.linkedAccounts);

  const role = useMemo(() => {
    return groups?.find(group => group.id === groupId)?.role;
  }, [groupId, groups]);

  const match = useRouteMatch();

  return (
    <>
      <div className='menu options-list'>
        <ul className='menu-list'>
          <li>
            <NavLink to={`${match.url}`} exact={true}>
              <Home className='icon' />
              <span>Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={`${match.url}/content`}>
              <Albums className='icon' />
              <span>Dashboard</span>
            </NavLink>
          </li>
          {groupId ? (
            <>
              <li>
                <NavLink to={`${match.url}/members`}>
                  <People className='icon' />
                  <span>Group Members</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`${match.url}/schedule`}>
                  <Calendar className='icon' />
                  <span>Content Plan</span>
                </NavLink>
              </li>
            </>
          ) : (
            <li>
              <NavLink to={`${match.url}/profile`}>
                <PersonCircle className='icon' />
                <span>Profile</span>
              </NavLink>
            </li>
          )}
        </ul>

        <div className='button-container button-invite'>
          {groupId && role === GroupUserRoles.Admin && (
            <button
              className='button is-secondary'
              onClick={() => store.dispatch(setInviteMemberModalOpen(true))}
            >
              <span className='icon'>
                <PersonAdd size='1rem' />
              </span>
              {sidebarOpen && <span>Invite team members</span>}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default SidebarAnalyzeList;
