import styled from 'styled-components';
import { colors } from '../../styles/variables';

export const Container = styled.div`
  position: relative;
  align-self: center;
  margin-right: 2rem;
  padding: 0.75rem;
  border-radius: 999px;
  background-color: transparent;
  transition: background-color 0.25s ease;
  &:hover {
    background-color: ${colors.veryLightBlue};
  }
  &:active {
    background-color: ${colors.lightGrey};
  }
`;
