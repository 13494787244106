import React, { useCallback, useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import { adjustHue } from "polished";
import "./style.scss";
import {
  ActivityCompanyHighlight,
  ActivityOccupationHighlight,
  ActivityRegionHighlight
} from "../services/activities-service";
import ArrowUp from "../assets/icons/Arrow.svg";

interface ActivityHighlightsChartProps {
  highlights?: Array<
    | ActivityCompanyHighlight
    | ActivityOccupationHighlight
    | ActivityRegionHighlight
  >;
}

function isCompanyHighlight(
  highlights: Array<
    | ActivityCompanyHighlight
    | ActivityOccupationHighlight
    | ActivityRegionHighlight
  >
): highlights is Array<ActivityCompanyHighlight> {
  return (highlights as Array<ActivityCompanyHighlight>).every(
    entry => entry.universalName
  );
}
function isCompanyRegion(
  highlights: Array<
    | ActivityCompanyHighlight
    | ActivityOccupationHighlight
    | ActivityRegionHighlight
  >
): highlights is Array<ActivityRegionHighlight> {
  return (highlights as Array<ActivityRegionHighlight>).every(
    entry => entry.region
  );
}

export const OverviewHighlightsChart: React.FC<ActivityHighlightsChartProps> = ({
  highlights
}) => {
  const getTransormatedName = (city: string) => {
    return city
      .replace("Metropolitan", "")
      .replace("Area", "")
      .replace("Greater", "")
      .replace("City", "")
      .trim();
  };

  const isAlreadyAdded = useCallback(
    (clusteredHighlights: any[], hl: ActivityRegionHighlight) =>
      clusteredHighlights.find(clHl =>
        hl.region.includes(getTransormatedName(clHl.region))
      ),
    []
  );

  const [isShowAll, setIsShowAll] = useState(false);
  const [clusteredHighlights, setClusteredHighlights] = useState<
    ActivityRegionHighlight[]
  >([]);

  useEffect(() => {
    const clusteredHighlights: ActivityRegionHighlight[] = [];
    highlights?.forEach(hl => {
      if ("region" in hl && isAlreadyAdded(clusteredHighlights, hl)) {
        isAlreadyAdded(clusteredHighlights, hl).amountViews += hl.amountViews;
      } else {
        clusteredHighlights.push(hl as ActivityRegionHighlight);
      }
    });
    setClusteredHighlights(clusteredHighlights);
  }, [highlights, isAlreadyAdded]);

  if (!clusteredHighlights || !clusteredHighlights.length) {
    return (
      <div className="ActivityHighlightsChart">
        <i>No data yet.</i>
      </div>
    );
  }

  const nameKey = isCompanyHighlight(clusteredHighlights)
    ? "name"
    : isCompanyRegion(clusteredHighlights)
    ? "region"
    : "title";

  const handleToggleLegend = () => setIsShowAll(!isShowAll);

  return (
    <div style={{ position: "relative" }}>
      <PieChart width={292} height={isShowAll ? 600 : 400}>
        <Pie
          data={clusteredHighlights}
          innerRadius={74}
          outerRadius={146}
          dataKey="amountViews"
          nameKey={nameKey}
          startAngle={90}
          endAngle={-270}
        >
          {clusteredHighlights.map((_entry, index) => (
            <Cell
              key={index}
              fill={adjustHue(
                Math.round(360 / clusteredHighlights.length) * index,
                "rgba(8, 124, 240, 0.6)"
              )}
            />
          ))}
        </Pie>
        <Tooltip />

        {isShowAll && (
          <Legend
            wrapperStyle={{
              marginTop: 20,
              borderTop: "1px solid #f5f9fc",
              width: "100%"
            }}
          />
        )}
      </PieChart>
      <div className={"chart-show-data"} onClick={handleToggleLegend}>
        {isShowAll ? "Hide All" : "Show All"}
        {isShowAll ? (
          <img
            src={ArrowUp}
            alt="inlytics"
            style={{ width: 12, marginLeft: 10, transform: "rotate(180deg)" }}
          />
        ) : (
          <img
            src={ArrowUp}
            alt="inlytics"
            style={{ width: 12, marginLeft: 10 }}
          />
        )}
      </div>
    </div>
  );
};
