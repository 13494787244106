import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { ChevronDown } from "@styled-icons/ionicons-solid";

import { store } from "../store";
import { RootState } from "../store/reducers";
import {
  setSelectedAccount,
  setSelectedGroup
} from "../store/actions/linkedAccounts";

import "../styles/components/SidebarAccountSection.scss";
import {
  LinkedInAccount,
  LinkedInAccountShared,
  updateMainAccount
} from "../services/account-service";
import ClickAwayListener from "./ClickAwayListener";
import { AccountsTableEntryViewModel } from "./linked-accounts/AccountList";
import { GroupUserRoles } from "./accountSharing/AccountSharingModal";
import { GroupAccountIcon } from "./accountSharing/GroupAccountIcon";
import { demoUserEmail } from "../services/user-service";
import { useValidateImageURL } from "use-validate-image-url";
import emptyAvatarIcon from "../assets/icons/IconImage.svg";
import { SidebarDropDownAvatar } from "./sidebar-drop-down-avatar/SidebarDropDownAvatar";

const SidebarAccountSection = () => {
  const {
    mainAccount,
    selected,
    selectedGroup,
    sharedConnections,
    groupsConnections,
    groups
  } = useSelector((state: RootState) => state.linkedAccounts);
  const { user } = useSelector((state: RootState) => state.auth);
  const profileStatus = useValidateImageURL(user ? user.profile.picture : "");
  const selectedProfileStatus = useValidateImageURL(
    selected ? selected.profile.displayPhoto.small : ""
  );
  const groupStatus = useValidateImageURL(
    selectedGroup && selectedGroup.avatar ? selectedGroup.avatar : ""
  );
  const avatar =
    profileStatus === "invalid" ? emptyAvatarIcon : user?.profile.picture;
  const groupAvatar =
    groupStatus === "invalid" ? emptyAvatarIcon : selectedGroup?.avatar;
  const selectProfileAvatar =
    selectedProfileStatus === "invalid"
      ? emptyAvatarIcon
      : selected?.profile.displayPhoto.small;

  const location = useLocation();
  const { accountId, groupId } = useParams<{
    accountId: string;
    groupId: string;
  }>();

  const [dropdownActive, setDropdownActive] = useState(false);

  useEffect(() => {
    // Load linked accounts on init
    if (mainAccount === null) {
      updateMainAccount();
    }
  }, [mainAccount]);

  const handleSelectAccount = (account: LinkedInAccount) => {
    setDropdownActive(false);
    store.dispatch(setSelectedAccount(account));
  };

  const handleSelectGroup = (group: AccountsTableEntryViewModel) => {
    setDropdownActive(false);
    store.dispatch(setSelectedGroup(group));
  };

  const getLink = (id: string): string => {
    return id === accountId
      ? `/account/${id}/analyze/content`
      : location.pathname.replace(accountId, id);
  };

  const getGroupLink = (id: string) => {
    return id === groupId
      ? `/account/${accountId}/group/${id}/analyze/content`
      : location.pathname.replace(groupId, id);
  };

  const dropdownGroupOptions: AccountsTableEntryViewModel[] = useMemo(() => {
    // const filterKey = /\/schedule$/.test(location.pathname) ? 'isSharedSchedule' : 'isSharedAnalytics';
    const res = groups.filter(
      group =>
        // group[filterKey] && commented while testing scheduler
        group.role === GroupUserRoles.Analyst ||
        group.role === GroupUserRoles.Admin
    );
    return [...res];
  }, [groups]);

  const dropdownAccountOptions: LinkedInAccount[] = useMemo(() => {
    if (!mainAccount) {
      return [];
    }
    if (!sharedConnections?.length && !groupsConnections?.length) {
      return [mainAccount];
    }

    const filterKey = /\/schedule$/.test(location.pathname)
      ? "isSharedSchedule"
      : "isSharedAnalytics";
    const shared = sharedConnections.filter(
      connection => connection[filterKey]
    );
    const groupShared = groupsConnections
      .filter(
        connection =>
          connection[filterKey] &&
          user?.mainAccount !== connection._id &&
          !shared.find(item => item._id === connection._id)
      )
      .reduce((general, curr) => {
        if (!general.find(item => item._id === curr._id)) {
          general.push(curr);
        }
        return general;
      }, [] as LinkedInAccountShared[]);

    return [mainAccount, ...shared, ...groupShared];
  }, [
    user,
    mainAccount,
    sharedConnections,
    groupsConnections,
    location.pathname
  ]);

  const isDemoUser = useCallback(
    (account: LinkedInAccount | null | undefined) =>
      account?.email === demoUserEmail,
    []
  );

  if (!accountId || !mainAccount || (!selected && !selectedGroup)) {
    return null;
  }

  // TODO show all possible shared accounts in the dropdown
  return (
    <div className="SidebarAccountSection">
      <>
        <figure className="image">
          {(groupId ? (
            selectedGroup?.avatar
          ) : isDemoUser(selected) ? (
            user?.profile.picture
          ) : (
            selected?.profile.displayPhoto.small
          )) ? (
            <>
              {profileStatus !== "progress" &&
                groupStatus !== "progress" &&
                selectedProfileStatus !== "progress" && (
                  <img
                    className="is-rounded current-avatar"
                    src={
                      groupId
                        ? groupAvatar
                        : isDemoUser(selected)
                        ? avatar
                        : selectProfileAvatar
                    }
                    alt="Account avatar"
                  />
                )}
            </>
          ) : (
            <div className="current-avatar">
              <GroupAccountIcon fill="#919FA9" />
            </div>
          )}
        </figure>
        <ClickAwayListener onOutSideClick={() => setDropdownActive(false)}>
          <div
            className={classNames("dropdown", { "is-active": dropdownActive })}
          >
            <div
              className="dropdown-trigger"
              onClick={() => setDropdownActive(current => !current)}
            >
              <div className="info">
                <div className="name truncate has-text-weight-bold">
                  {groupId
                    ? selectedGroup?.name
                    : isDemoUser(selected)
                    ? `${user?.profile.givenName} ${user?.profile.familyName}`
                    : `${selected?.profile.firstName} ${selected?.profile.lastName}`}
                </div>
                {!groupId && !isDemoUser(selected) && (
                  <div className="occupation truncate">
                    {selected?.profile.occupation}
                  </div>
                )}
              </div>
              <span className="icon">
                <ChevronDown
                  size="1rem"
                  style={{
                    transform: `rotate(${dropdownActive ? "180deg" : "0deg"})`
                  }}
                />
              </span>
            </div>
            <div
              className="dropdown-menu is-fullwidth"
              id="select-account-dropdown"
              role="menu"
            >
              <div className="dropdown-content">
                {groupId
                  ? dropdownGroupOptions.map(group => (
                      <Link
                        key={group.id}
                        to={getGroupLink(group.id)}
                        onClick={() => handleSelectGroup(group)}
                        className="dropdown-item"
                      >
                        <div className="level">
                          <div className="level-left">
                            <div className="level-item image is-32x32 avatar">
                              {group.avatar ? (
                                <img
                                  className="is-rounded"
                                  src={groupAvatar}
                                  alt={`Avatar of ${group.name}`}
                                />
                              ) : (
                                <GroupAccountIcon fill="#919FA9" />
                              )}
                            </div>
                          </div>
                          <div className="level-right name-part">
                            <div className="level-item">{group.name}</div>
                          </div>
                        </div>
                      </Link>
                    ))
                  : dropdownAccountOptions.map(account => (
                      <Link
                        key={account.email + account._id}
                        to={getLink(account._id)}
                        onClick={() => handleSelectAccount(account)}
                        className="dropdown-item"
                      >
                        <div className="level">
                          <div className="level-left">
                            <div className="level-item image is-32x32 avatar">
                              <SidebarDropDownAvatar
                                isDemoUser={isDemoUser}
                                account={account}
                                user={user}
                              />
                            </div>
                          </div>
                          <div className="level-right name-part">
                            <div className="level-item">
                              {isDemoUser(account)
                                ? `${user?.profile.givenName} ${user?.profile.familyName}`
                                : `${account.profile.firstName} ${account.profile.lastName}`}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </>
    </div>
  );
};

export default SidebarAccountSection;
