import React from 'react';
import { colors } from '../../styles/variables';
import * as Styled from './Banner.styles';

interface BannerProps {
  onHide?: () => void;
  hideable?: boolean;
  bgColor?: string;
  className?: string;
}

const Banner: React.FC<BannerProps> = ({
  children,
  hideable = false,
  onHide,
  bgColor = colors.darkBlue,
  className,
}) => {
  return (
    <Styled.Wrapper bgColor={bgColor} className={className}>
      <Styled.Content>{children}</Styled.Content>
      {hideable && <Styled.Close onClick={() => onHide && onHide()} />}
    </Styled.Wrapper>
  );
};

export default Banner;
