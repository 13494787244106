import dateRangeFilter from './dateRangeFilter';
import auth from './auth';
import linkedAccounts from './linkedAccounts';
import scheduledActivities from './scheduledActivities';
import settings from './settings';
import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

export const rootReducer = combineReducers({
  dateRangeFilter,
  auth,
  linkedAccounts,
  scheduledActivities,
  settings,
});

export const rootEpic = combineEpics();

export type RootState = ReturnType<typeof rootReducer>;
