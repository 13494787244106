import React from 'react';
import IconViews from '../assets/images/icon-views.svg';
import IconImpression from '../assets/images/icon-impressions.svg';
import IconLikes from '../assets/images/icon-likes.svg';
import IconComments from '../assets/images/icon-comments.svg';
import IconEngagement from '../assets/images/icon-engagement.svg';
import { AmountReactionType } from '../services/statistics-service';
import ReferenceTypePositive from '../assets/images/green-circle-arrow-top.svg';
import ReferenceTypeNegative from '../assets/images/red-circle-arrow-bottom.svg';
import ReactionIconEmpathy from '../assets/images/reaction-empathy.svg';
import ReactionIconInterest from '../assets/images/reaction-interest.svg';
import ReactionIconLike from '../assets/images/reaction-like.svg';
import ReactionIconMaybe from '../assets/images/reaction-maybe.svg';
import ReactionIconPraise from '../assets/images/reaction-praise.svg';
import ReactionIconAppreciation from '../assets/images/reaction-appreciation.svg';
import '../styles/components/DashboardCard.scss';
import { ReferenceStatisticsType } from './DashboardStatisticCards';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { USER_PLAN } from '../services/user-service';
import { Link } from 'react-router-dom';
import { HelpCircle, LockClosed } from '@styled-icons/ionicons-solid';
import { Timer } from '@styled-icons/ionicons-outline/Timer';
import StyledTooltip from './StyledTooltip';

export type CardVariant =
  | 'views'
  | 'impressions'
  | 'likes'
  | 'comments'
  | 'shares'
  | 'engagement'
  | 'posts'
  | 'articles'
  | 'connections'
  | 'pageViews'
  | 'uniqueVisitors'
  | 'reactions'
  | 'totalFollowers'
  | 'newFollowers'
  | 'buttonClicks';

interface DashboardCardProps {
  title: string;
  variant: CardVariant;
  value: string | number;
  referenceValue?: { value: string; type: ReferenceStatisticsType };
  reactionAmountMap?: AmountReactionType[];
  disableIcon?: boolean;
  helpText?: string;
  updateInterval?: string;
}

const iconMap = {
  views: IconViews,
  impressions: IconImpression,
  likes: IconLikes,
  comments: IconComments,
  shares: null, // TODO update?
  engagement: IconEngagement,
  posts: null, // TODO update?
  articles: null, // TODO update?
  connections: null, // TODO update?
  pageViews: null,
  uniqueVisitors: null,
  reactions: null,
  totalFollowers: null,
  newFollowers: null,
  buttonClicks: null,
};

const reactionIconMap: { [key: string]: string } = {
  EMPATHY: ReactionIconEmpathy,
  INTEREST: ReactionIconInterest,
  LIKE: ReactionIconLike,
  MAYBE: ReactionIconMaybe,
  PRAISE: ReactionIconPraise,
  APPRECIATION: ReactionIconAppreciation,
};

const referenceTypeIconMap: { [key: string]: string } = {
  positive: ReferenceTypePositive,
  negative: ReferenceTypeNegative,
};

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  variant,
  value,
  referenceValue,
  reactionAmountMap,
  disableIcon,
  helpText,
  updateInterval,
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const icon = iconMap[variant];

  if (typeof value === 'number') {
    // Convert numbers to nicely formatted numbers
    value = value.toLocaleString();
  }
  return (
    <div className={`box DashboardCard company-box ${variant}`}>
      <div className='left'>
        <div
          className='title'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {title}
          {helpText && (
            <>
              <div
                data-tip
                data-for={`styled-tooltip-dashboard-title-${title}`}
              >
                <HelpCircle height={'1.2rem'} />
              </div>
              <StyledTooltip
                id={`styled-tooltip-dashboard-title-${title}`}
                delayHide={500}
                clickable={true}
              >
                <span
                  style={{
                    textTransform: 'initial',
                  }}
                  dangerouslySetInnerHTML={{ __html: helpText }}
                ></span>
              </StyledTooltip>
            </>
          )}
        </div>
        <div className='amountContainer'>
          {!disableIcon && icon !== null && (
            <img src={icon} alt={`Type ${variant}`} />
          )}
          {variant === 'engagement' && user!.plan === USER_PLAN.FREE ? (
            <Link to='/upgrade' className='button'>
              <span className='icon'>
                <LockClosed size='1rem' />
              </span>
              <span>Upgrade</span>
            </Link>
          ) : (
            <span className='value'>{value}</span>
          )}
        </div>
        {(variant !== 'engagement' || user!.plan !== USER_PLAN.FREE) &&
          referenceValue &&
          referenceValue.value && (
            <div
              className={`reference-value ${referenceValue.type} is-flex is-align-items-center`}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {referenceValue.type !== 'neutral' && (
                <img
                  src={referenceTypeIconMap[referenceValue.type]}
                  alt={`Reference type icon ${referenceValue.type}`}
                  title={referenceValue.type}
                  className='mr-2'
                />
              )}
              <span>{referenceValue.value || '0'}%</span>
              <div
                data-tip
                data-for={`styled-tooltip-dashboard-interval-${title}`}
              >
                <Timer height={'1rem'} color='#002d5a' />
                <small
                  style={{
                    marginLeft: '0.3rem',
                    color: '#002d5a',
                  }}
                >
                  {updateInterval}
                </small>
                <StyledTooltip
                  id={`styled-tooltip-dashboard-interval-${title}`}
                  delayHide={500}
                  clickable={true}
                >{`Updated every ${updateInterval}`}</StyledTooltip>
              </div>
            </div>
          )}
      </div>
      {reactionAmountMap && (
        <div className='reactionsContainer'>
          {reactionAmountMap.map(reaction => {
            return (
              <div className='reaction' key={reaction.type}>
                <img
                  src={reactionIconMap[reaction.type]}
                  alt={`Reaction icon ${reaction.type}`}
                  title={reaction.type}
                />
                <span className='amount'>{reaction.count}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DashboardCard;
