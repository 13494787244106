import React from 'react';

interface Props {
  number: number;
  percentage?: number | string;
  title?: string | boolean;
}

const ChartInfoBlock: React.FC<Props> = ({ number, percentage, title }) => (
  <>
    {title && <span className={'title is-size-5 has-text-grey has-text-weight-normal mb-0'}>{title}</span>}
    <span className='increase'>{number}</span>
    {percentage != null && (
      <span className={`percentage ${percentage < 0 ? 'negative' : 'positive'}`}>
        {percentage > 0 && '+'}
        {typeof percentage === 'string'
          ? percentage
          : `${percentage.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}%`}
      </span>
    )}
  </>
);

export { ChartInfoBlock };
