import React, { useEffect, useState } from "react";
import * as localStorage from "../../services/local-storage-service";
import { updateMainAccount } from "../../services/account-service";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { useQuery } from "react-query";
import { Time } from "@styled-icons/ionicons-solid";
import "./RecentlyConnectedBanner.webflow.scss";
import { StyledCommon } from "../../styles/common/Common.styles";
import { colors } from "../../styles/variables";

/**
 * Shows a banner if the user has connected their account within the last 24 hours to warn them about potentially missing data.
 */
const EngagementBanner: React.FC = () => {
  const user = useSelector((root: RootState) => root.auth.user);
  const { data: mainAccount } = useQuery(
    ["updateMainAccount"],
    updateMainAccount,
    { staleTime: 0, enabled: !!user }
  );
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (
      mainAccount &&
      localStorage.getEngagementEntry(
        localStorage.STORAGE_KEY.ENGAGEMENT_BANNER_HIDDEN
      ) !== true
    ) {
      setVisible(true);
    }
  }, [mainAccount]);

  if (!user || !visible) {
    return null;
  }

  return (
    <StyledCommon.Section>
      <StyledCommon.Wrapper>
        <div className="RecentlyConnectedBanner announce-bar">
          <div className="announce-bar-icon-wrap">
            <Time size="1.5rem" color={colors.lightOrange} />
          </div>
          <div className="paragraph-wrap">
            <div className="bold-text-wrap">
              <div className="paragraph">
                <b>Information:</b>
              </div>
            </div>
            <p className="paragraph has-text-grey">
              This section calculates the engagement that occurred specifically
              within the date range selected.
            </p>
          </div>
          <div className="button-wrap">
            <button
              className="button is-outlined is-primary"
              onClick={() => {
                setVisible(false);
                localStorage.setEngagementEntry(
                  localStorage.STORAGE_KEY.ENGAGEMENT_BANNER_HIDDEN,
                  true
                );
              }}
            >
              Alright, got it
            </button>
          </div>
        </div>
      </StyledCommon.Wrapper>
    </StyledCommon.Section>
  );
};

export default EngagementBanner;
