export const helpTexts = {
  engagementRateCard: `Relation between views and (reactions and comments).\nUpdate interval is calculated live based onthe activities.\n* Please note that this is metric is from Inlytics and NOT from LinkedIn.`,
  commentsCard: `Amount of comments. \n* Please note that deleted comments from a post may affect your data.`,
  postViewsCard:
    'Please note that posts that are only text will have the same number for both views and impressions. Other post types will differ due to the way LinkedIn defines a view for posts with links or videos. Learn more <a href="https://help.inlytics.io/support/solutions/articles/73000588286-linkedin-impressions-vs-views" target="_blank">here</a>',
  postImpressionsCard:
    'The total number of times your post was displayed onscreen. This number is an estimate and may not be precise.',
  reactionsCard:
    'Amount of reactions to an activity: Likes, Recommendation, etc.',
  topPosts: `List of all posts in order of Engagement rate. \n* The list is affected by the date range selected.`,
  HOW_TO_RECONNECT_BLOG_URL:
    'https://help.inlytics.io/help/how-to-reconnect-my-account-it-shows-error-or-not-connected',
  SHOW_ME_WHAT_YOU_TRACK_URL:
    'https://help.inlytics.io/en/support/solutions/articles/73000571128-historic-data-is-missing-or-not-loading',
  DELETE_IMAGE_ERROR: `The image can not be deleted since it is being used in a publication.`,
  RELOAD_BTN_TEXT: `Reload your stats now, this action can be done every 10 minutes. Please consider that this can take a few minutes.`,
  FETCHED_DATA_TEXT: `Your data is up-to-date.`,
};
