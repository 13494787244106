import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { APIError } from '../../services/api-client';
import * as Yup from 'yup';
import '../../styles/components/LoginRegisterForm.scss';
import classNames from 'classnames';
import { useMutation } from 'react-query';
import { forgotPassword } from '../../services/auth-service';
import { Mail } from '@styled-icons/ionicons-solid';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('This field is required'),
});

const ForgotPasswordForm: React.FC = () => {
  const [forgotPasswordMutation, { error, isSuccess }] = useMutation<void, APIError, string>(forgotPassword);

  return (
    <div className='login-signup-form-wrapper'>
      <p className='mb-4'>
        Forgot your password? No problem, enter your email and request a link to set a new password.
      </p>

      <Formik
        initialValues={{ email: '' }}
        onSubmit={({ email }) => forgotPasswordMutation(email)}
        validationSchema={ForgotPasswordSchema}>
        {({ isSubmitting, isValid, errors, touched }) => (
          <Form>
            <div className='field'>
              <label htmlFor='email' className='label'>
                Email address
              </label>
              <div className='control is-expanded has-icons-left'>
                <Field
                  className={classNames('input', { 'is-danger': touched.email && errors.email })}
                  type='email'
                  name='email'
                  id='email'
                  autoComplete='email'
                  required
                  placeholder='name@example.com'
                />
                <span className='icon is-left'>
                  <Mail size='1rem' />
                </span>
              </div>
              <ErrorMessage component='p' className='help is-danger' name='email' />
            </div>
            <button
              className={classNames('button is-fullwidth is-primary', { 'is-loading': isSubmitting })}
              type='submit'
              disabled={isSubmitting || !isValid}>
              Reset password
            </button>
          </Form>
        )}
      </Formik>

      {/* Error */}
      {error && <div className='notification is-danger is-light has-text-centered'>{error.message}</div>}

      {/* Success */}
      {isSuccess && (
        <div className='notification is-success is-light has-text-centered'>
          We've sent you an email with further instructions.
        </div>
      )}
    </div>
  );
};

export default ForgotPasswordForm;
