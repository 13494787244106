import React, { useState, useEffect } from 'react';

import { AnalyzeIcon, ScheduleIcon } from '../CustomIcons';

import { acceptAccountConnection, getRequestsToOthers, removeAccountConnection } from '../../services/profile-service';
import { AccountConnection } from '../../services/account-service';

interface Props {
  data: AccountConnection;
  type: 'requestReceived' | 'requestSent';
}

export const ConnectionRequest: React.FC<Props> = ({ data, type }) => {
  const { _id, analytics, schedule, requestType, fromUser, toUser } = data;

  const profile = fromUser?.mainAccount?.profile || toUser?.mainAccount?.profile;

  const [isExpanded, setExpanded] = useState(false);

  const [isAnalytics, setAnalytics] = useState<boolean>(!!analytics);
  const [isSchedule, setSchedule] = useState<boolean>(!!schedule);

  useEffect(() => {
    setAnalytics(!!analytics);
  }, [analytics]);

  useEffect(() => {
    setSchedule(!!schedule);
  }, [schedule]);

  return (
    <div className='security-settings-connection-request'>
      {isExpanded && type === 'requestReceived' && (
        <h4 className='security-settings-title'>
          {requestType} to view {requestType === 'request' && 'Your'} personal stats
        </h4>
      )}
      <div className='security-settings-shared'>
        <img
          className='security-settings-shared__profile-picture'
          src={profile?.displayPhoto?.small || '/assets/images/default-avatar.svg'}
          alt="user's avatar"
        />
        <p className='security-settings-shared__profile-name'>
          {profile?.firstName} {profile?.lastName}
        </p>
        <input
          type='checkbox'
          id={`security-settings-shared-expand-control-${data._id}`}
          name={`security-settings-expand-control-${data._id}`}
          className='security-settings-shared__expand-checkbox'
          onChange={() => setExpanded(!isExpanded)}
        />
        <label
          htmlFor={`security-settings-shared-expand-control-${data._id}`}
          className='security-settings-shared__expand-label'>
          See {requestType}ed permissions
        </label>
      </div>
      {isExpanded && (
        <>
          <div className='security-settings-shared-expand'>
            <p className='security-settings-shared-expand__note'>
              You can always change your settings later by navigating to your personal settings.
            </p>
            <p className='security-settings-shared-expand__description'>
              {type === 'requestSent'
                ? `You want ${requestType === 'offer' ? profile?.firstName : ''} to:`
                : `${profile?.firstName} wants ${requestType === 'offer' ? 'you' : ''} to:`}
            </p>
            <div className='shared-areas'>
              <div className='shared-areas__item'>
                <AnalyzeIcon />
                <span className='shared-areas__item-title'>
                  See{' '}
                  {(type === 'requestSent' && requestType === 'offer') ||
                  (type === 'requestReceived' && requestType === 'request')
                    ? 'your'
                    : 'his/her'}{' '}
                  Analytics
                </span>
                <label className='switch'>
                  <input
                    type='checkbox'
                    className='tool-input'
                    defaultChecked={analytics}
                    disabled={!analytics || type === 'requestSent'}
                    onClick={(event: any) => setAnalytics(event.target.checked)}
                  />
                  <span className='slider round'></span>
                </label>
              </div>
              <div className='shared-areas__item'>
                <ScheduleIcon />
                <span className='shared-areas__item-title'>
                  Schedule Posts for{' '}
                  {(type === 'requestSent' && requestType === 'offer') ||
                  (type === 'requestReceived' && requestType === 'request')
                    ? 'you'
                    : 'him/her'}
                </span>
                <label className='switch'>
                  <input
                    type='checkbox'
                    className='tool-input'
                    defaultChecked={schedule}
                    disabled={!schedule || type === 'requestSent'}
                    onClick={(event: any) => setSchedule(event.target.checked)}
                  />
                  <span className='slider round'></span>
                </label>
              </div>
            </div>
            {requestType === 'request' && type === 'requestReceived' && (
              <p className='security-settings-shared-expand__warning'>
                Note: Sharing access to your scheduling tool enables people to post on your LinkedIn account. Please
                make sure you trust this person. You can always revoke this access later in your sharing settings.
              </p>
            )}
          </div>
          <div className='security-settings-btn-group'>
            {type === 'requestSent' ? (
              <button className='btn is-danger' onClick={() => removeAccountConnection(_id, getRequestsToOthers)}>
                Delete Request
              </button>
            ) : (
              <>
                <button className='btn is-secondary' onClick={() => removeAccountConnection(_id)}>
                  Decline
                </button>
                <button
                  className='btn is-primary'
                  disabled={!isAnalytics && !isSchedule}
                  onClick={() =>
                    acceptAccountConnection(_id, { analytics: isAnalytics, schedule: isSchedule }, requestType)
                  }>
                  {requestType === 'offer' ? 'Accept' : 'Allow'}
                </button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
