import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { APIError } from '../../services/api-client';
import * as Yup from 'yup';
import '../../styles/components/LoginRegisterForm.scss';
import classNames from 'classnames';
import { useMutation } from 'react-query';
import { resetPassword } from '../../services/auth-service';
import { Key } from '@styled-icons/ionicons-solid';
import { Link, useParams } from 'react-router-dom';

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Please use atleast 8 characters')
    .required('This field is required'),
  confirmPassword: Yup.string().when('password', {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf([Yup.ref('password')], 'Both passwords need to be the same'),
  }),
});

const ResetPasswordForm: React.FC = () => {
  const { token } = useParams<{ token: string }>();

  const [forgotPasswordMutation, { error, isSuccess }] = useMutation<
    void,
    APIError,
    Yup.InferType<typeof ResetPasswordSchema>
  >(({ confirmPassword, password }) => resetPassword({ confirmPassword, password, token }));

  return (
    <div className='login-signup-form-wrapper'>
      <p className='mb-4'>Please use the form below to set a new password for your account.</p>

      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={values => forgotPasswordMutation(values)}
        validationSchema={ResetPasswordSchema}>
        {({ isSubmitting, isValid, errors, touched }) => (
          <Form>
            <div className='field'>
              <label htmlFor='password' className='label'>
                New password
              </label>
              <div className='control is-expanded has-icons-left'>
                <Field
                  className={classNames('input', { 'is-danger': touched.password && errors.password })}
                  id='password'
                  type='password'
                  name='password'
                  autoComplete='new-password'
                  required
                />
                <span className='icon is-left'>
                  <Key size='1rem' />
                </span>
              </div>
              <ErrorMessage component='p' className='help is-danger' name='password' />
            </div>
            <div className='field'>
              <label htmlFor='confirm-password' className='label'>
                New password (confirm)
              </label>
              <div className='control is-expanded has-icons-left'>
                <Field
                  className={classNames('input', {
                    'is-danger': touched.confirmPassword && errors.confirmPassword,
                  })}
                  id='confirm-password'
                  type='password'
                  name='confirmPassword'
                  autoComplete='new-password'
                  required
                />
                <span className='icon is-left'>
                  <Key size='1rem' />
                </span>
              </div>
              <ErrorMessage component='p' className='help is-danger' name='confirmPassword' />
            </div>
            <button
              className={classNames('button is-fullwidth is-primary', { 'is-loading': isSubmitting })}
              type='submit'
              disabled={isSubmitting || !isValid}>
              Save password
            </button>
          </Form>
        )}
      </Formik>

      {/* Error */}
      {error && <div className='notification is-danger is-light has-text-centered'>{error.message}</div>}

      {/* Success */}
      {isSuccess && (
        <div className='notification is-success is-light has-text-centered'>
          You've successfully updated your password. You can now <Link to='/login'>log into your account</Link>.
        </div>
      )}
    </div>
  );
};

export default ResetPasswordForm;
