import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { constants } from './constants';

function addWaterMark(
  doc: any,
  height: number,
  start: string | undefined,
  end: string | undefined
) {
  var totalPages = doc.internal.getNumberOfPages();

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
    doc.setTextColor(150);
    doc.text(10, 20, `From ${start} to ${end}`);
  }

  return doc;
}

export const exportToPdf = async (
  divToPrint: string,
  filename: string,
  width: number,
  height: number = constants.DEFAULT_HEIGHT,
  isModule = false,
  dateRange?: {
    start: string;
    end: string;
  }
) => {
  const input = document.getElementById(divToPrint) as HTMLElement;
  const w = 1920;
  const h = height;

  const contentCanvas = await html2canvas(input, {
    windowWidth: w - 200,
    windowHeight: h,
  });
  const content = contentCanvas.toDataURL('image/png');

  const pdf = new jsPDF('p', 'px', [w, h]);
  pdf.setPage(1);
  if (isModule) addWaterMark(pdf, h, dateRange?.start, dateRange?.end);
  pdf.addImage(content, 'JPEG', 0, 30, 0, 0);
  pdf.save(`${filename}.pdf`);
};
