import React, { useEffect } from 'react';
import { Media } from '../../services/media-service';
import { FormErrors } from './ScheduledActivityPopup';

export enum MEDIA_FILES_ERROR_MESSAGE_ENUM {
  countOfVideoNotSupported = 'is-count-of-video-not-supported',
  differentTypeOfMedia = 'is-different-type-of-media',
}

export const setFormError = (
  setFormErrors: React.Dispatch<React.SetStateAction<FormErrors>>,
  errorTitle: string,
  message: string
): void => {
  setFormErrors(prevState => ({
    ...prevState,
    [errorTitle]: message,
  }));
};

export const useValidationMediaFiles = (
  mediaFiles: Media[] | undefined,
  setFormErrors: React.Dispatch<React.SetStateAction<FormErrors>>
) => {
  useEffect(() => {
    setFormError(
      setFormErrors,
      MEDIA_FILES_ERROR_MESSAGE_ENUM.differentTypeOfMedia,
      mediaFiles?.length &&
        !mediaFiles.every(value => value.type === 'image') &&
        !mediaFiles.every(value => value.type === 'video')
        ? 'Uploading different types of media is not allowed'
        : ''
    );
    setFormError(
      setFormErrors,
      MEDIA_FILES_ERROR_MESSAGE_ENUM.countOfVideoNotSupported,
      mediaFiles?.length &&
        mediaFiles[0].type === 'video' &&
        mediaFiles.length > 1 &&
        mediaFiles.every(value => value.type === 'video')
        ? 'Only one video is allowed to upload'
        : ''
    );
  }, [setFormErrors, mediaFiles]);
};
