import React from 'react';
import { ProfileOptimizationCategory } from '../services/profile-service';

export const initialCategories: ProfileOptimizationCategory[] = [
  {
    name: 'Headline',
    type: 'headline',
    progress: 0,
    helpText: (
      <div>
        Your LinkedIn headline is the most seen component of your LinkedIn profile. It appears everywhere your name does
        and gives recruiters or your prospects a quick overview of who you are and why they should click your profile.
      </div>
    ),
    actions: [
      {
        id: 'headline_right_length',
        name: 'Right length',
        helpText: (
          <div>
            Remember that it is important that you use keywords you expect recruiters or your prospects to be searching
            for. A good reference for the right length is a value between <b>80 characters and 200 characters</b>. Make
            sure that you use keywords you expect recruiters or your prospects to be searching for. Also check that the
            most important keywords are visible at the beginning, so that they are seen in the mobile view. You can
            check the length of your headline here:{' '}
            <a href='https://charactercounttool.com/' target='_blank' rel='noopener noreferrer'>
              https://charactercounttool.com/
            </a>
          </div>
        ),
        checked: false,
      },
      {
        id: 'headline_special_characters',
        name: 'Special characters',
        helpText: (
          <div>
            While it can be good to use special characters in your headline you certainly shouldn't overuse them. Some
            symbols, for example emojis, come across as unprofessional on your profile and may not display correctly on
            all browsers. <b>Check whether or not you have overused them</b>.
          </div>
        ),
        checked: false,
      },
      {
        id: 'headline_no_buzzwords',
        name: 'No Buzzwords',
        helpText: (
          <div>
            Buzzwords are terms and phrases that sound impressive, cool, or even exciting, but not everyone always
            understands the definition of them. <b>Whenever you can try to avoid them.</b>
          </div>
        ),
        checked: false,
      },
      {
        id: 'headline_spelling_errors',
        name: 'Spelling errors',
        helpText: (
          <div>
            Spelling errors significantly decrease the impact of your profile.{' '}
            <b>Check twice that you've spelled everything correctly</b>, including any industry terminology /
            certifications. You may use a tool like grammarly to check your spelling.
          </div>
        ),
        checked: false,
      },
    ],
  },
  {
    name: 'Summary',
    type: 'summary',
    progress: 0,
    helpText: (
      <div>
        Your summary gives you the chance to make a great first impression to recruiters, potential clients, and other
        professionals who rely on LinkedIn, and is where you explain why you're the right fit.
      </div>
    ),
    actions: [
      {
        id: 'summary_right_length',
        name: 'Summary length',
        helpText: (
          <div>
            Top LinkedIn profiles take advantage of the 2000 character limit and usually have{' '}
            <b>summaries between 200-300 words long</b>. Consider adding extra detail to your summary to let recruiters
            and prospects know more about you. This might include adding a list of technical skills, interests,
            extracurricular activities and measurable accomplishments.
          </div>
        ),
        checked: false,
      },
      {
        id: 'summary_special_characters',
        name: 'Special characters',
        helpText: (
          <div>
            While it can be good to use special characters in your headline you certainly shouldn't overuse them. Some
            symbols, for example emojis, come across as unprofessional on your profile and may not display correctly on
            all browsers. <b>Check whether or not you have overused them</b>.
          </div>
        ),
        checked: false,
      },
      {
        id: 'summary_no_buzzwords',
        name: 'No Buzzwords',
        helpText: (
          <div>
            Buzzwords are terms and phrases that sound impressive, cool, or even exciting, but not everyone always
            understands the definition of them. <b>Whenever you can try to avoid them.</b>
          </div>
        ),
        checked: false,
      },
      {
        id: 'summary_spelling_errors',
        name: 'Spelling errors',
        helpText: (
          <div>
            Spelling errors significantly decrease the impact of your profile.{' '}
            <b>Check twice that you've spelled everything correctly</b>, including any industry terminology /
            certifications. You may use a tool like grammarly to check your spelling.
          </div>
        ),
        checked: false,
      },
      {
        id: 'summary_call_to_action',
        name: 'Call to action',
        helpText: (
          <div>
            Make sure that you include some sort of call to action in order to get your prospects to see/ view your
            offer/ service you name it.
          </div>
        ),
        checked: false,
      },
    ],
  },
  {
    name: 'Experience',
    type: 'experience',
    progress: 0,
    helpText: (
      <div>
        People who've made it to your work experience have read your summary and are interested in your story so far.
        They now want to make sure you have the right experience to back up everything. Recruiters and your prospects
        will pay close attention to the job titles you've held and your achievements.
      </div>
    ),
    actions: [
      {
        id: 'experience_precise_job_title',
        name: 'Precise job title',
        helpText: (
          <div>
            <b>Consider using keywords and being descriptive about your job</b>. For example, instead of just writing
            'Developer', use “Android App Developer'. This ensures you appear in more search results.
          </div>
        ),
        checked: false,
      },
      {
        id: 'experience_completeness',
        name: 'Completeness',
        helpText: (
          <div>
            Make sure you <b>fill in all the available fields</b> and the picture of your company is shown in the
            preview.
          </div>
        ),
        checked: false,
      },
      {
        id: 'experience_work_description_text',
        name: 'Work description text',
        helpText: (
          <div>
            Make sure that you give the spectator a <b>good overview of your activities, references and skills</b>. Used
            reasonably, special characters can help here and there make the description better and easier to pick up.
          </div>
        ),
        checked: false,
      },
      {
        id: 'experience_active_voice',
        name: 'Active voice',
        helpText: (
          <div>
            Whenever you can, try to use active voice instead of passiv voice.
            <br />
            <br />
            <b>Good</b>: I was doing the analytics research at Atlantic Labs.
            <br />
            <b>Improvable</b>: At Atlantics Labs the analytics research was done by me.
          </div>
        ),
        checked: false,
      },
    ],
  },
  {
    name: 'Education',
    type: 'education',
    progress: 0,
    helpText: (
      <div>
        Adding in your education gives you a more rounded profile and increases your network's overall reach.
        Additionally, recruiters often pay particular attention to this section to check that you meet qualifications
        they are looking for.
      </div>
    ),
    actions: [
      {
        id: 'education_precise_title',
        name: 'Precise title',
        helpText: (
          <div>
            <b>Consider using keywords and being descriptive about your education</b>. For example, instead of just
            writing “harvard university”, consider using “Studying Doctor of Philosophy (PhD) at harvard university”.
            This ensures you appear in more search results.
          </div>
        ),
        checked: false,
      },
      {
        id: 'education_completeness',
        name: 'Completeness',
        helpText: (
          <div>
            Make sure you <b>fill in all the available fields</b> and the picture of your company is shown in the
            preview.
          </div>
        ),
        checked: false,
      },
      {
        id: 'education_work_description_text',
        name: 'Description text',
        helpText: (
          <div>
            Make sure that you give the spectator a{' '}
            <b>good overview of your activities, references and skills that you gained in that time</b>. Used
            reasonably, special characters can help here and there make the description better and easier to pick up.
          </div>
        ),
        checked: false,
      },
      {
        id: 'education_active_voice',
        name: 'Active voice',
        helpText: (
          <div>
            Whenever you can, try to use active voice instead of passiv voice.
            <br />
            <br />
            <b>Good</b>: I was doing the analytics research at Atlantic Labs.
            <br />
            <b>Improvable</b>: At Atlantics Labs the analytics research was done by me.
          </div>
        ),
        checked: false,
      },
    ],
  },
  {
    name: 'Other Checks',
    type: 'other_checks',
    progress: 0,
    helpText: <div>Here are some final things you should do to make sure your profile is complete and memorable.</div>,
    actions: [
      {
        id: 'other_checks_custom_linkedin_profile_url',
        name: 'Custom LinkedIn profile URL',
        helpText: (
          <div>
            Consider adding a custom LinkedIn profile URL like <b>www.linkedin.com/in/firstname.lastname</b>. A custom
            URL is memorable and easier to share (i.e. on business cards, emails, job applications)
          </div>
        ),
        checked: false,
      },
      {
        id: 'other_checks_honors_and_awards_section',
        name: 'Honors and Awards section',
        helpText: (
          <div>
            Consider adding any awards or honors you've received throughout your career. This section is not critical,
            but can add value to your LinkedIn profile.
          </div>
        ),
        checked: false,
      },
      {
        id: 'other_checks_add_your_location',
        name: 'Add your location',
        helpText: (
          <div>
            Make sure your city/country is up to date as all recruiters filter candidates by location, and it ensures
            you appear in their search results.
          </div>
        ),
        checked: false,
      },
      {
        id: 'other_checks_add_certifications_or_publications',
        name: 'Add Certifications or Publications',
        helpText: (
          <div>
            This is not critical, but can add value to your LinkedIn profile since recruiters often search for
            candidates by specific qualifications or certifications.
          </div>
        ),
        checked: false,
      },
      {
        id: 'other_checks_professional_profile_photo',
        name: 'Professional profile photo',
        helpText: (
          <div>
            LinkedIn prioritizes profiles that have a photograph in search results. Additionally, a professional photo
            on your LinkedIn profile is likely to increase the number of people clicking into your profile and improve
            first impressions.
          </div>
        ),
        checked: false,
      },
      {
        id: 'other_checks_fill_the_skills',
        name: 'Fill the skills',
        helpText: (
          <div>
            Note that many recruiters filter for candidates by specific skills so you want to make sure you've added in
            all specific technical skills you have.
          </div>
        ),
        checked: false,
      },
    ],
  },
];
