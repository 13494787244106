import React, { ChangeEvent, useEffect } from 'react';
import { Statistics, StatisticsSnapshot } from '../services/statistics-service';
import { variables } from '../styles/variables';
import { store } from '../store';
import { setDashboardTimelineOption } from '../store/actions/settings';
import { Tooltip, XAxis, YAxis, AreaChart, Area } from 'recharts';
import * as Styled from './DashboardTimeline.styles';
import { HelpCircle } from '@styled-icons/ionicons-solid';

import StyledTooltip from './StyledTooltip';
import * as StyledLoading from '../styles/common/Loading.styles';
import { ChartInfoBlock } from './ChartInfoBlock';
import { useAmountStats } from '../hooks/useAmountStats';
import { helpTexts } from '../helpers/helpTexts';

export enum TIMELINE_TYPE {
  VIEWS,
  IMPRESSIONS,
  REACTIONS,
  COMMENTS,
  ENGAGEMENT,
  SHARES,
}

const COLOR_MAP = {
  [TIMELINE_TYPE.VIEWS]: variables.typeViews,
  [TIMELINE_TYPE.IMPRESSIONS]: variables.typeImpressions,
  [TIMELINE_TYPE.REACTIONS]: variables.typeLikes,
  [TIMELINE_TYPE.COMMENTS]: variables.typeComments,
  [TIMELINE_TYPE.SHARES]: variables.typeShares,
  [TIMELINE_TYPE.ENGAGEMENT]: variables.typeEngagement,
};

const LABEL_MAP = {
  [TIMELINE_TYPE.VIEWS]: 'Views',
  [TIMELINE_TYPE.IMPRESSIONS]: 'Impressions',
  [TIMELINE_TYPE.REACTIONS]: 'Reactions',
  [TIMELINE_TYPE.COMMENTS]: 'Comments',
  [TIMELINE_TYPE.SHARES]: 'Shares',
  [TIMELINE_TYPE.ENGAGEMENT]: 'Engagement Rate',
};

export interface DashboardTimelineProps {
  /**
   * Just a generic id, as there are multiple of these components on the home screen
   */
  id: number;
  group?: boolean;
  statistics: Statistics;
  referenceStatistics: Statistics | undefined;
  data: StatisticsSnapshot[] | undefined;
  isLoading: boolean;
  defaultTimeLineType?: TIMELINE_TYPE;
  typeAmount?: Statistics;
}

const DashboardTimeline: React.FC<DashboardTimelineProps> = ({
  id,
  group,
  statistics,
  referenceStatistics,
  data,
  isLoading,
  defaultTimeLineType = TIMELINE_TYPE.IMPRESSIONS,
  typeAmount,
}) => {
  const { rechartData, percentage, timelineType } = useAmountStats({
    data,
    id,
  });

  const handleOnTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const type = +e.currentTarget.value as TIMELINE_TYPE;
    store.dispatch(setDashboardTimelineOption(id, { type }));
  };

  // TODO: remove this when shares are available
  useEffect(() => {
    // handle "shares" option if one is pesisted in redux store
    if (timelineType === TIMELINE_TYPE.SHARES) {
      store.dispatch(
        setDashboardTimelineOption(id, { type: TIMELINE_TYPE.IMPRESSIONS })
      );
    }
  }, [id, timelineType]);

  useEffect(() => {
    store.dispatch(
      setDashboardTimelineOption(id, { type: defaultTimeLineType })
    );
  }, [id, defaultTimeLineType]);

  return (
    <Styled.Wrapper className='box'>
      <Styled.InfoContainer>
        <div
          className='button is-white is-rounded is-help-button'
          data-tip
          data-for={`styled-tooltip-dashboard-timeline-${id}`}
          style={{ color: variables.lightGrey }}
        >
          <span style={{ marginRight: 8 }}>
            {LABEL_MAP[timelineType]} (tracked by inlytics)
          </span>
          <HelpCircle size='1.25rem' />
        </div>
        <StyledTooltip
          id={`styled-tooltip-dashboard-timeline-${id}`}
          delayHide={1000}
          clickable={true}
        >
          <div>
            This metric is tracked after your first connection via the Chrome
            Extension. You will notice a difference from the values below, as
            this is what LinkedIn’s frontend API has historically shown us.
            Learn more here:{' '}
            <a
              href={helpTexts.SHOW_ME_WHAT_YOU_TRACK_URL}
              target='_blank'
              rel='noopener noreferrer'
            >
              Show me what you track
            </a>
          </div>
        </StyledTooltip>

        <div className='select'>
          <select onChange={handleOnTypeChange} defaultValue={timelineType}>
            {Object.entries(LABEL_MAP)
              /* TODO: remove this filter when shares are available */
              .filter(([type]) => +type !== TIMELINE_TYPE.SHARES)
              .map(([type, value]) => (
                <option key={type} value={type}>
                  {value}
                </option>
              ))}
          </select>
        </div>
      </Styled.InfoContainer>

      {isLoading && (
        <StyledLoading.LoadingContainer>
          <StyledLoading.LoadingBarSmall active />
        </StyledLoading.LoadingContainer>
      )}

      {percentage && !isLoading && (
        <div className='info-block'>
          <ChartInfoBlock
            number={percentage.increase || 0}
            percentage={percentage.percentage}
          />
        </div>
      )}

      {rechartData && !isLoading && (
        <Styled.ChartContainer width='100%'>
          <AreaChart data={rechartData}>
            <defs>
              <linearGradient
                id={`gradient-${timelineType}-${id}`}
                x1='0'
                y1='0'
                x2='0'
                y2='1'
              >
                <stop
                  offset='0%'
                  stopColor={COLOR_MAP[timelineType]}
                  stopOpacity={0.8}
                />
                <stop
                  offset='100%'
                  stopColor={COLOR_MAP[timelineType]}
                  stopOpacity={0.05}
                />
              </linearGradient>
            </defs>

            <XAxis dataKey='timestamp' tickLine={false} axisLine={false} />
            <YAxis dataKey='value' tickLine={false} axisLine={false} />
            <Tooltip />

            <Area
              type='monotone'
              dataKey='value'
              name={LABEL_MAP[timelineType]}
              fill={`url(#gradient-${timelineType}-${id})`}
              stroke={COLOR_MAP[timelineType]}
            />
          </AreaChart>
        </Styled.ChartContainer>
      )}
    </Styled.Wrapper>
  );
};

export default DashboardTimeline;
