import axios from 'axios';

export interface APIError {
  status: number;
  message: string;
  error: Error;
  stack?: string;
}

export interface APIRequestMeta {
  onSuccess: (payload?: any) => void;
  onError: (payload?: any) => void;
}

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
  transformResponse: [],
});

apiClient.interceptors.response.use(
  res => {
    if (!res.data) {
      return res;
    }
    // Parse JSON and parse date strings
    res.data = JSON.parse(res.data, (_, value) => {
      if (
        typeof value === 'string' &&
        /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/.test(value)
      ) {
        return new Date(value);
      }
      return value;
    });
    return res;
  },
  e => {
    let error: Error = e;
    try {
      error = JSON.parse(e?.response?.data).error;
    } catch (parseErr) {
      console.error('Failed to parse server response');
      console.error(parseErr);
    }
    console.error(error);
    return Promise.reject(error);
  }
);

export default apiClient;
