import React from 'react';

interface Props {
  text: string;
}

export const NoContentBox: React.FC<Props> = ({ text }) => (
  <div className='security-settings-no-content'>
    <img src='/assets/images/parcel.svg' alt='empty box' />
    <span className='security-settings-no-content__text'>{text}</span>
  </div>
);
