import React, { useCallback, useEffect, useState } from "react";
import Header from "../Header";
import { Link, useParams } from "react-router-dom";
import { LinkedInAccount, loadAccount } from "../../services/account-service";
import "../../styles/components/Profile.scss";
import { addWeeks, getWeek } from "date-fns";
import {
  getProfileConnections,
  LinkedInConnection
} from "../../services/profile-service";
import ProfileOptimizationChecklist from "./ProfileOptimizationChecklist";
import { useQuery } from "react-query";
import { APIError } from "../../services/api-client";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { USER_PLAN } from "../../services/user-service";
import PremiumTeaser from "../payment/PremiumTeaser";
import { HelpCircle, LockClosed, Timer } from "@styled-icons/ionicons-solid";
import linkedinConnectionsImage from "../../assets/images/premium-teaser/linkedin-connections.png";
import followersFollowingImage from "../../assets/images/premium-teaser/followers-following.png";
import ProfileViewTimeline, { ProfileCsv } from "./ProfileViewTimeline";
import FollowerTimeline, { FollowersCsv } from "./FollowerTimeline";
import ConnectionTimeline, { ConnectionsCsv } from "./ConnectionTimeline";
import { CsvLoad, PageType } from "../CsvLoad";
import {
  IProfileConverter,
  profileConverter
} from "../../services/csv-service";
import { useDemoUser } from "../../hooks/useDemoUser";
import StyledTooltip from "../StyledTooltip";

const Profile = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { selected } = useSelector((state: RootState) => state.linkedAccounts);

  const [newConnections, setNewConnections] = useState(0);
  const [csvData, setCsvData] = useState<IProfileConverter>();

  const { data: account, isLoading } = useQuery<
    LinkedInAccount,
    APIError,
    [string, string]
  >([accountId, "loadAccount"], loadAccount);

  const { data: connections } = useQuery<
    LinkedInConnection[],
    APIError,
    [string, string]
  >([accountId, "getProfileConnections"], accountId =>
    getProfileConnections(accountId)
  );

  const isDemoUser = useDemoUser(selected);

  useEffect(() => {
    if (!connections?.length) {
      setNewConnections(0);
      return;
    }

    // Count how many connections there were since last week
    let newLastWeek = 0;
    const lastWeekDate = addWeeks(new Date(), -1);
    const lastWeek = getWeek(lastWeekDate, { weekStartsOn: 1 });
    const lastWeekYear = lastWeekDate.getFullYear();
    connections.forEach(con => {
      if (
        con.createdAt.getFullYear() === lastWeekYear &&
        getWeek(con.createdAt, { weekStartsOn: 1 }) === lastWeek
      ) {
        newLastWeek++;
      }
    });
    setNewConnections(newLastWeek);
  }, [connections, setNewConnections]);

  const addToCsv = useCallback(
    (data: any) => {
      const search = "Search appearances";
      setCsvData(prevState => ({
        ...(prevState || {}),
        ...data,
        [search]: account?.amountSearchAppearances
      }));
    },
    [account]
  );

  useEffect(() => {
    addToCsv({
      [ConnectionsCsv["Connections in total"]]: connections?.length,
      [ConnectionsCsv["New connections last week"]]: newConnections,
      [FollowersCsv["Followers total"]]: account?.amountFollowers,
      [FollowersCsv["Following total"]]: account?.amountFollowing,
      [ProfileCsv["Profile views last 90 days"]]: account?.amountProfileViews
    });
  }, [connections, account, newConnections, addToCsv]);

  return (
    <div className="Profile">
      <Header
        title="Profile"
        loading={isLoading}
        content={
          csvData && (
            <CsvLoad
              type={PageType.Profile}
              data={csvData}
              converter={profileConverter}
            />
          )
        }
      />
      <div className="section wrapper">
        {account && (
          <>
            <div className="profile-info-container">
              <div className="sticky-container">
                <h5 className="title is-size-5 has-text-grey has-text-weight-normal">
                  Your profile
                </h5>
                <div className="box profile-info">
                  <img
                    className="is-rounded avatar"
                    src={
                      isDemoUser
                        ? user?.profile.picture
                        : account.profile.displayPhoto.small
                    }
                    alt="Account avatar"
                  />
                  <span className="name">
                    {isDemoUser
                      ? `${user?.profile.givenName} ${user?.profile.familyName}`
                      : `${account.profile.firstName} ${account.profile.lastName}`}
                  </span>
                  {!isDemoUser && (
                    <span className="occupation">
                      {account.profile.occupation}
                    </span>
                  )}
                  {/* TODO profile description */}
                </div>
              </div>
            </div>

            <div className="profile-content-container">
              <section className="section">
                <h5 className="title is-size-5 has-text-grey has-text-weight-normal">
                  Profile views
                  <HelpCircle
                    data-tip
                    data-for={`styled-tooltip-dashboard-interval-top-posts`}
                    size={"1.5rem"}
                    style={{
                      marginLeft: "0.5rem"
                    }}
                  />
                  <StyledTooltip
                    id={`styled-tooltip-dashboard-interval-top-posts`}
                    delayHide={500}
                    clickable={true}
                  >
                    <p
                      style={{
                        textTransform: "initial"
                      }}
                    >
                      <Timer
                        height={"1rem"}
                        style={{
                          marginRight: "0.3rem"
                        }}
                      />
                      <span
                        style={{
                          textTransform: "initial"
                        }}
                      >
                        Updates every 12 hours
                      </span>
                    </p>
                  </StyledTooltip>
                </h5>
                <div className="box profile-view-history-chart">
                  <ProfileViewTimeline
                    brushEnabled={true}
                    onDataLoaded={addToCsv}
                  />
                </div>
              </section>

              <section className="section">
                <h5 className="title is-size-5 has-text-grey has-text-weight-normal">
                  Recomendations to optimize your Profile appearence
                </h5>
                <div className="box">
                  <ProfileOptimizationChecklist />
                </div>
              </section>

              <section className="section">
                <h5 className="title is-size-5 has-text-grey has-text-weight-normal">
                  Statistics
                </h5>
                <div className="profile-stats-container">
                  <div className="profile-stat box profile-views">
                    <div
                      className="title"
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      Profile views
                      <HelpCircle
                        data-tip
                        data-for={`styled-tooltip-dashboard-views-card`}
                        size={"1.5rem"}
                        style={{
                          marginLeft: "0.5rem"
                        }}
                      />
                      <StyledTooltip
                        id={`styled-tooltip-dashboard-views-card`}
                        delayHide={500}
                        clickable={true}
                      >
                        <p
                          style={{
                            textTransform: "initial"
                          }}
                        >
                          <Timer
                            size={"1rem"}
                            color="#838485"
                            style={{
                              marginRight: "0.3rem"
                            }}
                          />
                          <span
                            style={{
                              textTransform: "initial"
                            }}
                          >
                            Updates every 12 hours
                          </span>
                        </p>
                      </StyledTooltip>
                    </div>
                    <div className="value">
                      {account.amountProfileViews || 0}
                    </div>
                    <div className="bottom-text">Last 90 days</div>
                  </div>
                  <div className="profile-stat box search-appearances">
                    <div
                      className="title"
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      Search appearances
                      <HelpCircle
                        data-tip
                        data-for={`styled-tooltip-dashboard-search-card`}
                        size={"1.5rem"}
                        style={{
                          marginLeft: "0.5rem"
                        }}
                      />
                      <StyledTooltip
                        id={`styled-tooltip-dashboard-search-card`}
                        delayHide={500}
                        clickable={true}
                      >
                        <p
                          style={{
                            textTransform: "initial"
                          }}
                        >
                          <Timer
                            size={"1rem"}
                            color="#838485"
                            style={{
                              marginRight: "0.3rem"
                            }}
                          />
                          <span
                            style={{
                              textTransform: "initial"
                            }}
                          >
                            Updates once a day
                          </span>
                        </p>
                      </StyledTooltip>
                    </div>
                    <div className="value">
                      {user!.plan === USER_PLAN.FREE ? (
                        <Link to="/upgrade" className="button">
                          <span className="icon">
                            <LockClosed size="1rem" />
                          </span>
                          <span>Upgrade</span>
                        </Link>
                      ) : (
                        account.amountSearchAppearances || 0
                      )}
                    </div>
                    <div className="bottom-text">Last week</div>
                  </div>
                  <div className="profile-stat box connections">
                    <div
                      className="title"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        textTransform: "initial"
                      }}
                    >
                      Connections
                      <HelpCircle
                        data-tip
                        data-for={`styled-tooltip-dashboard-connections-card`}
                        size={"1.5rem"}
                        style={{
                          marginLeft: "0.5rem"
                        }}
                      />
                      <StyledTooltip
                        id={`styled-tooltip-dashboard-connections-card`}
                        delayHide={500}
                        clickable={true}
                      >
                        <p>
                          <Timer
                            size={"1rem"}
                            color="#838485"
                            style={{
                              marginRight: "0.3rem"
                            }}
                          />
                          Updates every 6 hours
                        </p>
                      </StyledTooltip>
                    </div>
                    <div className="value">{connections?.length}</div>
                    <div className="bottom-text">Total</div>
                  </div>
                  <div className="profile-stat box connections">
                    <div
                      className="title"
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      New Connections
                      <HelpCircle
                        data-tip
                        data-for={`styled-tooltip-dashboard-newconnections-card`}
                        size={"1.5rem"}
                        style={{
                          marginLeft: "0.5rem"
                        }}
                      />
                      <StyledTooltip
                        id={`styled-tooltip-dashboard-newconnections-card`}
                        delayHide={500}
                        clickable={true}
                      >
                        <p
                          style={{
                            textTransform: "initial"
                          }}
                        >
                          <Timer
                            size={"1rem"}
                            color="#838485"
                            style={{
                              marginRight: "0.3rem"
                            }}
                          />
                          <span
                            style={{
                              textTransform: "initial"
                            }}
                          >
                            Updates every 6 hours
                          </span>
                        </p>
                      </StyledTooltip>
                    </div>
                    <div className="value">
                      {user!.plan === USER_PLAN.FREE ? (
                        <Link to="/upgrade" className="button">
                          <span className="icon">
                            <LockClosed size="1rem" />
                          </span>
                          <span>Upgrade</span>
                        </Link>
                      ) : (
                        newConnections
                      )}
                    </div>
                    <div className="bottom-text">Made last week</div>
                  </div>
                  <div className="profile-stat box amount-followers">
                    <div
                      className="title"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        textTransform: "initial"
                      }}
                    >
                      Followers
                      <HelpCircle
                        data-tip
                        data-for={`styled-tooltip-dashboard-followers-card`}
                        size={"1.5rem"}
                        style={{
                          marginLeft: "0.5rem"
                        }}
                      />
                      <StyledTooltip
                        id={`styled-tooltip-dashboard-followers-card`}
                        delayHide={500}
                        clickable={true}
                      >
                        <p>
                          <Timer
                            size={"1rem"}
                            color="#838485"
                            style={{
                              marginRight: "0.3rem"
                            }}
                          />
                          Updates every 6 hours
                        </p>
                      </StyledTooltip>
                    </div>
                    <div className="value">{account.amountFollowers || 0}</div>
                    <div className="bottom-text">Total</div>
                  </div>
                  <div className="profile-stat box amount-following">
                    <div
                      className="title"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        textTransform: "initial"
                      }}
                    >
                      Following
                      <HelpCircle
                        data-tip
                        data-for={`styled-tooltip-dashboard-following-card`}
                        size={"1.5rem"}
                        style={{
                          marginLeft: "0.5rem"
                        }}
                      />
                      <StyledTooltip
                        id={`styled-tooltip-dashboard-following-card`}
                        delayHide={500}
                        clickable={true}
                      >
                        <p>
                          <Timer
                            size={"1rem"}
                            color="#838485"
                            style={{
                              marginRight: "0.3rem"
                            }}
                          />
                          Updates every 6 hours
                        </p>
                      </StyledTooltip>
                    </div>
                    <div className="value">{account.amountFollowing || 0}</div>
                    <div className="bottom-text">Total</div>
                  </div>
                </div>
              </section>

              <section className="section">
                <h5 className="title is-size-5 has-text-grey has-text-weight-normal">
                  Connections
                  <HelpCircle
                    data-tip
                    data-for={`styled-tooltip-dashboard-following-card`}
                    size={"1.5rem"}
                    style={{
                      marginLeft: "0.5rem"
                    }}
                  />
                  <StyledTooltip
                    id={`styled-tooltip-dashboard-following-card`}
                    delayHide={500}
                    clickable={true}
                  >
                    <p
                      style={{
                        textTransform: "initial"
                      }}
                    >
                      <Timer
                        size={"1rem"}
                        color="#838485"
                        style={{
                          marginRight: "0.3rem"
                        }}
                      />
                      Updates every 6 hours
                    </p>
                  </StyledTooltip>
                </h5>
                <div className="box">
                  {user!.plan === USER_PLAN.FREE ? (
                    <PremiumTeaser
                      requiredPlan={USER_PLAN.SOLO}
                      image={linkedinConnectionsImage}
                    />
                  ) : (
                    <ConnectionTimeline brushEnabled={true} />
                  )}
                </div>
              </section>

              <section className="section">
                <h5 className="title is-size-5 has-text-grey has-text-weight-normal">
                  Followers &amp; Following
                  <HelpCircle
                    data-tip
                    data-for={`styled-tooltip-dashboard-newconnections-card`}
                    size={"1.5rem"}
                    style={{
                      marginLeft: "0.5rem"
                    }}
                  />
                  <StyledTooltip
                    id={`styled-tooltip-dashboard-newconnections-card`}
                    delayHide={500}
                    clickable={true}
                  >
                    <p
                      style={{
                        textTransform: "initial"
                      }}
                    >
                      <span
                        style={{
                          textTransform: "initial"
                        }}
                      >
                        The Followers and Following data are metrics inlytics is
                        unable to pull historical data
                      </span>
                    </p>
                  </StyledTooltip>
                </h5>
                <div className="box">
                  {user!.plan === USER_PLAN.FREE ? (
                    <PremiumTeaser
                      requiredPlan={USER_PLAN.SOLO}
                      image={followersFollowingImage}
                    />
                  ) : (
                    <FollowerTimeline brushEnabled={true} />
                  )}
                </div>
              </section>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
