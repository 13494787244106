import React, { useContext } from 'react';

import AccountInputComponent from './AccountInputComponent';
import ChooseTool from './ChooseTool';
import ToolsCard from './ToolsCard';
import { FlaskIcon, ShieldCheckmarkIcon } from '../CustomIcons';
import { CompanyUserRoles } from './AccountSharingModal';
import '../../styles/components/accountSharing/InviteGroupMember.scss';

interface Props {
  context: React.Context<any>;
}

const InviteCompanyMember: React.FC<Props> = ({ context }) => {
  const { userInputData, userCompanyRole } = useContext(context);

  const [emailsInput, setEmailsInput] = userInputData;
  const [companyRole, setCompanyRole] = userCompanyRole;

  return (
    <div className='invite-group-member'>
      <h2 className='headline-2'>Invite People</h2>
      <p className='description-main'>
        Invite people and define their role for this managed company page (later you’d be able to change the level of
        permission and invite more people).
      </p>
      <AccountInputComponent
        state={emailsInput}
        setState={setEmailsInput}
        headline='Company Access'
        description='Enter the email adresses of the people you want to join this page'
        note='(add comma to seperate adresses).'
      />
      <ChooseTool headline='Define The Rights For The Members Who Join'>
        <ToolsCard
          image={FlaskIcon}
          headline='Analyst (default role for most team members)'
          description='Can view the team statistics (dashboard).'
          state={companyRole === CompanyUserRoles.Analyst}
          setState={(val: boolean) => setCompanyRole(val ? CompanyUserRoles.Analyst : undefined)}
        />
        <ToolsCard
          image={ShieldCheckmarkIcon}
          headline='Admin'
          description='Can view the team statistics (dashboard). Can add or delete group members. Can define new admins.'
          state={companyRole === CompanyUserRoles.Admin}
          setState={(val: boolean) => setCompanyRole(val ? CompanyUserRoles.Admin : undefined)}
        />
      </ChooseTool>
    </div>
  );
};

export default InviteCompanyMember;
