import apiClient from '../services/api-client';

export const getUserLabels = async (userId: string): Promise<string[]> => {
  const USER_LABELS_ENDPOINT = `/users/me/${userId}/labels`;
  const response = await apiClient.get(USER_LABELS_ENDPOINT);
  return response.data.labels;
};

export const getActivityLabels = async (activityId: string) => {
  const ACTIVITY_LABELS_ENDPOINT = `/activities/${activityId}/labels`;
  const response = await apiClient.get(ACTIVITY_LABELS_ENDPOINT);
  return response.data.labels;
};

interface TLabelsChanges {
  toSubmitLabels: string[];
  initialLabels?: string[];
}

export const labelsChange = ({
  toSubmitLabels,
  initialLabels,
}: TLabelsChanges) =>
  initialLabels &&
  initialLabels.length === toSubmitLabels.length &&
  initialLabels &&
  initialLabels.every((v, i) => v === toSubmitLabels[i]);
