import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CaretForward, Refresh, Settings } from '@styled-icons/ionicons-solid'; // Refresh
import { AddCircle } from '@styled-icons/fluentui-system-filled/AddCircle';
import classNames from 'classnames';
import { useMutation } from 'react-query';

import {
  deleteMainAccount,
  useMainAccount,
} from '../../services/account-service';
import { removeAccountConnection } from '../../services/profile-service';
import * as Styled from './AccountList.styles';
import StyledTooltip from '../StyledTooltip';
import { AlbumsIcon, AnalyzeIcon, ScheduleIcon } from '../CustomIcons';
import { StyledTable } from '../../styles/common/Tables.styles';
import { APIError } from '../../services/api-client';
import DeleteButton from '../button/DeleteButton';
import {
  CompanyUserRoles,
  GroupUserRoles,
} from '../accountSharing/AccountSharingModal';
import UnlinkButton from '../button/UnlinkButton';
import {
  deleteGroup,
  getGroups,
  unlinkGroup,
} from '../../services/groups-service';
import { USER_PLAN } from '../../services/user-service';
import { DeleteModal } from './delete-modal/DeleteModal';
import Modal from '../modal/Modal';
import { Button } from '../button/Button';
import { GroupEditModal } from '../settings/group/GroupEditModal';
import { useDemoUser } from '../../hooks/useDemoUser';
import { deleteCompany, getCompanies } from '../../services/company-service';
import { CompanyEditModal } from '../settings/organizations/CompanyEditModal';
import { AccountAvatar } from '../accountAvatar/AccountAvatar';
import { helpTexts } from '../../helpers/helpTexts';
import UpgradeTooltip from '../UpgradeTooltip';

export enum AccountListTypes {
  Own = 'own',
  Shared = 'shared',
  Group = 'group',
  Company = 'company',
}

export interface AccountsTableEntryViewModel {
  id: string;
  accountId?: string;
  avatar?: string;
  name: string;
  plan?: string;
  status?: boolean;
  role?: GroupUserRoles | CompanyUserRoles;
  isSharedAnalytics?: boolean;
  isSharedSchedule?: boolean;
  isDemographicsLoaded?: boolean;
  members?: number;
  hashtags?: string;
}

interface AccountListProps {
  accounts: AccountsTableEntryViewModel[];
  type: AccountListTypes;
  isCreateNewAvailable: boolean;
  createNewText?: string;
  createNewHandler?: React.Dispatch<React.SetStateAction<any>>;
  personalAccount?: boolean;
}

export interface DeleteModalProps {
  deleteId?: string;
  type: AccountListTypes;
  label: 'remove' | 'unlink';
  role?: GroupUserRoles | CompanyUserRoles;
}

const DELETE_BUTTON_DATA_TIPS: { [K in AccountListTypes]: string } = {
  [AccountListTypes.Own]: 'Delete this account.',
  [AccountListTypes.Shared]:
    'Unlinking this profile will remove your access to this account.',
  [AccountListTypes.Group]: 'Delete this group.',
  [AccountListTypes.Company]: 'Delete this company',
};

const generateLink = (
  type: AccountListTypes,
  accountId: string | undefined,
  optionId: string
) => {
  return type === AccountListTypes.Group
    ? `/account/${accountId}/group/${optionId}/analyze`
    : type === AccountListTypes.Company
    ? `/company/${optionId}/visitors`
    : '';
};

const AccountList = ({
  accounts,
  type,
  isCreateNewAvailable,
  createNewText,
  createNewHandler,
}: AccountListProps) => {
  const { data: mainAccount, refetch: refetchMainAccount } = useMainAccount();
  const isDemoUser = useDemoUser();

  // info for delete modal window
  const [current, setCurrent] = useState<DeleteModalProps>({
    type,
    label: 'unlink',
  });

  const [uniqueKey, setUniqueKey] = useState<string>('');
  const [chosenAccount, setChosenAccount] = useState<
    { account: AccountsTableEntryViewModel; type: AccountListTypes } | undefined
  >();

  const isEmpty = useMemo(() => !accounts?.length, [accounts]);
  const [
    deleteMainAccountMutation,
    { isLoading: mainAccountDeletionLoading },
  ] = useMutation<any, APIError>(
    () => {
      const confirm = window.confirm(
        `Are you sure you want to remove this account?
Any data that was recorded will be lost.
This action cannot be undone.`
      );
      if (!confirm) {
        return Promise.resolve();
      }
      return deleteMainAccount();
    },
    {
      onError: err => alert(err.message),
      onSuccess: () => {
        refetchMainAccount();
      },
    }
  );

  const handleRemove = (id: string, label?: 'remove' | 'unlink') => {
    if (type === AccountListTypes.Own) {
      deleteMainAccountMutation();
    } else if (type === AccountListTypes.Shared) {
      const confirm = window.confirm(
        `Are you sure you want to drop access to this account?
Access will be lost.
This action cannot be undone.`
      );

      if (confirm) {
        removeAccountConnection(id);
      }
    } else if (type === AccountListTypes.Company && label) {
      deleteCompany(id, label).catch((e: Error) => console.log(e.message));
    } else {
      if (label === 'remove') {
        deleteGroup(id);
      } else {
        unlinkGroup(id);
      }
    }
  };

  const saveAccount = () => {
    if (chosenAccount?.type === AccountListTypes.Group)
      getGroups().then(() => setUniqueKey(Date.now().toString()));
    if (chosenAccount?.type === AccountListTypes.Company)
      getCompanies().catch(console.error);
    setChosenAccount(undefined);
  };

  const closeAccount = () => {
    if (chosenAccount?.type === AccountListTypes.Group)
      getGroups().catch(console.error);
    if (chosenAccount?.type === AccountListTypes.Company)
      getCompanies().catch(console.error);
    setChosenAccount(undefined);
  };

  return (
    <>
      <Styled.Wrapper>
        <Styled.Card className='px-0'>
          <Styled.Table className='table is-fullwidth'>
            <thead>
              <tr>
                <StyledTable.Header className='is-avatar'>
                  {/* Avatar */}
                </StyledTable.Header>
                <StyledTable.Header className='table__cell table__cell--name'>
                  {type === AccountListTypes.Company && 'Company '}Name
                </StyledTable.Header>
                <StyledTable.Header className='is-centered'>
                  {type !== AccountListTypes.Group &&
                  type !== AccountListTypes.Company
                    ? 'Account Type'
                    : ''}
                </StyledTable.Header>
                <StyledTable.Header className='is-centered'>
                  {type === AccountListTypes.Group ||
                  type === AccountListTypes.Company
                    ? 'Role'
                    : 'Status'}
                </StyledTable.Header>
                <StyledTable.Header>{/* Reconnect */}</StyledTable.Header>
                <StyledTable.Header className='is-centered'>
                  Products
                </StyledTable.Header>
                <StyledTable.Header className='is-centered'>
                  {type === AccountListTypes.Group ? 'Group Members' : ''}
                  {type === AccountListTypes.Company ? 'Company Members' : ''}
                </StyledTable.Header>
                <StyledTable.Header className='is-centered'>
                  Actions
                </StyledTable.Header>
              </tr>
            </thead>
            <tbody>
              {accounts.map(account => {
                const hasAccessToSchedulePage = ![
                  USER_PLAN.ANALYZE,
                  USER_PLAN.PROFESSIONAL,
                ].includes(account.plan as USER_PLAN);
                return (
                  <tr key={account.id}>
                    <Styled.AvatarCell className='is-avatar'>
                      {!!account.avatar && (
                        <figure className='image'>
                          <AccountAvatar
                            account={account}
                            uniqueKey={uniqueKey}
                          />
                          {mainAccount?._id === account.id && (
                            <Styled.MainAccountCheckmark data-tip='This symbol indicates that you are the owner of this account.' />
                          )}
                        </figure>
                      )}
                    </Styled.AvatarCell>
                    <Styled.TableCell className='table__cell table__cell--name'>
                      <b>{account.name}</b>
                    </Styled.TableCell>
                    <Styled.TableCell className='is-centered'>
                      <div>
                        {type !== AccountListTypes.Group &&
                        type !== AccountListTypes.Company
                          ? account.plan
                          : ''}
                      </div>
                      {account.plan &&
                        [USER_PLAN.SOLO, USER_PLAN.FREE].includes(
                          account.plan as USER_PLAN
                        ) && (
                          <div className='inline-block'>
                            <Link to='/upgrade'>
                              <Styled.LinkToPlan>
                                <CaretForward size='1rem' />
                                Upgrade Now!
                              </Styled.LinkToPlan>
                            </Link>
                          </div>
                        )}
                    </Styled.TableCell>
                    {type === AccountListTypes.Group ||
                    type === AccountListTypes.Company ? (
                      <Styled.TableCell className='is-centered'>
                        {account.role}
                      </Styled.TableCell>
                    ) : (
                      <Styled.TableCell
                        className={classNames('is-centered', {
                          'has-text-orange': !account.status,
                          'has-text-success': account.status,
                        })}
                      >
                        <span>
                          {account.status ? 'Connected' : 'Not connected'}
                        </span>
                      </Styled.TableCell>
                    )}
                    <Styled.TableCell className='is-centered'>
                      {type === AccountListTypes.Own && !account.status && (
                        <Styled.ReconnectCell>
                          <Styled.ReconnectBtn
                            data-tip
                            data-for='reconnect-info'
                          >
                            <span className='margin-right'>Reconnect</span>
                            <Refresh size='1.2rem' />
                            <StyledTooltip
                              id='reconnect-info'
                              delayHide={1000}
                              clickable={true}
                            >
                              <div>
                                <span>
                                  To reconnect your account open your chrome
                                  extension and click "Connect" again -{' '}
                                </span>
                                <a
                                  href={helpTexts.HOW_TO_RECONNECT_BLOG_URL}
                                  className='link-text'
                                >
                                  learn more here
                                </a>
                              </div>
                            </StyledTooltip>
                          </Styled.ReconnectBtn>
                        </Styled.ReconnectCell>
                      )}
                    </Styled.TableCell>
                    <Styled.TableCell>
                      <Styled.ProductButtonsWrapper>
                        {type === AccountListTypes.Group ||
                        type === AccountListTypes.Company ? (
                          <>
                            {(account.isSharedAnalytics &&
                              account.role !== GroupUserRoles.User) ||
                            type === AccountListTypes.Company ? (
                              <Styled.ProductButton
                                to={generateLink(
                                  type,
                                  mainAccount?._id,
                                  account.id
                                )}
                                className='button'
                              >
                                <AlbumsIcon />
                                <span>Dashboard</span>
                              </Styled.ProductButton>
                            ) : (
                              <Styled.ProductButtonDisabled className='button'>
                                <AlbumsIcon className='is-disabled' />
                                <span>Dashboard</span>
                              </Styled.ProductButtonDisabled>
                            )}
                          </>
                        ) : (
                          <>
                            {account.isSharedAnalytics ? (
                              <Styled.ProductButton
                                to={`/account/${account.accountId}/analyze`}
                                className='button'
                              >
                                <AnalyzeIcon />
                                <span>Analyze</span>
                              </Styled.ProductButton>
                            ) : (
                              <Styled.ProductButtonDisabled className='button'>
                                <AnalyzeIcon className='is-disabled' />
                                <span>Analyze</span>
                              </Styled.ProductButtonDisabled>
                            )}
                          </>
                        )}

                        {type === AccountListTypes.Group ||
                        type === AccountListTypes.Company ? (
                          <>
                            {/* TODO: add links for groups */}
                            {account.isSharedSchedule && (
                              <Styled.ProductButtonDisabled className='button'>
                                <ScheduleIcon className='is-disabled' />
                                <span>Schedule</span>
                              </Styled.ProductButtonDisabled>
                            )}
                          </>
                        ) : (
                          <>
                            {account.isSharedSchedule &&
                            hasAccessToSchedulePage ? (
                              <Styled.ProductButton
                                to={`/account/${account.accountId}/schedule`}
                                className='button'
                              >
                                <ScheduleIcon />
                                <span>Schedule</span>
                              </Styled.ProductButton>
                            ) : (
                              <div data-tip data-for='schedule-btn-access'>
                                <UpgradeTooltip id={'schedule-btn-access'} />
                                <Styled.ProductButtonDisabled className='button'>
                                  <ScheduleIcon className='is-disabled' />
                                  <span>Schedule</span>
                                </Styled.ProductButtonDisabled>
                              </div>
                            )}
                          </>
                        )}
                      </Styled.ProductButtonsWrapper>
                    </Styled.TableCell>
                    <Styled.TableCell className='is-centered'>
                      {type === AccountListTypes.Group ||
                      type === AccountListTypes.Company
                        ? account.members
                        : ''}
                    </Styled.TableCell>
                    <Styled.TableCell className='has-text-grey'>
                      <div className='buttons  is-centered'>
                        {type === AccountListTypes.Own ||
                        account.role === GroupUserRoles.Admin ||
                        account.role === CompanyUserRoles.Admin ? (
                          <DeleteButton
                            disabled={isDemoUser}
                            className={classNames({
                              'is-loading': mainAccountDeletionLoading,
                              'is-disabled': isDemoUser,
                            })}
                            data-tip={DELETE_BUTTON_DATA_TIPS[type]}
                            onClick={() =>
                              setCurrent({
                                deleteId: account.id,
                                label: 'remove',
                                type,
                                role: account.role,
                              })
                            }
                          />
                        ) : (
                          <UnlinkButton
                            disabled={isDemoUser}
                            className={classNames({
                              'is-loading': mainAccountDeletionLoading,
                              'is-disabled': isDemoUser,
                            })}
                            data-tip={
                              type === AccountListTypes.Shared
                                ? DELETE_BUTTON_DATA_TIPS[type]
                                : 'Unlinking this group will remove you from the group.'
                            }
                            onClick={() =>
                              setCurrent({
                                deleteId: account.id,
                                label: 'unlink',
                                type,
                                role: account.role,
                              })
                            }
                          />
                        )}
                        {((type === AccountListTypes.Group &&
                          account.role === GroupUserRoles.Admin) ||
                          (type === AccountListTypes.Company &&
                            account.role === CompanyUserRoles.Admin)) && (
                          <Button
                            onClick={() => setChosenAccount({ account, type })}
                          >
                            <Settings size='1rem' />
                          </Button>
                        )}
                      </div>
                    </Styled.TableCell>
                  </tr>
                );
              })}
              {isCreateNewAvailable && (
                <Styled.CreateNewRow isEmpty={isEmpty}>
                  <td>
                    <AddCircle
                      className='icon create-text'
                      onClick={createNewHandler}
                    />
                  </td>
                  <td>
                    <p
                      onClick={createNewHandler}
                      className='create-text create-text_content'
                    >
                      {createNewText}
                    </p>
                  </td>
                  <Styled.AvatarCell className='is-avatar px-4 has-text-centered' />
                  <td />
                  {isEmpty ? (
                    <td className='no-content has-text-grey'>
                      <img src='/assets/images/parcel.svg' alt='empty box' />
                      <span className='mt-2'>No other Profiles here</span>
                    </td>
                  ) : (
                    <td />
                  )}
                  <td />
                  <td />
                  <td />
                </Styled.CreateNewRow>
              )}
            </tbody>
          </Styled.Table>
          <StyledTooltip />
        </Styled.Card>
      </Styled.Wrapper>

      {chosenAccount && (
        <Modal className='' open={true} fullWidth>
          <div className='box group-edit'>
            {chosenAccount.type === AccountListTypes.Group && (
              <GroupEditModal
                data={chosenAccount.account}
                onClose={closeAccount}
                onSave={saveAccount}
              />
            )}
            {chosenAccount.type === AccountListTypes.Company && (
              <CompanyEditModal
                data={chosenAccount.account}
                onClose={closeAccount}
                onSave={saveAccount}
              />
            )}
          </div>
        </Modal>
      )}

      {current?.deleteId && (
        <Modal className='' open={true} fullWidth>
          <div className='box delete-modal'>
            <DeleteModal
              onAccept={handleRemove}
              onClose={setCurrent.bind(null, { type, label: 'unlink' })}
              id={current.deleteId}
              label={current.label}
              type={current.type}
              role={current.role}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default AccountList;
