import React from 'react';
import '../../styles/components/LoginRegisterPage.scss';
import dashboard from '../../assets/images/linkedin-dashboard-analytics-tool.jpg';
import dashboard2x from '../../assets/images/linkedin-dashboard-analytics-tool@2x.jpg';
import { RegisterAppSumoForm } from './RegisterAppSumoForm';

const RegisterAppSumoPage: React.FC = () => {
  return (
    <div className='LoginRegisterPage'>
      <div className='columns'>
        {/* Actual forms */}
        <div className='column left-side'>
          <section className='section'>
            <div className='title-container'>
              <h1 className='title is-1'>Hey Sumo-lings &#128400;</h1>
              <h2 className='subtitle is-5 has-text-grey mt-4'>
                Please share your Name, Email address, AppSumo Code and choose a save password to get started using
                inlytics on your lifetime plan!
              </h2>
              <h2 className='subtitle is-5 has-text-grey mt-4'>
                After your successful submission you’ll receive an email with some onboarding material on how to use
                inlytics.
              </h2>
              <h2 className='subtitle is-5 has-text-grey mt-4 mb-0'>Much love &#128153;</h2>
              <h2 className='subtitle is-5 has-text-grey mt-0'>The inlytics Team</h2>
            </div>
            <section className='section'>
              <div className='columns is-centered is-vcentered'>
                <div className='column is-narrow box mt-5'>
                  <div className='form-container appsumo-form-container'>
                    <RegisterAppSumoForm />
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>

        {/* Screenshot preview */}
        <div className='column image-container'>
          <figure className='image'>
            <img src={dashboard} srcSet={`${dashboard2x} 2x`} alt='Screenshot showing the account dashboard' />
          </figure>
        </div>
      </div>
    </div>
  );
};

export default RegisterAppSumoPage;
