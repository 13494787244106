import React from 'react';
import { MutateFunction } from 'react-query';
import { APIError } from '../../../../services/api-client';
import { Styled as StyledModal } from '../../../modal/Modal.styles';
import { Trash } from '@styled-icons/ionicons-solid';
import './DeleteModal.scss';

interface Props {
  id: string;
  objectId: string;
  onClose: () => void;
  onSuccess: () => void;
  closeModal: () => void;
  remove: MutateFunction<any, APIError, any>;
}

const DeleteModal: React.FC<Props> = ({ objectId, id, onClose, onSuccess, closeModal, remove }) => {
  const deleteHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    remove({ id, objectId }).catch();
  };

  return (
    <StyledModal.ContentCard>
      <StyledModal.Headline>Sure To Remove User?</StyledModal.Headline>

      <p className='has-text-grey mb-4'>
        This action will remove the person from the group. The person will be notified that her/ his account has been
        removed. Please do mind that all data from this person that has been captured to this point, will still be
        visible within your dashboard.
      </p>

      <div className='level button-container'>
        <button className='button is-danger level-right' onClick={deleteHandler}>
          <span className='icon'>
            <Trash size='1rem' />
          </span>
          <span>Yes, remove user</span>
        </button>
        <button className='button is-secondary' onClick={onClose}>
          No, go back
        </button>
      </div>
    </StyledModal.ContentCard>
  );
};

export { DeleteModal };
