export enum ACTION_TYPE {
  SET_DATE_RANGE_FILTER,
  SET_USER,
  REMOVE_LOGGED_IN_USER,
  SET_LINKED_ACCOUNTS,
  SET_MAIN_ACCOUNT,
  SET_SHARED_CONNECTIONS,
  SET_LINKED_ACCOUNTS_REQUEST,
  SET_GROUPS,
  SELECT_ACCOUNT,
  SELECT_GROUP,
  SELECT_COMPANY,
  SET_SCHEDULED_ACTIVITIES_FOR_ACCOUNT,
  SET_SCHEDULED_ACTIVITIES_FOR_GROUP,
  SET_CURRENT_SCHEDULED_ACTIVITY,
  SET_SIDEBAR_OPEN,
  SET_DASHBOARD_TIMELINE,
  SET_GROUP_OFFERS,
  SET_INVITE_MEMBER_MODAL_OPEN,
  SET_GROUP_CONNECTIONS,
  SET_COMPANIES,
  SET_COMPANY_OFFERS,
  SET_CHOSEN_ANALYTIC_JOB
}
