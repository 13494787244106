import React from 'react';

const ConnectionCard = (props: { title: string; handleClick: any; isActive: any }) => {
  return (
    <div className={props.isActive ? 'card active' : 'card'} onClick={props.handleClick}>
      <p>{props.title}</p>
    </div>
  );
};

export default ConnectionCard;
