import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import Profile from './profile/Profile';
import Dashboard from './Dashboard';
import Content from './Content';
import SingleContent from './SingleContent';
import { RootState } from '../store/reducers';
import { store } from '../store';
import {
  setSelectedAccount,
  setSelectedGroup,
} from '../store/actions/linkedAccounts';
import ScheduleOverview from './schedule/ScheduleOverview';
import { updateMainAccount } from '../services/account-service';
import { getSharedConnections } from '../services/profile-service';
import { GroupDashboard } from './group/GroupDashboard';
import { GroupContent } from './group/GroupContent';
import { GroupMembers } from './group/GroupMembers';
import { CompanyVisitors } from './company/companyVisitors/CompanyVisitors';
import { CompanyUpdates } from './company/companyUpdates/CompanyUpdates';
import { CompanyFollowers } from './company/companyFollowers/CompanyFollowers';
import { Banner } from './linked-accounts/LinkedAccounts.styles';
import { USER_PLAN } from '../services/user-service';

const AccountContainer = () => {
  const { accountId: paramAccountId, groupId } = useParams<{
    accountId: string;
    groupId: string;
  }>();
  const match = useRouteMatch<{ companyId: string }>();

  const {
    mainAccount,
    sharedConnections,
    groupsConnections,
    groups,
    selected,
    companies,
  } = useSelector((state: RootState) => state.linkedAccounts);
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    getSharedConnections();
  }, []);

  useEffect(() => {
    (async () => {
      // Load linked accounts on init
      if (mainAccount === null) {
        await updateMainAccount();
      }

      if (!paramAccountId) {
        return;
      }

      const selectedAcc =
        paramAccountId === mainAccount?._id && selected?.email === user?.email
          ? mainAccount
          : sharedConnections?.find(({ _id }) => paramAccountId === _id) ||
            groupsConnections?.find(({ _id }) => paramAccountId === _id) ||
            mainAccount;
      if (selectedAcc) {
        store.dispatch(setSelectedAccount(selectedAcc));
      }

      const selectedGroup =
        groupId && groups.find(group => group.id === groupId);
      if (selectedGroup) {
        store.dispatch(setSelectedGroup(selectedGroup));
      }
    })();
  }, [
    paramAccountId,
    mainAccount,
    sharedConnections,
    groupsConnections,
    groupId,
    groups,
    selected,
    user,
  ]);
  const currentCompany = companies.filter(
    ({ id }) => id === match.params.companyId
  )[0];

  const hasAccessToSchedulePage = ![
    USER_PLAN.ANALYZE,
    USER_PLAN.PROFESSIONAL,
  ].includes(user?.plan as USER_PLAN);

  const hasAccessToCompanyPage = ![
    USER_PLAN.ANALYZE,
    USER_PLAN.ESSENTIAL,
  ].includes(user?.plan as USER_PLAN);

  return (
    <>
      {match.path.includes('/company') &&
        !currentCompany?.isDemographicsLoaded && (
          <Banner>
            <span className='is-primary'>
              The demographics of your company's visitors and followers are
              uploaded. &nbsp;
            </span>
            <span>It takes up to 5 days.</span>
          </Banner>
        )}
      <Switch>
        <Route path={`${match.path}/analyze/content`}>
          {groupId ? <GroupContent /> : <Content />}
        </Route>

        <Route path={`${match.path}/analyze/activity/:activityId`}>
          <SingleContent />
        </Route>

        <Route path={`${match.path}/analyze/members`}>
          <GroupMembers />
        </Route>

        <Route path={`${match.path}/analyze/profile`}>
          <Profile />
        </Route>

        <Route
          path={[`${match.path}/analyze/schedule`, `${match.path}/schedule`]}
        >
          {hasAccessToSchedulePage ? (
            <ScheduleOverview group={!!groupId} />
          ) : (
            <Redirect to='/' />
          )}
        </Route>

        <Route path={`${match.path}/analyze`}>
          {groupId ? <GroupDashboard /> : <Dashboard />}
        </Route>

        <Route path={`${match.path}/visitors`}>
          {hasAccessToCompanyPage ? <CompanyVisitors /> : <Redirect to='/' />}
        </Route>

        <Route path={`${match.path}/updates`}>
          <CompanyUpdates />
        </Route>

        <Route path={`${match.path}/followers`}>
          <CompanyFollowers />
        </Route>
      </Switch>
    </>
  );
};

export default AccountContainer;
