import { ACTION_TYPE } from "../actions";
import {
  LinkedInAccount,
  LinkedInAccountShared
} from "../../services/account-service";
import { LinkedAccountsTypes } from "../actions/linkedAccounts";
import { AccountsTableEntryViewModel } from "../../components/linked-accounts/AccountList";
import { GroupOfferViewModel } from "../../components/security/SecurityGroup";
import { GroupTypes } from "../../components/accountSharing/AccountSharingModal";
import { CompanyOffer } from "../../services/company-service";

export type LinkedAccountRequests =
  | "isLoadingConnections"
  | "isLoadingGroups"
  | "isLoadingGroupOffers"
  | "isLoadingCompanies"
  | "isLoadingCompanyOffers"; // TODO: add more

export type LinkedAccountsState = {
  mainAccount: LinkedInAccount | null;
  sharedConnections: LinkedInAccountShared[];
  groupsConnections: LinkedInAccountShared[];
  groups: AccountsTableEntryViewModel[];
  groupOffers: GroupOfferViewModel[];
  selected: LinkedInAccount | null | undefined;
  selectedGroup: AccountsTableEntryViewModel | null | undefined;
  selectedCompany: AccountsTableEntryViewModel | null | undefined;
  companies: AccountsTableEntryViewModel[];
  companyOffers: CompanyOffer[];

  // fetch state
  isLoadingConnections: boolean;
  isLoadingGroups: boolean;
  isLoadingGroupsOffers: boolean;
  isLoadingCompanies: boolean;
  isLoadingCompanyOffers: boolean;
};

const initialState: LinkedAccountsState = {
  mainAccount: null,
  selected: undefined,
  selectedGroup: undefined,
  selectedCompany: undefined,
  sharedConnections: [],
  groupsConnections: [],
  groups: [],
  groupOffers: [],
  companies: [],
  companyOffers: [],

  isLoadingConnections: false,
  isLoadingGroups: false,
  isLoadingGroupsOffers: false,
  isLoadingCompanies: false,
  isLoadingCompanyOffers: false
};

const linkedAccounts = (
  state: LinkedAccountsState = initialState,
  action: LinkedAccountsTypes
): LinkedAccountsState => {
  switch (action.type) {
    case ACTION_TYPE.SET_MAIN_ACCOUNT:
      return { ...state, mainAccount: action.payload };
    case ACTION_TYPE.SELECT_ACCOUNT:
      return { ...state, selected: action.payload };
    case ACTION_TYPE.SELECT_GROUP:
      return { ...state, selectedGroup: action.payload };
    case ACTION_TYPE.SELECT_COMPANY:
      return { ...state, selectedCompany: action.payload };
    case ACTION_TYPE.SET_SHARED_CONNECTIONS:
      const result: LinkedInAccountShared[] = [];

      (action.payload || []).forEach(item => {
        if (item?.fromUser?.mainAccount?.profile) {
          result.push({
            ...item.fromUser.mainAccount,
            isSharedAnalytics: item?.analyticsConfirmed,
            isSharedSchedule: item?.scheduleConfirmed,
            hasValidSession: !!item.fromUser.cookies,
            connectionId: item._id,
            plan: item.fromUser?.plan,
            email: item.fromUser.email
          });
        }
      });

      return { ...state, sharedConnections: result };
    case ACTION_TYPE.SET_GROUPS:
      return { ...state, groups: action.payload };
    case ACTION_TYPE.SET_GROUP_CONNECTIONS:
      const groupResult: LinkedInAccountShared[] = [];
      (action.payload.groupConnections || []).forEach(item => {
        if (item?.user?.mainAccount?.profile) {
          groupResult.push({
            ...item.user.mainAccount,
            isSharedAnalytics: item?.group.type === GroupTypes.Analytics,
            isSharedSchedule: item?.group.type === GroupTypes.Schedule,
            hasValidSession: !!item.user.cookies,
            plan: item.user.plan,
            email: item.user.email,
            connectionId: item.group.id
          });
        }
      });

      return { ...state, groupsConnections: groupResult };
    case ACTION_TYPE.SET_GROUP_OFFERS:
      return { ...state, groupOffers: action.payload };
    case ACTION_TYPE.SET_LINKED_ACCOUNTS_REQUEST:
      return { ...state, [action.payload.key]: action.payload.value };
    case ACTION_TYPE.SET_COMPANIES:
      return { ...state, companies: action.payload };
    case ACTION_TYPE.SET_COMPANY_OFFERS:
      return { ...state, companyOffers: action.payload };
    default:
      return state;
  }
};

export default linkedAccounts;
