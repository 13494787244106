import React, { useMemo, useState } from 'react';
import { format, getWeek, getYear, startOfWeek } from 'date-fns';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  BarChart,
  Bar,
  Brush,
} from 'recharts';
import { APIError } from '../../services/api-client';
import {
  ProfileViewHistoryEntry,
  getProfileViewHistory,
} from '../../services/profile-service';
import { colors, variables } from '../../styles/variables';
import * as StyledLoading from '../../styles/common/Loading.styles';
import {
  getGroupViewHistory,
  GroupViewHistoryEntry,
} from '../../services/groups-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { ChartInfoBlock } from '../ChartInfoBlock';
import { endOfWeek } from 'date-fns/esm';
import { rangeChartData, TChartData } from './filterRangeData';

interface Props {
  group?: boolean;
  brushEnabled?: boolean;
  onDataLoaded?: (data: any) => void;
}

export enum ProfileCsv {
  'Profile views' = 'Profile views',
  'Profile views last 90 days' = 'Profile views last 90 days',
  'Aggregated Profile Views' = 'Aggregated Profile Views',
}

const ProfileViewTimeline: React.VFC<Props> = ({
  group,
  brushEnabled,
  onDataLoaded,
}) => {
  const { accountId, groupId } = useParams<{
    accountId: string;
    groupId: string;
  }>();
  const [chartData, setChartData] = useState<
    { Views: number; timestamp: string; entryTimestamp: Date }[]
  >([]);
  const { startDate, endDate } = useSelector(
    (state: RootState) => state.dateRangeFilter
  );

  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(0);

  const { isLoading } = useQuery<
    ProfileViewHistoryEntry[] | GroupViewHistoryEntry[],
    APIError,
    [string, string]
  >(
    [
      accountId,
      groupId,
      startDate,
      endDate,
      'getProfileViewHistory',
      'getGroupViewHistory',
    ],
    () => {
      return group
        ? getGroupViewHistory(groupId, startDate, endDate)
        : getProfileViewHistory(accountId);
    },
    {
      onSuccess: history => {
        setEndIndex(0);
        setStartIndex(0);
        if (!history || !history.length) {
          return setChartData([]);
        }
        const sorted = history.sort(
          (a, b) => a.endTimestamp.getTime() - b.endTimestamp.getTime()
        );
        const viewData: TChartData[] = [];
        const mappedByWeek = new Map();
        sorted.forEach(entry => {
          // Only use one entry per week, as we actually store the newest date per week
          entry.endTimestamp = new Date(entry.endTimestamp);
          const week = getWeek(entry.endTimestamp, { weekStartsOn: 1 });
          const year = getYear(entry.endTimestamp);
          const key = `${year}-${week}`;
          mappedByWeek.set(key, entry);
        });
        mappedByWeek.forEach(entry => {
          const startOfWeekDate = startOfWeek(entry.endTimestamp, {
            weekStartsOn: 1,
          });
          const endOfWeekDate = endOfWeek(entry.endTimestamp, {
            weekStartsOn: 1,
          });
          viewData.push({
            Views: entry.numViews,
            timestamp: `${format(startOfWeekDate, 'do LLL')} - ${format(
              endOfWeekDate,
              'do LLL'
            )}`,
            entryTimestamp: entry.endTimestamp,
          });
        });
        setChartData(viewData);
        setStartIndex(0);
        setEndIndex(viewData.length - 1);
      },
    }
  );

  const handleUpdate = ({
    startIndex,
    endIndex,
  }: {
    startIndex: number;
    endIndex: number;
  }) => {
    setStartIndex(startIndex);
    setEndIndex(endIndex);
  };

  const percentageForViews = useMemo(() => {
    if (!chartData?.length || chartData.length === 1) {
      return { increase: 0, percentage: 0 };
    }
    const copy = [...chartData.slice(startIndex, endIndex + 1)];
    let [last, previous] = [copy.pop(), copy.pop()];
    while (copy.length && previous?.timestamp === last?.timestamp) {
      previous = copy.pop();
    }
    const percentage =
      (last?.Views &&
        previous?.Views &&
        ((last.Views - previous.Views) / previous.Views) * 100) ||
      0;
    const increase =
      chartData
        .filter(({ entryTimestamp }) => {
          return entryTimestamp >= startDate && entryTimestamp <= endDate;
        })
        .slice(startIndex, endIndex + 1)
        .reduce((sum, curr) => (sum += curr.Views), 0) || 0;

    onDataLoaded &&
      onDataLoaded(
        group
          ? { [ProfileCsv['Aggregated Profile Views']]: increase }
          : { [ProfileCsv['Profile views']]: increase }
      );
    return { increase, percentage };
  }, [
    chartData,
    onDataLoaded,
    group,
    startIndex,
    endIndex,
    startDate,
    endDate,
  ]);

  if (isLoading) {
    return (
      <StyledLoading.LoadingContainer>
        <StyledLoading.LoadingBarSmall active />
      </StyledLoading.LoadingContainer>
    );
  }

  const selectedChart = rangeChartData(chartData, startDate, endDate);

  return (
    <div className='info-block'>
      <ChartInfoBlock
        title={group ? '' : 'Profile views'}
        number={percentageForViews.increase}
        percentage={percentageForViews.percentage}
      />
      <ResponsiveContainer height={400}>
        <AreaChart
          data={
            brushEnabled
              ? selectedChart.slice(startIndex, endIndex + 1)
              : selectedChart
          }
        >
          <defs>
            <linearGradient id='gradient' x1='0' y1='0' x2='0' y2='1'>
              <stop
                offset='0%'
                stopColor={colors.royalBlueDark}
                stopOpacity={0.8}
              />
              <stop
                offset='100%'
                stopColor={colors.royalBlueDark}
                stopOpacity={0.05}
              />
            </linearGradient>
          </defs>

          <XAxis dataKey='timestamp' tickLine={false} axisLine={false} />
          <YAxis tickLine={false} axisLine={false} />
          <Tooltip />
          <Area type='monotone' dataKey='Views' fill='url(#gradient)' />
        </AreaChart>
      </ResponsiveContainer>
      {brushEnabled && !!chartData?.length && (
        <div className='chart-container'>
          <ResponsiveContainer height={60} className='chart-base'>
            <BarChart data={selectedChart}>
              <YAxis
                yAxisId='Views'
                tickLine={false}
                axisLine={false}
                tick={false}
              />

              <Bar
                yAxisId='Views'
                dataKey='Views'
                stroke={variables.lightGrey}
                fill={variables.lightGrey}
                isAnimationActive={false}
              />
            </BarChart>
          </ResponsiveContainer>
          <ResponsiveContainer height={60} className='chart-brush'>
            <BarChart data={chartData}>
              <YAxis yAxisId='Views' tick={false} />

              <Brush
                startIndex={startIndex}
                endIndex={endIndex}
                height={60}
                fill='rgba(0, 0, 0, 0)'
                stroke={colors.lightBlue}
                onChange={handleUpdate}
                dataKey='timestamp'
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default ProfileViewTimeline;
