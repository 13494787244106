import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Key, ArrowUndo } from "@styled-icons/ionicons-solid";

import { RootState } from "../../store/reducers";

import { colors } from "../../styles/variables";

import { AnalyzeIcon, ScheduleIcon } from "../CustomIcons";

import { getRequestsToOthers } from "../../services/profile-service";
import { AccountConnection } from "../../services/account-service";
import { NoContentBox } from "./NoContentBox";
import { ConnectionRequest } from "./ConnectionRequest";
import * as StyledLoading from "../../styles/common/Loading.styles";
import { AccountAvatar } from "../accountAvatar/AccountAvatar";

interface Props {
  newAccountConnections: AccountConnection[];
}

export const SecurityPersonal: React.FC<Props> = ({
  newAccountConnections
}) => {
  const { sharedConnections, isLoadingConnections } = useSelector(
    (state: RootState) => state.linkedAccounts
  );

  const [isExpandedRequestsToOthers, setExpandedRequestsToOthers] = useState(
    false
  );

  const {
    data: pendingRequestsToOthers,
    isLoading: isPendingRequestsLoading
  } = useQuery("getRequestsToOthers", getRequestsToOthers);
  return (
    <>
      {isPendingRequestsLoading === false ? (
        <>
          {pendingRequestsToOthers?.length ? (
            <>
              {isExpandedRequestsToOthers ? (
                <>
                  <div
                    className="requests-to-others-popup-info"
                    onClick={() => setExpandedRequestsToOthers(false)}
                  >
                    <ArrowUndo size="1.25rem" color={colors.lightBlue} />
                    <span className="requests-to-others-popup-info__text">
                      Go back to the overview popup
                    </span>
                  </div>
                  <h4 className="security-settings-title">
                    Pending Requests To Others
                  </h4>
                  {pendingRequestsToOthers.map(connectionRequest => (
                    <ConnectionRequest
                      key={connectionRequest._id}
                      data={connectionRequest}
                      type="requestSent"
                    />
                  ))}
                </>
              ) : (
                <div
                  className="requests-to-others-popup-info"
                  onClick={() => setExpandedRequestsToOthers(true)}
                >
                  <Key size="1.25rem" color={colors.grey} />
                  <span className="requests-to-others-popup-info__text">
                    You have sent {pendingRequestsToOthers.length} pending
                    requests to others
                  </span>
                </div>
              )}
            </>
          ) : (
            <>
              <h4 className="security-settings-title">
                Pending Requests To Others
              </h4>
              <NoContentBox text="No Requests Yet" />
            </>
          )}
        </>
      ) : (
        <StyledLoading.LoadingContainer>
          <StyledLoading.LoadingBarSmall active />
        </StyledLoading.LoadingContainer>
      )}

      {!!newAccountConnections?.length &&
        !isExpandedRequestsToOthers &&
        newAccountConnections.map(newConnection => (
          <ConnectionRequest
            key={newConnection._id}
            data={newConnection}
            type="requestReceived"
          />
        ))}

      {isLoadingConnections ? (
        <StyledLoading.LoadingContainer>
          <StyledLoading.LoadingBarSmall active />
        </StyledLoading.LoadingContainer>
      ) : (
        <>
          <h4 className="security-settings-title">Connected Accounts</h4>
          {sharedConnections?.length ? (
            <table className="list-grid connected-accounts-popup-table">
              <thead className="header">
                <tr>
                  <th className="setting-column setting-column--name">Name</th>
                  <th className="setting-column">Products</th>
                </tr>
              </thead>
              <tbody>
                {sharedConnections.map(
                  ({
                    _id,
                    profile: { firstName, lastName, displayPhoto },
                    isSharedAnalytics,
                    isSharedSchedule
                  }) => (
                    <tr className="list-grid__account-row" key={_id}>
                      <td className="list-grid__cell list-grid__cell--name">
                        <AccountAvatar
                          account={{
                            avatar: displayPhoto?.small,
                            name: "firstName"
                          }}
                          uniqueKey={_id}
                        />
                        <span className="list-grid__text">{`${firstName} ${lastName}`}</span>
                      </td>

                      <td className="list-grid__cell list-grid__cell--products">
                        {isSharedAnalytics && <AnalyzeIcon />}
                        {isSharedSchedule && <ScheduleIcon />}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          ) : (
            <NoContentBox text="No Connections Yet" />
          )}
        </>
      )}

      {/* TODO: ADD link address*/}
      <div className="security-settings-link">
        <Link to="/settings/personal">Bring me to my personal settings</Link>
      </div>
    </>
  );
};
