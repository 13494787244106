import React, { useEffect, useState } from 'react';
import { Styled } from './MediaLibrary.styles';
import {
  getMediaFiles,
  deleteMediaFile,
  Media,
} from '../../services/media-service';
import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import MediaUploadDroparea from './MediaUploadDroparea';
import MediaImg from './MediaImg';
import { APIError } from '../../services/api-client';
import { helpTexts } from '../../helpers/helpTexts';

export interface MediaModalProps {
  onSelect: (files: Media[]) => void;
  initiallySelected?: Media[];
  modalOpenState: boolean;
}

const MediaLibrary: React.FC<MediaModalProps> = ({
  onSelect,
  initiallySelected = [],
  modalOpenState,
}) => {
  const { accountId } = useParams<{ accountId: string }>();
  const [isMediaLoading, setIsMediaLoading] = useState<boolean>(false);
  const [media, setMedia] = useState<Media[]>([]);
  useQuery(['getMediaFiles', accountId], getMediaFiles, {
    staleTime: 0,
    keepPreviousData: true,
    onSuccess: setMedia,
  });
  const [selected, setSelected] = useState<Media[]>(initiallySelected);
  const [deleteImageError, setDeleteImageError] = useState(false);
  const [deleteImageLoading, setDeleteImageLoading] = useState(false);
  const [deleteMediaFileMutation] = useMutation<void, APIError, Media>(
    deleteMediaFile,
    {
      onSuccess: (_data, deletedMedia) => {
        removeFromSelection(deletedMedia);
        setMedia([...media.filter(m => m._id !== deletedMedia._id)]);
        unloadImageDeleteProcess();
      },
      onError: error => {
        setDeleteImageError(true);
        unloadImageDeleteProcess();
      },
    }
  );

  const unloadImageDeleteProcess = () => {
    setDeleteImageLoading(false);
  };

  const handleNewMediaFiles = (newMedia: Media[]) => {
    addToSelection(...newMedia);
    setMedia([...media, ...newMedia]);
  };

  const handleOnMediaEntryClick = (clickedMedia: Media) => {
    // Remove or add it to the selection
    if (selected.some(m => m._id === clickedMedia._id)) {
      removeFromSelection(clickedMedia);
    } else {
      addToSelection(clickedMedia);
    }
  };

  const removeFromSelection = (media: Media) =>
    setSelected([...selected.filter(m => m._id !== media._id)]);
  const addToSelection = (...media: Media[]) =>
    setSelected([...selected, ...media]);

  useEffect(() => {
    if (!modalOpenState) {
      setDeleteImageError(false);
    }
  }, [modalOpenState]);

  return (
    <Styled.MediaLibrary>
      {media.length > 0 && (
        <Styled.ImgContainer>
          {media
            .sort((a, b) => a._id.localeCompare(b._id))
            .map(m => (
              <Styled.ImgEntry
                key={m._id}
                isSelected={selected.some(mf => mf._id === m._id)}
                onClick={() => handleOnMediaEntryClick(m)}
                style={{
                  cursor: deleteImageLoading ? 'progress' : 'pointer',
                }}
              >
                <Styled.ImgDeleteBtn
                  className='delete'
                  onClick={() => {
                    setDeleteImageError(false);
                    setDeleteImageLoading(true);
                    deleteMediaFileMutation(m);
                  }}
                />
                {m.type === 'video' ? (
                  <Styled.Video as={MediaImg} key={m._id} media={m} />
                ) : (
                  <Styled.Img as={MediaImg} key={m._id} media={m} />
                )}
              </Styled.ImgEntry>
            ))}
        </Styled.ImgContainer>
      )}
      {media.length === 0 && (
        <Styled.EmptyState>No media files uploaded yet.</Styled.EmptyState>
      )}
      {deleteImageError && (
        <div className='field'>
          <p className='help is-danger'>{helpTexts.DELETE_IMAGE_ERROR}</p>
        </div>
      )}
      <MediaUploadDroparea
        setIsMediaLoading={setIsMediaLoading}
        onNewMediaFiles={handleNewMediaFiles}
        media={initiallySelected}
      />
      <div className='field is-grouped mt-2'>
        <div className='control'>
          <button
            className='level-item button is-primary'
            type='button'
            onClick={() => onSelect(selected)}
            disabled={isMediaLoading}
          >
            Confirm
          </button>
        </div>
        <div className='control'>
          <button
            className='level-item button is-secondary'
            type='button'
            onClick={() => onSelect(initiallySelected)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Styled.MediaLibrary>
  );
};

export default MediaLibrary;
