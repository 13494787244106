import { darken } from 'polished';
import styled from 'styled-components';
import { colors } from '../../styles/variables';

export const Button = styled.button`
  border: none;
  padding: 0.5rem;
  height: auto;
  background-color: transparent;
  color: ${colors.grey};
  &:hover {
    background: rgba(${colors.grey}, 0.5);
    color: ${darken(0.1, colors.grey)};
  }
`;
