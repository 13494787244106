import React from 'react';
import '../styles/components/TopActivityCard.scss';
import {
  ActivityStatistics,
  calculateEngagementRate,
} from '../services/statistics-service';
import { Link, useParams } from 'react-router-dom';
import { StatsChart } from '@styled-icons/ionicons-outline';
import StyledTooltip from './StyledTooltip';
import { HelpCircle } from '@styled-icons/ionicons-solid';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { USER_PLAN } from '../services/user-service';

interface TopActivityCard {
  activityStatistics: ActivityStatistics;
}

const TopActivityCard: React.FC<TopActivityCard> = ({ activityStatistics }) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { accountId } = useParams<{ accountId: string }>();
  const { activity, statistics } = activityStatistics;

  return (
    <div className='box TopActivityCard'>
      <Link
        className='content-container'
        to={`/account/${accountId}/analyze/activity/${activity._id}`}
      >
        <div className='open-in-new-icon'>
          <StatsChart />
        </div>
        <div className='content'>{activity.content}</div>
        {activity.image && <img src={activity.image} alt={activity.content} />}
      </Link>
      <div className='statistics-container'>
        {user!.plan !== USER_PLAN.FREE && (
          <>
            <progress
              className='progress'
              value={calculateEngagementRate(statistics) * 100}
              max='25'
            ></progress>
            <div className='statistic engagement-rate'>
              <div className='name'>
                <span>Total Engagement</span>
                <div
                  className='button is-white is-help-button'
                  data-tip='The total engagement rate is calculated from the engagement of all your reactions, comments and shares in relation to the post views.'
                >
                  <div className='icon'>
                    <HelpCircle size='1rem' />
                  </div>
                </div>
              </div>
              <div className='value'>
                {(calculateEngagementRate(statistics) * 100).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
                {'%'}
              </div>
            </div>
          </>
        )}
        <div className='statistic'>
          <div className='name'>Views</div>
          <div className='value'>
            {statistics.amountViews > 0 ? statistics.amountViews : 0}
          </div>
        </div>
        <div className='statistic'>
          <div className='name'>Impressions</div>
          <div className='value'>
            {statistics.amountImpressions > 0
              ? statistics.amountImpressions
              : 0}
          </div>
        </div>
        <div className='statistic'>
          <div className='name'>Reactions</div>
          <div className='value'>
            {statistics.amountLikes > 0 ? statistics.amountLikes : 0}
          </div>
        </div>
        <div className='statistic'>
          <div className='name'>Comments</div>
          <div className='value'>
            {statistics.amountComments > 0 ? statistics.amountComments : 0}
          </div>
        </div>
        {/* TODO: uncomment this when shares are available */}
        {/* <div className='statistic'>
          <div className='name'>Shares</div>
          <div className='value'>{statistics.amountShares}</div>
        </div> */}
      </div>
      <StyledTooltip />
    </div>
  );
};

export default TopActivityCard;
