import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Key } from "@styled-icons/ionicons-solid";

import { RootState } from "../../store/reducers";

import { colors } from "../../styles/variables";

import StyledTooltip from "../StyledTooltip";

import "./style.scss";
import { SecurityPersonal } from "./SecurityPersonal";
import { SecurityGroup } from "./SecurityGroup";
import { getSharedConnections } from "../../services/profile-service";
import { getGroupOffers, getGroups } from "../../services/groups-service";
import { getCompanies, getCompanyOffers } from "../../services/company-service";
import { SecurityOrganization } from "./SecurityOrganization";

enum Tabs {
  Personal = 0,
  Groups = 1,
  Organization = 2
}

export const Security = () => {
  const {
    mainAccount,
    groupOffers,
    isLoadingGroupsOffers,
    companyOffers,
    isLoadingCompanyOffers
  } = useSelector((state: RootState) => state.linkedAccounts);

  const newAccountConnections = mainAccount?.newAccountConnections || [];

  const [tab, setTab] = useState<Tabs>();

  useEffect(() => {
    getSharedConnections();
    getGroupOffers();
    getGroups();
    getCompanyOffers();
    getCompanies();
  }, []);

  return (
    <div className="security-settings-container">
      <Key
        size="1.75rem"
        color={colors.grey}
        data-tip
        data-for={"security-and-privacy-settings"}
        onClick={() => setTab(Tabs.Personal)}
      />
      {(!!newAccountConnections.length ||
        !!(groupOffers || []).length ||
        !!(companyOffers || []).length) && (
        <span className="security-settings-container__badge">
          {newAccountConnections.length +
            (groupOffers || []).length +
            (companyOffers || []).length}
        </span>
      )}

      <StyledTooltip
        id={"security-and-privacy-settings"}
        event="click"
        eventOff="blur"
        className="security-settings-react-tooltip"
        // delayHide={600000}
        afterShow={() => setTab(Tabs.Personal)}
        clickable={true}
      >
        <div className="security-settings-tooltip">
          <div className="security-settings-radio">
            {["Personal", "Groups", "Company"].map((item, i) => (
              <Fragment key={item}>
                <input
                  className="security-settings-radio__input"
                  type="radio"
                  id={`security-settings-${item}`}
                  name="security-settings-tab"
                  onClick={() => setTab(i)}
                />
                <label
                  className={classNames("security-settings-radio__label", {
                    "security-settings-radio__label--active": tab === i
                  })}
                  htmlFor={`security-settings-${item}`}
                >
                  {item}
                </label>
              </Fragment>
            ))}
          </div>

          {tab === Tabs.Personal && (
            <SecurityPersonal newAccountConnections={newAccountConnections} />
          )}
          {tab === Tabs.Groups && (
            <SecurityGroup
              groupOffers={groupOffers}
              isLoading={isLoadingGroupsOffers}
            />
          )}
          {tab === Tabs.Organization && (
            <SecurityOrganization
              isLoading={isLoadingCompanyOffers}
              companyOffers={companyOffers}
            />
          )}
        </div>
      </StyledTooltip>
    </div>
  );
};
