import React from 'react';
import { Route } from 'react-router-dom';
import '../styles/components/Sidebar.scss';
import SidebarAccountSection from './SidebarAccountSection';
import SidebarAnalyzeList from './SidebarAnalyzeList';
import SidebarScheduleList from './SidebarScheduleList';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import classNames from 'classnames';
import { CaretBack, CaretForward } from '@styled-icons/ionicons-solid';
import { setSidebarOpenState } from '../store/actions/settings';
import { store } from '../store';
import SidebarCompanyList from './SidebarCompanyList';

interface SidebarProps {
  hide: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ hide }) => {
  const settingsSidebarOpen = useSelector(
    (state: RootState) => state.settings.sidebarOpen
  );

  if (hide) {
    // Sidebar is hidden for this route
    return <></>;
  }

  return (
    <aside
      className={classNames('Sidebar', { closed: !settingsSidebarOpen })}
      role='navigation'
      aria-label='secondary navigation'
    >
      {/* Close button */}
      <button
        className='button button-close'
        onClick={() =>
          store.dispatch(setSidebarOpenState(!settingsSidebarOpen))
        }
      >
        {settingsSidebarOpen && <CaretBack className='icon has-text-grey' />}
        {!settingsSidebarOpen && (
          <CaretForward className='icon has-text-grey' />
        )}
      </button>
      <div className='sidebar-container'>
        <Route
          path={[
            '/account/:accountId/group/:groupId/analyze',
            '/account/:accountId/analyze',
            '/account/:accountId/schedule',
          ]}
        >
          <SidebarAccountSection />
        </Route>
        <Route
          path={[
            '/account/:accountId/group/:groupId/analyze',
            '/account/:accountId/group/:groupId/schedule',
            '/account/:accountId/analyze',
          ]}
        >
          <SidebarAnalyzeList />
        </Route>
        <Route
          path={[
            '/company/:companyId/visitors',
            '/company/:companyId/updates',
            '/company/:companyId/followers',
          ]}
        >
          <SidebarCompanyList />
        </Route>
        <Route path='/account/:accountId/schedule'>
          <SidebarScheduleList />
        </Route>
        {/*<div className='menu bottom-list'>*/}
        {/*  <ul className='menu-list'>*/}
        {/*<li>*/}
        {/*  <NavLink to='/feedback' exact={true}>*/}
        {/*    <Chatbubble className='icon' />*/}
        {/*    <span>Feedback</span>*/}
        {/*  </NavLink>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <NavLink to={`/settings`} exact={true}>*/}
        {/*    <Settings className='icon' />*/}
        {/*    <span>Settings</span>*/}
        {/*  </NavLink>*/}
        {/*</li>*/}
        {/* <li>
              <NavLink to='/share' exact={true}>
                <ShareSocial className='icon' />
                <span>Share the Tool</span>
              </NavLink>
            </li> */}
        {/*  </ul>*/}
        {/*</div>*/}
      </div>
    </aside>
  );
};

export default Sidebar;
