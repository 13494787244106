import { ACTION_TYPE } from '.';
import { TIMELINE_TYPE } from '../../components/DashboardTimeline';

export interface SetSidebarOpenAction {
  type: ACTION_TYPE.SET_SIDEBAR_OPEN;
  payload: boolean;
}
export interface SetDashboardTimelineOptionAction {
  type: ACTION_TYPE.SET_DASHBOARD_TIMELINE;
  payload: {
    id: number;
    type: TIMELINE_TYPE;
  };
}

export interface SetInviteMemberModalOpen {
  type: ACTION_TYPE.SET_INVITE_MEMBER_MODAL_OPEN;
  payload: boolean;
}

export const setSidebarOpenState = (newState: boolean): SetSidebarOpenAction => ({
  type: ACTION_TYPE.SET_SIDEBAR_OPEN,
  payload: newState,
});

export const setDashboardTimelineOption = (
  id: number,
  options: { type: TIMELINE_TYPE }
): SetDashboardTimelineOptionAction => ({
  type: ACTION_TYPE.SET_DASHBOARD_TIMELINE,
  payload: {
    id,
    type: options.type,
  },
});

export const setInviteMemberModalOpen = (payload: boolean): SetInviteMemberModalOpen => ({
  type: ACTION_TYPE.SET_INVITE_MEMBER_MODAL_OPEN,
  payload,
});

export type SettingsTypes = SetSidebarOpenAction | SetDashboardTimelineOptionAction | SetInviteMemberModalOpen;
