import apiClient from './api-client';
import { store } from '../store';
import { setUser } from '../store/actions/auth';

export enum USER_PLAN {
  FREE = 'FREE',
  SOLO = 'SOLO',
  BUSINESS = 'BUSINESS',
  APPSUMO = 'APPSUMO',
  ANALYZE = 'ANALYZE',
  PROFESSIONAL = 'PROFESSIONAL',
  ESSENTIAL = 'ESSENTIAL',
  COMPLETE = 'COMPLETE',
  CORPORATE = 'CORPORATE',
}

export const demoUserEmail = 'demoaccount@inlytics.io';

export interface User {
  _id: string;
  email: string;
  auth: USER_AUTH_LEVEL;
  profile: UserProfile;
  employmentType?: string;
  employmentIndustry?: string;
  occupation?: string;
  onboarding?: UserOnboarding;
  usedReferral?: {
    code: string;
    source: string;
  };
  linkedinAuth?: {
    username: string;
    password: string;
  };
  plan: USER_PLAN;
  createdAt: Date;
  updatedAt: Date;
  mainAccount?: string;
  test50?: number;
  stripeCustomerId: string;
  stripeSubscription: StripeSubscription;
}

export interface StripeSubscription {
  oldPrice: boolean;
  _id: string;
  status: string;
  periodEnd: Date;
  plan: string;
}

export interface UserProfile {
  givenName: string;
  familyName: string;
  picture: string; // Profile picture
}

export interface UserOnboarding {
  motivation?: string;
  source?: string;
}

export enum USER_AUTH_LEVEL {
  ADMIN = 100,
  USER = 10,
  NO_AUTH = 0,
}

export async function updateUserState() {
  const userId = (await apiClient.get<{ user: string | null }>(`/auth/status`))
    .data.user;
  if (!userId) {
    // User is logged out
    store.dispatch(setUser(null));
  } else {
    // User is logged in, get user model
    const user = (await apiClient.get<User>(`/users/me`)).data;
    store.dispatch(setUser(user));
  }
}

export async function updateOnboarding(onboarding: Partial<UserOnboarding>) {
  const res = await apiClient.put<UserOnboarding>(
    `/users/me/onboarding`,
    onboarding
  );
  return res.data;
}

export type PersonalSettings = Pick<
  User,
  'employmentIndustry' | 'employmentType' | 'occupation'
>;
export async function updatePersonalSettings(settings: PersonalSettings) {
  const res = await apiClient.put<User>(
    `/users/me/settings/personal`,
    settings
  );
  return res.data;
}

export function deleteUserAccount() {
  return apiClient.delete<void>('/users/me');
}

export async function connectLIAccount({
  username,
  password,
}: {
  username: string;
  password: string;
}) {
  const res = await apiClient.put('/users/link-account', {
    username,
    password,
  });
  updateUserState().catch(console.error);
  return res.data;
}

export async function disconnectLIAccount(): Promise<string> {
  const res = await apiClient.delete('/users/unlink-account');
  updateUserState().catch(console.error);
  return res.data;
}
