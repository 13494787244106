import React from 'react';
import { useParams } from 'react-router-dom';
import '../styles/components/SidebarScheduleList.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { format } from 'date-fns';
import MediaImg from './media/MediaImg';
import { store } from '../store';
import { setCurrentScheduledActivity } from '../store/actions/scheduledActivities';
import { Time } from '@styled-icons/ionicons-outline';

const SidebarScheduleList: React.FC = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const scheduledActivities = useSelector((state: RootState) => {
    if (!accountId) {
      return null;
    }
    const activities = state.scheduledActivities.activitiesByAccount[accountId];
    return (activities || [])
      .filter(act => act.publishingDate.getTime() > Date.now())
      .sort((a, b) => a.publishingDate.getTime() - b.publishingDate.getTime());
  });

  function truncate(
    string: string,
    options: {
      length: number;
    }
  ) {
    return string.substring(0, options.length - 3) + '...';
  }

  return (
    <div className='SidebarScheduleList'>
      <h4 className='title is-size-4'>Upcoming posts</h4>
      <p className='sub-title has-text-grey'>
        See your next planned posts in a lineup
      </p>
      <Time className='icon icon-timeline' />
      <ul className='upcoming-posts-list'>
        {scheduledActivities &&
          scheduledActivities.map(activity => (
            <li
              key={activity._id}
              className='upcoming-post'
              onClick={() =>
                store.dispatch(setCurrentScheduledActivity(activity))
              }
            >
              <div className='media'>
                {activity.media.length > 0 && (
                  <MediaImg media={activity.media[0]} />
                )}
              </div>
              <div className='info is-size-7 has-text-grey'>
                <p className='commentary'>
                  {activity.commentary
                    ? truncate(activity.commentary, { length: 20 })
                    : 'Media post'}
                </p>
                <p className='date'>
                  {format(activity.publishingDate, 'yyyy-MM-dd HH:mm')}
                </p>
                <p className='type'>{activity.postType}</p>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SidebarScheduleList;
