import React from 'react';
import '../styles/components/Header.scss';
import LoadingBar from './loader/LoadingBar';

interface HeaderProps {
  title: React.ReactNode;
  content?: React.ReactNode;
  loading?: boolean;
}

const Header: React.FC<HeaderProps> = ({ title, content, loading = false }) => {
  return (
    <div className='Header'>
      <div className='header-content'>
        <div className='title is-3'>{title}</div>
        <div className='content-container'>{content}</div>
      </div>
      <LoadingBar active={loading} />
    </div>
  );
};

export default Header;
