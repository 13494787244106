import React, { useRef, useEffect } from 'react';

interface ClickAwayListenerProps {
  onOutSideClick: (e: MouseEvent) => void;
}

const ClickAwayListener: React.FC<ClickAwayListenerProps & React.HTMLAttributes<HTMLDivElement>> = ({
  onOutSideClick,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutSideClick(event);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onOutSideClick]);

  return <div ref={ref}>{children}</div>;
};

export default ClickAwayListener;
