import styled from 'styled-components';
import variables from '../variables';

// This wrapper should have a max-width
const Wrapper = styled.div`
  padding: 0 5rem;
  margin: 0 auto;

  & + & {
    margin-top: 3.5rem;
  }

  .title {
    margin-bottom: 0;

    &.with-tooltip {
      margin-bottom: -0.5rem;
      display: flex;
      align-items: center;

      .__react_component_tooltip {
        padding: 1rem;
        font-size: 1rem;
      }
    }
  }
`;

const Section = styled.section`
  padding: 2rem 0;
  @media (min-width: 768px) {
    padding: 4rem 0;
  }
`;

const Card = styled.div`
  padding: 1.5rem;
  border-radius: 10px;
  background-color: ${variables.contrast};
  //box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.16);
  overflow: hidden;
`;

export const StyledCommon = { Wrapper, Section, Card };
