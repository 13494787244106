import React, { Fragment, useEffect, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import { RechartData } from '../../../services/company-service';
import { getPercentage } from '../../../helpers/functions';
import { format } from 'date-fns';
import { adjustHue } from 'polished';
import './style.scss';

export enum TIMELINE_TYPE {
  IMPRESSIONS,
  UNIQUE_IMPRESSIONS,
  CLICKS,
  REACTIONS,
  COMMENTS,
  ENGAGEMENT_RATE,
}

interface Props {
  data: RechartData[];
  brushEnabled?: boolean;
}

export const UpdateTable: React.FC<Props> = ({ data }) => {
  const [visitorsRechartData, setVisitorsRechartData] = useState<RechartData[]>(
    []
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    let newData: RechartData[] = data.map(entry => {
      return {
        ...entry,
        clickThroughRateColor: '',
        clicksColor: '',
        commentsColor: '',
        engagementRateColor: '',
        impressionsColor: '',
        likesColor: '',
        sharesColor: '',
        viewsColor: '',
        followsColor: '',
      };
    });
    const propsToShowRelative = [
      'clickThroughRate',
      'clicks',
      'comments',
      'engagementRate',
      'impressions',
      'reactions',
      'shares',
      'views',
      'follows',
    ];
    const maxAmounts = new Map();
    propsToShowRelative.forEach(prop => {
      const max = Math.max(...newData.map(act => (act as any)[prop]));
      maxAmounts.set(prop, max === 0 ? 0.01 : max);
    });
    newData = newData.map(entry => {
      propsToShowRelative.forEach(prop => {
        const max = maxAmounts.get(prop);
        const percentage = (entry as any)[prop] / max;
        let color = adjustHue(percentage * 120, '#f0bba9');

        if (color === '#a9a9a9') {
          color = '';
        }
        if (max < 0.02) {
          color = '';
        }
        (entry as any)[prop + 'Color'] = color;
      });
      return entry;
    });
    setVisitorsRechartData(newData);
  }, [data]);

  return (
    <div className='box info-block'>
      <ResponsiveContainer>
        <table className='list-grid single-account-sharing-settings'>
          <thead className='header'>
            <tr>
              <th className='setting-column'>Update Name</th>
              <th className='setting-column has-text-centered'>Date</th>
              <th className='setting-column has-text-centered'>Type</th>
              <th className='setting-column has-text-centered'>CTR</th>
              <th className='setting-column has-text-centered'>Clicks</th>
              <th className='setting-column has-text-centered'>Views</th>
              <th className='setting-column has-text-centered'>Comments</th>
              <th className='setting-column has-text-centered'>
                EngagementRate
              </th>
              <th className='setting-column has-text-centered'>Views</th>
              <th className='setting-column has-text-centered'>Reactions</th>
              <th className='setting-column has-text-centered'>Shares</th>
              <th className='setting-column has-text-centered'>Follows</th>
            </tr>
          </thead>
          <tbody>
            {visitorsRechartData.map(
              ({
                _id,
                clickThroughRate,
                clicks,
                comments,
                engagementRate,
                impressions,
                likes,
                shares,
                views,
                follows,
                text,
                timestamp,
                type,
                ...entry
              }) => (
                <Fragment key={_id}>
                  <tr className={`list-grid__account-row }`} onClick={() => {}}>
                    <td className='list-grid__cell list-grid__cell--name list-grid__cell-max200'>
                      {text}
                    </td>
                    <td className='list-grid__cell list-grid__cell--name has-text-centered'>
                      {format(timestamp, 'yyyy-MM-dd HH:mm')}
                    </td>
                    <td className='list-grid__cell list-grid__cell--name has-text-centered'>
                      {type}
                    </td>
                    <td
                      className='list-grid__cell list-grid__cell--name has-text-centered'
                      style={{ background: entry.clickThroughRateColor }}
                    >
                      {getPercentage(clickThroughRate)}
                    </td>
                    <td
                      className='list-grid__cell list-grid__cell--name has-text-centered'
                      style={{ background: entry.clicksColor }}
                    >
                      {clicks}
                    </td>
                    <td
                      className='list-grid__cell list-grid__cell--name has-text-centered'
                      style={{ background: entry.viewsColor }}
                    >
                      {views ? views : '-'}
                    </td>
                    <td
                      className='list-grid__cell list-grid__cell--name has-text-centered'
                      style={{ background: entry.commentsColor }}
                    >
                      {comments}
                    </td>
                    <td
                      className='list-grid__cell list-grid__cell--name has-text-centered'
                      style={{ background: entry.engagementRateColor }}
                    >
                      {getPercentage(engagementRate)}
                    </td>
                    <td
                      className='list-grid__cell list-grid__cell--name has-text-centered'
                      style={{ background: entry.impressionsColor }}
                    >
                      {impressions}
                    </td>
                    <td
                      className='list-grid__cell list-grid__cell--name has-text-centered'
                      style={{ background: entry.likesColor }}
                    >
                      {likes}
                    </td>
                    <td
                      className='list-grid__cell list-grid__cell--name has-text-centered'
                      style={{ background: entry.sharesColor }}
                    >
                      {shares}
                    </td>
                    <td
                      className='list-grid__cell list-grid__cell--name has-text-centered'
                      style={{ background: entry.followsColor }}
                    >
                      {follows ? follows : '-'}
                    </td>
                  </tr>
                </Fragment>
              )
            )}
          </tbody>
        </table>
      </ResponsiveContainer>
    </div>
  );
};
