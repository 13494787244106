import ms from 'ms';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import apiClient, { APIError } from '../services/api-client';

const LinkAccountManually = () => {
  const [li_at, setLi_at] = useState('');
  const [JSESSIONID, setJSESSIONID] = useState('');

  const [linkAccountManually, { isLoading, error }] = useMutation<void, APIError, never>(() => {
    const expires = Date.now() + ms('1year');
    return apiClient.post('/accounts/link', {
      JSESSIONID: { value: JSESSIONID, expires },
      li_at: { value: li_at, expires },
    });
  });

  if (process.env.REACT_APP_ENV !== 'staging') {
    // This is only available in the staging environment, as there is no way to use the Chrome extension there
    return null;
  }

  return (
    <Wrapper>
      <span>Only available in the staging environment.</span>
      <form
        onSubmit={e => {
          e.preventDefault();
          linkAccountManually();
        }}>
        <input
          type='text'
          name='li_at'
          id='li_at'
          placeholder='li_at'
          value={li_at}
          onChange={e => setLi_at(e.currentTarget.value)}
        />

        <input
          type='text'
          name='JSESSIONID'
          id='JSESSIONID'
          placeholder='JSESSIONID'
          value={JSESSIONID}
          onChange={e => setJSESSIONID(e.currentTarget.value)}
        />

        <button type='submit' disabled={isLoading}>
          Connect account manually
        </button>

        {error && <p>{error.message}</p>}
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: orange;
  padding: 1rem;
  border-radius: 10px;
`;

export default LinkAccountManually;
