export interface TChartData {
  Views: number;
  timestamp: string;
  entryTimestamp: Date;
}

export function rangeChartData(
  chartData: TChartData[],
  startDate: Date,
  endDate: Date
) {
  return chartData.filter(
    ({ entryTimestamp }) =>
      entryTimestamp >= startDate && entryTimestamp <= endDate
  );
}

export function rangeFollowersChartData(
  chartData: TChartData[],
  startDate: Date,
  endDate: Date
) {
  return chartData.filter(
    ({ timestamp }) =>
      new Date(timestamp) >= startDate && new Date(timestamp) <= endDate
  );
}
