import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import ms from 'ms';
import classNames from 'classnames';
import {
  createScheduledActivity,
  updateScheduledActivity,
  loadScheduledActivity,
  deleteScheduledActivity,
  ScheduledActivity,
} from '../../services/schedule-service';
import { format as dateFnsFormat, parse, isValid } from 'date-fns';
import TimePicker from 'react-time-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import ScheduledActivityMedia from './ScheduledActivityMedia';
import { Media } from '../../services/media-service';
import '../../styles/components/ScheduledActivityPopup.scss';
import MediaImg from '../media/MediaImg';
import { useMutation, useQuery } from 'react-query';
import { APIError } from '../../services/api-client';
import { Alarm, LogoLinkedin, Mail } from '@styled-icons/ionicons-solid';
import Modal from '../modal/Modal';
import {
  LinkedInAccount,
  useMainAccount,
} from '../../services/account-service';
import { RootState } from '../../store/reducers';
import { useSelector } from 'react-redux';
import { demoUserEmail, User } from '../../services/user-service';
import { useDemoUser } from '../../hooks/useDemoUser';
import {
  MEDIA_FILES_ERROR_MESSAGE_ENUM,
  useValidationMediaFiles,
} from './useValidationMediaFiles';
import ErrorForm from '../ErrorForm';
import { getLocaleDateString } from '../../helpers/functions';
import { serializeMainAccount } from '../linked-accounts/LinkedAccounts';
import { helpTexts } from '../../helpers/helpTexts';
interface ScheduledActivityPopupProps {
  scheduledActivityId?: string;
  presetPublishingDate?: Date | null;
  isEditable: boolean;
  onClose: () => void;
  isRetry: boolean | null;
}

export interface FormErrors {
  [MEDIA_FILES_ERROR_MESSAGE_ENUM.countOfVideoNotSupported]?: string;
  [MEDIA_FILES_ERROR_MESSAGE_ENUM.differentTypeOfMedia]?: string;
}

const initialPublishingDate = new Date(Date.now() + ms('1 hour'));

const ScheduledActivityPopup: React.FC<ScheduledActivityPopupProps> = ({
  scheduledActivityId: existingScheduledActivityId,
  presetPublishingDate,
  onClose,
  isEditable,
  isRetry,
}) => {
  const [postUrn, setPostUrn] = useState<string | null>();
  const [retry, setRetry] = useState(false);
  const { data: mainAccount } = useMainAccount();

  const ScheduledActivitySchema = Yup.object().shape(
    {
      commentary: Yup.string()
        // eslint-disable-next-line no-template-curly-in-string
        .max(3000, 'You have reached the maximum number of ${max} characters.')
        .when('media', {
          is: media => !media.length,
          then: Yup.string().required(
            'Please provide either some text or media in your post.'
          ),
        }),
      media: Yup.array()
        .of(Yup.string())
        // eslint-disable-next-line no-template-curly-in-string
        .max(9, 'Please select a maximum of ${max} media items.')
        .when('commentary', {
          is: commentary => !commentary || !commentary.length,
          then: Yup.array()
            .of(Yup.string())
            .required('Please provide either some text or media in your post.'),
        }),
      postType: Yup.mixed<'auto' | 'notification'>()
        .oneOf(['auto', 'notification'])
        .required(),
      publishingDate: Yup.date()
        .required()
        .test({
          name: 'is-in-the-future',
          test: (value: Date | null) => {
            if (!value) {
              return false;
            }
            return retry || value.getTime() > Date.now() + ms('3 min');
          },
          message: 'Date has to be min. 3min into the future.',
        }),
    },
    [
      ['media', 'commentary'],
      ['commentary', 'media'],
    ]
  );

  const { accountId } = useParams<{ accountId: string }>();
  const [initialValues, setInitialValues] = useState<Yup.InferType<
    typeof ScheduledActivitySchema
  > | null>(null);
  const [scheduledActivityId, setScheduledActivityId] = useState(
    existingScheduledActivityId
  );
  const { linkedAccounts } = useSelector((state: RootState) => state); // TODO implement preview
  const { user } = useSelector((state: RootState) => state.auth); // TODO implement preview

  const [selectedDay, setSelectedDay] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<string>(
    dateFnsFormat(initialPublishingDate, 'HH:mm')
  );
  const [mediaFiles, setMediaFiles] = useState<Media[]>();
  const [formDirty, setFormDirty] = useState(false);
  const [formValues, setFormValues] = useState<Yup.InferType<
    typeof ScheduledActivitySchema
  > | null>(null);
  const [showPreview, setShowPreview] = useState(true);
  const [currentActivity, setCurrentActivity] = useState<ScheduledActivity>();
  const [currentAccount, setCurrentAccount] = useState<
    LinkedInAccount | User
  >();
  const [currentAccountEmail, setCurrentAccountEmail] = useState<string>();
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  useValidationMediaFiles(mediaFiles, setFormErrors);

  const isDemoUser = useDemoUser();
  const isDemoUserEmail = useCallback(
    (email?: string) => email === demoUserEmail,
    []
  );

  const serializedMainAccount = useMemo(() => {
    return mainAccount
      ? serializeMainAccount(mainAccount, user?.plan, isDemoUser, user)
      : null;
  }, [mainAccount, user, isDemoUser]);

  const { /*isLoading,*/ error } = useQuery<
    {
      scheduledActivity: ScheduledActivity;
      account: LinkedInAccount | User;
      authorEmail: string;
    },
    APIError
  >([accountId, scheduledActivityId], loadScheduledActivity, {
    enabled: !!existingScheduledActivityId,
    onSuccess: ({ scheduledActivity: act, account, authorEmail }) => {
      setCurrentActivity(act);
      setCurrentAccount(account);
      setCurrentAccountEmail(authorEmail);
      setScheduledActivityId(existingScheduledActivityId);
      setInitialValues({
        commentary: act.commentary,
        media: act.media.map(m => m._id),
        postType: act.postType,
        publishingDate: act.publishingDate,
      });
      setSelectedDay(act.publishingDate);
      setSelectedTime(dateFnsFormat(act.publishingDate, 'HH:mm'));
      setMediaFiles(act.media);
      setPostUrn(act.postUrn);
    },
  });

  const authorName = useMemo(() => {
    if (!currentAccount || !currentActivity) return;
    return isDemoUserEmail(currentAccountEmail)
      ? `${currentActivity.author.profile.givenName} ${currentActivity.author.profile.familyName}`
      : `
            ${('firstName' in currentAccount.profile
              ? currentAccount.profile.firstName
              : currentAccount.profile.givenName) ||
              linkedAccounts.selected?.profile.firstName} 
            ${('lastName' in currentAccount.profile
              ? currentAccount.profile.lastName
              : currentAccount.profile.familyName) ||
              linkedAccounts.selected?.profile.lastName}
                      `;
  }, [
    currentAccount,
    currentAccountEmail,
    currentActivity,
    isDemoUserEmail,
    linkedAccounts.selected,
  ]);

  useEffect(() => {
    if (!existingScheduledActivityId) {
      if (linkedAccounts?.selected) {
        setCurrentAccount(linkedAccounts.selected);
      } else if (user) {
        setCurrentAccount(user);
      }
      // This is going to be a new post
      let selectedDay = new Date();
      let selectedTime = initialPublishingDate;
      if (presetPublishingDate) {
        selectedDay = presetPublishingDate;
        selectedTime = presetPublishingDate;
      }
      setSelectedDay(selectedDay);
      setSelectedTime(dateFnsFormat(selectedTime, 'HH:mm'));
      setInitialValues({
        commentary: '',
        media: [],
        postType:
          'displayPhoto' in (currentAccount?.profile || {}) && !isDemoUser
            ? 'auto'
            : 'notification',
        publishingDate: selectedTime,
      });
    }
  }, [
    presetPublishingDate,
    existingScheduledActivityId,
    linkedAccounts,
    user,
    currentAccount,
    isDemoUser,
  ]);

  const [
    createScheduledActivityMutation,
    { isLoading: isLoadingCreate, error: errorCreate },
  ] = useMutation<
    ScheduledActivity,
    APIError,
    Yup.InferType<typeof ScheduledActivitySchema>
  >(values => createScheduledActivity(accountId, values), {
    onSuccess: onClose,
  });

  const [
    updateScheduledActivityMutation,
    { isLoading: isLoadingUpdate, error: errorUpdate },
  ] = useMutation<
    ScheduledActivity,
    APIError,
    Yup.InferType<typeof ScheduledActivitySchema>
  >(
    values =>
      updateScheduledActivity(
        accountId,
        scheduledActivityId ?? '',
        values,
        retry
      ),
    { onSuccess: onClose }
  );

  const submitForm = async (
    values: Yup.InferType<typeof ScheduledActivitySchema>
  ) => {
    if (!accountId) {
      return;
    }
    if (!scheduledActivityId) {
      // This is going to be a new post
      await createScheduledActivityMutation(values);
    } else {
      // This is an existing post, we are editing it
      await updateScheduledActivityMutation(values);
    }
  };

  const handleDayChange = (day: Date) => {
    setSelectedDay(day);
    const newDate = day;
    const [hours, minutes] = selectedTime.split(':');
    newDate.setHours(+hours);
    newDate.setMinutes(+minutes);
    return newDate;
  };
  const parseDate = (str: string, format: string) => {
    const parsed = parse(str, format, new Date());
    if (isValid(parsed)) {
      return parsed;
    }
    return undefined;
  };

  const formatDate = (date: Date, format: string) => {
    return dateFnsFormat(date, format);
  };

  const handleTimeChange = (newTime: string) => {
    setSelectedTime(newTime);
    const newDate = new Date(selectedDay.getTime());
    const [hours, minutes] = newTime.split(':');
    newDate.setHours(+hours);
    newDate.setMinutes(+minutes);
    return newDate;
  };

  const handleMediaChange = (newMedia: Media[]) => {
    setMediaFiles(newMedia);
    return newMedia.map(m => m._id);
  };

  const handleDeleteBtnClick = async () => {
    if (!existingScheduledActivityId || !accountId) {
      return;
    }
    // TODO refactor into nice popup
    const confirm = window.confirm(
      `Are you sure you want to delete this scheduled post?`
    );
    if (!confirm) {
      return;
    }
    await deleteScheduledActivity(accountId, existingScheduledActivityId)
      .then(onClose)
      .catch(console.error);
  };

  const handleCloseBtnClick = async () => {
    if (!formDirty) {
      // Nothing was changed, just close the popup
      return onClose();
    }
    const confirm = window.confirm(
      `Any unsaved progress will be lost. Proceed?`
    );
    if (!confirm) {
      return;
    }
    return onClose();
  };

  const nameOfLinkedAccount = `${linkedAccounts.selected?.profile.firstName} ${linkedAccounts.selected?.profile.lastName}`;

  return (
    <Modal
      open={true}
      onClose={handleCloseBtnClick}
      className={classNames('ScheduledActivityPopup', {
        'preview-shown': showPreview,
        'already-posted': !!postUrn,
      })}
      fullWidth={true}
    >
      {initialValues && !postUrn && (
        <div className='modal-content box'>
          {isEditable && (
            <div className='box form-wrapper'>
              <button className='delete' onClick={handleCloseBtnClick}></button>
              <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
                Schedule Post
              </h5>
              {error && (
                <div className='notification is-error has-text-light'>
                  {error.message}
                </div>
              )}
              <Formik
                initialValues={initialValues}
                onSubmit={submitForm}
                validationSchema={ScheduledActivitySchema}
                enableReinitialize={true}
                validateOnMount={true}
              >
                {({ isValid, values, setFieldValue, errors, dirty }) => {
                  setFormDirty(dirty);
                  setFormValues(values);
                  return (
                    <Form>
                      <div className='field'>
                        <label
                          htmlFor='commentary'
                          className='label preview-toggle'
                        >
                          Text
                          {/* TODO use styled checkbox */}
                          <label className='checkbox'>
                            <input
                              className='mr-2'
                              type='checkbox'
                              checked={showPreview}
                              onChange={() => setShowPreview(!showPreview)}
                            />
                            Show preview
                          </label>
                        </label>
                        <div className='control is-expanded has-icons-left'>
                          <Field
                            component='textarea'
                            className='textarea'
                            rows={8}
                            name='commentary'
                            id='commentary'
                            placeholder='What would you like to talk about?'
                          />
                        </div>
                        <ErrorMessage
                          component='p'
                          className='help is-danger'
                          name='commentary'
                        />
                      </div>

                      <label className='label'>Media</label>
                      <div className='field'>
                        <ScheduledActivityMedia
                          mediaFiles={mediaFiles ?? []}
                          onChange={newMedia =>
                            setFieldValue('media', handleMediaChange(newMedia))
                          }
                        />
                        {dirty && errors['media'] && (
                          <p className='help is-danger'>{errors['media']}</p>
                        )}
                        {formErrors[
                          MEDIA_FILES_ERROR_MESSAGE_ENUM
                            .countOfVideoNotSupported
                        ] && (
                          <ErrorForm
                            text={
                              formErrors[
                                MEDIA_FILES_ERROR_MESSAGE_ENUM
                                  .countOfVideoNotSupported
                              ]
                            }
                          />
                        )}
                        {formErrors[
                          MEDIA_FILES_ERROR_MESSAGE_ENUM.differentTypeOfMedia
                        ] && (
                          <ErrorForm
                            text={
                              formErrors[
                                MEDIA_FILES_ERROR_MESSAGE_ENUM
                                  .differentTypeOfMedia
                              ]
                            }
                          />
                        )}
                      </div>
                      <label className='label'>Options</label>
                      <div className='field has-addons is-marginless'>
                        <div className='control has-icons-left'>
                          <div className='select'>
                            <Field component='select' name='postType'>
                              <option
                                value='auto'
                                disabled={
                                  !(
                                    'displayPhoto' in
                                    (currentAccount?.profile || {})
                                  ) || isDemoUser
                                }
                              >
                                Post automatically
                              </option>
                              <option value='notification'>
                                Email Notification
                              </option>
                            </Field>
                          </div>

                          <span className='icon is-left'>
                            {values.postType === 'notification' && (
                              <Mail size='1rem' />
                            )}
                            {values.postType === 'auto' && (
                              <Alarm size='1rem' />
                            )}
                          </span>
                        </div>
                        <div className='control'>
                          <DayPickerInput
                            value={formatDate(
                              selectedDay,
                              getLocaleDateString(navigator.language)
                            )}
                            format={getLocaleDateString(navigator.language)}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            inputProps={{
                              className: 'input',
                            }}
                            dayPickerProps={{
                              selectedDays: selectedDay,
                              disabledDays: { before: new Date() },
                              onDayClick: newDate =>
                                setFieldValue(
                                  'publishingDate',
                                  handleDayChange(newDate)
                                ),
                            }}
                          />
                        </div>
                        <div className='control'>
                          <TimePicker
                            value={selectedTime}
                            onChange={(newTime: string) => {
                              if (!newTime) newTime = '00:00';
                              setFieldValue(
                                'publishingDate',
                                handleTimeChange(newTime)
                              );
                            }}
                            clockIcon={null}
                            disableClock={true}
                            format={'HH:mm'}
                            className={'input'}
                            clearIcon={null}
                          />
                        </div>
                      </div>

                      <div className='field'>
                        {!(
                          'displayPhoto' in (currentAccount?.profile || {})
                        ) && (
                          <p className='help is-danger'>
                            You account is currently not connected if you want
                            us to automatically post your content on LinkedIn
                            make sure you reconnect your account.{' '}
                            <a
                              href={helpTexts.HOW_TO_RECONNECT_BLOG_URL}
                              className='link-text'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              Here is how to do that.
                            </a>
                          </p>
                        )}
                      </div>

                      <div className='field'>
                        <ErrorMessage
                          component='p'
                          className='help is-danger'
                          name='postType'
                        />
                        {errors['publishingDate'] && (
                          <p className='help is-danger'>
                            {errors['publishingDate']}
                          </p>
                        )}
                      </div>

                      <div className='field'>
                        {errorCreate && (
                          <p className='help is-danger'>
                            {errorCreate.message}
                          </p>
                        )}
                      </div>

                      <div className='field'>
                        {errorUpdate && (
                          <p className='help is-danger'>
                            {errorUpdate.message}
                          </p>
                        )}
                      </div>
                      {serializedMainAccount && !serializedMainAccount?.status && (
                        <div className='field'>
                          <p className='help is-danger'>
                            Your inlytics account is not connected, please
                            reconnect your account to be able to retry this
                            post.{' '}
                            <a href={helpTexts.HOW_TO_RECONNECT_BLOG_URL}>
                              <strong>
                                Learn how to reconnect your account.
                              </strong>
                            </a>
                          </p>
                        </div>
                      )}

                      <div className='field is-grouped action-bar'>
                        <div className='control'>
                          <button
                            className={classNames('button is-primary', {
                              'is-loading': isLoadingCreate || isLoadingUpdate,
                            })}
                            type='submit'
                            disabled={
                              isLoadingCreate ||
                              isLoadingUpdate ||
                              !isValid ||
                              !!formErrors[
                                MEDIA_FILES_ERROR_MESSAGE_ENUM
                                  .differentTypeOfMedia
                              ] ||
                              !!formErrors[
                                MEDIA_FILES_ERROR_MESSAGE_ENUM
                                  .countOfVideoNotSupported
                              ]
                            }
                          >
                            Save &amp; Schedule
                          </button>
                        </div>
                        {serializedMainAccount?.status && (
                          <div className='control'>
                            <button
                              className='button'
                              type='submit'
                              disabled={!isRetry}
                              onClick={() => {
                                const time = new Date();
                                setSelectedTime(
                                  `${time.getHours()}:${time.getMinutes()}`
                                );
                                setFieldValue(
                                  'publishingDate',
                                  handleDayChange(time)
                                );
                                setRetry(true);
                              }}
                            >
                              Retry
                            </button>
                          </div>
                        )}
                        <div className='control'>
                          <button
                            type='button'
                            className='button'
                            onClick={handleCloseBtnClick}
                          >
                            Close
                          </button>
                        </div>
                        <div className='control is-expanded'></div>
                        <div className='control'>
                          {existingScheduledActivityId && (
                            <button
                              type='button'
                              className='button is-danger'
                              onClick={handleDeleteBtnClick}
                            >
                              Delete post
                            </button>
                          )}
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          )}
          {showPreview && currentAccount && linkedAccounts && (
            <div className='box preview'>
              <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
                Preview
              </h5>
              {!isEditable && (
                <button
                  className='delete'
                  onClick={handleCloseBtnClick}
                ></button>
              )}
              <div className='linked-in-preview'>
                <div className='actor'>
                  <figure className='image'>
                    <img
                      className='is-rounded'
                      src={
                        isDemoUserEmail(currentAccountEmail)
                          ? currentActivity?.author.profile.picture
                          : ('displayPhoto' in currentAccount?.profile
                              ? currentAccount?.profile.displayPhoto.tiny
                              : currentAccount?.profile.picture) ||
                            linkedAccounts.selected?.profile.displayPhoto.tiny
                      }
                      alt={authorName}
                    />
                  </figure>
                  <div className='meta'>
                    <div className='name'>
                      {authorName || nameOfLinkedAccount}
                    </div>
                    <div className='occupation'>
                      {'occupation' in currentAccount.profile &&
                      !isDemoUserEmail(currentAccountEmail)
                        ? currentAccount?.profile.occupation
                        : ''}
                    </div>
                    <div>Just now •</div>
                  </div>
                </div>
                <div className='share-content'>
                  {isEditable
                    ? formValues?.commentary
                    : currentActivity?.commentary}
                </div>
                {mediaFiles && mediaFiles.length > 0 && (
                  <div
                    className={classNames(
                      'share-media',
                      `has-${mediaFiles.length}`,
                      {
                        'has-more-than-5': mediaFiles.length > 5,
                      }
                    )}
                  >
                    {mediaFiles.slice(0, 5).map(media => (
                      <MediaImg key={media._id} media={media} controls />
                    ))}
                    {mediaFiles.length > 5 && (
                      <div className='more-images'>
                        +{mediaFiles.length - 5}
                      </div>
                    )}
                  </div>
                )}
                <div className='social-actions'>
                  <div className='ghost-button'></div>
                  <div className='ghost-button'></div>
                  <div className='ghost-button'></div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {initialValues && postUrn && (
        <div className='box already-posted'>
          <button className='delete' onClick={onClose}></button>
          <h4 className='title is-size-4'>Your post has been published!</h4>
          <p className='sub-title has-text-grey'>
            The post you are trying to edit was already posted.
            <br />
            You can now view it directly on LinkedIn.
          </p>
          <a
            href={`https://www.linkedin.com/feed/update/${postUrn}`}
            target='_blank'
            rel='noopener noreferrer'
            className='button is-primary is-fullwidth'
          >
            <span className='icon'>
              <LogoLinkedin size='1rem' />
            </span>
            <span>View on LinkedIn</span>
          </a>
        </div>
      )}
    </Modal>
  );
};

export default ScheduledActivityPopup;
