import React from 'react';
import { ErrorIcon } from '../assets/icons/ErrorIcon';

interface FromErrorProps {
  text: string | undefined;
}

const ErrorOfForm: React.FC<FromErrorProps> = ({ text }) => {
  return (
    <div className={`field has-icons-left is-flex`}>
      <span className='icon is-left'>
        <ErrorIcon />
      </span>
      <span className='has-text-danger ml-2'>{text}</span>
    </div>
  );
};

export default ErrorOfForm;
