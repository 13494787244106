import { ACTION_TYPE } from '../actions';
import { ScheduledActivity } from '../../services/schedule-service';
import { ScheduledActivitiesAction } from '../actions/scheduledActivities';

export type ScheduledActivitiesState = {
  activitiesByAccount: { [accountId: string]: ScheduledActivity[] };
  activitiesByGroup: { [groupId: string]: ScheduledActivity[] };
  currentScheduledActivity: ScheduledActivity | null;
  chosenAnalyticJob: { jobId: string; name: string } | null;
};

const initialState = {
  activitiesByAccount: {},
  activitiesByGroup: {},
  currentScheduledActivity: null,
  chosenAnalyticJob: null,
};

const scheduledActivities = (
  state: ScheduledActivitiesState = initialState,
  action: ScheduledActivitiesAction
): ScheduledActivitiesState => {
  switch (action.type) {
    case ACTION_TYPE.SET_SCHEDULED_ACTIVITIES_FOR_ACCOUNT:
      return {
        ...state,
        activitiesByAccount: {
          ...state.activitiesByAccount,
          [action.payload.accountId]: action.payload.activities,
        },
      };
    case ACTION_TYPE.SET_SCHEDULED_ACTIVITIES_FOR_GROUP:
      return {
        ...state,
        activitiesByGroup: {
          ...state.activitiesByGroup,
          [action.payload.groupId]: action.payload.activities,
        },
      };
    case ACTION_TYPE.SET_CURRENT_SCHEDULED_ACTIVITY:
      // TODO Only allow setting it, if this is a valid activity for our current account
      // TODO reset upon account switching
      return {
        ...state,
        currentScheduledActivity: action.payload,
      };
    case ACTION_TYPE.SET_CHOSEN_ANALYTIC_JOB:
      return {
        ...state,
        chosenAnalyticJob: action.payload,
      };
    default:
      return state;
  }
};

export default scheduledActivities;
