import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { CloseCircle, HelpCircle } from '@styled-icons/ionicons-solid';

import apiClient from '../services/api-client';
import Modal from './modal/Modal';
import * as StyledLoading from '../styles/common/Loading.styles';
import StyledTooltip from './StyledTooltip';
import { getActivityLabels, getUserLabels, labelsChange } from '../helpers';

interface ModalProps {
  isOpen: boolean;
  handleOpen: (open: boolean) => void;
  userLabels: string[];
  activityLabels: string[] | undefined;
  userId: string;
  activityId: string;
}

const LabelsModal = ({
  isOpen,
  handleOpen,
  userLabels,
  activityLabels,
  userId,
  activityId,
}: ModalProps) => {
  const [postLabels, setPostLabels] = useState<string[]>(activityLabels || []);
  const [isLoading, setIsLoading] = useState(false);
  const [newTag, setNewTag] = useState('');

  useEffect(() => setPostLabels(activityLabels || []), [activityLabels]);

  const handleNewTag = (e: any) => {
    if (e.key !== 'Enter') return;
    setNewTag(e.target.value);
    if (!newTag.trim()) return;
    if (activityLabels && !activityLabels.includes(e.target.value)) {
      setPostLabels([...postLabels, e.target.value]);
    }
    setNewTag('');
  };

  const handleCloseBtn = () => {
    if (changes) {
      const confirm = window.confirm(
        'Any unsaved progress will be lost. Proceed?'
      );
      if (!confirm) return;
      handleOpen(false);
      setPostLabels(activityLabels || []);
    } else {
      handleOpen(false);
    }
  };

  const handleSaveBtn = () => {
    setIsLoading(true);
    Promise.all([
      apiClient.put(`/users/me/${userId}/labels`, {
        labels: postLabels,
      }),
      apiClient.put(`/activities/${activityId}/labels`, {
        labels: postLabels,
      }),
    ])
      .then(response => {
        if (response.every(response => response.status === 200)) {
          handleOpen(false);
          setIsLoading(false);
          getUserLabels(userId);
          getActivityLabels(activityId);
          window.location.reload();
        } else {
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const changes = !labelsChange({
    initialLabels: activityLabels,
    toSubmitLabels: postLabels,
  });

  return (
    <Modal
      open={isOpen}
      fullWidth={true}
      className={classNames('ScheduledActivityPopup')}
    >
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Post Labels</p>
          <button
            className='delete'
            aria-label='close'
            onClick={handleCloseBtn}
          ></button>
        </header>
        <section className='modal-card-body'>
          {isLoading ? (
            <StyledLoading.LoadingContainer>
              <StyledLoading.LoadingBarSmall active />
            </StyledLoading.LoadingContainer>
          ) : (
            <div>
              <div>
                <h4
                  style={{
                    marginBottom: '1rem',
                  }}
                >
                  Your Labels:{' '}
                  <HelpCircle
                    size='1.5rem'
                    data-tip
                    data-for={'user-labels-help'}
                    cursor='help'
                  />
                </h4>

                <StyledTooltip id='user-labels-help' place='right'>
                  Your saved labels
                </StyledTooltip>
                {userLabels.map(tag => {
                  return (
                    <span
                      key={tag}
                      className={`tag is-info is-medium ${postLabels &&
                        postLabels.includes(tag) &&
                        'is-light'}`}
                      style={{
                        marginLeft: '0.5rem',
                        marginBottom: '0.5rem',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (postLabels && postLabels.includes(tag)) return;
                        postLabels && setPostLabels([...postLabels, tag]);
                      }}
                    >
                      {tag}
                      <CloseCircle
                        size='1rem'
                        style={{
                          marginLeft: '0.5rem ',
                          cursor: 'pointer',
                        }}
                      />
                    </span>
                  );
                })}
              </div>
              <div
                style={{
                  marginTop: '1rem',
                }}
              >
                <h4
                  style={{
                    marginBottom: '1rem',
                  }}
                >
                  Post Labels:{' '}
                  <HelpCircle
                    size='1.5rem'
                    data-tip
                    data-for={'post-labels-help'}
                    cursor='help'
                  />
                </h4>
                <StyledTooltip id='post-labels-help' place='right'>
                  Specific labels for this post
                </StyledTooltip>
                {postLabels.map(tag => (
                  <span
                    key={tag}
                    className='tag is-info is-medium'
                    style={{
                      marginLeft: '0.5rem',
                      marginBottom: '0.5rem',
                    }}
                  >
                    {tag}
                    <CloseCircle
                      size='1rem'
                      style={{
                        marginLeft: '1rem ',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        setPostLabels(postLabels.filter(label => label !== tag))
                      }
                    />
                  </span>
                ))}
                <br />
                <input
                  onKeyDown={handleNewTag}
                  className='input'
                  placeholder='New tag...'
                  value={newTag}
                  onChange={e => setNewTag(e.target.value)}
                  style={{
                    marginTop: '1rem',
                  }}
                />
              </div>
            </div>
          )}
          {!isLoading && (
            <button
              className='button is-info'
              style={{
                marginTop: '1rem',
              }}
              onClick={handleSaveBtn}
              disabled={!changes}
            >
              Save changes
            </button>
          )}
        </section>
      </div>
    </Modal>
  );
};

export default LabelsModal;
