import React, { useContext } from 'react';

import ChooseTool from './ChooseTool';
import ToolsCard from './ToolsCard';
import { AnalyzeIcon, ScheduleIcon } from '../CustomIcons';
import '../../styles/components/accountSharing/CreateGroup.scss';
import { AccountSharingModalContext, GroupTypes } from './AccountSharingModal';

const CreateGroup = () => {
  const {
    groupName: groupNameFromContext,
    groupImage: groupImageFromContext,
    groupType: groupTypeFromContext,
  } = useContext(AccountSharingModalContext);

  const [groupName, setGroupName] = groupNameFromContext;
  const [groupImage, setGroupImage] = groupImageFromContext;
  const [groupType, setGroupType] = groupTypeFromContext;

  const handleLogo = (event: any) => {
    const file: File = event.target.files[0];

    if (!file) {
      return;
    }

    // 1 Mb in bytes
    if (file.size > 1048576) {
      window.alert('File size is too big: 1 Mb allowed');
      return;
    }

    setGroupImage(file);
  };

  return (
    <div className='createGroup'>
      <h2 className='headline-2'>Create A New Group</h2>
      <p className='description-main'>Here you can configurate how the group should work.</p>
      <div className='groupInfo-container'>
        <h3 className='headline-3'>Group Branding</h3>
        <div className='name-container'>
          <label className='label'>Name of the group</label>
          <input className='name' type='text' onChange={event => setGroupName(event.target.value)} value={groupName} />
        </div>
        <div className='upload-container'>
          <label className='label'>Add group Logo (optional 1:1)</label>
          <input
            className='upload'
            id='upload'
            type='file'
            accept='image/png, image/jpeg'
            multiple={false}
            onChange={handleLogo}
          />
          <label htmlFor='upload' className='upload-button'>
            Upload file
          </label>
          {!!groupImage && (
            <div className='preview-container'>
              <img className='preview' alt='Group preview' src={URL.createObjectURL(groupImage)} />
            </div>
          )}
        </div>
      </div>
      <ChooseTool headline='Group Purpose'>
        <ToolsCard
          image={AnalyzeIcon}
          headline='Analytics'
          description='Gives people access to your analytics tool'
          state={groupType === GroupTypes.Analytics}
          setState={(val: boolean) => setGroupType(val ? GroupTypes.Analytics : undefined)}
        />
        <ToolsCard
          image={ScheduleIcon}
          headline='Scheduling'
          description='Gives people access to your scheduling tool'
          state={false}
          setState={() => {}}
          helpIcon='🔒'
          helpText="For now, we decided to disable the sharing ability for the Scheduling Tool in Groups. Why? We would like to reduce the risk of abusive or accidental postings on someone else's LinkedIn account. To share or request access to the Scheduling Tool use the „Single Account Sharing Feature“. "
          disabled
        />
      </ChooseTool>
    </div>
  );
};

export default CreateGroup;
