import { ACTION_TYPE } from '.';
import { User } from '../../services/user-service';

export interface SetLoggedInUserAction {
  type: ACTION_TYPE.SET_USER;
  payload: User | null;
}

export const setUser = (user: User | null): SetLoggedInUserAction => ({
  type: ACTION_TYPE.SET_USER,
  payload: user,
});

export type AuthTypes = SetLoggedInUserAction;
