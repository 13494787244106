import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { USER_PLAN } from '../../services/user-service';
import {
  DATE_RANGE,
  setDateRangeFilter,
} from '../../store/actions/dateRangerFilter';
import { RootState } from '../../store/reducers';
import StyledTooltip from '../StyledTooltip';
import { Calendar } from '@styled-icons/ionicons-solid';
import classNames from 'classnames';

export interface DateRangeDropdownEntry {
  value: DATE_RANGE;
  name: string;
}

export const DATE_RANGES: DateRangeDropdownEntry[] = [
  {
    value: DATE_RANGE.LAST_3_DAYS,
    name: 'Last 3 days',
  },
  {
    value: DATE_RANGE.LAST_30_DAYS,
    name: 'Last 30 days',
  },
  {
    value: DATE_RANGE.ONE_WEEK,
    name: '1 week',
  },
  {
    value: DATE_RANGE.FOUR_WEEKS,
    name: '4 weeks',
  },
  {
    value: DATE_RANGE.MONTH_TO_DATE,
    name: 'Month to date',
  },
  {
    value: DATE_RANGE.YEAR_TO_DATE,
    name: 'Year to date',
  },
  {
    value: DATE_RANGE.ALL_TIME,
    name: 'All time',
  },
  {
    value: DATE_RANGE.CUSTOM,
    name: 'Custom',
  },
];

const RangeCategorySelect = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { dateRange } = useSelector(
    (state: RootState) => state.dateRangeFilter
  );
  const { accountId: paramAccountId, groupId: paramGroupId } = useParams<{
    accountId: string;
    groupId: string;
  }>();
  const { pathname } = useLocation();
  const disable3days = pathname.includes('analyze/profile');
  const [chosenDateRange, setChosenDateRange] = useState<DATE_RANGE>(dateRange);
  const dispatch = useDispatch();

  useEffect(() => {
    setChosenDateRange(
      dateRange === DATE_RANGE.LAST_3_DAYS && disable3days
        ? DATE_RANGE.LAST_30_DAYS
        : dateRange
    );
  }, [dateRange, disable3days]);

  useEffect(() => {
    dispatch(
      setDateRangeFilter(chosenDateRange, paramGroupId || paramAccountId)
    );
  }, [dispatch, paramGroupId, paramAccountId, chosenDateRange]);

  const handleOnDateRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const targetValue = e.target.value as DATE_RANGE;
    setChosenDateRange(targetValue);
    // dispatch(setDateRangeFilter(targetValue, paramGroupId || paramAccountId));
  };

  return (
    <div className='field range-select'>
      <div className='control has-icons-left'>
        <div
          className={classNames('select is-small', {
            'is-disabled': user!.plan === USER_PLAN.FREE,
          })}
          data-tip={
            user!.plan === USER_PLAN.FREE
              ? `As a free user, you are limited to 30 days of data history. Please upgrade your account to get access to unlimited data history.`
              : ''
          }
        >
          <select
            name='date-range'
            id='date-range'
            value={dateRange}
            disabled={user!.plan === USER_PLAN.FREE}
            onChange={handleOnDateRangeChange}
          >
            {!pathname.includes('analyze/profile') &&
              DATE_RANGES.map(entry => {
                return (
                  <option key={entry.value} value={entry.value}>
                    {entry.name}
                  </option>
                );
              })}
            {pathname.includes('analyze/profile') &&
              DATE_RANGES.filter(
                range => range.value !== DATE_RANGE.LAST_3_DAYS
              ).map(entry => {
                return (
                  <option key={entry.value} value={entry.value}>
                    {entry.name}
                  </option>
                );
              })}
          </select>
          <StyledTooltip disable={user!.plan !== USER_PLAN.FREE} />
        </div>
        <span className='icon is-left'>
          <Calendar size='1rem' />
        </span>
      </div>
    </div>
  );
};

export default RangeCategorySelect;
