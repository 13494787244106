import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Statistics,
  StatisticsSnapshot,
  loadHistoryForAccount,
  loadMetricsForAccount,
  agregateStatistics,
} from '../services/statistics-service';
import '../styles/components/Content.scss';

import Header from './Header';
import DateRangePicker from './header/DateRangePicker';
import DashboardStatisticCards from './DashboardStatisticCards';
import ActivityTimelineChart from './ActivityTimelineChart';

import { RootState } from '../store/reducers';
import ContentTable from './ContentTable';
import RecentlyConnectedBanner from './banner/RecentlyConnectedBanner';
import { CsvLoad, PageType } from './CsvLoad';
import { contentConverter, IContentConverter } from '../services/csv-service';
import { HelpCircle, Timer } from '@styled-icons/ionicons-solid';
import StyledTooltip from './StyledTooltip';
import EngagementBanner from './banner/EngagementBanner';
import { ACTIVITY_TYPE_NAME_MAP } from '../services/activities-service';
import { DocumentPdf } from '@styled-icons/fluentui-system-filled';
import { store } from '../store';
import { setSidebarOpenState } from '../store/actions/settings';
import { exportToPdf } from '../helpers/exportToPdf';
import { useAmountStats } from '../hooks/useAmountStats';

const Content = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const [statistics, setStatistics] = useState<Statistics>();
  const [referenceStatistics, setReferenceStatistics] = useState<Statistics>();
  const [history, setHistory] = useState<StatisticsSnapshot[]>();
  const { startDate, endDate } = useSelector(
    (state: RootState) => state.dateRangeFilter
  );
  const startTimeStamp = startDate.getTime();
  const endTimeStamp = endDate.getTime();

  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState<IContentConverter>();
  const [pdfLoading, setPdfLoading] = useState(false);
  const [width, setWidth] = useState(0);
  const [unifiedStatistics, setUnifiedStatistics] = useState<Statistics>();

  useEffect(() => {
    setWidth(window.outerWidth);
  }, [statistics]);

  useEffect(() => {
    if (!accountId) {
      return;
    }
    setLoading(true);
    setHistory([
      {
        amountComments: 0,
        amountLikes: 0,
        amountShares: 0,
        amountViews: 0,
        amountImpressions: 0,
        amountReactionType: [],
        timestamp: 0,
      },
    ]);
    const startDate = new Date(startTimeStamp);
    const endDate = new Date(endTimeStamp);

    // Load both the history and dashboard metrics
    Promise.all([
      loadHistoryForAccount(accountId, startDate, endDate),
      loadMetricsForAccount(accountId, startDate, endDate),
    ])
      .then(([timeline, metrics]) => {
        setHistory(timeline);
        setStatistics(metrics.statistics);
        if (metrics.reference) {
          setReferenceStatistics(metrics.reference);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [accountId, startTimeStamp, endTimeStamp]);

  const id = Math.floor(Math.random() * 1000);
  const { generalStats } = useAmountStats({ data: history, id: id });

  useEffect(() => {
    if (generalStats) {
      setUnifiedStatistics(agregateStatistics(generalStats));
    }
  }, [generalStats]);
  const addToCsv = useCallback((data: any) => {
    setCsvData(prevState => ({ ...(prevState || {}), ...data }));
  }, []);

  const handlePdf = () => {
    setPdfLoading(true);
    store.dispatch(setSidebarOpenState(false));
    setTimeout(() => {
      exportToPdf('ContentPage', 'inlytics-dashboard', width);
      setPdfLoading(false);
    }, 500);
  };

  return (
    <div className='Content' id='ContentPage'>
      <Header
        title='Dashboard'
        content={
          <>
            <div
              data-html2canvas-ignore='true'
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {csvData && (
                <CsvLoad
                  type={PageType.Content}
                  data={{
                    ...csvData,
                    Activities: csvData.Activities.map(act => {
                      return {
                        ...act,
                        activity: {
                          ...act.activity,
                          type: ACTIVITY_TYPE_NAME_MAP[act.activity.type] as
                            | 'post'
                            | 'share',
                        },
                      };
                    }),
                  }}
                  converter={contentConverter}
                />
              )}

              {statistics && (
                <div
                  className='csv-block'
                  style={{
                    cursor: `${pdfLoading ? 'progress' : 'pointer'}`,
                  }}
                  onClick={() => handlePdf()}
                >
                  <DocumentPdf size={'1rem'} className='icon' />
                  Download PDF
                </div>
              )}
            </div>

            <DateRangePicker />
          </>
        }
        loading={loading}
      />

      <RecentlyConnectedBanner />

      {statistics && (
        <section className='section' id='DashboardStatisticCards'>
          <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
            <span>Tracked Statistics</span>
            <HelpCircle
              data-html2canvas-ignore='true'
              data-tip
              data-for={`styled-tooltip-history`}
              size={'1.5rem'}
              style={{
                marginLeft: '0.5rem',
              }}
            />
            <StyledTooltip
              id={`styled-tooltip-history`}
              delayHide={500}
              clickable={true}
            >
              <p>
                <Timer
                  height={'1rem'}
                  style={{
                    marginRight: '0.3rem',
                  }}
                />
                Updates every 30 min
              </p>
              <br />
              <div>The activities can be “share”, “post”, “video”.</div>
            </StyledTooltip>
          </h5>
          <DashboardStatisticCards
            data={history}
            statistics={unifiedStatistics}
            referenceStatistics={referenceStatistics}
            cardFilter={[
              'views',
              'likes',
              'comments',
              'engagement',
              'impressions',
            ]}
          />
        </section>
      )}
      {history && (
        <section className='section' id='TimelineAllContent'>
          <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
            <span>Timeline all content</span>
            <HelpCircle
              data-html2canvas-ignore='true'
              data-tip
              data-for={`styled-tooltip-history`}
              size={'1.5rem'}
              style={{
                marginLeft: '0.5rem',
              }}
            />
            <StyledTooltip
              id={`styled-tooltip-history`}
              delayHide={500}
              clickable={true}
            >
              <p
                style={{
                  textTransform: 'initial',
                }}
              >
                <Timer
                  height={'1rem'}
                  style={{
                    marginRight: '0.3rem',
                  }}
                />
                Updates every 30 min
              </p>
              <br />
              <div
                style={{
                  textTransform: 'initial',
                }}
              >
                <ul>
                  <li>
                    This data is being collected from the time you register for
                    inlytics. Please note that historical data cannot be
                    visualized, so there may be a discrepancy between the
                    visualized data and the data listed above.
                  </li>
                  <li>
                    Display a timeline of the selected metric for all content
                    grouped
                  </li>
                  <li>
                    The percentage comparison is comparing with the same range
                    for the previous period.
                  </li>
                </ul>
              </div>
            </StyledTooltip>
          </h5>
          <ActivityTimelineChart
            brushEnabled={true}
            timeline={history}
            isGroup={false}
          />
        </section>
      )}
      <section className='section'>
        <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
          Content overview
          <HelpCircle
            data-tip
            data-for={`styled-tooltip-dashboard-interval-top-posts`}
            size={'1.5rem'}
            style={{
              marginLeft: '0.5rem',
            }}
          />
          <StyledTooltip
            id={`styled-tooltip-dashboard-interval-top-posts`}
            delayHide={500}
            clickable={true}
          >
            <p
              style={{
                textTransform: 'initial',
              }}
            >
              <Timer
                height={'1rem'}
                style={{
                  marginRight: '0.3rem',
                }}
              />
              Updates every 30 min
            </p>
            <br />
            <span
              style={{
                textTransform: 'initial',
              }}
            >
              Display all activities of the user
            </span>
          </StyledTooltip>
        </h5>
        <EngagementBanner />
        <ContentTable onDataLoaded={addToCsv} isLoading={pdfLoading} />
      </section>
    </div>
  );
};

export default Content;
