interface SelectEntry {
  value: string;
  name: string;
}

export const employmentTypeOptions = [
  {
    value: 'self-employed',
    name: 'self-employed',
  },
  {
    value: 'employed',
    name: 'employed',
  },
];
export const employmentIndustryOptions = [
  {
    value: 'construction-industry',
    name: 'Construction industry',
  },
  {
    value: 'chemicals-and-raw-materials',
    name: 'Chemicals & Raw Materials',
  },
  {
    value: 'services-and-craft-industry',
    name: 'Services & Craft industry',
  },
  {
    value: 'e-commerce-and-trade',
    name: 'E-Commerce & Trade',
  },
  {
    value: 'energy-and-environment',
    name: 'Energy & Environment',
  },
  {
    value: 'finance-insurance-real-estate',
    name: 'Finance, insurance, real estate',
  },
  {
    value: 'retail',
    name: 'Retail',
  },
  {
    value: 'internet-and-it',
    name: 'Internet & IT',
  },
  {
    value: 'media-and-marketing',
    name: 'Media & Marketing',
  },
  {
    value: 'metal-and-electronics',
    name: 'Metal & Electronics',
  },
  {
    value: 'health',
    name: 'Health',
  },
  {
    value: 'politics',
    name: 'Politics',
  },
  {
    value: 'automotive',
    name: 'Automotive',
  },
  {
    value: 'education-and-law',
    name: 'Education & Law',
  },
  {
    value: 'agency-and-service',
    name: 'Agency / Service Company',
  },
];
export const occupationOptions = [
  {
    value: 'marketing',
    name: 'Marketing',
  },
  {
    value: 'founder',
    name: 'Founder',
  },
  {
    value: 'executive',
    name: 'Executive',
  },
  {
    value: 'manager',
    name: 'Manager',
  },
  {
    value: 'accounting',
    name: 'Accounting',
  },
  {
    value: 'business-development',
    name: 'Business Development',
  },
  {
    value: 'sales',
    name: 'Sales',
  },
  {
    value: 'customer-success',
    name: 'Customer Success',
  },
  {
    value: 'recruiting',
    name: 'Recruiting',
  },
  {
    value: 'hr',
    name: 'HR',
  },
  {
    value: 'engineer',
    name: 'Engineer',
  },
  {
    value: 'data',
    name: 'Data ',
  },
  {
    value: 'design',
    name: 'Design',
  },
  {
    value: 'social-media',
    name: 'Social Media',
  },
  {
    value: 'consultant',
    name: 'Consultant',
  },
];
export const onboardingMotivationOptions = [
  {
    value: 'linkedln-analytics',
    name: 'Linkedln Analytics',
  },
  {
    value: 'linkedln-post-scheduling',
    name: 'Linkedln Post Scheduling',
  },
  {
    value: 'linkedln-profile-seo',
    name: 'Linkedln Profile SEO',
  },
];

export const onboardingSourceOptions = [
  {
    value: 'linkedin-organic',
    name: 'Linkedln Organic',
  },
  {
    value: 'web-search',
    name: 'Web Search',
  },
  {
    value: 'recommendation-from-friends',
    name: 'Recommendation from friends',
  },
  {
    value: 'advertising',
    name: 'Advertising',
  },
];
