import apiClient from './api-client';
import { AmountReactionType } from './statistics-service';
import { workerClient } from './workers-client';

export interface Activity {
  _id: string;
  urn: string; // e.g. urn:li:activity:6616711047082459136
  type: 'share' | 'post';
  userId: string; // User id that posted this article
  account?: {
    id: string;
  };
  publicationDate: Date; // Date this activity was published
  content: string; // Content as simple string
  image: string; // URL to image (800px width)
  imageThumb: string; // URL to image thumbnail (160px width)
  amountComments: number;
  amountLikes: number;
  amountViews: number;
  amountImpressions?: number;
  amountShares: number;
  amountReactionType: AmountReactionType[];
  sortIndex: number; // Sorting as provided by LinkedIn
  highlights?: ActivityHighlights;
  labels?: string[];
}

export interface ActivityCompanyHighlight {
  urn: string;
  name: string; // Display name
  universalName: string; // Universal name, can be used to link to said company (e.g. 'daimler' -> https://www.linkedin.com/company/daimler/)
  amountViews: number; // Amount of views from this company
}

export interface ActivityOccupationHighlight {
  title: string; // Job title
  amountViews: number; // Amount of views from this occupation
}

export interface ActivityRegionHighlight {
  // Region name
  region: string;
  amountViews: number; // Amount of views from this region
}

export interface ActivityHighlights {
  company?: ActivityCompanyHighlight[];
  occupation?: ActivityOccupationHighlight[];
  region?: ActivityRegionHighlight[];
}

export const ACTIVITY_TYPE_NAME_MAP = {
  share: 'Share',
  post: 'Post',
  video: 'Video',
};

export interface ActivityNote {
  activity: string;
  user: string;
  content: string;
}

export async function loadActivity(activityId: string): Promise<Activity> {
  const result = await apiClient.get<Activity>(`/activities/${activityId}`);
  return result.data;
}

export async function loadNotes(
  activityIds: string[]
): Promise<Map<string, ActivityNote>>;
export async function loadNotes(
  accountId: string
): Promise<Map<string, ActivityNote>>;
export async function loadNotes(
  filter: string[] | string
): Promise<Map<string, ActivityNote>> {
  let params: { [key: string]: string | string[] } = {};
  if (Array.isArray(filter)) {
    // Filtered by activityIds
    params.activityIds = filter;
  } else if (typeof filter === 'string') {
    // Filtered by accountId
    params.accountId = filter;
  }
  const result = await apiClient.get<{ notes: ActivityNote[] }>(
    `/activities/notes`,
    { params }
  );
  const { notes } = result.data;
  return new Map(notes.map(n => [n.activity, n]));
}

export async function upsertNoteForActivity(
  activityId: string,
  content: string
) {
  await apiClient.put(`/activities/${activityId}/note`, { content });
}

export async function deleteNoteForActivity(activityId: string) {
  await apiClient.delete(`/activities/${activityId}/note`);
}

export async function updateActivitiesHighlights(
  accountId: string,
  jobType: string
) {
  await workerClient.post('/worker/process', {
    jobType,
    accountId,
  });
}
