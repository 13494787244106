import styled from 'styled-components';
import { colors } from '../../styles/variables';

export const Banner = styled.div`
  background-color: ${colors.orange};
  padding: 10px 0;
  text-align: center;
  color: #ffffff;

  .is-primary {
    font-weight: 700;
  }
`;

export const HowToReconnectBnt = styled.a`
  display: inline-block;
  margin-left: 20px;
  background-color: #ffffff;
  color: ${colors.orange};
  text-decoration: none;
  padding: 7px 15px;
  border-radius: 10px;
  transition: 0.25s ease;
  border: 1px solid #ffffff;

  &:hover {
    color: #ffffff;
    background-color: ${colors.orange};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem 5rem;
`;

export const HeaderTitle = styled.h2`
  font-size: 2rem;
  && {
    margin-bottom: 0;
  }
`;

export const CreateGroupBtn = styled.button`
  &.button.is-primary {
    background-color: transparent;
    border: solid 2px #087cf0;
    color: #087cf0;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const ConnectMainAccountBtn = styled.button`
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const PersonalSettingsForm = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const NoAccountConnected = styled.div``;
