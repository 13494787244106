import apiClient from "./api-client";

export interface Media {
  _id: string;
  uploadedBy: string; // User id of the uploader
  type: "image" | "video";
  mimeType: string;
  ext: string;
  publicUrl?: string;
  publicUrlExpires?: Date;
}

export interface MediaUploadError {
  error: string;
}

export async function getMediaFiles(): Promise<Media[]> {
  const res = await apiClient.get<{ media: Media[] }>(`/media`);
  return res.data.media;
}

export async function uploadMediaFiles(files: File[]) {
  const formData = new FormData();
  files.forEach(file =>
    formData.append(`media[${encodeURIComponent(file.name)}]`, file)
  );
  const response = await apiClient.post<{
    media: Array<Media | MediaUploadError>;
  }>(`/media`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  return response.data.media;
}

export async function deleteMediaFile(media: Media) {
  await apiClient.delete(`/media/${media._id}`);
}

export async function mediaUpload(file: File) {
  return await apiClient.post(`/media/mediaUpload`, {
    name: file.name,
    mimetype: file.type
  });
}

export async function mediaUploadToBucket(url: string, file: File) {
  const formData = new FormData();
  formData.append(`media[${encodeURIComponent(file.name)}]`, file);

  return new Promise((resolve, reject) => {
    const xhr = new window.XMLHttpRequest();
    xhr.open("PUT", url);
    xhr.onload = resolve;
    xhr.onerror = reject;
    xhr.setRequestHeader("Content-Type", "application/octet-stream");
    xhr.send(file);
  });
}
