import React, { useState } from 'react';
import SettingHeader from '../../accountSettings/SettingHeader';
import { GroupUserRoles } from '../../accountSharing/AccountSharingModal';
import { AccountsTableEntryViewModel } from '../../linked-accounts/AccountList';
import { GroupMembersList } from './GroupMembersList';
import { useMutation } from 'react-query';
import { updateGroup, UpdateGroupPayload } from '../../../services/groups-service';
import { APIError } from '../../../services/api-client';
import { useDemoUser } from '../../../hooks/useDemoUser';
import './GroupEditModal.scss';

interface Props {
  data: AccountsTableEntryViewModel;
  onClose: () => void;
  onSave: () => void;
}

export const GroupEditModal: React.FC<Props> = ({ data, onClose, onSave }) => {
  const { id, avatar, name, role, hashtags } = data;
  const isDemoUser = useDemoUser();

  const [groupName, setGroupName] = useState<string>(name);
  const [groupImage, setGroupImage] = useState<File>();
  const [groupHashtags, setGroupHashtags] = useState<string>(hashtags?.replace(';', ' ') || '');

  const [isHashtagInputActive, setIsHashTagInputActive] = useState<boolean>(false);

  const [updateGroupMutation] = useMutation<any, APIError, UpdateGroupPayload>(updateGroup);

  const handleLogo = (event: any) => {
    const file: File = event.target.files[0];

    if (!file) {
      return;
    }

    // 1 Mb in bytes
    if (file.size > 1048576) {
      window.alert('File size is too big: 1 Mb allowed');
      return;
    }

    setGroupImage(file);
  };

  const saveChanges = () => {
    setIsHashTagInputActive(false);
    const newHashtags = filterHashtags(';');

    if (groupImage || name !== groupName || hashtags !== newHashtags) {
      return updateGroupMutation({
        groupId: id,
        groupLogo: groupImage,
        name: groupName,
        hashtags: newHashtags,
      }).then(() => onSave());
    }

    onClose();
  };

  const filterHashtags = (separator: ';' | ' ') => {
    const filtered = groupHashtags.match(/(#\w+)/g)?.join(separator) || '';
    setGroupHashtags(filtered);
    return filtered;
  };

  return (
    <>
      <button type='button' className='modal-close' onClick={() => onClose()} />
      <div className=''>
        <SettingHeader
          headline={`Settings for ${name}`}
          description='Here you can configurate how the group should work.'
          tip={role === GroupUserRoles.Admin ? 'Group Branding & Invites' : 'Group Members'}
        />

        {role !== GroupUserRoles.User && (
          <div className='groupInfo-container'>
            <div className='name-container responsive-container'>
              <label className='label'>Name of the group</label>
              <input
                className={`name ${role !== GroupUserRoles.Admin && 'disabled'}`}
                type='text'
                onChange={event => setGroupName(event.target.value)}
                value={groupName}
                disabled={role !== GroupUserRoles.Admin}
              />
            </div>
            <div className='upload-container responsive-container'>
              <label className='label'>Add group Logo (optional 1:1)</label>
              <input
                className='upload'
                id='upload'
                type='file'
                accept='image/png, image/jpeg'
                multiple={false}
                onChange={handleLogo}
                disabled={role !== GroupUserRoles.Admin}
              />
              <label htmlFor='upload' className={`upload-button ${role !== GroupUserRoles.Admin && 'disabled'}`}>
                Upload file
              </label>
              {(!!groupImage || !!avatar) && (
                <div className='preview-container'>
                  <img
                    className='preview'
                    alt='Group preview'
                    src={!!groupImage ? URL.createObjectURL(groupImage) : avatar}
                  />
                </div>
              )}
            </div>
            <div className='hashtag-container responsive-container'>
              <label className='label'>Only include posts with these hashtags</label>
              {isHashtagInputActive && role === GroupUserRoles.Admin ? (
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    setIsHashTagInputActive(false);
                  }}>
                  <input
                    className='name'
                    type='text'
                    value={groupHashtags}
                    onBlur={setIsHashTagInputActive.bind(null, false)}
                    onChange={e => setGroupHashtags(e.target.value)}
                  />
                </form>
              ) : (
                <div
                  className={`name input-div ${role !== GroupUserRoles.Admin && 'disabled'}`}
                  onClick={() => {
                    setIsHashTagInputActive(true);
                    filterHashtags(' ');
                  }}>
                  {groupHashtags.match(/(#\w+)/g)?.map(hashtag => (
                    <span key={hashtag} className='hashtag'>
                      {hashtag}
                    </span>
                  ))}
                </div>
              )}
            </div>
            <div className='setting-header'>
              <p className='tip'>Group Members</p>
            </div>
          </div>
        )}

        <GroupMembersList groupId={id} closeModal={onClose} />
      </div>

      <div className='modal-footer'>
        <div className='button-container responsive-container'>
          <button className='button is-secondary' onClick={onClose}>
            Cancel
          </button>
          <button
            disabled={isDemoUser}
            className={`button is-primary ${isDemoUser && 'is-disabled'}`}
            type='submit'
            onClick={saveChanges}>
            Save
          </button>
        </div>
      </div>
    </>
  );
};
