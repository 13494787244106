import { useEffect, useState } from 'react';
import { StatisticsSnapshot } from '../services/statistics-service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { format } from 'date-fns';
import { TIMELINE_TYPE } from '../components/DashboardTimeline';

interface IAmountStats {
  data: StatisticsSnapshot[] | undefined;
  id: number;
  group?: boolean;
}

interface ChartData {
  value: number;
  timestamp: string;
}

const getFullIncrease = (chartData: ChartData[]): number => {
  return chartData.reduce((gen, curr) => (gen += curr.value), 0);
};

const getPercentage = (
  chartData: ChartData[],
  isEngagement: boolean = false
): { increase: number; percentage: string } => {
  const copy = [...chartData];
  const [last, previous] = [copy.pop(), copy.pop()];
  if (!previous?.value || !last?.value)
    return {
      percentage: '0%',
      increase: isEngagement ? 0 : getFullIncrease(chartData),
    };
  return {
    percentage: `${(
      ((last.value - previous.value) / previous.value) *
      100
    ).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })}%`,
    increase: isEngagement ? last.value : getFullIncrease(chartData),
  };
};

export const useAmountStats = ({ data, id }: IAmountStats) => {
  const [rechartData, setRechartData] = useState<
    { value: number; timestamp: string }[]
  >([]);
  const [percentage, setPercentage] = useState<{
    increase: number;
    percentage: string;
  }>({
    increase: 0,
    percentage: '0%',
  });

  const { dashboardTimelineOptions } = useSelector(
    (state: RootState) => state.settings
  );

  const timelineType =
    dashboardTimelineOptions[id]?.type ?? TIMELINE_TYPE.IMPRESSIONS;

  const [likesTimeline, setLikesTimeline] = useState<ChartData[]>([]);
  const [commentsTimeline, setCommentsTimeline] = useState<ChartData[]>([]);
  const [viewsTimeline, setViewsTimeline] = useState<ChartData[]>([]);
  const [impressionsTimeline, setImpressionsTimeline] = useState<ChartData[]>(
    []
  );
  const [engagementTimeline, setEngagementTimeline] = useState<ChartData[]>([]);

  const [generalStats, setGeneralStats] = useState<{
    likes: { increase: number; percentage: string };
    comments: { increase: number; percentage: string };
    views: { increase: number; percentage: string };
    impressions: { increase: number; percentage: string };
    engagement: { increase: number; percentage: string };
  }>({
    likes: { increase: 0, percentage: '0%' },
    comments: { increase: 0, percentage: '0%' },
    views: { increase: 0, percentage: '0%' },
    impressions: { increase: 0, percentage: '0%' },
    engagement: { increase: 0, percentage: '0%' },
  });

  useEffect(() => {
    if (!data || !data.length) {
      setRechartData([]);
      setCommentsTimeline([]);
      setEngagementTimeline([]);
      setLikesTimeline([]);
      setViewsTimeline([]);
      setImpressionsTimeline([]);
      setGeneralStats({
        likes: { increase: 0, percentage: '0%' },
        comments: { increase: 0, percentage: '0%' },
        views: { increase: 0, percentage: '0%' },
        impressions: { increase: 0, percentage: '0%' },
        engagement: { increase: 0, percentage: '0%' },
      });
    } else {
      const likesTimeline: ChartData[] = [];
      const commentsTimeline: ChartData[] = [];
      const viewsTimeline: ChartData[] = [];
      const impressionsTimeline: ChartData[] = [];
      const engagementTimeline: ChartData[] = [];

      let previous: {
        amountLikes: number;
        amountComments: number;
        amountViews: number;
        amountImpressions: number;
      } | null = null;

      data.forEach(entry => {
        if (!entry.timestamp) {
          return;
        }

        const timestamp = format(entry.timestamp, 'yyyy-MM-dd HH:mm');

        if (!previous) {
          likesTimeline.push({ value: 0, timestamp });
          commentsTimeline.push({ value: 0, timestamp });
          viewsTimeline.push({ value: 0, timestamp });
          impressionsTimeline.push({ value: 0, timestamp });
          engagementTimeline.push({
            value:
              Math.round(
                ((entry.amountComments +
                  entry.amountLikes +
                  entry.amountShares) /
                  entry.amountViews) *
                  100 *
                  100
              ) / 100,
            timestamp,
          });
        } else {
          likesTimeline.push({
            value:
              entry.amountLikes - previous.amountLikes > 0
                ? entry.amountLikes - previous.amountLikes
                : 0,
            timestamp,
          });
          commentsTimeline.push({
            value:
              entry.amountComments - previous.amountComments > 0
                ? entry.amountComments - previous.amountComments
                : 0,
            timestamp,
          });
          viewsTimeline.push({
            value:
              entry.amountViews - previous.amountViews > 0
                ? entry.amountViews - previous.amountViews
                : 0,
            timestamp,
          });
          impressionsTimeline.push({
            value:
              entry.amountImpressions - previous.amountImpressions > 0
                ? entry.amountImpressions - previous.amountImpressions
                : 0,
            timestamp,
          });
          engagementTimeline.push({
            value:
              Math.round(
                ((entry.amountComments +
                  entry.amountLikes +
                  entry.amountShares) /
                  entry.amountViews) *
                  100 *
                  100
              ) / 100,
            timestamp,
          });
        }
        previous = {
          amountComments: entry.amountComments,
          amountLikes: entry.amountLikes,
          amountViews: entry.amountViews,
          amountImpressions: entry.amountImpressions,
        };
      });
      setCommentsTimeline(commentsTimeline);
      setEngagementTimeline(engagementTimeline);
      setLikesTimeline(likesTimeline);
      setViewsTimeline(viewsTimeline);
      setImpressionsTimeline(impressionsTimeline);

      setGeneralStats({
        likes: getPercentage(likesTimeline),
        comments: getPercentage(commentsTimeline),
        views: getPercentage(viewsTimeline),
        impressions: getPercentage(impressionsTimeline),
        engagement: getPercentage(engagementTimeline, true),
      });
    }
  }, [data]);

  useEffect(() => {
    switch (timelineType) {
      case TIMELINE_TYPE.COMMENTS: {
        setRechartData(commentsTimeline);
        setPercentage(getPercentage(commentsTimeline));
        break;
      }
      case TIMELINE_TYPE.ENGAGEMENT: {
        setRechartData(engagementTimeline);
        setPercentage(getPercentage(engagementTimeline, true));
        break;
      }
      case TIMELINE_TYPE.VIEWS: {
        setRechartData(viewsTimeline);
        setPercentage(getPercentage(viewsTimeline));
        break;
      }
      case TIMELINE_TYPE.IMPRESSIONS: {
        setRechartData(impressionsTimeline);
        setPercentage(getPercentage(impressionsTimeline));
        break;
      }
      case TIMELINE_TYPE.REACTIONS: {
        setRechartData(likesTimeline);
        setPercentage(getPercentage(likesTimeline));
        break;
      }
      default: {
        throw Error('Invalid type');
      }
    }
  }, [
    timelineType,
    likesTimeline,
    viewsTimeline,
    impressionsTimeline,
    commentsTimeline,
    engagementTimeline,
  ]);

  return {
    timelineType,
    rechartData,
    percentage,
    commentsTimeline,
    likesTimeline,
    viewsTimeline,
    impressionsTimeline,
    engagementTimeline,
    generalStats,
  };
};
