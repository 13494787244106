import React, { useCallback, useEffect, useRef, useState } from 'react';
import ScheduledActivityPopup from './ScheduledActivityPopup';
import { Link, useParams } from 'react-router-dom';
import {
  loadAllGroupScheduledActivities,
  loadAllScheduledActivities,
  POSTS_PER_MONTH,
  POSTS_PER_MONTH_WARNING_LIMIT,
  ScheduledActivity,
} from '../../services/schedule-service';
import '../../styles/components/ScheduleOverview.scss';
import {
  addDays,
  addWeeks,
  endOfMonth,
  format,
  isThisHour,
  isToday,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { endOfWeek } from 'date-fns/esm';
import ms from 'ms';
import MediaImg from '../media/MediaImg';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { store } from '../../store';
import { setCurrentScheduledActivity } from '../../store/actions/scheduledActivities';
import {
  ArrowBack,
  ArrowForward,
  CaretBack,
  CaretForward,
  HelpCircle,
  People,
} from '@styled-icons/ionicons-solid';
import { colors } from '../../styles/variables';
import * as Styled from './ScheduleOverview.styles';
import { demoUserEmail, User, USER_PLAN } from '../../services/user-service';
import { transparentize } from 'polished';

import { GroupTopActivityCard } from '../group/GroupTopActivityCard';
import { Statistics } from '../../services/statistics-service';
import { LinkedInAccount } from '../../services/account-service';
import { useMutation, useQuery } from 'react-query';
import {
  AnalyticJob,
  getAnalyticJob,
  getUserAudiences,
} from '../../services/audience.service';
import { APIError } from '../../services/api-client';
import { AudienceTable } from '../audience/audienceTable/AudienceTable';
import StyledTooltip from '../StyledTooltip';

const setColor = (_score: number): string => {
  let score = Math.trunc((_score - 1) * 100 * 10) / 10;
  let color: string = '';

  if (score <= 10) {
    color = `rgba(14,${150 + score * 10},14,0.08)`;
  }
  if (score > 10 && score <= 20) {
    color = `rgba(14,${150 + score * 5},14,0.25)`;
  } else if (score > 20 && score <= 40) {
    color = `rgba(14,${150 + score * 3.2},14,0.4)`;
  } else if (score > 40 && score <= 60) {
    color = `rgba(14,${130 + score * 2.3},14,0.55)`;
  } else if (score > 60 && score <= 80) {
    color = `rgba(14,${130 + score * 1.5},14,0.7)`;
  }

  return color;
};
const daysArray = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const dayHoursLoop = Array.from(Array(24), (_, i) => {
  const date = new Date();
  date.setHours(i, 0, 0, 0);
  return date;
});

const headerHeight = 80;

const entriesPerPage = 4;

const emptyStatistic: Statistics = {
  amountShares: 0,
  amountViews: 0,
  amountLikes: 0,
  amountImpressions: 0,
  amountComments: 0,
  amountReactionType: [],
};

interface Props {
  group: boolean;
}

const ScheduleOverview: React.FC<Props> = ({ group }) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { accountId, groupId } = useParams<{
    accountId: string;
    groupId: string;
  }>();
  const scheduledActivities = useSelector((state: RootState) => {
    if (!accountId && !groupId) {
      return [];
    }
    return group
      ? state.scheduledActivities.activitiesByGroup[groupId]
      : state.scheduledActivities.activitiesByAccount[accountId];
  });
  const currentScheduledActivity = useSelector((state: RootState) => {
    return state.scheduledActivities.currentScheduledActivity;
  });
  const chosenAnalyticJob = useSelector(
    (state: RootState) => state.scheduledActivities.chosenAnalyticJob
  );
  const [page, setPage] = useState(0);
  const [upcomingPosts, setUpcomingPosts] = useState<ScheduledActivity[]>([]);
  const [currentUpcomingPosts, setCurrentUpcomingPosts] = useState<
    ScheduledActivity[]
  >([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [currentWeekStart, setCurrentWeekStart] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [presetPublishingDate, setPresetPublishingDate] = useState<Date | null>(
    null
  );
  const calendarRef = useRef<HTMLDivElement>(null);
  const [limitBannerText, setLimitBannerText] = useState('');
  const [hitLimit, setHitLimit] = useState(false);
  const [isNearLimit, setIsNearLimit] = useState(false);
  const [analyticJobs, setAnalyticJobs] = useState<AnalyticJob[]>([]);
  const [analyticJobData, setAnalyticJobData] = useState<
    Record<string, { color: string; score: number }>
  >();
  const [isAnalyticJobEnabled, setIsAnalyticJobEnabled] = useState<boolean>(
    true
  );

  useQuery<AnalyticJob[], APIError>('getUserAudiences', getUserAudiences, {
    onSuccess: data => {
      setAnalyticJobs(data);
    },
  });

  const [getAnalyticJobMutate] = useMutation<AnalyticJob, APIError, string>(
    getAnalyticJob,
    {
      onSuccess: data => {
        const newData = data.bestPostTime?.reduce((gen, curr) => {
          const dayNumber = daysArray.indexOf(curr.publicationDay);

          return {
            ...gen,

            [`${dayNumber}-${+curr.publicationHour}`]: {
              color: setColor(curr.Score),
              score: curr.Score,
            },
          };
        }, {} as Record<string, { color: string; score: number }>);
        setAnalyticJobData(newData);
      },
    }
  );

  useEffect(() => {
    if (chosenAnalyticJob)
      getAnalyticJobMutate(chosenAnalyticJob.jobId).catch(e =>
        console.log(e.message)
      );
    else setAnalyticJobData({});
  }, [chosenAnalyticJob, getAnalyticJobMutate]);

  const handleOnPopupClose = () => {
    store.dispatch(setCurrentScheduledActivity(null));
    setPresetPublishingDate(null);
    setPopupOpen(false);
    if (accountId) {
      loadAllScheduledActivities(accountId).catch(console.error);
    }
  };

  const handleOnScheduledActivityClick = (
    scheduledActivity: ScheduledActivity
  ) => {
    store.dispatch(setCurrentScheduledActivity(scheduledActivity));
    setPopupOpen(true);
  };

  const handleOnWeekChange = (change: number) => {
    const newWeekStart = addWeeks(currentWeekStart, change);
    setCurrentWeekStart(newWeekStart);
  };

  const handleOnEmptySlotClick = (presetDate: Date) => {
    setPresetPublishingDate(presetDate);
    setPopupOpen(true);
  };

  const currentWeekDayLoop = Array.from(Array(7), (_, i) =>
    addDays(currentWeekStart, i)
  );

  const scrollToCurrent = () => {
    if (calendarRef.current) {
      const currentHour = new Date(Date.now()).getHours();
      const slotsHeight = calendarRef.current.scrollHeight - headerHeight;
      const scrollFromTop = (slotsHeight / 24) * currentHour;
      const scrollTo =
        scrollFromTop - (calendarRef.current.clientHeight - headerHeight) / 2;
      calendarRef.current.scrollTo({ top: scrollTo });
    }
    setCurrentWeekStart(startOfWeek(new Date(), { weekStartsOn: 1 }));
  };

  useEffect(() => {
    if (!accountId && !groupId) {
      return;
    }
    // Load scheduled activities on startup
    loadAllScheduledActivities(accountId).catch(console.error);
    if (group && groupId)
      loadAllGroupScheduledActivities(groupId).catch(console.error);
    // Scroll container to current time
    scrollToCurrent();
  }, [accountId, group, groupId, calendarRef]);

  function isNil(value: ScheduledActivity[]) {
    return value == null;
  }

  useEffect(() => {
    if (isNil(scheduledActivities)) {
      return;
    }
    const allowedNumOfPosts = POSTS_PER_MONTH[user!.plan];
    const postedThisMonth = scheduledActivities.filter(
      act =>
        act.publishingDate > startOfMonth(new Date()) &&
        act.publishingDate < endOfMonth(new Date())
    ).length;

    setHitLimit(false);

    let bannerText: string = '';
    if (postedThisMonth === 0) {
      bannerText = `You can schedule ${allowedNumOfPosts} posts this month.`;
    } else if (postedThisMonth < allowedNumOfPosts) {
      bannerText = `You scheduled ${postedThisMonth} of ${allowedNumOfPosts} posts this month.`;
      if (
        user!.plan !== USER_PLAN.BUSINESS &&
        user!.plan !== USER_PLAN.APPSUMO
      ) {
        // Users cannot upgrade as business is the highest plan
        bannerText += ` Upgrade to get more.`;
      }
    } else if (postedThisMonth >= allowedNumOfPosts) {
      bannerText = `You already scheduled ${allowedNumOfPosts} posts this month.`;
      if (
        user!.plan !== USER_PLAN.BUSINESS &&
        user!.plan !== USER_PLAN.APPSUMO
      ) {
        // Users cannot upgrade as business is the highest plan
        bannerText += ` Upgrade to get more.`;
      }
      setHitLimit(true);
    }

    setIsNearLimit(
      allowedNumOfPosts - postedThisMonth <=
        POSTS_PER_MONTH_WARNING_LIMIT[user!.plan]
    );

    setLimitBannerText(bannerText);
  }, [scheduledActivities, user]);

  useEffect(() => {
    setUpcomingPosts(
      (scheduledActivities || []).filter(
        post => post.publishingDate.getTime() > Date.now()
      )
    );
  }, [scheduledActivities]);

  useEffect(() => {
    setCurrentUpcomingPosts(
      [...(upcomingPosts || [])]
        .sort((a, b) => a.publishingDate.getTime() - b.publishingDate.getTime())
        .filter(post => post.publishingDate.getTime() > Date.now())
        .slice(page * entriesPerPage, page * entriesPerPage + entriesPerPage)
    );
  }, [page, upcomingPosts]);

  const isDemoUser = useCallback(
    (account: LinkedInAccount | User) => account.email === demoUserEmail,
    []
  );

  return (
    <div className='ScheduleOverview'>
      {limitBannerText && !group && (
        <Styled.LimitBanner
          bgColor={transparentize(
            0.8,
            isNearLimit ? colors.lightOrange : colors.green
          )}
        >
          <span>{limitBannerText}</span>
          {user!.plan !== USER_PLAN.BUSINESS &&
            user!.plan !== USER_PLAN.APPSUMO && (
              <Link to='/upgrade' className='button is-contrast ml-4'>
                Upgrade
              </Link>
            )}
        </Styled.LimitBanner>
      )}
      <section className='section columns top-bar'>
        <div className='column'>
          <div className='field has-addons date-control'>
            <div className='control'>
              <div
                className='button is-secondary'
                onClick={() => handleOnWeekChange(-1)}
              >
                <span className='icon'>
                  <ArrowBack size='1rem' />
                </span>
              </div>
            </div>
            <div className='control'>
              <div className='button is-static date-block'>
                {format(currentWeekStart, 'MMM do Y')} &minus;{' '}
                {format(endOfWeek(currentWeekStart), 'MMM do Y')}{' '}
              </div>
            </div>
            <div className='control'>
              <div
                className='button is-secondary'
                onClick={() => handleOnWeekChange(1)}
              >
                <span className='icon'>
                  <ArrowForward size='1rem' />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='column'>
          <button className='button is-outlined' onClick={scrollToCurrent}>
            Today
          </button>
        </div>
        <>
          <div className='is-flex is-align-items-center mr-4 has-text-dark-blue has-text-weight-bold'>
            <label className='switch mx-3'>
              <input
                type='checkbox'
                className='tool-input'
                defaultChecked={isAnalyticJobEnabled}
                onChange={e => setIsAnalyticJobEnabled(e.target.checked)}
              />
              <span className='slider round' />
            </label>
            <p>Best time to post</p>
            <HelpCircle
              size='1.5rem'
              className='ml-2'
              color={colors.grey}
              data-for='audience-tooltip'
              data-tip='Based on your target audience, we search for patterns in our database to display the times when we think the potential of getting more Views with your post is the highest.

The timezone displayed is based on the one you have chosen in your settings. The heatmap recommendations will be based on when we think you should publish your post to get views in the location of the defined audience.'
            />
            <StyledTooltip id='audience-tooltip' />
          </div>
          <div className='navbar-item has-dropdown is-hoverable right-btn'>
            <div className='navbar-link audience-dropdown'>
              <People className='icon mr-3 has-text-grey' />
              <div className='name'>
                {chosenAnalyticJob?.name || '- none -'}
              </div>
            </div>
            <div className='navbar-dropdown audience-wrapper'>
              <div className='audience-block has-text-grey'>
                <p className='has-font-size-3 pl-4 pt-4'>Saved Audiences</p>
                <AudienceTable
                  analyticJobs={analyticJobs}
                  skipActions
                  selectable
                  isNoneActive
                />
                {/*<p className='p-4'>*/}
                {/*  <span className='has-text-weight-bold'>Info</span>: Manage*/}
                {/*  your Audiences in the <Link to='/settings'>settings</Link>*/}
                {/*</p>*/}
              </div>
            </div>
          </div>
          <div className='column is-narrow right-btn'>
            <button
              className='button is-primary'
              disabled={hitLimit}
              onClick={() => setPopupOpen(true)}
            >
              Schedule post
            </button>
          </div>
        </>
      </section>
      {scheduledActivities && (
        <section className='section'>
          <div className='box calendar' ref={calendarRef}>
            {/* Headers */}
            <div className='header'></div>
            {Array.from(Array(7), (_, i) => i).map(i => {
              const date = addDays(currentWeekStart, i);
              return (
                <div
                  key={`header-${i}`}
                  className={classNames('header', {
                    'has-text-primary': isToday(date),
                  })}
                  style={{ height: headerHeight }}
                >
                  {format(date, 'E, do')}
                </div>
              );
            })}
            {/* Cotent rows */}
            {dayHoursLoop.map(currentHour => {
              return [
                <div key={`header-${currentHour}`} className='time is-size-7'>
                  {format(currentHour, 'HH:mm')}
                </div>,
                currentWeekDayLoop.map(currentDay => {
                  const thisHour = new Date(currentDay.getTime());
                  thisHour.setHours(currentHour.getHours(), 0, 0, 0);
                  const nextHour = new Date(thisHour.getTime() + ms('1hour'));
                  const isInPast = nextHour.getTime() < Date.now();
                  const foundActivities = scheduledActivities.filter(
                    act =>
                      act.publishingDate.getTime() >= thisHour.getTime() &&
                      act.publishingDate.getTime() < nextHour.getTime()
                  );
                  const isSameDayAndHour = isThisHour(thisHour);
                  const isSameDayOrHour =
                    currentHour.getHours() === new Date().getHours() ||
                    isToday(thisHour);

                  if (foundActivities.length) {
                    // We have an active activity in this time slot
                    return (
                      <div
                        key={`header-${currentDay}`}
                        className={classNames('slot filled', {
                          'is-same-day-and-hour': isSameDayAndHour,
                          'is-same-day-or-hour': isSameDayOrHour,
                        })}
                      >
                        {foundActivities.map(act => {
                          const isPosted = !!act.postUrn;
                          const mayRetry =
                            act.publishingDate.getTime() < Date.now();
                          return (
                            <div
                              key={act._id}
                              className={classNames('activity', {
                                posted: isPosted,
                                retry: !isPosted && mayRetry,
                              })}
                              onClick={() =>
                                handleOnScheduledActivityClick(act)
                              }
                            >
                              <div className='commentary is-size-7 truncate'>
                                {act.commentary}
                              </div>
                              <div className='media'>
                                {act.media.length > 0 && (
                                  <MediaImg media={act.media[0]} />
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  } else {
                    // Nothing here, empty slot
                    const job = analyticJobData
                      ? analyticJobData[
                          `${currentDay.getDay()}-${currentHour.getHours()}`
                        ]
                      : { color: '', score: 1 };
                    return (
                      <div
                        key={`header-${currentDay}`}
                        className={classNames(`slot empty`, {
                          'is-same-day-and-hour': isSameDayAndHour,
                          'is-same-day-or-hour': isSameDayOrHour,
                          'is-in-past': isInPast,
                          disabled: group,
                        })}
                        style={{
                          backgroundColor: isAnalyticJobEnabled
                            ? job?.color
                            : '',
                        }}
                        onClick={() =>
                          !isInPast &&
                          !group &&
                          handleOnEmptySlotClick(thisHour)
                        }
                      />
                    );
                  }
                }),
              ];
            })}
          </div>
        </section>
      )}

      {group && currentUpcomingPosts?.length && (
        <section className='section'>
          <h5 className='title is-size-5 has-text-grey has-text-weight-normal'>
            Upcoming Posts
          </h5>
          <div className='top-activity-card-container'>
            <button
              className='button button-pagination is-left'
              onClick={() => setPage(page - 1)}
              disabled={page === 0}
            >
              <CaretBack className='icon has-text-grey' />
            </button>

            {currentUpcomingPosts.map(post => (
              <GroupTopActivityCard
                key={post._id}
                statistics={emptyStatistic}
                activity={post}
                user={
                  isDemoUser(post.author)
                    ? post.author
                    : post.account || post.author
                }
                onClick={handleOnScheduledActivityClick.bind(null, post)}
                scheduled
              />
            ))}

            <button
              className='button button-pagination is-right'
              onClick={() => setPage(page + 1)}
              disabled={(page + 1) * entriesPerPage >= upcomingPosts.length}
            >
              <CaretForward className='icon has-text-grey' />
            </button>
          </div>
        </section>
      )}

      {(popupOpen || currentScheduledActivity) && (
        <ScheduledActivityPopup
          scheduledActivityId={currentScheduledActivity?._id}
          onClose={handleOnPopupClose}
          presetPublishingDate={presetPublishingDate}
          isEditable={!group}
          isRetry={
            currentScheduledActivity &&
            currentScheduledActivity.publishingDate.getTime() < Date.now()
          }
        />
      )}
    </div>
  );
};

export default ScheduleOverview;
