// import { Trash } from '@styled-icons/ionicons-solid';
import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';
import * as Styled from './Button.styles';
import styles from './Button.module.scss';

export const Button = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <Styled.Button {...props} className={classNames('button is-outlined', props.className)}>
      {props.children}
    </Styled.Button>
  );
};

export const ButtonBlueOutline = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return <button className={styles.buttonBlueOutline}>{props.children}</button>;
};
