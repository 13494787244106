import React, { useState } from 'react';
import Check from '../assets/images/check.svg';
import Cross from '../assets/images/cross.svg';
import {
  LinkedInAccount,
  updateMainAccount,
} from '../services/account-service';
import styled from 'styled-components';
import { Open, Refresh } from '@styled-icons/ionicons-solid';
import { useQuery } from 'react-query';
import ms from 'ms';
import { APIError } from '../services/api-client';
import classNames from 'classnames';
import LinkAccountManually from './LinkAccountManually';

const StepGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1.5rem auto;
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.25rem;
  margin-top: 1.5rem;

  &:first-child {
    margin-top: 0;
  }
`;

const StatusCheckbox = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #919fa9;
  border-radius: 50%;
`;

const StepText = styled.p`
  grid-area: 1 / 2 / 2 / 3;
`;

const Buttons = styled.div`
  grid-area: 2 / 2 / 2 / 3;
`;

interface HowToLinkAccountProps {
  isConnected: boolean;
  setIsConnected: React.Dispatch<React.SetStateAction<boolean>>;
  isInstalled: boolean;
  setIsInstalled: React.Dispatch<React.SetStateAction<boolean>>;
}

function pingExtension(): Promise<boolean> {
  if (process.env.REACT_APP_EXTENSION_ID === '') {
    // In specific situations, e.g. the staging environment, we would like to skip this check
    return Promise.resolve(true);
  }
  return new Promise(resolve => {
    try {
      chrome.runtime.sendMessage(
        process.env.REACT_APP_EXTENSION_ID ?? '',
        {
          check: 'install',
        },
        (response: boolean) => {
          // Add a bit of artifical delay since this request is almost instant and feels weird to the user
          setTimeout(() => {
            if (chrome.runtime.lastError) {
              return resolve(false);
            }
            return resolve(response);
          }, 500);
        }
      );
    } catch (e) {
      return resolve(false);
    }
  });
}

const HowToLinkAccount: React.FC<HowToLinkAccountProps> = ({
  isConnected,
  isInstalled,
  setIsConnected,
  setIsInstalled,
}) => {
  const [manuallyTestedAccount, setManuallyTestedAccount] = useState(false);
  const { refetch: checkExtension, isLoading: isLoadingExtension } = useQuery<
    boolean,
    Error
  >('pingExtension', pingExtension, {
    enabled: !isInstalled,
    onSuccess: response => setIsInstalled(!!response),
    onError: () => setIsInstalled(false),
    retry: false,
    staleTime: ms('3sec'),
    refetchInterval: ms('3sec'),
  });
  const {
    data: mainAccount,
    refetch: checkAccount,
    isFetching: isFetchingAccount,
    isLoading: isLoadingAccount,
    error: errorAccount,
  } = useQuery<LinkedInAccount | null, APIError>(
    'updateMainAccount-test',
    updateMainAccount,
    {
      onSuccess: account => {
        setIsConnected(!!account);
      },
    }
  );

  return (
    <div className='HowToLinkAccount'>
      <StepGrid>
        <StatusCheckbox>
          <img
            src={isInstalled ? Check : Cross}
            alt={isInstalled ? 'install check' : 'install failed'}
          />
        </StatusCheckbox>
        <StepText>Install the inlytics Chrome extension</StepText>
        <Buttons className='buttons'>
          <a
            className='button is-outlined'
            href={`https://chrome.google.com/webstore/detail/${process.env.REACT_APP_EXTENSION_ID}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <span className='icon'>
              <Open size='1rem' />
            </span>
            <span>Chrome Webstore</span>
          </a>
          <button
            className={classNames('button is-outlined', {
              'is-loading': isLoadingExtension,
            })}
            disabled={isLoadingExtension}
            onClick={() => checkExtension()}
          >
            <span className='icon'>
              <Refresh size='1rem' />
            </span>
            <span>Check extension</span>
          </button>
        </Buttons>
      </StepGrid>

      <StepGrid>
        <StatusCheckbox>
          <img
            src={isConnected ? Check : Cross}
            alt={isConnected ? 'connected check' : 'connected failed'}
          />
        </StatusCheckbox>
        <StepText>
          Test if your LinkedIn account is connected
          <LinkAccountManually />
        </StepText>
        <Buttons>
          <button
            className={classNames('button is-outlined', {
              'is-loading': isLoadingAccount || isFetchingAccount,
            })}
            onClick={() => {
              checkAccount();
              setManuallyTestedAccount(true);
            }}
            disabled={isLoadingAccount || isFetchingAccount}
          >
            <span className='icon'>
              <Refresh size='1rem' />
            </span>
            <span>Check connection</span>
          </button>
        </Buttons>
      </StepGrid>

      {manuallyTestedAccount && mainAccount === null && (
        <div className='notification is-danger is-light mt-5'>
          Please make sure that you've opened the inlytics Chrome extension and
          clicked the "Connect" button.
        </div>
      )}

      <p className='has-text-grey mt-4'>
        Make sure that you are logged into your account that you want to
        connect. <b>Info</b>: It’s normal that you get disconnected from your
        LinkedIn account as soon as the connection to inlytics is made.
      </p>

      {errorAccount && (
        <div className='notification is-danger'>{errorAccount.message}</div>
      )}
    </div>
  );
};

export default HowToLinkAccount;
