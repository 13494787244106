import React from 'react';
import DashboardCard, { CardVariant } from './DashboardCard';
import {
  ActivityStatistics,
  loadActivityStatisticsForAccount,
  Statistics,
  StatisticsSnapshot,
} from '../services/statistics-service';
import '../styles/components/DashboardStatisticCards.scss';
// import { useAmountStats } from '../hooks/useAmountStats';
import { helpTexts } from '../helpers/helpTexts';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { ActivityHighlights } from '../services/activities-service';

export interface DashboardStatisticCardsProps {
  statistics?: Statistics;
  referenceStatistics?: Statistics;
  amountPosts?: number;
  amountShares?: number;
  pageViews?: { number: number; percentage: number };
  reactions?: { number: number; percentage: number };
  comments?: { number: number; percentage: number };
  shares?: { number: number; percentage: number };
  uniqueVisitors?: { number: number; percentage: number };
  buttonClicks?: { number: number; percentage: number };
  totalFollowers?: { number: number; percentage: number };
  newFollowers?: number;
  cardFilter?: CardVariant[];
  data?: StatisticsSnapshot[] | undefined;
  extraClassName?: string;
}

export type ReferenceStatisticsType = 'positive' | 'negative' | 'neutral';

const getReferenceType = (value: number): ReferenceStatisticsType => {
  return value > 0 ? 'positive' : value < 0 ? 'negative' : 'neutral';
};

export interface ReferenceStatistics {
  amountComments: {
    value: string;
    type: ReferenceStatisticsType;
  };
  amountLikes: {
    value: string;
    type: ReferenceStatisticsType;
  };
  amountViews: {
    value: string;
    type: ReferenceStatisticsType;
  };
  amountImpressions: {
    value: string;
    type: ReferenceStatisticsType;
  };
  amountShares: {
    value: string;
    type: ReferenceStatisticsType;
  };
}

function getChangeInPercentage(
  currentValue: number,
  referenceValue: number
): { value: string; type: ReferenceStatisticsType } {
  if (currentValue === 0 || referenceValue === 0) {
    return {
      value: Number(0).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }),
      type: 'neutral',
    };
  }
  const change = (currentValue - referenceValue) / referenceValue;
  const negative = referenceValue > currentValue;
  return {
    value: Math.abs(Math.round(change * 100) / 100).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }),
    type: negative ? 'negative' : 'positive',
  };
}

const DashboardStatisticCards: React.FC<DashboardStatisticCardsProps> = ({
  statistics,
  referenceStatistics,
  amountPosts,
  amountShares,
  pageViews,
  uniqueVisitors,
  buttonClicks,
  reactions,
  comments,
  shares,
  totalFollowers,
  newFollowers,
  cardFilter,
  data,
  extraClassName,
}) => {
  // const { generalStats } = useAmountStats({ data, id: 1 });
  const { accountId } = useParams<{ accountId: string }>();
  const { startDate, endDate } = useSelector(
    (state: RootState) => state.dateRangeFilter
  );
  const { data: tempActivities, isLoading } = useQuery(
    [`statistics-temp-${startDate}-${endDate}`],
    () => loadActivityStatisticsForAccount(accountId, startDate, endDate)
  );

  const { activities } = tempActivities || {
    activities: new Array<ActivityStatistics>(),
    statistics: new Array<ActivityHighlights>(),
  };

  const tempViews = activities.reduce(
    (acc, act) => acc + act.statistics.amountViews,
    0
  );
  const tempImpressions = activities.reduce(
    (acc, act) => acc + act.statistics.amountImpressions,
    0
  );
  const tempReactions = activities.reduce(
    (acc, act) => acc + act.statistics.amountLikes,
    0
  );
  const tempComments = activities.reduce(
    (acc, act) => acc + act.statistics.amountComments,
    0
  );
  const tempShares = activities.reduce(
    (acc, act) => acc + act.statistics.amountShares,
    0
  );

  let engagementRate = 0;
  // if (statistics && statistics.amountViews > 0) {
  //   engagementRate =
  //     (statistics.amountComments +
  //       statistics.amountLikes +
  //       statistics.amountShares) /
  //     statistics.amountViews;
  // }
  if (tempViews > 0) {
    engagementRate = (tempComments + tempReactions + tempShares) / tempViews;
  }
  let referenceDifference: ReferenceStatistics | null = null;
  // if (statistics && referenceStatistics) {
  //   referenceDifference = {
  //     amountComments: getChangeInPercentage(
  //       statistics.amountComments,
  //       referenceStatistics.amountComments
  //     ),
  //     amountLikes: getChangeInPercentage(
  //       statistics.amountLikes,
  //       referenceStatistics.amountLikes
  //     ),
  //     amountViews: getChangeInPercentage(
  //       statistics.amountViews,
  //       referenceStatistics.amountViews
  //     ),
  //     amountImpressions: getChangeInPercentage(
  //       statistics.amountImpressions,
  //       referenceStatistics.amountImpressions
  //     ),
  //     amountShares: getChangeInPercentage(
  //       statistics.amountShares,
  //       referenceStatistics.amountShares
  //     ),
  //   };
  // }
  if (referenceStatistics) {
    referenceDifference = {
      amountComments: getChangeInPercentage(
        tempComments,
        referenceStatistics.amountComments
      ),
      amountLikes: getChangeInPercentage(
        tempReactions,
        referenceStatistics.amountLikes
      ),
      amountViews: getChangeInPercentage(
        tempViews,
        referenceStatistics.amountViews
      ),
      amountImpressions: getChangeInPercentage(
        tempImpressions,
        referenceStatistics.amountImpressions
      ),
      amountShares: getChangeInPercentage(
        tempShares,
        referenceStatistics.amountShares
      ),
    };
  }

  const {
    engagementRateCard,
    commentsCard,
    postImpressionsCard,
    postViewsCard,
    reactionsCard,
  } = helpTexts;

  return (
    <div className={`DashboardStatisticCards ${extraClassName}`}>
      <div className={`card-wrapper grid-columns-${cardFilter?.length}`}>
        {(!cardFilter || cardFilter.includes('views')) && statistics && (
          <DashboardCard
            title='Post Views'
            variant='views'
            helpText={postViewsCard}
            updateInterval='30 min'
            value={
              // data ? generalStats?.views?.increase :
              // statistics.amountViews
              isLoading ? '...' : statistics.amountViews
            }
            referenceValue={referenceDifference?.amountViews}
          />
        )}
        {(!cardFilter || cardFilter.includes('impressions')) && statistics && (
          <DashboardCard
            title='Post Impressions'
            variant='impressions'
            helpText={postImpressionsCard}
            updateInterval='30 min'
            value={
              // data
              // ? generalStats?.impressions?.increase
              // :
              // statistics.amountImpressions
              isLoading ? '...' : statistics.amountImpressions
            }
            referenceValue={referenceDifference?.amountImpressions}
          />
        )}
        {(!cardFilter || cardFilter.includes('likes')) && statistics && (
          <DashboardCard
            key='Reactions'
            title='Reactions'
            variant='likes'
            helpText={reactionsCard}
            updateInterval='30 min'
            value={
              // data ? generalStats?.likes?.increase :
              // statistics.amountLikes
              isLoading ? '...' : statistics.amountLikes
            }
            referenceValue={referenceDifference?.amountLikes}
            // commented till not fixed
            // reactionAmountMap={statistics.amountReactionType}
          />
        )}
        {(!cardFilter || cardFilter.includes('comments')) && statistics && (
          <DashboardCard
            key='Comments'
            title='Comments'
            variant='comments'
            helpText={commentsCard}
            updateInterval='30 min'
            value={
              // data
              //   ? generalStats?.comments?.increase
              //   : statistics.amountComments
              isLoading ? '...' : statistics.amountComments
            }
            referenceValue={referenceDifference?.amountComments}
          />
        )}
        {(!cardFilter || cardFilter.includes('shares')) && statistics && (
          <DashboardCard
            key='Shares'
            title='Shares'
            variant='shares'
            value={isLoading ? '...' : statistics.amountShares}
            referenceValue={referenceDifference?.amountShares}
          />
        )}
        {(!cardFilter || cardFilter.includes('engagement')) && statistics && (
          <DashboardCard
            key='Engagement rate'
            title='Engagement rate'
            variant='engagement'
            helpText={engagementRateCard}
            updateInterval='Calculated live based on the activities'
            value={
              // data
              //   ? `${generalStats?.engagement?.increase}%`
              //   :
              isLoading
                ? '...'
                : `${(engagementRate * 100).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}%`
            }
          />
        )}

        {cardFilter && cardFilter.includes('pageViews') && pageViews && (
          <DashboardCard
            key='Page Views'
            title='Page Views'
            variant='pageViews'
            value={`${pageViews.number}`}
            referenceValue={{
              value: `${pageViews.percentage}`,
              type: getReferenceType(pageViews.percentage),
            }}
          />
        )}

        {cardFilter && cardFilter.includes('uniqueVisitors') && uniqueVisitors && (
          <DashboardCard
            key='Unique Visitors'
            title='Unique Visitors'
            variant='uniqueVisitors'
            value={`${uniqueVisitors.number}`}
            referenceValue={{
              value: `${uniqueVisitors.percentage}`,
              type: getReferenceType(uniqueVisitors.percentage),
            }}
          />
        )}

        {cardFilter && cardFilter.includes('buttonClicks') && buttonClicks && (
          <DashboardCard
            key='Button Clicks'
            title='Button Clicks'
            variant='buttonClicks'
            value={`${buttonClicks.number}`}
            referenceValue={{
              value: `${buttonClicks.percentage}`,
              type: getReferenceType(buttonClicks.percentage),
            }}
          />
        )}

        {cardFilter && cardFilter.includes('reactions') && reactions && (
          <DashboardCard
            key='Reactions'
            title='Reactions'
            variant='reactions'
            value={`${reactions.number}`}
            referenceValue={{
              value: `${reactions.percentage}`,
              type: getReferenceType(reactions.percentage),
            }}
          />
        )}

        {cardFilter && cardFilter.includes('comments') && comments && (
          <DashboardCard
            key='Comments'
            title='Comments'
            variant='comments'
            value={`${comments.number}`}
            referenceValue={{
              value: `${comments.percentage}`,
              type: getReferenceType(comments.percentage),
            }}
            disableIcon
          />
        )}

        {cardFilter && cardFilter.includes('shares') && shares && (
          <DashboardCard
            key='Shares'
            title='Shares'
            variant='shares'
            value={`${shares.number}`}
            referenceValue={{
              value: `${shares.percentage}`,
              type: getReferenceType(shares.percentage),
            }}
          />
        )}

        {cardFilter && cardFilter.includes('totalFollowers') && totalFollowers && (
          <DashboardCard
            key='Total Followers'
            title='Total Followers'
            variant='totalFollowers'
            value={`${totalFollowers.number}`}
            referenceValue={{
              value: `${totalFollowers.percentage.toFixed(2)}`,
              type: getReferenceType(totalFollowers.percentage),
            }}
          />
        )}

        {cardFilter && cardFilter.includes('newFollowers') && (
          <DashboardCard
            key='New Followers in the last 30 days'
            title='New Followers in the last 30 days'
            variant='newFollowers'
            value={`${newFollowers}`}
          />
        )}

        {amountPosts !== undefined &&
          (!cardFilter || cardFilter.includes('posts')) && (
            <DashboardCard
              key='Posts'
              title='Posts'
              variant='posts'
              value={amountPosts}
            />
          )}
        {amountShares !== undefined &&
          (!cardFilter || cardFilter.includes('articles')) && (
            <DashboardCard
              key='Articles'
              title='Articles'
              variant='articles'
              value={amountShares}
            />
          )}
      </div>
    </div>
  );
};

export default DashboardStatisticCards;
